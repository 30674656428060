.chat-container.non-battle-chat {

  .chatbody.chatVC {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    position: static !important;
    max-height: none;
    font-weight: 100;
    font-size: 14px;
    font-family: 'Open Sans';
    overflow: auto;

    .chat-room {
      display: flex;
      flex-direction: column;
      flex: 1;
      height: 100%;

      &__center {
        display: flex;
        flex-direction: column;
        flex: 1;
        height: 100%;
        overflow: auto;

        .message-list {
          flex: 1;
          height: 100%;
          padding: 10px;

          .chat-message {
            font-weight: normal;
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 19px;
            margin: 0 0 10px 0;

            &.chat-date {
              margin: 10px 0;
              display: block;
              text-align: center;
              font-size: 12px;
              line-height: 16px;
              color: rgba(255, 255, 255, .6);
            }

            .chat-message__author {
              font-weight: normal;
            }

            .chat-message-date {
              color: rgba(255, 255, 255, .6);
            }

            .chat-message__text {
              line-height: 19px;
            }

            .chat-message__wrapper {
              width: auto;
            }
          }
        }

        .message-input {

          .filter-input {
            height: 30px;

            .message-input__text {
              border-right: none;
            }
          }
        }
      }
    }
  }

  .chat-room__container {
    overflow: hidden !important;
  }

  .chat-room__container,
  .chat-room__list,
  .chat-room__private_rooms,
  .chat-room__empty_container {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .chat-room__list {
    overflow: auto;
  }

  .chat-room__private_rooms {
    overflow: auto;
    flex: 0 0 auto;
  }

  .chat-room__empty_container {
    justify-content: center;
    align-items: center;
  }

  .chat-room__no_data_found {
    font-style: normal;
    font-weight: normal;
    color: #FFFFFF;
    text-align: center;
    max-width: 300px;

    &__description {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      margin-top: 25px;
    }
  }

  .header {
    position: relative;
    height: 48px;
    background: #414D5C;
    align-items: center;
    flex-direction: row;
    display: flex;
    padding-left: 50px;
    justify-content: flex-start;
    width: calc(100% - 51px);

    &__text {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: calc(100% - 35px);
      .user-photo {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        margin-right: 6px;
      }
      & .user-name {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }      

    .chat-back-arrow {
      position: absolute;
      width: 21px;
      height: 100%;
      padding: 0 15px;
      top: 0;
      left: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;

      svg path {
        fill: #F65932;
      }
    }
  }

  .chat-room__item-userphoto {
    margin-right: 10px;
    margin-top: 5px;
    border-radius: 50%;
    padding: 2px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: none;
    max-height: 36px;
    position: relative;

    &.active {
      border: 2px solid #0ABB31;
    }

    img {
      width: 36px;
      height: 36px;
      border: 0;
      padding: 0;
      border-radius: 50%;
      overflow: hidden;

      &:hover {
        cursor: pointer;
      }
    }

    .avgScore {
      color: #fff;
      font-size: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      height: 14px;
      width: 25px;
      background: #252B32;
      border-radius: 7px;
      position: absolute;
      bottom: 0;
      left: -10px;
    }
  }

  // .chat-room__list {
  //   margin: 0;
  //   padding: 0;
  //   list-style: none;
  // }

  .chat-room__item {
    display: flex;
    font-family: 'Open Sans', sans-serif;
    padding: 10px 15px;
    background: #414C59;
    margin: 3px 0;
    align-items: flex-start;
    cursor: pointer;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &.battle {
      background: #F65932;
      margin-bottom: 6px;
      & .badge {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        line-height: 12px;
        width: 16px;
        height: 16px;
        max-width: 16px;
        min-width: 16px;
        border-radius: 50%;
        background-color: #fff !important;
        color: #F65932;
      }
      & .chat-room__item-message {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    &.support {
      background: #20A39E;

      .chat-room__item-message {

        &-bottom {

          .badge {
            background-color: #fff !important;
            color: #20a39e;
          }
        }
      }
    }

    .chat-room__item-message {
      flex: 1;

      .chat-room__item-message-top {
        opacity: 0.6;
        font-size: 12px;
        flex: 1;
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        line-height: 19px;
      }

      .chat-room__item-message-bottom {
        font-size: 14px;
        flex: 1;
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        line-height: 19px;
        align-items: center;

        .badge {
          background-color: #f65932;
          font-size: 10px;
          height: 16px;
          line-height: 16px;
          min-width: 16px;
          border-radius: calc(16px / 2);
          text-align: center;
          margin-left: 14px;
        }
      }
    }

    .chat-room__item-message * {
      margin: 2px 0;
    }
  }

  .menu-header {
    border-bottom: 1px solid rgba(255, 255, 255, .2) !important;
  }

  .search {
    padding: 10px 15px;
    height: 30px;
    display: flex;
    background: #414C59;

    .filter-input {
      width: 100%;
      display: flex;
    }

    .input {
      width: 100%;
      color: white;
      opacity: 0.4;
      border-width: 1px;
      padding-left: 10px;
      font-size: 14px;
      flex: 1;

      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: #fff;
      }

      &::-moz-placeholder {
        /* Firefox 19+ */
        color: #fff;
      }

      &:-ms-input-placeholder {
        /* IE 10+ */
        color: #fff;
      }

      &:-moz-placeholder {
        /* Firefox 18- */
        color: #fff;
      }
    }
  }
}

.user-photo__container {
  position: relative;
}