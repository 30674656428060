.account_page {
  position: absolute;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  .account_page__container {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 100%;
    flex: 1;
    overflow: hidden;

    .account_page__right_sidebar {
      position: absolute;
      top: 0;
      right: -25vw;
      @media screen and (max-width: 1124px) {
        right: -281px;
      }
      width: 25vw;
      height: 100%;
      min-width: 281px;
      overflow: hidden;
      border-left: 1px solid #25292C;
      box-shadow: -10px 0px 10px rgba(0, 0, 0, 0.15);
      background: #25292c;
      box-sizing: border-box;
      transition: right .3s linear;
      z-index: 20;

      &.shown {
        right: 0;
      }

      .menu-header {
        width: 100%;
        font-size: 20px;
        height: 50px;
        margin-top: -1px;
        box-sizing: border-box;
        line-height: normal;
        background-color: #414d5c;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top: 1px solid #626567;
        border-bottom: 1px solid #626567;
        position: relative;
        font-family: Oswald;
        font-weight: 500;
        text-transform: uppercase;
      }
    }

    .account_page__content {
      flex: 1;
      overflow: auto;
    }

    .account_page__left_sidebar {
      min-width: 210px;
      border-right: 1px solid #25292C;
      overflow: auto;

      .account_page__menu_item {
        position: relative;
        font-family: Oswald;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        text-transform: uppercase;
        color: rgba(256,256,256, .6);
        padding: 10px 20px;
        white-space: nowrap;
        display: block;

        &.parent {
          cursor: pointer;

          .account_page__menu_item__childs {
            display: none;
            margin-left: -20px;

            .account_page__menu_item.child {
              padding-left: 40px;
            }
          }

          &.active {

            .account_page__menu_item__childs {
              display: block;
            }

            .account_page__menu_item__title>span::after {
              border-color: #F65932 transparent transparent transparent;
            }
          }

          .account_page__menu_item__title {

            &>span {
              position: relative;
              display: inline-block;

              &::after {
                content: '';
                position: absolute;
                top: 12px;
                left: calc(100% + 10px);
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 9px 8px 0 8px;
                border-color: rgba(256,256,256, .6) transparent transparent transparent;
              }
            }
          }
        }

        &.active {
          color: #F65932;

          &:not(.parent)::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 3px;
            height: 100%;
            background: #F65932;
          }
        }
      }
    }
  }

  .account_page__footer {
    height: 42px;
    border-top: 1px solid #25292C;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .account_page__sidebar_items {
      display: flex;
      flex-direction: row;
      width: 25vw;
      min-width: 281px;

      .account_page__sidebar_item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1;
        border-left: 1px solid #25292C;
        box-sizing: border-box;
        cursor: pointer;

        svg path {
          fill: #6C7076;
        }
  
        &.active {
          background: #25292C;

          svg path {
            fill: #F65932;
          }
        }
      }
    }
  }
}

.friends-filter {
  border-bottom: 1px solid #25292c;
}

.friends-filter__container {
  width: 60%;
  padding: 0px 20px 10px 20px;
  box-sizing: border-box;
}

.friends-filter__container {
  display: flex;
}

.friends-filter__input {
  flex: 1 1 33.33%;
  box-sizing: border-box;
}
.friends-filter__input .filter-input .filter-input__data {
  box-sizing: border-box;
  height: 30px !important;
  width: 100%;
}
.friends-filter__selectcountry {
  margin-left: 20px;
  flex: 1 1 33.33%;
}

.friends-filter__selectgender {
  margin-left: 20px;
  flex: 1 1 33.33%;
}

.username__container {
  align-items: center;
  display: flex;
}

.img__container {
  width: 30px;
  border-radius: 30px;
  margin-right: 10px;
}
