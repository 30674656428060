.analytic_container {
  max-width: 1000px;
  display: flex;
  margin: auto;
  flex-direction: column;
}

.btnAnalyticsBc {
  min-width: 120px;
  max-height: 33px;
}

.analyticDate_container{
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
}
.date-group__elements__picker {
padding-left: 0px;
}

.analyticSum_container{
  display: flex;
  justify-content: flex-end;
  background-color: crimson;
}

.p_wrapper-UniqueMember {
  min-width: 218px;
}

.p_wrapper-CountGame {
  min-width: 157px;
}

.p_wrapper-EndedGame {
  min-width: 175px;
}
