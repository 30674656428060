#user-info-panel {
  width: 25vw;
  min-width: 281px;
  display: flex;
  align-items: center;
  padding: 13px 15px 13px 15px;
  box-sizing: border-box;
}

#user-info-panel .user-info {
  padding-left: 22px;
}

#user-info-panel .avatar {
  border-radius: 50%;
  background: rgb(246,89,50);
  background: linear-gradient(180deg, rgba(246,89,50,1) 0%, rgba(204,53,53,1) 100%, rgba(0,212,255,1) 100%);
  padding: 3px;
  box-sizing: border-box;
}

#user-info-panel .avatar > img {
  width: 100%;
  border-radius: 50%;
}

#user-info-panel .avatar > svg {
  display: none;
}

#user-info-panel .avatar .avatar__flag {
  top: 56px;
  left: 63px;
  right: auto;
  bottom: auto;
  border-radius: 50%;
}

#user-info-panel label {
  font-size: 14px;
  font-weight: 300;
  color: rgba(255,255,255,0.4);
}

#user-info-panel .user-info__name {
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 2px;
}

#user-info-panel .user-info__login {
  font-size: 12px;
  font-weight: 300;
  color: #f65932;
  margin-bottom: 6px;
}

#user-info-panel .user-info__balance {
  display: flex;
  flex-direction: column;
  font-size: 14px;
}
#user-info-panel .user-info__balance label {
  margin-right: 10px;
}
#user-info-panel .user-info__balance__button-cash {
  width: 65px;
  margin-top: 10px;
}


#user-info-panel .user-info__status {

}
#user-info-panel .user-info__status label {
  margin-right: 5px;
}
