.ant-time-picker-panel {
  font-size: 14px;
  font-variant: tabular-nums;
  /* line-height: 1.5; */
  color: #fff;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  z-index: 1050;
  position: absolute;
}
.ant-time-picker-panel-inner {
  position: relative;
  outline: none;
  list-style: none;
  font-size: 14px;
  text-align: left;
  background-color: #6d7177;
  /* border-radius: 4px; */
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  background-clip: padding-box;
  overflow: hidden;
  left: 0;
}
.ant-time-picker-panel-input {
  margin: 0;
  padding: 0;
  border: 0;
  max-width: 112px;
  cursor: auto;
  outline: 0;

  height: 30px;
  padding: 2px 11px 0;
  background-color: transparent;
  border-bottom: 1px solid rgba(255,255,255,0.4);
  /* border: 1px solid rgba(255,255,255,0.4); */
  color: #fff;
  /* font-size: 14px; */
  font-weight: 300;
  width: 92px;
}
.ant-time-picker-panel-input::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-time-picker-panel-input:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-time-picker-panel-input::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-time-picker-panel-input-wrap {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
  /* padding: 7px 2px 7px 12px; */
  /* border: 1px solid rgba(255,255,255,0.4); */
}
.ant-time-picker-panel-input-invalid {
  border-color: red;
}
.ant-time-picker-panel-clear-btn {
  position: absolute;
  right: 8px;
  cursor: pointer;
  overflow: hidden;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  top: 7px;
  margin: 0;
}
.ant-time-picker-panel-clear-btn-icon svg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.25);
  display: inline-block;
  -webkit-transition: color 0.3s ease;
  transition: color 0.3s ease;
}
.ant-time-picker-panel-clear-btn-icon svg:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-time-picker-panel-narrow .ant-time-picker-panel-input-wrap {
  max-width: 112px;
}
.ant-time-picker-panel-select {
  float: left;
  font-size: 14px;
  border-left: 1px solid rgba(255,255,255,0.4);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 56px;
  overflow: hidden;
  position: relative;
  max-height: 256px;
}
.ant-time-picker-panel-select:hover {
  overflow-y: auto;
}
.ant-time-picker-panel-select:first-child {
  border-left: 0;
  margin-left: 0;
}
.ant-time-picker-panel-select:last-child {
  border-right: 0;
}
.ant-time-picker-panel-select:only-child {
  width: 100%;
}
.ant-time-picker-panel-select ul {
  list-style: none;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0 0 160px;
  width: 100%;
}
.ant-time-picker-panel-select li {
  list-style: none;
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  margin: 0;
  padding: 0 0 0 18px;
  width: 100%;
  height: 32px;
  line-height: 32px;
  text-align: left;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-transition: background 0.3s;
  transition: background 0.3s;
}
.ant-time-picker-panel-select li:hover {
  background: #478a8a;
}
li.ant-time-picker-panel-select-option-selected {
  background: #20a39e;
  font-weight: bold;
}
li.ant-time-picker-panel-select-option-selected:hover {
  background: #478a8a;
}
li.ant-time-picker-panel-select-option-disabled {
  color: rgba(0, 0, 0, 0.25);
}
li.ant-time-picker-panel-select-option-disabled:hover {
  background: transparent;
  cursor: not-allowed;
}
.ant-time-picker-panel-combobox {
  zoom: 1;
}
.ant-time-picker-panel-combobox:before,
.ant-time-picker-panel-combobox:after {
  content: "";
  display: table;
}
.ant-time-picker-panel-combobox:after {
  clear: both;
}
.ant-time-picker-panel-addon {
  padding: 8px;
  border-top: 1px solid #e8e8e8;
}
.ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-topLeft,
.ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-topRight,
.ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-topLeft,
.ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-topRight {
  -webkit-animation-name: antSlideDownIn;
          animation-name: antSlideDownIn;
}
.ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-bottomLeft,
.ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-bottomRight,
.ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-bottomLeft,
.ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-bottomRight {
  -webkit-animation-name: antSlideUpIn;
          animation-name: antSlideUpIn;
}
.ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-topLeft,
.ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-topRight {
  -webkit-animation-name: antSlideDownOut;
          animation-name: antSlideDownOut;
}
.ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-bottomLeft,
.ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-bottomRight {
  -webkit-animation-name: antSlideUpOut;
          animation-name: antSlideUpOut;
}
.ant-time-picker {
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  display: inline-block;
  outline: none;
  -webkit-transition: opacity .3s;
  transition: opacity .3s;
  width: 128px;
}
.ant-time-picker-input {
  position: relative;
  display: inline-block;
  /* width: 100%; */
  font-size: 14px;
  line-height: 1.5;
  -webkit-transition: all .3s;
  transition: all .3s;

  height: 28px;
  padding: 0 10px;
  background-color: transparent;
  border: 1px solid rgba(255,255,255,0.4);
  color: #fff;
  font-size: 14px;
  font-weight: 300;
  width: 90px;
}
.ant-time-picker-input::-moz-placeholder {
  color: #6d7177;
  opacity: 1;
}
.ant-time-picker-input:-ms-input-placeholder {
  color: #6d7177;
}
.ant-time-picker-input::-webkit-input-placeholder {
  color: #6d7177;
}
/* .ant-time-picker-input:hover {
  border-color: #40a9ff;
  border-right-width: 1px !important;
} */
.ant-time-picker-input:focus {
  /* border-color: #40a9ff; */
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
          box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  /* border-right-width: 1px !important; */
}
.ant-time-picker-input-disabled {
  background-color: #f5f5f5;
  opacity: 1;
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.25);
}
.ant-time-picker-input-disabled:hover {
  border-color: #e6d8d8;
  border-right-width: 1px !important;
}
textarea.ant-time-picker-input {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
  -webkit-transition: all .3s, height 0s;
  transition: all .3s, height 0s;
  min-height: 32px;
}
.ant-time-picker-input-lg {
  padding: 6px 11px;
  height: 40px;
  font-size: 16px;
}
.ant-time-picker-input-sm {
  padding: 1px 7px;
  height: 24px;
}
.ant-time-picker-input[disabled] {
  /* background-color: #f5f5f5; */
  opacity: 1;
  cursor: not-allowed;
  /* color: rgba(0, 0, 0, 0.25); */
}
/* .ant-time-picker-input[disabled]:hover {
  border-color: #e6d8d8;
  border-right-width: 1px !important;
} */
.ant-time-picker-open {
  opacity: 0;
}
.ant-time-picker-icon {
  /* position: absolute;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 14px;
  height: 14px;
  line-height: 14px;
  right: 11px;
  color: rgba(0, 0, 0, 0.25);
  top: 50%;
  margin-top: -7px; */
  display: none;
}
/* .ant-time-picker-icon .ant-time-picker-clock-icon {
  color: rgba(0, 0, 0, 0.25);
  display: block;
  line-height: 1;
} */
.ant-time-picker-large .ant-time-picker-input {
  padding: 6px 11px;
  height: 40px;
  font-size: 16px;
}
.ant-time-picker-small .ant-time-picker-input {
  padding: 1px 7px;
  height: 24px;
}
/* .ant-time-picker-small .ant-time-picker-icon {
  right: 7px;
} */
