.protocol {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;

  .protocol_header {
    position: relative;
    height: 100px;
    border-bottom: 1px solid #25292c;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-image: url('/static/media/bg2.5568fab1.png');

    .protocol_header_left {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 300px;
      min-width: 300px;
      height: 100%;
      display: flex;
      flex-direction: row;

      .protocol_title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-left: 20px;

        &>div {
          font-family: Oswald;
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 30px;
          letter-spacing: .1em;
          color: #fff;
        }
      }

      .back-arrow {
        background: #242b33;
        height: 100px;
        width: 50px;
        min-width: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }

    .protocol_header_center {
      flex: 1;
      
      .match-score {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 1;

        .game-result {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          font-family: Oswald;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          display: flex;
          align-items: center;
          letter-spacing: 0.05em;
          color: #FFFFFF;
          margin-bottom: 7px;

          .result {
            text-transform: uppercase;
            line-height: 26px;
          }

          .prize {
            background: rgba(246, 89, 50, 0.1);
            border: 1px solid #F65932;
            box-sizing: border-box;
            border-radius: 5px;
            margin-left: 10px;
            height: 26px;
            box-sizing: border-box;
            line-height: 24px;
            padding: 0 5px;
          }

        }

        .match-scoreboard {
          flex: 1;

          .login,
          .full,
          .rating-value {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 19px;
            white-space: nowrap;
          }

          .rating-stars {
            height: 20px;
            display: flex;
            flex-direction: row;
            align-items: center;
            white-space: nowrap;
          }

          .rating-value {
            line-height: 20px;
            color: #FFFFFF;
          }

          .avatar {
            width: 54px;
            height: 54px;
            min-width: 54px;
            margin-right: 12px;

            &>img {
              width: 100%;
              border-radius: 50%;
              border: 2px solid transparent;
              box-sizing: border-box;
            }

            .avatar__flag {
              width: 24px;
              height: 24px;
              border-radius: 50%;
            }
          }

          .score {
            justify-content: center;
            margin: 0 25px;
            flex: 0 0 auto;
            max-width: none;

            &>span {
              color: #FFFFFF;
              font-size: 40px;
              letter-spacing: 0.1em;

              &:last-child {
                color: #f65932;
              }
            }
          }

        }
      }
    }

    .protocol_header_right {
      position: absolute;
      top: 0;
      right: 0;
      width: 70px;
      min-width: 70px;
    }
  }

  &.resolved {
    .protocol_footer {
      padding-bottom: 0;

      .protocol_footer_group {
  
        &:first-child {
          border-top: 1px solid #25292C;
          border-bottom: none;
          padding: 5px 20px;
        }

        &:last-child {
          margin-top: 0;
        }  
      }
    }
  }

  .protocol_footer {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding-bottom: 10px;
    background: #0C131C;

    .protocol_footer_group {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      padding: 0 20px;

      &:first-child {
        border-top: 1px solid #25292C;
        border-bottom: 1px solid #25292C;
        padding: 10px 20px;
      }

      &:last-child {
        margin-top: 10px;
      }

      .party-result {
        background: rgba(246, 89, 50, 0.1);
        border: 1px solid #F65932;
        box-sizing: border-box;
        border-radius: 5px;
        width: 100%;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        color: #FFFFFF;
        padding: 5px 10px 15px 10px;

        .party-result-title {
          opacity: 0.6;
          margin-bottom: 5px;
        }
      }

      .party-comment {
        flex: 1;

        label {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 19px;
          color: #FFFFFF;
          display: block;
          margin-bottom: 5px;
          text-align: left;
        }

        textarea {
          width: 100%;
          height: 55px;
        }
      }

      .protocol_footer_checkbox {
        flex: 1;
        margin-right: 40px;

        .checkbox {
          text-align: left;

          .checkbox__checkmark {
            min-width: 24px;
            box-sizing: border-box;

            &.checkbox__checked {

              img {
                display: block;
                width: 100%;
              }
            }
          }
        }
      }

      .protocol_footer_buttons {

        .button {
          width: 172px;
          height: 40px;
        }
      }

    }
  }

  .protocol_body {
    height: 100%;
    flex: 1;
    background: #0C131C;
    overflow: auto;

    .protocol_table {

      .protocol_table_row {
        background-color: #252B32;

        &:nth-child(6n+1) {
          background: #252B32;
        }

        &:nth-child(6n+2) {
          background: #252B32;
        }

        &:nth-child(6n+3) {
          background: #252B32;
        }

        &:nth-child(6n+4) {
          background: #0C131C;
        }

        &:nth-child(6n+5) {
          background: #0C131C;
        }

        &:nth-child(6n+6) {
          background: #0C131C;
        }
      }

      .ant-table-thead tr th,
      .protocol_table_row td {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 200;
        font-size: 14px;
        line-height: 19px;
        color: #FFFFFF;
      }

      .ant-table-thead tr th {
        padding: 10px;
        background-color: #0C131C;
      }

      .protocol_table_row {

        &>td {
          padding: 10px;
          vertical-align: top;

          &.row-1:nth-child(3) {
            position: relative;

            &:after {
              content: '';
              position: absolute;
              height: calc(100% - 10px);
              top: 10px;
              right: 0;
              width: 1px;
              background: #454a4f;
            }
          }

          &.row-2:nth-child(2) {
            position: relative;

            &:after {
              content: '';
              position: absolute;
              height: calc(100% - 10px);
              top: 0;
              right: 0;
              width: 1px;
              background: #454a4f;
            }
          }

          // &.row-3 {
          //   border-top: 1px solid #454a4f;
          // }

          &.empty {
            padding: 0;
          }

          &.no-right-padding {
            padding-right: 0;
            text-align: right;
          }

          .gamenumber,
          .framenumber {
            margin-bottom: 5px;
            white-space: nowrap;
          }

          .collapse {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 22px;
            color: #F65932;
            margin-top: 5px;
            display: flex;
            cursor: pointer;

            &>div:last-child {
              margin-left: 5px;
              display: flex;
              align-items: center;
            }
          }

          .player {
            display: flex;
            flex-direction: row;
            white-space: nowrap;

            .avatar {
              width: 40px;
              height: 40px;
              min-width: 40px;
              margin-right: 20px;

              &>img {
                width: 100%;
                border-radius: 50%;
              }

              .avatar__flag {
                width: 18px;
                height: 18px;
                border-radius: 50%;
              }

            }
          }

          .protest_comment {
            min-width: 385px;

            .reason,
            .referee {
              font-weight: bold;
              margin-bottom: 5px;
            }

          }

          .protest_status {
            white-space: nowrap;
            display: flex;
            flex-direction: row;
            align-items: center;

            &__button {
            
              .svg-button {
                width: 30px;
                height: 30px;
                border: 1px solid #20A39E;
                box-sizing: border-box;

                &>svg>path {
                  fill: #20A39E;
                }

                &:hover {
                  background: #20a39e;

                  &>svg>path {
                    fill: #ffffff;
                  }
                }
              }
            }

            &__link {
              margin-left: 10px;
            }
          }

          .scoring {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            max-height: 70px;
            // max-width: 375px;
            align-items: center;

            .frame {
              display: flex;
              flex-direction: column;
              border-left: 1px solid #25292C;
              background: #343D49;
              max-height: 70px;
              height: 70px;
              width: auto;

              &.total {
                width: 50px;
              }
              &>div {
                text-align: center;
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 15px;
                color: #FFFFFF;
              }

              .framenumber {
                height: 15px;
              }

              .result {
                background: #8d9096;
                color: #25292c;
                flex: 1 1;
                min-width: 35px;
                display: flex;
                align-items: center;
                flex-direction: row;
                justify-content: center;
                padding: 0 8px;
                box-sizing: border-box;
                position: relative;

                &>.shot {
                  margin-right: 8px;

                  &:last-child {
                    margin-right: 0;
                  }
                }

                .flags {
                  position: absolute;
                  top: 0;
                  left: 0;
                  display: flex;
                  flex-direction: row;

                  &>div {
                    width: 10px;
                    height: 8.5px;
                    background: #20a39e;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;

                    &>svg {
                      width: 5px;
                      height: 6px;

                      &>path {
                        fill: #FFFFFF;
                      }
                    }

                    &.refereed {
                      background: #D80027;
                    }

                    &.changed {
                      background: #20A39E;
                    }

                    &.foul {
                      background: #F65932;
                    }

                    &:last-child {
                      border-bottom-right-radius: 4px;
                    }

                  }
                }

              }

              .score {
                font-weight: bold;
                height: 17px;
                position: relative;
                .hdc {
                  position: absolute;
                  transform: rotate(270deg);
                  font-size: 7px;
                  left: -5px;
                  top: 0px
                }
              }

              &.selected {
                background: #5B4344;

                .result {
                  background: #F65932;
                  color: #FFFFFF;

                  .foul {
                    background: #FFFFFF !important;

                    &>svg>path {
                      fill: #F65932 !important;
                    }
                  }
                }
              }
            }

            &.original {

              .frame.selected {
                background: #005956;

                .result {
                  background: #20A39E;
                  color: #FFFFFF;

                  .flags {

                    .changed {
                      background: #FFFFFF !important;

                      &>svg>path {
                        fill: #20A39E !important;
                      }
                    }

                    .foul {
                      background: #F65932 !important;

                      &>svg>path {
                        fill: #FFFFFF !important;
                      }
                    }
                  }
                }
              }

              .vector {
                margin-left: 10px;
              }
            }
          }

        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {

  .protocol_header_center {
    margin-left: 300px;
  }

}