.competitionsTable {
  width: 100%;
  * {
    line-height: 19px;
  }
  table {
    border-collapse: collapse;
  }
  td:first-child, th:first-child {
    position: relative;
    padding-left: 15px;
  }
  .mybattle::before {
    content: '';
    background: linear-gradient(269.37deg, #0ABB31 0%, #206B0D 100%);
    top: 0;
    bottom: 0;
    left: 0;
    width: 10px;
    position: absolute;
  }
  .participant__result {
    &.largest {
      color: #F65932;
    }
    &.lowest {
      color: rgba(255, 255, 255, 0.4);
    }
  }
  .tournamentRow {
    .button {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      color: #FFFFFF;
      padding-top: 6px;
      padding-bottom: 6px;
      width: 120px;
    }
    .not_exists {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      text-align: left;
      color: #7C8084;
    }
    .play_trash {
      display: flex;
      align-items: center;
      .svg-button {
        margin-left: 10px;
      }
    }
  }

  .participant-actions {
    display: flex;
    flex-direction: row;

    .add-replay {
      width: auto;
      margin-left: 10px;
    }
  }
}
 
.tournamentTable {
  position: relative;
  .react-tabs__tab-list {
    margin-bottom: 0 !important;
    margin-left: 50px !important;
  }
}

.headBackButton {
  height: 50px;
  width: 50px;
  position: absolute;
  background: #242B33;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.tournamentTable {
  .ratingWrap {
    svg {
      margin-right: 3px;
    }
    display: flex;
  }
}

.tournamentTable {
  .player-filter#tournament-player-filter {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    height: auto;
    margin-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #25292C;
    .player-filter-gender, .player-filter-participant, .player-filter-reset {
      min-width: 100px;
      max-width: 150px;
    }
    .player-filter-reset {
      flex: 0 0 auto;
      cursor: pointer;
      font-family: Open Sans, sans-serif;
      color: rgb(246, 89, 50);
      font-weight: 200;
      min-width: auto;
    }
    .player-filter-age {
      display: flex;
      flex-direction: row;
      & > span {
        font-family: Open Sans, sans-serif;
        font-size: 14px;
        line-height: 19px;
        color: rgba(255, 255, 255, 0.4);
        font-weight: normal;
      }
      & > div:first-child {
        margin-left: 10px;
      }
      & > div {
        margin-left: 5px;
      }
    }
    & > div {
      flex-direction: row;
      align-items: center;
      flex-basis: auto;
      margin-left: 10px;
      flex-grow: 1;
      min-width: 125px;
      max-width: 200px;
    }
    & > div:last-child {
      margin-right: 20px;
    }
    .filter-input input {
      height: 30px;
      padding-left: 10px;
      padding-right: 10px;
      width: calc(100% - 20px);
    }
  }
}

.tournament__createBattle {
  .elemGroup {
    padding: 10px 20px;
    &:not(:last-child) {
      border-bottom: 1px solid #3B3F42;
    }
    &:last-child {
      padding-top: 6px;
    }
  }
  .label {
    color: #BABABA;
    font-size: 14px;
    margin-bottom: 7px;
  }
  .ant-slider-with-marks {
    margin-top: 35px;
  }
  .date-group__elements__picker {
    padding-left: 0;
  }
  .betWrap input {
    box-sizing: border-box;
    width: 100%;
  }
  .date-group__pickers {
    flex-wrap: nowrap;
    margin-right: 0;
    & > div {
      display: flex;
      & > span {
        width: 100%;
      }
      input {
        width: calc(100% - 35px);
      }
    }
    & > .ant-time-picker {
      input {
        width: 81px;
      }
    }
  }
  .ant-time-picker + .input__error {
    display: none;
  }
}

.date-group__edit {
  display: flex;
  .date-group__del {
    width: 7px;
    height: 1px;
    margin: 14px 8px 0 8px;
    background-color: white;
  }
  .date-group__elements__picker,
  .date-group__elements__picker {
    padding-left: 0;
    width: auto;
    overflow: visible;
  }
  .ant-calendar-picker-input,
  .ant-time-picker-input {
    padding: 0;
    border: none;
  }
  .ant-calendar-picker-input {
    width: 52px;
    text-align: right;
  }
  .ant-time-picker-input {
    width: 50px;
  }
}

.result__container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  &.clickable {
    cursor: pointer;
  }
}

.result__refereeing {
  padding-right: 10px;
}

.result__refereeing path {
  fill: #FF4242;
}

.tournament__createBattle {
  button {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    padding: 0;
  }
  .opponent__avatar, .opponent__image {
    width: 35px !important;
    height: 35px !important;
    padding: 0 !important;
  }
  .playerPicker {
    padding-top: 10px;
    .label {
      padding-left: 20px;
      letter-spacing: 0.05em;
      font-family: Oswald;
      color: #FFFFFF;
    }
    .opponent__nickname {
      pointer-events: none;
    }
    .selectedPlayer {
      position: relative;
    }
    .close {
      position: absolute;
      top: 5px;
      right: 20px;
      cursor: not-allowed;
    }
    .dropDownButton {
      position: absolute;
      right: 20px;
      bottom: 15px;
      pointer-events: none;
    }
    .dropDownMenu {
      position: absolute;
      width: 100%;
      z-index: 10;
      height: auto;
      max-height: 0px;
      overflow: auto;
      transition: 0.25s max-height;
      transition-delay: 0.5s;
      overflow: auto;
      .opponent {
        background: #343D49;
        border-top: 1px solid #25292C;
        &:hover {
          background: #30515A;
        }
      }
    }
    .shutter {
      position: absolute;
      top: 0; bottom: 0;
      left: 0; right: 0;
      pointer-events: none;
    }
    .selectedPlayer > .opponent {
      cursor: pointer;
      background: #3B3E41;
      &:focus {
        & ~ .shutter {
          pointer-events: all;
        }
        & ~ .dropDownMenu {
          max-height: 400px;
          transition: 0.25s max-height;
          transition-delay: 0;
          .opponent {
            cursor: pointer;
          }
        }
      }
    } 
  }
}

.rightPinned {
  text-align: right !important;
}

.errorGroup {
  width: 100%;
  padding: 10px 20px;
  background-color: rgba(246,89,50,.2);
  vertical-align: middle;
  box-sizing: border-box;
}
.opponent__avgscore{
  position: absolute;
    bottom: 0;
    right: 80px;
    color: #fff;
    font-size: 18px;
    background: #0c131d;
    border-radius: 21px;
    height: 20px;
    border-radius: 7px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 2px;
}
.opponent_feedback{
  color: #f65932;
    font-size: 12px;
    margin-left: 10px;
    line-height: 20px;
}
.modal-user_rating{
  top: 47px; bottom: 0;
  right: 0;
  width: 50%;
  padding: 0 15px;
  overflow-y: auto;
  position: absolute;
  box-sizing: border-box;
}
.modal-user_singlerate {
  padding: 15px 0;
  border-bottom: 1px solid #25292c;
  * {
    box-sizing: border-box;
  }
  .preambula {
    display: flex;
    justify-content: space-between;
    .user {
      display: flex;
      align-items: center;
      .user__photo {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        object-fit: cover;
        flex: 0 1 30px;
        margin-right: 15px;
      }
      .user__flag_container {
        position: relative;
        height: 0;
        width: 0;
        .user__flag {
          width: 16px;
          height: 16px;
          position: absolute;
          bottom: -17px;
          right: 7px;
          border-radius: 8px;
          overflow: hidden;
        }
      }
      .user__name {
        flex: 1 1;
        flex-direction: column;
        justify-content: center;
        .fullname {
          display: block;
          font-size: 14px;
          font-family: 'Open Sans';
          color: #fff;
          margin-bottom: 4px;
        }
        .nickname {
          display: block;
          font-size: 12px;
          font-family: 'Open Sans';
          color: #f65932;
        }
      }
    }
    .daterate {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
      & > * {
        flex: 0 0 100%;
        text-align: end;
      }
    }
  }
  .ratelabel {
    margin-top: 10px;
    display: block;
  }
  .content {
    display: block;
    color: #fff;
  }
}
.ant-modal-footer{
  z-index: 1;
}
.modal-user_info .ant-modal-footer{
  background-image: linear-gradient(to top, #0c131c, #0d141d);
}

.tournamentTable .tournamentRow {
  .date-group__pickers {
    & > * {
      flex: 0 0 auto;
      width: auto;
    }
    span.ant-calendar-picker > * {
      display: inline-block;
    }
  }
}

.tournament_agreements {
  padding: 20px 0;
  & > span {
    padding-bottom: 20px;
    display: block;
  }
}

.favoriteParticipant {
  background: #414D5C !important;
  border-top: 1px solid #0c131c;
  //border-bottom: 2px solid #0c131c;
}

.currentUser {
  td:first-child {
    border-left: 10px solid #0ABB31;
  }
}

.confirm-modal__body {
  margin-top: 15px;
}

.tournament-table-result {
  &-value {
    font-weight: bold;
    width: 80px;
    color: #fff;
    text-align: right;
    padding: 5px 10px;
    box-sizing: border-box;
    &:hover {
      cursor: pointer;
      outline: 1px solid;
    }
    &.not-hover:hover {
      outline: none;
      cursor: default;
    }
  }

  &__container {
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  &__input {
    font-weight: bold;
    padding: 5px 10px;
    width: 80px;
    background-color: transparent;
    color: #fff;
    text-align: right;
    border: 1px solid #A7A7A7;
    box-sizing: border-box;
  }
}

.stage-standings {
  display: flex;
  flex-direction: column;
  margin: 20px 0;
}

.stage {
  margin-bottom: 40px;
  .ant-table-content {
    border-color: rgba(100, 100, 100, 0.3);
    border-width: 1px;
    border-style: solid;
  }
  .place-input {
    width: 50px;
    height: 30px;
    border: 1px solid #A7A7A7;
    box-sizing: border-box;
    padding: 0 10px;
    background: transparent;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    color: #FFFFFF;
    text-align: center;
  }
  .checkbox-container {
    flex-direction: row;
    align-content: center;
    justify-content: center;
    display: flex;
    &.disabled {
      opacity: 0.5;
    }
    .checkbox__checkmark {
      margin-right: 0px;
    }
  }
  .stage-controls {
    display: flex;
    flex-direction: row;
    margin-left: 15px;
    margin-bottom: 10px;
    .input {
      background-color: transparent;
      border: 1px solid #A7A7A7;
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      height: 30px;
      color: #FFFFFF;
      padding-left: 10px;
      min-width: 150px;
    }
    .delete-stage-button {
      margin-left: 20px;
      background-color: #E9153B;
      border: none;
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      color: #FFFFFF;
      &:hover {
        background-color: #BA102F;
      }
    }
    .save-stage-button {
      margin-left: 20px;
      background-color: #39BB2E;
      border: none;
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      color: #FFFFFF;
      &:hover {
        background-color: #278220;
      }
    }
  }
}

.competitor-sizer{
  min-width: 210px;
  margin: 10px 0px;

  .competitor__container {
    display: flex;
    flex-direction: row;
  }

  .avatar-sizer {
    align-items: center;
  }

  .userNameContainer {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;

    p {
      margin: 0px;
    }

    .login-text {
      color: rgba(246, 89, 50, 1);
    }
  }

}





.modal-content {
  padding: 16px 0;
}

.add-stage {
  margin-left: 15px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 11px 20px;
  background-color: #26303C;
  max-width: 180px;

  .add-stage-icon {
    width: 30px;
    height: 30px;
    margin-right: 30px;
  }

  .avatar__container {
    width: 54px;
    height: 36px;
  }

  &:hover {
    background-color: #3b444f;
  }

}

.clickableSpan {
  &:hover {
    color: #f65932;
    cursor: pointer;
  }
}