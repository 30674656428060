.friends-list {
  padding-top: 10px
}

.friends__table-container {
  width: 60%;
}

.friends__table-container .login__link:hover {
  color: #ff3e18;
}

.friends__table-container .username__container {
  align-items: center;
  display: flex;
}
.friends__table-container .img__container{
  width: 30px;
  border-radius: 30px;
  margin-right: 10px;
}

.friends__table-container .buttons__container {
  display: flex;
  align-items: center;
}

.friends__table-container .buttons__container .button__container {

}

.friends__table-container .buttons__container .tarsh__container {
  margin-left: 10px;
  cursor: pointer;
}

.friends__table .friends__table__row {
  border-right: 1px solid #25292c;
}

.friends__table-container .ant-table-thead tr th:last-child {
  border-right: 1px solid #25292c !important;
}

.friend_delete_caption {
  padding: 15px 20px;
}

.friends__table-notfind {
  margin-top: 100px;
  display: flex;
  align-self: center;
  justify-content: center;
}

.notfind_container {
  width: 250px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.notfind__img {
  width: 200px;
}

.notfind__text {
  text-align: center;
  margin-top: 10px;
}