.requestbc-form {
  &__header {
    margin-top: 10px;
    color: #FFFFFF;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
  }
  &__body {
    .row {
      display: flex;
      width: 100%;
      max-width: 100%;
      margin-top: 15px;
    }
    &:last-child {
      margin-bottom: 15px;
    }
  }
  &-container {
    &-50 {
      flex: 0 50%;
    }
    &-100 {
      flex: 0 100%;
    }
    &-33 {
      flex: 0 33%;
    }
  }
  .form__input {
    width: 100%;
    padding-left: 5px;
    height: 30px;
    .error {
      border-color: #f65932;
    }
    &-label {
      &.error {
        color: #f65932;
      }
      margin-bottom: 10px;
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
    }
  }
  .form__select {
    &.error > div {
      border-color: #f65932;
      background-color: rgba(246, 89, 50, 0.2) !important;
    }
  }
  .section__title {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #FFFFFF;
  }
  .mr-15 {
    margin-right: 15px;
  }

  .input {
    height: 30px;
    padding: 0 10px;
    background-color: transparent;
    border: 1px solid rgba(255,255,255,0.4);
    color: #fff;
    font-size: 14px;
    font-weight: 300;
    box-sizing: border-box;
  }
}

.requestbc-modal {
  overflow: auto !important;

  .ant-modal-body {
    z-index: 2;
    flex: 0 0 auto !important;
    overflow: visible !important;
    background-image: linear-gradient(to top, #0c131c, #0d141d);
    min-height: initial !important;
  }
  
  .ant-modal {
    overflow: visible !important;
  }

  .ant-modal {
    overflow: visible !important;
  }

  .ant-modal-content {
    overflow: visible !important;
  }
  
  .ant-modal-header {
    flex: 0 0 auto !important;
    background-image: linear-gradient(to top, #0c131c, #0d141d);
  }
  
  .ant-modal-footer {
    flex: 0 0 auto !important;
    background-image: linear-gradient(to top, #0c131c, #0d141d);
  }

  .footer-btn {
    display: flex;
    button {
      &:first-child {
        background-color: transparent;
        border: 1px solid #20A39E;
        color: #20A39E;
      }
    }
  }
}

.success-modal-body {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 20px;
  .modal__img {
    max-width: 100%;
    max-height: 100%;
  }
  .container-img {
    width: 180px;
    height: 140px;
    margin-bottom: 30px;
  }
  .modal__text {
    font-family: Oswald;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #FFFFFF;
  }
}