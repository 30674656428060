@keyframes magnify {
  0%    { transform: translate(-5px,2px) }
  33.33% { transform: translate(20px,0px) }
  66.66% { transform: translate(-5px,20px) }
  100%    { transform: translate(-5px,2px) }
}

.progress-container {
  position: relative;
  display: flex;
  justify-items: center;
  flex-direction: column;
  align-items: center;
}

.progress-container__img {
  animation: magnify 1s linear infinite;
  position: absolute
}

.progress-label {
  margin-top: 80px;
}