.moderation_container{
  margin-left: 20px;
}

.moderation_page_name_container {
  display: flex;
  flex-direction: row;
}
.moderation_page_name {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #6E7277;
  text-transform: uppercase;
  width: 209px;
  height: 36px;
  margin-top: 10px;
  cursor: pointer;
}

.orange {
  color: #F65932;
  border-bottom: 3px solid #F65932;
}

.banners_list_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 51px;
  margin-bottom: 40px;
  overflow:auto;
  white-space: nowrap;
}
.moderation_banner_data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.data_container {
  width: 40vw;
}

.selected_image_bc {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 10px;
}
// TEXT

.text {
  font-family: 'Open Sans';
  color: #FFFFFF;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.gray {
  color: #6D7177;
}
.checkName {
  margin-right: 10px;
  font-size: 14px;
  line-height: 19px;
}

.link {
  margin-left: 10px;
}

.administrator{
  margin-top: 20px;
  font-weight: 600;
}

.invalid_weight_text {
  color: #D80027;
  margin-top: 10px;
}

// -------------

.link_container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  border: 1px solid #6D7177;
  margin-top: 30px;
}

.publication {
  font-weight: 400;
  font-size: 20px;
}

// Table style
table {
  width: 100%;
}

th, td {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}
th {
  color: #6D7177;
}

td {
  color: #FFFFFF;

}

caption,td,th {
  text-align: left;
}

// ----------

// Banner Weight Style
form {
  margin-top: 20px;
}

.weight_label {
  margin-bottom: 10px;
  margin-top: 20px;
}
.bc_banners_input {
  width: 100%;
  background-color: transparent;
  box-sizing: border-box;
  border: 1px solid #6D7177;
  height: 40px;
}

.invalid_weight {
  border: 1px solid #FF0230;
}
// --------------

// Publication button Style
.publication_button {
  background-color: #F65932;
  height: 59px;
  width: 26vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.center_alignment {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 60px;
}
// ------------------