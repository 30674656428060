.CoookiePanel * {
  box-sizing: border-box;
}

.CoookiePanel {
  position: fixed;
  transition: bottom 1s linear;
  z-index: 99999;
  left: 0;
  right: 0;
  font-size: 14px;
  .PanelBody {
    display: flex;
    margin: auto;
    width: 75%;
    max-width: 900px;
    background: #455060;
    padding: 15px 50px;
    color: #fff;
    border-radius: 15px 15px 0 0;
    position: relative;
  }

  .infoIcon, .closeButton {
    position: absolute;
    border-color: #fff;
    height: 24px;
    width: 24px;
    top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-style: solid;
  }
  .infoIcon {
    left: 15px;
    border-radius: 12px;
    border-width: 2px;
    opacity: 0.7;
  }
  .closeButton {
    right: 15px;
    border-width: 1px;
    opacity: 0.5;
    cursor: pointer;
    padding-bottom: 2px;
  }

  .content {
    flex: 1;
    display: flex;
    .text {
      flex: 1;
      a {
        text-decoration: underline;
        color: #20a39e;
      }
    }
    .cookiebutton {
      flex: 0.8;
      margin-left: 20px;
      border-color: #fff;
      border-width: 1px;
      border-style: solid;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
}