.message-modal.ant-modal {
  top: 30%;
}
.message-modal {
  .ant-modal-content {
    width: 540px;
    height: 300px;
    background: #0d1828;
  }
  .ant-modal-header {
    background: #0d1828;
  }
  .ant-modal-header, .ant-modal-body, .ant-modal-footer, .ant-modal-body .modal-group {
    border-bottom: none;
    border-top: none;
  }
  .message-sent-content {
    height: 300px;
    display: flex;
    flex-direction: column;
    .message-icon {
      display: flex;
      justify-content: center;
      height: 150px;
      align-items: center;
    }
    .message-text {
      display: flex;
      justify-content: center;
      align-self: center;
      font-size: 20px;
      font-weight: 300;
      font-family: Open Sans, sans-serif;
      span {
        text-align: center;
      }
    }
  }
}