.protest_decision_modal {
  .frame-result-container {
    display: flex;
    align-items: center;
    justify-content: center;

    .frame-old-result {
      background: #343D49;
      margin-right: 30px;

      .frame-result {
        color: #25292C;
        background: #D2D4D8;
      }
    }

    .frame-new-result {
      background: #5B4344;
      margin-left: 30px;

      .frame-result {
        color: #FFFFFF;
        background: #F65932;
      }
    }

    .frame-old-result,
    .frame-new-result {
      display: flex;
      flex-direction: column;
      width: 70px;
      border-left: 1px solid #25292C;
      border-right: 1px solid #25292C;

      .frame {
        width: 100%;
        
        .frame__scores {
          border: none;
        }
      }

      .frame-number,
      .frame-score {
        height: 20px;
        text-align: center;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        color: #FFFFFF;
      }

      .frame-result {
        position: relative;
        padding: 10px;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
        height: 40px;
        box-sizing: border-box;

        .frame-result-shots {
          display: flex;
          align-items: center;
          justify-content: center;
          
          .frame-result-shot {
            margin-right: 10px;
  
            &:last-child {
              margin-right: 0;
            }
          }
        }

        .frame-result-flag {
          position: absolute;
          top: 0;
          left: 0;
          background: #20A39E;
          color: #fff;
          width: 13px;
          height: 11px;
          border-bottom-right-radius: 4px;
          font-size: 10px;
          text-align: center;
          line-height: 11px;
        }
      }
    }
  }
  .protest-container {
    display: flex;
    flex-direction: column;

    .protest {
      padding: 10px 0;

      .protest-category {
        margin-bottom: 10px;
      }

      .protest-category,
      .protest-comment {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        color: #fff;

        span {
          display: inline-block;
          opacity: .6;
          margin-bottom: 5px;
        }

      }
    }
  }
  .discussion-container {
    padding: 15px 0;

    textarea {
      height: 65px;
    }
  }
  .file-container {
    display: flex;
    padding: 15px 0;
    align-items: center;

    .file-label {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      color: #FFFFFF;
      opacity: 0.4;
      white-space: nowrap;
    }

    .file-input {
      display: flex;
      border: 1px solid hsla(0,0%,100%,.4);
      height: 32px;
      box-sizing: border-box;
      padding: 5px 10px;
      width: 100%;
      margin-left: 10px;
      align-items: center;    
      overflow: hidden;

      .file-name {
        width: 100%;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #fff;
        opacity: .4;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .file-upload-icon {
        margin-left: 10px;
        position: relative;
        top: 2px;
      }
    }

  }
}