.about_page {
  position: absolute;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  .about_page__container {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 100%;
    flex: 1;
    overflow: hidden;

    .about_page__right_sidebar {
      position: absolute;
      top: 0;
      right: -25vw;
      width: 25vw;
      height: 100%;
      min-width: 281px;
      overflow: hidden;
      border-left: 1px solid #25292C;
      box-shadow: -10px 0px 10px rgba(0, 0, 0, 0.15);
      background: #25292c;
      box-sizing: border-box;
      transition: right .3s linear;
      z-index: 20;

      &.shown {
        right: 0;
      }

      .menu-header {
        width: 100%;
        font-size: 20px;
        height: 50px;
        margin-top: -1px;
        box-sizing: border-box;
        line-height: normal;
        background-color: #414d5c;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top: 1px solid #626567;
        border-bottom: 1px solid #626567;
        position: relative;
        font-family: Oswald;
        font-weight: 500;
        text-transform: uppercase;
      }
    }

    .about_page__content {
      flex: 1;
      overflow: auto;
      .world_bowling_playing_rules {
        padding: 10px 20px;
      }
    }

    .about_page__left_sidebar {
      min-width: 210px;
      border-right: 1px solid #25292C;
      overflow: auto;

      .about_page__menu_item {
        position: relative;
        font-family: Oswald;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        text-transform: uppercase;
        color: #FFFFFF;
        opacity: 0.6;
        padding: 10px 20px;
        white-space: nowrap;
        display: block;

        &.active {
          color: #F65932;
          opacity: 1;

          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 3px;
            height: 100%;
            background: #F65932;
          }
        }
      }
    }
  }

  .about_page__footer {
    height: 42px;
    border-top: 1px solid #25292C;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .about_page__sidebar_items {
      display: flex;
      flex-direction: row;
      width: 25vw;
      min-width: 281px;

      .about_page__sidebar_item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1;
        border-left: 1px solid #25292C;
        box-sizing: border-box;
        cursor: pointer;

        svg path {
          fill: #6C7076;
        }
  
        &.active {
          background: #25292C;

          svg path {
            fill: #F65932;
          }
        }
      }
    }
  }
}

.about_platform {
  padding: 15px 40px;
  max-width: 700px;
  overflow: auto;

  .about_platform__header {
    padding-bottom: 20px;
    border-bottom: 1px solid #25292C;

    .about_platform__title {
      font-family: Oswald;
      font-style: normal;
      font-weight: 500;
      font-size: 30px;
      line-height: 44px;
      letter-spacing: 0.05em;
      color: #FFFFFF;
      margin-bottom: 10px;
    }

    .about_platform__regnumber {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 27px;
      color: white;

      .about_platform__regnumber__key {
        opacity: .6;
      }
    }
  }

  .about_platform__contacts {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;
    color: white;
    padding: 20px 0;
    border-bottom: 1px solid #25292C;

    .about_platform__email {
      margin-bottom: 10px;
    }

    .about_platform__email__key,
    .about_platform__phone__key {
      opacity: .6;
    }
    .about_platform__email__value {
      color: white;
    }
  }

  .about_platform__text {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    opacity: .8;

    p {
      margin: 20px 0;
    }
  }
}