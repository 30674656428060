.table__competitor {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
  .photowrap {
    position: relative;
    .flag-icon {
      position: absolute;
      bottom: 0;
      right: 8px;
      border-radius: 50%;
      display: block;
      width: 16px;
      height: 16px;
      overflow: hidden;    
    }
  }
  .avgscore {
    color: #fff;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    height: 14px;
    width: 25px;
    background: #252B32;
    border-radius: 7px;
    position: absolute;
    bottom: 0;
    left: -10px;
  }
  .photo {
    position: relative;
    height: 36px;
    width: 36px;
    border-radius: 18px;
    overflow: hidden;
    margin-right: 12px;
    background-color: rgba(255,255,255,.1);
    img {
      height: 100%;
      width: 100%;
      display: block;
    }
  }
  .user_credentials {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .user_login a {
      color: #f65932;
    }
  }
  .user_name {
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  } 
}