.ObserverScreen {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	justify-content: flex-start;
	height: 100vh;
	.header {
		height: 30px;
		display: flex;
		justify-content: space-between;
		position: relative;
		background: rgba(32, 163, 158, .6);
		flex: 0 0 auto;ChatBox
		.left-buttons,
		.right-buttons {
			height: 100%;
			display: flex;
		}
		.left-buttons {
			button {
				padding: 5px 20px;
			}
			button:not(:last-child) {
				position: relative;
				border-right: 1px solid #20a39e;
				&::after {
					content: '';
					width: 1px;
					top: 0;
					bottom: 0;
					right: -1px;
					opacity: 0.4;
					position: absolute;
					background-image: linear-gradient(to top, rgba(255, 255, 255, 0.2), #fff 50%, rgba(255, 255, 255, 0.2));
				}
			}
		}  
		.right-buttons {
			.start-datetime {
				margin-right: 17px;
				white-space: nowrap;
				font-family: Open Sans;
				font-style: normal;
				font-weight: bold;
				font-size: 14px;
				line-height: 118.56%;
				text-align: right;
				color: #FFFFFF;
				display: flex;
				flex-direction: row;
				align-items: center;
			}
		}
		.tournament-info {
			height: 100%;
			display: flex;
			align-items: center;
			background: #231A1E;
			padding: 3px 0;
			border-left: 1px solid #6d7176;
			border-right: 1px solid #6d7176;
			box-sizing: border-box;
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			&>div {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				font-family: Oswald;
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 21px;
				letter-spacing: 0.05em;
				color: #FFFFFF;
				padding: 0 10px;
				border-right: 1px solid rgba(255, 255, 255, .2);
				box-sizing: border-box;
				&.tournament-name {
					font-weight: 500;
					font-size: 18px;
				}
				&:last-child {
					border-right: none;
				}
			}
		}
	}

	.scoring {
		position: relative;
		padding: 10px 0;
		&.lowwidth {
		  display: flex;
		  align-items: center;
		  background: linear-gradient(to right, #0d1928, #0d141d);
		  padding: 0 20px;
			.match-scoreboard {
				flex: 1 1 100%;
				justify-content: flex-start;
				.score {
					flex: 0 0 auto;
				}
				.antag,
				.protag {
					flex: 0 1 auto;
				}
			}
			&>button,
			&>.protest__button {
				flex: 0 0 150px;
				height: 48px;
			}
		}
		&::before,
		&::after {
			width: 100%;
			left: 0;
			height: 1px;
			position: absolute;
			content: '';
			background-image: linear-gradient(to right, rgba(255, 255, 255, 0.2), white 50%, rgba(255, 255, 255, 0.2));
			opacity: 0.4;
		}
		&::before {
			top: 0px;
		}
		&::after {
			bottom: 1px;
		}
		.match-scoreboard {
			.antag,
			.protag {
				.avatar {
					height: 64px;
					width: 64px;
					min-width: 58px;
					img {
						width: 58px;
					}
					.avatar__flag {
						width: 24px;
						height: 24px;
					}
				}
			}
			.protag .avatar__flag {
				left: -7px;
				right: 0;
			}
			.score>span {
				color: #fff;
				&:last-child {
					color: #f65932;
				}
			}
		}
	}

	.frameGrid {
		flex: 0 0 auto;
		width: 100%;
		margin-bottom: 15px;
		.react-tabs__tab-list {
			display: flex;
			justify-content: center;
			margin: 0;
		}
		.react-tabs .react-tabs__tab {
			padding: 7px 0 6px;
		}
		.react-tabs .react-tabs__tab-panel {
			overflow: visible;
		}
	}

	.Telecom {
		display: flex;
		flex-direction: row;
		flex: 0 1 100%;
		overflow: hidden;
		.CameraBox {
			flex: 1 1 100%;
		}
		.ChatBox {
			flex: 0 0 25%;
		}
	}

	.CameraBox {
		.videosTabs-tab {
			// display: flex;
			.videosContainer {
				margin: 0 15px 0 0;
				&.mirrored {
					margin: 0 0 0 15px;
				}
			}
			.video-controller {
				color: #ff111f;
			}
		}
		.videosContainer {
			&:first-child {
				margin-right: 30px;
			}
		}

		.videosContainer {
			&.mirrored{
				padding-left: 15px;
			}
		}
	}
	.ChatBox {
		flex: 0 1 100%;
		min-height: 300px;
		position: relative;
		.react-tabs {
			height: 100%;
			.react-tabs__tab-list {
				margin-bottom: 0;
				background: #343d49;
				height: 30px;
				position: relative;
				&::before, &::after {
					position: absolute;
					left: 0;
					right: 0;
					width: 100%;
					height: 1px;
					background-image: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.2) 30%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0.2)70%, transparent);
				}
				&::before { top: 0 }
				&::after { bottom: 0 }
				.react-tabs__tab {
					padding: 0;
					font-size: 14px;
					display: flex;
					justify-content: center;
					align-items: center;
				}
				.react-tabs__tab:last-child {
					position: absolute;
					right: 20px;
					top: 0;
				}
				.react-tabs__tab--selected {
					border-bottom: none;
				}
			}
			.react-tabs__tab-panel {
				height: calc(100% - 30px);
			}
		}
	}

}

.game-score {
	&__container {
		margin-top: 12px;
	}
	&__label {
		font-family: 'Oswald';
		font-weight: 500;
		font-size: 20px;
		line-height: 24px;
		text-transform: uppercase;
		color: #fff;
		opacity: 0.4;
		font-style: normal;
		margin-bottom: 10px;
	}
}

.game {
	display: flex;
	justify-content: center;
	align-items: flex-end;
	width: 100%;
	.player {
		margin: 10px 15px 10px 15px;
		width: 50%;
		max-width: 942px;
		position: relative;
		.finish__check-user {
			position: absolute;
			right: -5px;
			bottom: 65px;
			height: 30px;
			width: 30px;
			border-radius: 15px;
			background: linear-gradient(to bottom, #20a39e, #01677e);
			display: flex;
			justify-content: center;
			align-items: center;
			&.with-protest {
				background: linear-gradient(to bottom, #f59c86, #f65932);
			}
		}
		&.owner {
			margin-left: 25px;
		}
		.player-head {
			justify-content: space-between;
			display: flex;
		}
		.names {
			.fullname {
				max-width: 300px;
				height: 22px;
				font-size: 16px;
				line-height: 22px;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
			}
			.login {
				width: 100%;
				height: 22px;
				line-height: 22px;
				font-size: 14px;
				color: #f65932;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
			}
		}
		.lane {
			margin-left: 30px;
			line-height: 22px;
			text-align: right;
			width: calc(50% - 30px);
			.flag-icon {
				width: 18px;
				height: 18px;
				border-radius: 50%;
				margin-right: 10px;
			}
			.bowling {
				width: 100%;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
			}
		}
		.game-score {
			margin-top: 10px;
		}
	}
}