$orangey-red: #f65932;

.erroredform {
  background-color: #f65932aa;
}

.personal-outlet {
  .personal-outlet__header {
    padding: 20px 40px;
    border-bottom: 1px solid rgba(196, 196, 196, 0.4);
  }
  .user-info {
    display: flex;
    flex-direction: row;
  }
  .user-info__avatar {
    margin-right: 30px;
    position: relative;
    padding-right: 5px;
  }
  .avatar__wrap {
    width: 110px;
    height: 110px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $orangey-red;
    background: -moz-linear-gradient(top, $orangey-red 0%, #f59c86 100%);
    background: -webkit-linear-gradient(top, $orangey-red 0%,#f59c86 100%);
    background: linear-gradient(to bottom, $orangey-red 0%,#f59c86 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$orangey-red', endColorstr='#f59c86',GradientType=0 );
    overflow: hidden;
    position: relative;
    img {
      width: calc(100% - 6px);
      height: calc(100% - 6px);
      border-radius: 50%;
    }
  }
  .change-avatar {
    position: absolute;
    height: 30px;
    width: calc(100% - 6px - 5px);
    left: 3px;
    right: 3px;
    bottom: 3px;
    overflow: hidden;
    .half-circle {
      height: 104px;
      width: 100%;
      border-radius: 0 0 100% 100%;
      background-color: rgba(0, 0, 0, 0.4);
      margin-top: -74px;
      display: flex;
      align-items: flex-end;
      overflow: hidden;
      span {
        cursor: pointer;
        flex: 1 1;
        height: 25px;
        font-size: 12px;
        text-align: center;
      }
    }
  }
  .city-flag {
    position: absolute;
    background-color: #fff;
    width: 33px;
    height: 33px;
    bottom: 0;
    right: 0;
    border-radius: 50%;
    overflow: hidden;
    span {
      width: 100%;
      height: 100%;
      background-size: cover;
      display: block;
    }
  }
  .avgScore {
    color: #fff;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    height: 14px;
    width: 25px;
    background: #252B32;
    border-radius: 7px;
    position: absolute;
    bottom: 0;
    left: 10px;
    position: absolute;
    z-index: 1;
  }
  .user-info__text {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    .text_full-name {
      font-size: 30px;
      font-weight: 300;
      font-family: Oswald;
      color: #fff;
      line-height: 36px;
      vertical-align: bottom;
      margin-bottom: 10px;
      span {
      }
    }
    .display_name {
      font-size: 20px;
      line-height: 28px;
      color: $orangey-red;
      vertical-align: middle;
      span {
      }
    }
  }
  .personal-outlet__body {
    padding: 8px 40px;
  }
  .user-data {
    max-width: 465px;
  }
  .row__group,
  .form__group {
    display: flex;
    height: 40px;
    align-items: center;
    border-bottom: 1px solid #25292c;
    font-size: 14px;
    color: #fff;
    font-weight: 300;
    &:last-child {
      border-bottom: 0;
    }
    .row__title,
    .form__label {
      opacity: .6;
      flex: 1;
    }
    .row__text,
    .form__control {
      flex: 1;
      [class*="css-"] {
        color: #fff;
      }
      .filter-input__data {
        width: 100%;
      }
      .ant-calendar-picker {
        padding: 0;
        .ant-calendar-picker-input {
          width: calc(100% - 22px);
        }
      }
    }
    .row__button {
      background-color: $orangey-red;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 20px;
      border: 0;
      color: #fff;
      font-weight: 300;
      margin-top: 20px;
      cursor: pointer;
      transition: opacity .1s;
      min-width: 170px;
      &.passchange_button {
        background-color: transparent;
        color: $orangey-red;
        margin-left: 10px;
        border: 1px solid $orangey-red;
      }
      &:hover {
        opacity: .8;
      }
      &.form__button_submit1 {
        color: #f65932;
        margin-right: 10px;
        background-color: transparent !important;
        border: 1px solid #f65932;
      }
      i {
        font-size: 18px;
        padding: 6px 6px 6px 0;
      }
    }
  }
}
