@charset "UTF-8";
.landing {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #0B141F; }
  .landing,
  .landing * {
    box-sizing: border-box; }
  .landing .mobileapp,
  .landing .application,
  .landing .slider,
  .landing .features {
    cursor: grab; }
    .landing .mobileapp.touched,
    .landing .application.touched,
    .landing .slider.touched,
    .landing .features.touched {
      cursor: grabbing; }
    .landing .mobileapp *,
    .landing .application *,
    .landing .slider *,
    .landing .features * {
      user-drag: none;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-drag: none;
      -webkit-user-select: none;
      -ms-user-select: none; }
  .landing .another-bg {
    position: relative;
    background: url("./images/background2.png");
    background-repeat: repeat-y;
    margin-bottom: 200px;
    padding: 50px 0; }
    .landing .another-bg::before {
      position: absolute;
      content: '';
      background: url("./images/triangle.png");
      height: 100px;
      width: 100%;
      top: -100px;
      left: 0; }
    .landing .another-bg::after {
      position: absolute;
      content: '';
      background: url("./images/triangle.png");
      height: 100px;
      width: 100%;
      bottom: -100px;
      left: 0;
      transform: scale(-1, -1);
      background-position: right; }
  .landing__full_container {
    max-width: 2200px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    background-image: url("./images/background.png");
    background-repeat: repeat-y;
    background-position: center; }
  .landing__container {
    max-width: 1200px;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 auto; }
  .landing__header {
    background-image: url("./images/header_bg.png");
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-height: 767px; }
    .landing__header > .landing__full_container {
      position: relative; }
    .landing__header__center {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: center; }
    .landing__header__top {
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between; }
      .landing__header__top .tournamentBadge {
        width: 162px;
        margin-top: -23px; }
      .landing__header__top > .links {
        display: flex;
        flex-direction: row; }
        .landing__header__top > .links > * {
          margin-right: 30px; }
        .landing__header__top > .links .dropdown {
          position: relative;
          margin-right: 0; }
          .landing__header__top > .links .dropdown__menu {
            position: absolute;
            top: 46px;
            left: 0;
            background: #FFFFFF;
            border-radius: 5px;
            z-index: 10; }
            .landing__header__top > .links .dropdown__menu .linkContainer {
              display: flex;
              margin: 10px 12px 0 12px; }
            .landing__header__top > .links .dropdown__menu .dropdown__menu__item {
              width: 100%;
              min-height: 24px;
              line-height: 10px;
              align-self: center;
              text-transform: none;
              flex-wrap: wrap;
              color: #333333; }
          .landing__header__top > .links .dropdown .linkContainer:last-child {
            margin-bottom: 10px; }
        .landing__header__top > .links > .install.active {
          color: #F65932; }
      .landing__header__top > .burger {
        display: none;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        align-items: center; }
        .landing__header__top > .burger > div {
          background-color: #fff;
          width: 30px;
          height: 2px;
          margin-bottom: 6px; }
        .landing__header__top > .burger > div:last-child {
          margin-bottom: 0; }
      .landing__header__top > .burger {
        display: none;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        align-items: center; }
        .landing__header__top > .burger > div {
          background-color: #fff;
          width: 30px;
          height: 2px;
          margin-bottom: 6px; }
        .landing__header__top > .burger > div:last-child {
          margin-bottom: 0; }
    .landing__header__bottom {
      display: flex;
      flex-direction: row;
      margin-top: 130px;
      justify-content: space-between; }
      .landing__header__bottom .left-side {
        flex: 0 1 489px; }
        .landing__header__bottom .left-side .slogan {
          position: relative;
          font-family: Oswald;
          font-style: normal;
          font-weight: normal;
          font-size: 36px;
          line-height: 53px;
          letter-spacing: 0.05em;
          text-transform: uppercase;
          color: #FFFFFF;
          margin-top: 51px;
          margin-bottom: 34px;
          padding: 5px 0;
          z-index: 1;
          display: inline-block; }
          .landing__header__bottom .left-side .slogan__mask {
            position: absolute;
            left: 50%;
            top: 0; }
            .landing__header__bottom .left-side .slogan__mask:before {
              content: '';
              position: absolute;
              width: 1140px;
              height: 62px;
              top: 0;
              left: -50%;
              margin-left: -570px;
              z-index: -1;
              background: linear-gradient(270deg, rgba(232, 0, 0, 0) 1.3%, #E80000 52.12%, rgba(232, 0, 0, 0) 100%); }
        .landing__header__bottom .left-side .description {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          line-height: 32px;
          color: #FFFFFF;
          opacity: 0.9;
          margin: 0; }
        .landing__header__bottom .left-side .actions {
          margin-top: 30px;
          display: flex;
          flex-direction: row;
          justify-content: space-between; }
          .landing__header__bottom .left-side .actions > .btn.big.bordered {
            margin-right: 20px; }
      .landing__header__bottom .right-side {
        flex: 0 0 auto; }
        .landing__header__bottom .right-side .phone {
          position: relative;
          display: flex;
          flex-direction: column; }
          .landing__header__bottom .right-side .phone img {
            position: relative;
            z-index: 1;
            margin-top: auto; }
          .landing__header__bottom .right-side .phone__screen {
            position: absolute;
            background: #000;
            top: 14px;
            left: 55px;
            width: 208px;
            height: 448px;
            z-index: 0; }
          .landing__header__bottom .right-side .phone__play {
            position: absolute;
            top: -57px;
            left: 243px; }
            .landing__header__bottom .right-side .phone__play__big_bubble {
              width: 163px;
              height: 163px;
              background: linear-gradient(180deg, #F65932 0%, rgba(246, 89, 50, 0.2) 45.84%, rgba(246, 89, 50, 0) 100%);
              border-radius: calc(100% / 2);
              animation: pulsePlayBubble ease-out 1.5s infinite alternate; }
            .landing__header__bottom .right-side .phone__play__small_bubble {
              position: absolute;
              top: 27px;
              left: 41px;
              width: 80px;
              height: 80px;
              background: rgba(255, 255, 255, 0.2);
              border-radius: calc(100% / 2);
              animation: pulsePlayBubble ease-out 1.5s infinite alternate; }
            .landing__header__bottom .right-side .phone__play__button {
              position: absolute;
              top: 37px;
              left: 51px;
              width: 60px;
              height: 60px;
              background: #FFFFFF;
              border-radius: calc(100% / 2);
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              z-index: 2; }
              .landing__header__bottom .right-side .phone__play__button svg {
                margin-left: 2px; }
  .landing {
    flex-direction: column;
    overflow: hidden; }
  .landing .fullcontainer {
    max-width: 1200px;
    margin: auto;
    width: 100%; }
  .landing h2 {
    margin: 0 0 10px 0;
    text-transform: uppercase;
    font-family: 'Oswald';
    font-size: 60px;
    letter-spacing: 0.05em;
    text-align: center;
    font-weight: normal;
    line-height: 90px;
    color: #fff; }
    .landing h2:first-letter {
      color: #F65932; }
  .landing h3 {
    color: #D0D2D5;
    letter-spacing: 0.05em;
    font-family: 'Oswald';
    font-weight: 300;
    font-size: 40px;
    margin: 0;
    text-align: center;
    line-height: 60px; }
  .landing .application {
    margin-top: 115px !important; }
    .landing .application .containerbox .representation .bubble {
      background: linear-gradient(180deg, #008F28 0%, rgba(31, 165, 44, 0.2) 45.84%, rgba(13, 146, 42, 0) 100%); }
      .landing .application .containerbox .representation .bubble:nth-child(1) {
        right: 0;
        top: 50px;
        width: 363px;
        height: 363px;
        animation-duration: 4s;
        transform: translateY(25%); }
    .landing .application .containerbox .imgwrap::after {
      background: linear-gradient(180deg, #008F28 0%, rgba(31, 165, 44, 0.2) 45.84%, rgba(13, 146, 42, 0) 100%); }
  .landing .features .containerbox .representation {
    position: relative; }
    .landing .features .containerbox .representation .bubble {
      background: linear-gradient(180deg, #F65932 0%, rgba(246, 89, 50, 0.2) 45.84%, rgba(246, 89, 50, 0) 100%); }
      .landing .features .containerbox .representation .bubble:nth-child(1) {
        left: 50px;
        top: 130px;
        width: 208px;
        height: 208px;
        animation-duration: 3s;
        animation-delay: -1s;
        transform: translateY(-25%); }
      .landing .features .containerbox .representation .bubble:nth-child(2) {
        right: 85px;
        bottom: 0;
        width: 320px;
        height: 320px;
        animation-duration: 5s;
        animation-delay: -5s;
        transform: translateY(-50%); }
  .landing .features .containerbox .imgwrap::after {
    background: linear-gradient(180deg, #F65932 0%, rgba(246, 89, 50, 0.2) 45.84%, rgba(246, 89, 50, 0) 100%); }
  .landing .features .underlay {
    align-items: center; }
  .landing .features .overlay {
    width: 100%;
    max-width: 282px; }
  .landing .features,
  .landing .application {
    user-select: none;
    margin: 0 auto 150px auto; }
    .landing .features .containerbox,
    .landing .application .containerbox {
      padding-top: 50px;
      display: flex;
      flex-direction: row;
      align-items: center; }
      .landing .features .containerbox > div,
      .landing .application .containerbox > div {
        flex: 0 0 50%; }
      .landing .features .containerbox .representation,
      .landing .application .containerbox .representation {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 560px; }
        .landing .features .containerbox .representation .underlay,
        .landing .application .containerbox .representation .underlay {
          position: absolute;
          top: 0;
          bottom: 0;
          z-index: 2;
          display: flex;
          justify-content: center;
          flex-direction: column;
          overflow: hidden;
          border-radius: 34%; }
          .landing .features .containerbox .representation .underlay img,
          .landing .application .containerbox .representation .underlay img {
            padding: 5%;
            height: 100%; }
          .landing .features .containerbox .representation .underlay video,
          .landing .application .containerbox .representation .underlay video {
            position: absolute;
            padding: 3%;
            height: 100%;
            z-index: 0;
            -webkit-transition: all 1s;
            transition: all 0.5s;
            width: 100%;
            opacity: 0;
            z-index: 0;
            border-radius: 10%; }
            .landing .features .containerbox .representation .underlay video.active,
            .landing .application .containerbox .representation .underlay video.active {
              opacity: 1;
              z-index: 1; }
        .landing .features .containerbox .representation .overlay,
        .landing .application .containerbox .representation .overlay {
          position: absolute;
          z-index: 3;
          top: 0;
          bottom: 0;
          display: flex;
          justify-content: center; }
          .landing .features .containerbox .representation .overlay > img,
          .landing .features .containerbox .representation .overlay .content,
          .landing .application .containerbox .representation .overlay > img,
          .landing .application .containerbox .representation .overlay .content {
            position: absolute;
            opacity: 1;
            animation: slideup 0.4s ease-out; }
        .landing .features .containerbox .representation .bubble,
        .landing .application .containerbox .representation .bubble {
          position: absolute;
          border-radius: 50%;
          animation: customBounce ease-in-out infinite alternate;
          z-index: 1; }
        .landing .features .containerbox .representation .frameimg,
        .landing .application .containerbox .representation .frameimg {
          height: 700px;
          max-height: 100%;
          object-fit: contain;
          position: relative;
          z-index: 3;
          border-radius: 13%; }
          .landing .features .containerbox .representation .frameimg.toned,
          .landing .application .containerbox .representation .frameimg.toned {
            background: rgba(16, 26, 39, 0.6); }
      .landing .features .containerbox .mobile-captions,
      .landing .features .containerbox .captions,
      .landing .application .containerbox .mobile-captions,
      .landing .application .containerbox .captions {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start; }
        .landing .features .containerbox .mobile-captions .item,
        .landing .features .containerbox .captions .item,
        .landing .application .containerbox .mobile-captions .item,
        .landing .application .containerbox .captions .item {
          display: flex;
          align-items: flex-start;
          opacity: 0.5;
          cursor: pointer;
          transition: opacity 1s ease-out; }
          .landing .features .containerbox .mobile-captions .item.active,
          .landing .features .containerbox .captions .item.active,
          .landing .application .containerbox .mobile-captions .item.active,
          .landing .application .containerbox .captions .item.active {
            opacity: 1; }
            .landing .features .containerbox .mobile-captions .item.active .imgwrap::after,
            .landing .features .containerbox .captions .item.active .imgwrap::after,
            .landing .application .containerbox .mobile-captions .item.active .imgwrap::after,
            .landing .application .containerbox .captions .item.active .imgwrap::after {
              opacity: 1; }
          .landing .features .containerbox .mobile-captions .item .imgwrap::after,
          .landing .features .containerbox .captions .item .imgwrap::after,
          .landing .application .containerbox .mobile-captions .item .imgwrap::after,
          .landing .application .containerbox .captions .item .imgwrap::after {
            content: '';
            position: absolute;
            height: 225%;
            width: 225%;
            border-radius: 50%;
            opacity: 0;
            z-index: 1;
            transition: opacity 2s ease-out; }
          .landing .features .containerbox .mobile-captions .item .imgwrap,
          .landing .features .containerbox .captions .item .imgwrap,
          .landing .application .containerbox .mobile-captions .item .imgwrap,
          .landing .application .containerbox .captions .item .imgwrap {
            position: relative;
            flex: 0 0 auto;
            margin-right: 50px;
            width: 32px;
            height: 32px;
            margin-top: 5px;
            display: flex;
            justify-content: center;
            align-items: center; }
            .landing .features .containerbox .mobile-captions .item .imgwrap img,
            .landing .features .containerbox .captions .item .imgwrap img,
            .landing .application .containerbox .mobile-captions .item .imgwrap img,
            .landing .application .containerbox .captions .item .imgwrap img {
              height: 100%;
              width: 100%;
              object-fit: contain;
              position: relative;
              z-index: 2; }
          .landing .features .containerbox .mobile-captions .item .textwrap,
          .landing .features .containerbox .captions .item .textwrap,
          .landing .application .containerbox .mobile-captions .item .textwrap,
          .landing .application .containerbox .captions .item .textwrap {
            flex: 1 1 100%; }
            .landing .features .containerbox .mobile-captions .item .textwrap .title,
            .landing .features .containerbox .mobile-captions .item .textwrap .text,
            .landing .features .containerbox .captions .item .textwrap .title,
            .landing .features .containerbox .captions .item .textwrap .text,
            .landing .application .containerbox .mobile-captions .item .textwrap .title,
            .landing .application .containerbox .mobile-captions .item .textwrap .text,
            .landing .application .containerbox .captions .item .textwrap .title,
            .landing .application .containerbox .captions .item .textwrap .text {
              display: block; }
            .landing .features .containerbox .mobile-captions .item .textwrap .title > img,
            .landing .features .containerbox .captions .item .textwrap .title > img,
            .landing .application .containerbox .mobile-captions .item .textwrap .title > img,
            .landing .application .containerbox .captions .item .textwrap .title > img {
              display: none; }
            .landing .features .containerbox .mobile-captions .item .textwrap .title:not(:last-child),
            .landing .features .containerbox .captions .item .textwrap .title:not(:last-child),
            .landing .application .containerbox .mobile-captions .item .textwrap .title:not(:last-child),
            .landing .application .containerbox .captions .item .textwrap .title:not(:last-child) {
              margin-bottom: 10px; }
            .landing .features .containerbox .mobile-captions .item .textwrap .title,
            .landing .features .containerbox .captions .item .textwrap .title,
            .landing .application .containerbox .mobile-captions .item .textwrap .title,
            .landing .application .containerbox .captions .item .textwrap .title {
              letter-spacing: 0.05em;
              font-family: 'Oswald';
              font-size: 30px;
              line-height: 44px; }
            .landing .features .containerbox .mobile-captions .item .textwrap .text,
            .landing .features .containerbox .captions .item .textwrap .text,
            .landing .application .containerbox .mobile-captions .item .textwrap .text,
            .landing .application .containerbox .captions .item .textwrap .text {
              font-size: 16px;
              line-height: 25px;
              display: flex; }
              .landing .features .containerbox .mobile-captions .item .textwrap .text a,
              .landing .features .containerbox .captions .item .textwrap .text a,
              .landing .application .containerbox .mobile-captions .item .textwrap .text a,
              .landing .application .containerbox .captions .item .textwrap .text a {
                margin-top: 15px;
                text-transform: uppercase;
                letter-spacing: 0.05em;
                text-decoration-line: underline;
                font-size: 16px;
                line-height: 24px;
                font-family: 'Oswald'; }
              .landing .features .containerbox .mobile-captions .item .textwrap .text .btn,
              .landing .features .containerbox .captions .item .textwrap .text .btn,
              .landing .application .containerbox .mobile-captions .item .textwrap .text .btn,
              .landing .application .containerbox .captions .item .textwrap .text .btn {
                white-space: nowrap; }
                .landing .features .containerbox .mobile-captions .item .textwrap .text .btn:not(:last-child),
                .landing .features .containerbox .captions .item .textwrap .text .btn:not(:last-child),
                .landing .application .containerbox .mobile-captions .item .textwrap .text .btn:not(:last-child),
                .landing .application .containerbox .captions .item .textwrap .text .btn:not(:last-child) {
                  margin-right: 10px; }
                .landing .features .containerbox .mobile-captions .item .textwrap .text .btn:last-child,
                .landing .features .containerbox .captions .item .textwrap .text .btn:last-child,
                .landing .application .containerbox .mobile-captions .item .textwrap .text .btn:last-child,
                .landing .application .containerbox .captions .item .textwrap .text .btn:last-child {
                  margin-right: -75px; }
        .landing .features .containerbox .mobile-captions .item:not(:last-child),
        .landing .features .containerbox .captions .item:not(:last-child),
        .landing .application .containerbox .mobile-captions .item:not(:last-child),
        .landing .application .containerbox .captions .item:not(:last-child) {
          margin-bottom: 30px; }
      .landing .features .containerbox .slider,
      .landing .features .containerbox .mobile-captions,
      .landing .application .containerbox .slider,
      .landing .application .containerbox .mobile-captions {
        display: none; }
  .landing .features .containerbox .representation .overlay video {
    position: absolute;
    padding: 3%;
    height: 100%;
    z-index: 0;
    -webkit-transition: all 1s;
    transition: all 0.5s;
    width: 100%;
    opacity: 0;
    z-index: 0;
    border-radius: 14%;
    bottom: 0; }
    .landing .features .containerbox .representation .overlay video.b1, .landing .features .containerbox .representation .overlay video.result {
      animation: slideup 0.5s ease-out; }
    .landing .features .containerbox .representation .overlay video.active {
      opacity: 1; }
      .landing .features .containerbox .representation .overlay video.active.b1, .landing .features .containerbox .representation .overlay video.active.result {
        z-index: 5; }
  .landing .slider {
    margin: 0 auto 200px;
    justify-content: center; }
    .landing .slider .containerbox {
      position: relative;
      padding-top: 40px; }
      .landing .slider .containerbox .mobile-slider {
        display: none; }
      .landing .slider .containerbox .sliderbox {
        position: absolute;
        height: 31%;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 2; }
        .landing .slider .containerbox .sliderbox .control {
          display: flex;
          justify-content: center;
          margin-top: 100px; }
          .landing .slider .containerbox .sliderbox .control .left,
          .landing .slider .containerbox .sliderbox .control .right {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 15px;
            cursor: pointer;
            transform: translateX(0);
            transition: transform 1s ease-in-out;
            user-select: none; }
            .landing .slider .containerbox .sliderbox .control .left.left:hover,
            .landing .slider .containerbox .sliderbox .control .right.left:hover {
              transform: translateX(-25px); }
            .landing .slider .containerbox .sliderbox .control .left.right:hover,
            .landing .slider .containerbox .sliderbox .control .right.right:hover {
              transform: translateX(25px); }
          .landing .slider .containerbox .sliderbox .control span {
            font-family: 'Oswald';
            font-weight: 300;
            letter-spacing: 0.05em;
            font-size: 30px; }
            .landing .slider .containerbox .sliderbox .control span.numcur {
              color: #FFFFFF; }
            .landing .slider .containerbox .sliderbox .control span.numall {
              color: #797C80; }
        .landing .slider .containerbox .sliderbox .images {
          display: flex;
          justify-content: center;
          align-items: center;
          transform-style: preserve-3d;
          perspective: 775px;
          height: 100%; }
          .landing .slider .containerbox .sliderbox .images .elem {
            width: 35%;
            position: absolute;
            transition: transform 1s ease-in-out; }
            .landing .slider .containerbox .sliderbox .images .elem img,
            .landing .slider .containerbox .sliderbox .images .elem video {
              display: block;
              width: 100%;
              height: auto;
              border-radius: 20px; }
            .landing .slider .containerbox .sliderbox .images .elem span {
              position: absolute;
              bottom: 20px;
              left: 0;
              right: 0;
              text-align: center;
              font-family: 'Oswald';
              letter-spacing: 0.05em;
              font-size: 30px;
              transition: color 1s ease-in-out; }
      .landing .slider .containerbox > img {
        margin: auto;
        display: block;
        width: 343px;
        max-width: 33%;
        z-index: 1;
        position: relative; }
      .landing .slider .containerbox .bubble {
        background: linear-gradient(180deg, #20A39E 0%, rgba(32, 163, 158, 0.2) 45.84%, rgba(50, 246, 234, 0) 100%);
        border-radius: 50%;
        position: absolute;
        z-index: 0;
        animation: customBounce ease-in-out infinite alternate; }
        .landing .slider .containerbox .bubble:nth-child(1) {
          height: 43px;
          width: 43px;
          bottom: 65%;
          right: calc(50% + 375px);
          animation-duration: 5s;
          animation-delay: -1s;
          transform: translateY(100%); }
        .landing .slider .containerbox .bubble:nth-child(2) {
          height: 133px;
          width: 133px;
          bottom: 25%;
          right: calc(50% + 200px);
          animation-duration: 7s;
          animation-delay: -4s;
          transform: translateY(-100%); }
        .landing .slider .containerbox .bubble:nth-child(3) {
          height: 44px;
          width: 44px;
          bottom: 55%;
          left: calc(50% + 150px);
          animation-duration: 7s;
          animation-delay: -3.5s;
          transform: translateY(50%); }
        .landing .slider .containerbox .bubble:nth-child(4) {
          height: 133px;
          width: 133px;
          bottom: 55%;
          left: calc(50% + 324px);
          animation-duration: 4s;
          transform: translateY(30%); }
  .landing .mobileapp {
    position: relative;
    margin: 70px auto 150px auto; }
    .landing .mobileapp h2,
    .landing .mobileapp h3 {
      display: none; }
    .landing .mobileapp .background {
      position: absolute;
      z-index: 0;
      border-radius: 50%;
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      opacity: 0;
      transition: opacity 1.5s ease-out; }
      .landing .mobileapp .background.active {
        opacity: 1; }
      .landing .mobileapp .background > div {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        max-width: 500px; }
        .landing .mobileapp .background > div * {
          position: absolute;
          padding-bottom: 100%;
          width: 100%;
          border-radius: 50%; }
      .landing .mobileapp .background .circleone {
        background: rgba(32, 163, 158, 0.1);
        transform: scale(0.95);
        animation-name: pulseCircleMid;
        animation-duration: 2.5s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
        animation-direction: alternate;
        filter: drop-shadow(0 0 150px #20A39E); }
      .landing .mobileapp .background .circletwo {
        background: linear-gradient(180deg, #20A39E 0%, rgba(32, 163, 158, 0) 100%);
        filter: drop-shadow(0 0 150px #20A39E);
        transform: scale(0.75);
        animation-name: pulseCircleLow;
        animation-duration: 1.5s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
        animation-direction: alternate;
        animation-delay: -1.2s; }
      .landing .mobileapp .background .circlethree {
        transform: scale(1.15);
        border: 1px dashed #20A39E;
        animation-name: spinDashedCircle;
        animation-duration: 120s;
        animation-iteration-count: infinite;
        animation-timing-function: linear; }
      .landing .mobileapp .background.greenone .circleone {
        background: rgba(32, 163, 158, 0.1);
        filter: drop-shadow(0 0 150px #008F28); }
      .landing .mobileapp .background.greenone .circletwo {
        background: linear-gradient(180deg, #008F28 0%, rgba(32, 163, 158, 0) 100%);
        filter: drop-shadow(0 0 150px #008F28); }
      .landing .mobileapp .background.greenone .circlethree {
        border: 1px dashed #008F28; }
    .landing .mobileapp .slidewrap {
      position: relative; }
    .landing .mobileapp .phonebox {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 70px 0;
      position: relative;
      transition: opacity 1.5s ease-out, transform 1s ease-out; }
      .landing .mobileapp .phonebox:nth-child(1) {
        opacity: 0;
        transform: translateX(-100%); }
      .landing .mobileapp .phonebox:nth-child(2) {
        opacity: 0;
        transform: translateX(100%); }
      .landing .mobileapp .phonebox.active {
        opacity: 1;
        transform: translateX(0);
        position: relative; }
      .landing .mobileapp .phonebox:not(.active) {
        position: absolute;
        left: 0;
        right: 0;
        top: 0; }
      .landing .mobileapp .phonebox .mobile-pointers {
        display: none; }
      .landing .mobileapp .phonebox .mobile-pointers,
      .landing .mobileapp .phonebox .pointers {
        position: absolute;
        top: 70px;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 6; }
        .landing .mobileapp .phonebox .mobile-pointers .pointerBox,
        .landing .mobileapp .phonebox .pointers .pointerBox {
          color: #fff;
          font-size: 40px;
          height: 60px;
          background: #3D4551;
          border-radius: 10px 10px 0px 0px;
          border-bottom: 2px solid #F65932;
          text-transform: uppercase;
          padding: 0 25px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          white-space: nowrap;
          font-family: 'Oswald';
          letter-spacing: 0.05em;
          font-weight: 300; }
        .landing .mobileapp .phonebox .mobile-pointers .ponterLine,
        .landing .mobileapp .phonebox .pointers .ponterLine {
          position: absolute;
          border-style: solid;
          border-color: #F65932;
          border-width: 0; }
          .landing .mobileapp .phonebox .mobile-pointers .ponterLine.l,
          .landing .mobileapp .phonebox .pointers .ponterLine.l {
            border-left-width: 2px; }
          .landing .mobileapp .phonebox .mobile-pointers .ponterLine.r,
          .landing .mobileapp .phonebox .pointers .ponterLine.r {
            border-right-width: 2px; }
          .landing .mobileapp .phonebox .mobile-pointers .ponterLine.b,
          .landing .mobileapp .phonebox .pointers .ponterLine.b {
            border-bottom-width: 2px; }
          .landing .mobileapp .phonebox .mobile-pointers .ponterLine.t,
          .landing .mobileapp .phonebox .pointers .ponterLine.t {
            border-top-width: 2px; }
          .landing .mobileapp .phonebox .mobile-pointers .ponterLine.l.t,
          .landing .mobileapp .phonebox .pointers .ponterLine.l.t {
            border-radius: 40px 0 0 0; }
          .landing .mobileapp .phonebox .mobile-pointers .ponterLine.t.r,
          .landing .mobileapp .phonebox .pointers .ponterLine.t.r {
            border-radius: 0 40px 0 0; }
          .landing .mobileapp .phonebox .mobile-pointers .ponterLine.r.b,
          .landing .mobileapp .phonebox .pointers .ponterLine.r.b {
            border-radius: 0 0 40px 0; }
          .landing .mobileapp .phonebox .mobile-pointers .ponterLine.b.l,
          .landing .mobileapp .phonebox .pointers .ponterLine.b.l {
            border-radius: 0 0 0 40px; }
        .landing .mobileapp .phonebox .mobile-pointers .pointerDot,
        .landing .mobileapp .phonebox .pointers .pointerDot {
          position: absolute;
          background: #F65932;
          height: 12px;
          width: 12px;
          border-radius: 6px;
          display: flex;
          justify-content: center;
          align-items: center; }
          .landing .mobileapp .phonebox .mobile-pointers .pointerDot::after,
          .landing .mobileapp .phonebox .pointers .pointerDot::after {
            position: absolute;
            background: #F65932;
            width: 12px;
            height: 12px;
            border-radius: 6px;
            content: '';
            animation: pulseSmallDot 2s ease-out infinite; }
          .landing .mobileapp .phonebox .mobile-pointers .pointerDot:nth-child(12n+3)::after,
          .landing .mobileapp .phonebox .pointers .pointerDot:nth-child(12n+3)::after {
            animation-delay: -0.5s; }
          .landing .mobileapp .phonebox .mobile-pointers .pointerDot:nth-child(12n+6)::after,
          .landing .mobileapp .phonebox .pointers .pointerDot:nth-child(12n+6)::after {
            animation-delay: -1s; }
          .landing .mobileapp .phonebox .mobile-pointers .pointerDot:nth-child(12n+9)::after,
          .landing .mobileapp .phonebox .pointers .pointerDot:nth-child(12n+9)::after {
            animation-delay: -1.5s; }
      .landing .mobileapp .phonebox img {
        position: relative;
        z-index: 5;
        max-width: 40vw;
        max-height: 560px; }
    .landing .mobileapp .switcher {
      position: relative;
      z-index: 1;
      display: flex;
      justify-content: center; }
      .landing .mobileapp .switcher .switch {
        background: rgba(255, 255, 255, 0.4);
        border-radius: 26px;
        width: 60px;
        height: 5px;
        margin: 10px;
        cursor: pointer; }
        .landing .mobileapp .switcher .switch .fill {
          width: 0;
          height: 100%;
          background-color: #FFFFFF;
          border-radius: 26px; }
    @media screen and (max-width: 1300px) {
      .landing .mobileapp .phonebox .pointerBox {
        font-size: 30px !important;
        margin-top: 20px !important;
        height: 40px !important; } }
    @media screen and (max-width: 1120px) {
      .landing .mobileapp .phonebox .pointerBox {
        font-size: 20px !important;
        margin-top: 35px !important;
        height: 25px !important; } }
    @media screen and (max-width: 940px) {
      .landing .mobileapp .phonebox .pointerBox {
        font-size: 20px !important;
        margin-top: 35px !important;
        height: 25px !important; } }
  .landing .mainrules {
    margin: 30px auto 280px;
    display: flex;
    flex-direction: column; }
    .landing .mainrules .ruleslist {
      padding: 70px 0 30px;
      display: flex;
      flex-wrap: wrap; }
      .landing .mainrules .ruleslist .rule {
        flex: 0 0 50%;
        display: flex;
        align-items: center;
        margin-bottom: 70px; }
        @media screen and (max-width: 767px) {
          .landing .mainrules .ruleslist .rule {
            flex: 0 0 100%; } }
        .landing .mainrules .ruleslist .rule .imgwrap {
          flex: 0 0 auto;
          margin-right: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 72px;
          width: 72px;
          border-radius: 36px;
          background: linear-gradient(180deg, #008F28 0%, rgba(31, 165, 44, 0.2) 45.84%, rgba(13, 146, 42, 0) 100%); }
        .landing .mainrules .ruleslist .rule span {
          flex: 1 1 100%;
          color: #D0D2D5;
          font-size: 24px;
          line-height: 33px;
          margin-right: 70px; }
          @media screen and (max-width: 767px) {
            .landing .mainrules .ruleslist .rule span {
              margin-right: 0; } }
    .landing .mainrules .btn {
      width: 100%;
      max-width: 278px;
      align-self: center; }
  .landing .firstTournament {
    display: flex;
    justify-content: center;
    background-color: #EA5A42; }
    .landing .firstTournament .container {
      max-width: 1200px;
      flex-direction: column;
      border-top: 2px solid #FFF;
      margin: 150px 0px; }
      .landing .firstTournament .container .headerBlock {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-top: 30px; }
        .landing .firstTournament .container .headerBlock .headerTitle {
          font-family: Inter;
          font-style: italic;
          font-weight: 900;
          font-size: 64px;
          line-height: 110%;
          letter-spacing: -0.02em;
          text-transform: uppercase;
          margin: 0px;
          text-align: left;
          flex: 0 0 80%; }
          .landing .firstTournament .container .headerBlock .headerTitle::first-letter {
            color: #FFFFFF; }
        .landing .firstTournament .container .headerBlock .headerSmallMsgBlock {
          flex: 0 0 18%;
          margin-left: 10px; }
          .landing .firstTournament .container .headerBlock .headerSmallMsgBlock .headerSmallMessage {
            font-family: Inter;
            font-style: italic;
            font-weight: 900;
            font-size: 20px;
            line-height: 140%;
            text-transform: uppercase;
            color: #702917; }
            .landing .firstTournament .container .headerBlock .headerSmallMsgBlock .headerSmallMessage:nth-child(2) {
              margin-left: -7px; }
          .landing .firstTournament .container .headerBlock .headerSmallMsgBlock .headerSmallMessage.mobile {
            display: none; }
      .landing .firstTournament .container .logoBlock {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 110px 0px 110px 0px; }
        .landing .firstTournament .container .logoBlock .logo {
          width: 100%;
          margin-left: -310px; }
        .landing .firstTournament .container .logoBlock .date {
          width: 389px;
          margin-top: 30px; }
      .landing .firstTournament .container .msgFirstBlock {
        display: flex;
        flex-direction: row;
        margin: 20px 0px; }
        .landing .firstTournament .container .msgFirstBlock .message {
          align-items: flex-start;
          font-family: Technor;
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 140%;
          text-transform: uppercase;
          color: #FFFFFF;
          flex: 0 0 32%; }
          .landing .firstTournament .container .msgFirstBlock .message:nth-child(2) {
            flex: 0 0 65%;
            margin-left: 25px;
            padding-left: 2px; }
      .landing .firstTournament .container .msgSecondBlock {
        display: flex;
        flex-direction: row;
        margin: 20px 0px;
        flex-wrap: wrap;
        justify-content: space-between; }
        .landing .firstTournament .container .msgSecondBlock .message {
          align-items: flex-start;
          margin: 40px 0px;
          padding-top: 10px;
          flex: 0 0 32%;
          border-top: 4px solid #702917;
          font-family: Technor;
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 140%;
          text-transform: uppercase;
          color: #FFFFFF; }
      .landing .firstTournament .container .footerBlock {
        display: flex;
        flex-direction: row;
        justify-content: space-between; }
        .landing .firstTournament .container .footerBlock .footerTitle {
          font-family: Technor;
          font-style: normal;
          font-weight: bold;
          font-size: 42px;
          line-height: 120%;
          text-transform: uppercase;
          color: #FFFFFF;
          letter-spacing: 2px;
          margin: 0px;
          text-align: left;
          flex: 0 0 66%; }
        .landing .firstTournament .container .footerBlock .footerLinkBlock {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          border: 2px solid #FFF;
          flex: 0 0 32%; }
          .landing .firstTournament .container .footerBlock .footerLinkBlock .footerLinkMsg {
            font-family: Technor;
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 140%;
            text-transform: uppercase;
            color: #FFFFFF;
            padding: 15px 0px 0px 15px;
            margin-right: 70px; }
          .landing .firstTournament .container .footerBlock .footerLinkBlock .footerLink {
            align-self: flex-end;
            width: 60px;
            height: 60px;
            margin: 15px 15px 15px 0px;
            text-decoration: none; }
            .landing .firstTournament .container .footerBlock .footerLinkBlock .footerLink .footerArrow {
              width: 60px;
              height: 60px; }
  .landing .downloadlinks {
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.1);
    display: flex;
    flex-wrap: wrap;
    padding-top: 100px;
    margin: 30px auto;
    border-radius: 20px;
    background: linear-gradient(180deg, #20A39E 0%, rgba(32, 163, 158, 0.2) 45.84%, rgba(50, 246, 234, 0) 100%);
    transform: transformY(0); }
    .landing .downloadlinks h2 {
      flex: 0 0 100%;
      margin-bottom: 28px;
      font-size: 60px;
      line-height: 89px; }
      .landing .downloadlinks h2::first-letter {
        color: #FFF; }
    .landing .downloadlinks .links a .links__button {
      display: flex;
      box-sizing: border-box;
      align-items: center;
      justify-content: center; }
      .landing .downloadlinks .links a .links__button > span {
        font-family: Oswald;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.05em;
        color: #FFFFFF; }
  .landing .footer-extended {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center; }
    .landing .footer-extended .footer-description {
      margin-top: 30px;
      font-size: 16px;
      line-height: 22px;
      padding: 0 30px; }
    .landing .footer-extended .footer-description2 {
      font-size: 14px;
      line-height: 19px;
      margin-bottom: 20px; }
  .landing .footer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-end;
    padding: 30px 0; }
    .landing .footer img {
      margin-bottom: 20px; }
    .landing .footer span {
      font-size: 16px;
      height: 32px;
      display: flex;
      align-items: center;
      color: #D0D2D5; }
    .landing .footer .terms {
      text-decoration: underline;
      cursor: pointer; }
    .landing .footer > * {
      display: flex;
      flex-direction: column;
      flex: 0 0 33%; }
      .landing .footer > *:nth-child(1) {
        align-items: flex-start; }
      .landing .footer > *:nth-child(2) {
        align-items: center; }
      .landing .footer > *:nth-child(3) {
        align-items: flex-end; }
      @media screen and (max-width: 767px) {
        .landing .footer > * {
          align-items: center !important;
          flex: 0 0 100%; } }
  .landing .equipment__from {
    padding: 42px 72px 50px 102px;
    background: rgba(196, 196, 196, 0.1);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center; }
    .landing .equipment__from > p {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      color: #D0D2D5;
      margin-top: 20px;
      margin-bottom: 0; }
    .landing .equipment__from .container {
      display: flex;
      justify-content: space-between;
      margin-bottom: 38.5px; }
      .landing .equipment__from .container .left {
        max-width: 451px; }
        .landing .equipment__from .container .left h2 {
          text-align: left;
          font-size: 60px;
          line-height: 75px;
          letter-spacing: 0.05em;
          text-transform: uppercase;
          margin: 0; }
        .landing .equipment__from .container .left p {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          line-height: 27px;
          display: flex;
          align-items: center;
          color: #FFFFFF;
          margin-top: 30.5px;
          margin-bottom: 0; }
      .landing .equipment__from .container .right {
        position: relative;
        max-width: 513px;
        margin-left: 30.5px; }
        .landing .equipment__from .container .right .error {
          position: absolute;
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 22px;
          display: flex;
          align-items: center;
          color: #FF7373;
          bottom: -30px; }
        .landing .equipment__from .container .right p {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
          color: #FFFFFF;
          margin-top: 25px;
          margin-bottom: 10px; }
    .landing .equipment__from .btn {
      width: 483px; }
  .landing .ant-form {
    margin-top: 15.5px; }
    .landing .ant-form .row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 13px; }
      .landing .ant-form .row:last-child {
        margin-bottom: 0; }
      .landing .ant-form .row .ant-form-item {
        display: flex;
        flex: 1; }
        .landing .ant-form .row .ant-form-item .ant-form-item-control-wrapper {
          display: flex;
          flex: 1; }
      .landing .ant-form .row > .ant-form-item {
        margin-right: 13px;
        display: flex;
        flex-direction: column; }
        .landing .ant-form .row > .ant-form-item .ant-form-item-label label {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 22px;
          color: #D0D2D5;
          display: block;
          margin-bottom: 10px; }
        .landing .ant-form .row > .ant-form-item:last-child {
          margin: 0; }
    .landing .ant-form .ant-form-item.no-bg .ant-form-item-control {
      background: none;
      border: none; }
    .landing .ant-form .ant-form-item-control {
      position: relative;
      background: #555C65;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      border-bottom: 1px solid white;
      flex: 1; }
      .landing .ant-form .ant-form-item-control::after {
        bottom: -1px;
        content: '';
        height: 2px;
        left: 45%;
        position: absolute;
        transition-duration: .2s;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        visibility: hidden;
        width: 10px;
        z-index: 1; }
      .landing .ant-form .ant-form-item-control[focused]::after {
        background-color: #1FA29D;
        left: 0;
        visibility: visible;
        width: 100%; }
      .landing .ant-form .ant-form-item-control > .ant-form-explain {
        display: none; }
      .landing .ant-form .ant-form-item-control.has-error {
        border-bottom: 1px solid #F95B5B; }
        .landing .ant-form .ant-form-item-control.has-error[focused]::after {
          background-color: #F95B5B; }
      .landing .ant-form .ant-form-item-control .ant-select {
        position: relative;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 100%; }
        .landing .ant-form .ant-form-item-control .ant-select .ant-select-selection {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 100%;
          padding: 0 40px 0 15px;
          background: none;
          border: none; }
          .landing .ant-form .ant-form-item-control .ant-select .ant-select-selection__rendered {
            margin: 0;
            height: 100%;
            flex: 1; }
          .landing .ant-form .ant-form-item-control .ant-select .ant-select-selection .ant-select-arrow {
            margin-top: -1px; }
          .landing .ant-form .ant-form-item-control .ant-select .ant-select-selection__placeholder {
            color: #D0D2D5; }
          .landing .ant-form .ant-form-item-control .ant-select .ant-select-selection .ant-select-selection-selected-value {
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: calc(100% - 20px);
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 22px;
            color: #ffffff;
            padding: 0;
            display: flex !important;
            align-items: center;
            flex: 1;
            height: 100%; }
      .landing .ant-form .ant-form-item-control .ant-input {
        position: relative;
        border: none;
        background: none;
        outline: none;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
        color: #ffffff;
        padding: 10px 15px 8px 15px;
        width: 100%;
        resize: none; }
        .landing .ant-form .ant-form-item-control .ant-input:focus {
          box-shadow: none; }
        .landing .ant-form .ant-form-item-control .ant-input::placeholder {
          color: #D0D2D5; }
  .landing .benefits h2 {
    margin-bottom: 50.5px; }
  .landing .benefits .container {
    display: flex; }
    .landing .benefits .container .right {
      max-width: 431px;
      margin-left: 76px; }
      .landing .benefits .container .right ol {
        list-style: none;
        margin: 0;
        padding: 0; }
        .landing .benefits .container .right ol li {
          display: flex;
          align-items: center;
          font-family: Oswald;
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          line-height: 30px;
          letter-spacing: 0.05em;
          color: #FFFFFF;
          margin-bottom: 40px; }
          .landing .benefits .container .right ol li:last-child {
            margin-bottom: 0; }
          .landing .benefits .container .right ol li span {
            font-size: 60px;
            line-height: 89px;
            text-transform: uppercase;
            color: #F65932;
            margin-right: 30px; }
  .landing .tournamentBody {
    margin: 0 0 150px 0; }
    .landing .tournamentBody .tournament {
      display: flex;
      margin: 50px 0 0 0; }
      .landing .tournamentBody .tournament .text {
        flex: 1;
        margin-right: 30px; }
      .landing .tournamentBody .tournament .image {
        display: flex;
        flex: 1;
        border-radius: 18px;
        overflow: hidden; }
        .landing .tournamentBody .tournament .image img {
          width: 100%;
          object-fit: cover; }
      .landing .tournamentBody .tournament .description {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 27px;
        color: #D0D2D5;
        margin: 0; }
    .landing .tournamentBody .oswald_text {
      font-family: Oswald;
      font-style: normal;
      font-weight: 200;
      font-size: 40px;
      line-height: 59px;
      text-align: center;
      letter-spacing: 0.05em;
      color: #FFFFFF;
      margin-bottom: 50px; }
    .landing .tournamentBody .tournament-btn {
      display: flex;
      align-items: center;
      justify-content: center; }
      .landing .tournamentBody .tournament-btn > div {
        width: 360px; }
  .landing .alley-list {
    position: relative;
    margin-bottom: 150px; }
    .landing .alley-list h2 {
      margin: 50px 0; }
    .landing .alley-list .bubble {
      border-radius: calc(100% / 2);
      animation: customBounce ease-in-out infinite alternate; }
      .landing .alley-list .bubble:nth-child(1) {
        position: absolute;
        width: 165px;
        height: 165px;
        right: 10px;
        top: 307.5px;
        background: linear-gradient(180deg, rgba(32, 163, 158, 0.4) 0%, rgba(32, 163, 158, 0.2) 45.84%, rgba(50, 246, 234, 0) 100%);
        animation-duration: 4s;
        animation-delay: -1s;
        transform: translateY(50%); }
      .landing .alley-list .bubble:nth-child(2) {
        position: absolute;
        width: 110px;
        height: 110px;
        right: 65px;
        top: 566.5px;
        background: linear-gradient(180deg, rgba(32, 163, 158, 0.6) 0%, rgba(32, 163, 158, 0.2) 45.84%, rgba(50, 246, 234, 0) 100%);
        animation-duration: 6s;
        animation-delay: 1s;
        transform: translateY(-55%); }
      .landing .alley-list .bubble:nth-child(3) {
        position: absolute;
        width: 43px;
        height: 43px;
        right: 234px;
        top: 472.5px;
        background: linear-gradient(180deg, rgba(32, 163, 158, 0.4) 0%, rgba(32, 163, 158, 0.2) 45.84%, rgba(50, 246, 234, 0) 100%);
        animation-duration: 5s;
        animation-delay: -1s;
        transform: translateY(-300%); }
    .landing .alley-list .divider {
      height: 1px;
      width: 100%;
      background: #585E68;
      margin: 15px 0; }
    .landing .alley-list .country {
      display: flex;
      margin-bottom: 25px; }
      .landing .alley-list .country__center_list, .landing .alley-list .country__address_list {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-left: 13px; }
      .landing .alley-list .country__name {
        font-family: Oswald;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 36px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #FFFFFF;
        flex: 0 0 33%;
        padding-right: 15px; }
      .landing .alley-list .country > .bc__details {
        width: 66%; }
        .landing .alley-list .country > .bc__details > .bc__details__item {
          flex-direction: row;
          display: flex;
          margin-left: 15px; }
          .landing .alley-list .country > .bc__details > .bc__details__item > .country__address {
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 36px;
            display: flex;
            align-items: center;
            color: #D0D2D5;
            margin-right: 0;
            margin-bottom: 6px;
            width: 50%; }
          .landing .alley-list .country > .bc__details > .bc__details__item .country__center {
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 36px;
            display: flex;
            align-items: center;
            color: #D0D2D5;
            margin-bottom: 6px;
            margin-right: 0;
            width: 50%;
            margin-left: 15px; }
            .landing .alley-list .country > .bc__details > .bc__details__item .country__center:last-child {
              margin-bottom: 0; }
  .landing__agreement .ant-modal-footer {
    display: none; }
  .landing__agreement .ant-modal-body p {
    font-family: Oswald;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.05em;
    color: #FFFFFF;
    margin-left: 20px; }
  .landing__agreement .ant-modal-body ul {
    list-style: none; }
    .landing__agreement .ant-modal-body ul li {
      margin: 8px 0;
      font-family: 'Open Sans';
      font-size: 14px;
      line-height: 21px;
      color: #FFFFFF; }
      .landing__agreement .ant-modal-body ul li div {
        margin-top: 8px; }
      .landing__agreement .ant-modal-body ul li b, .landing__agreement .ant-modal-body ul li i {
        font-family: 'Open Sans';
        font-size: 14px;
        line-height: 21px; }
      .landing__agreement .ant-modal-body ul li b {
        font-weight: bold; }
      .landing__agreement .ant-modal-body ul li i {
        font-style: italic; }
  .landing .header-left {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center; }
  .landing .header-left__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 80px; }
  .landing .header-center {
    position: relative; }
    .landing .header-center__img__container {
      position: relative;
      top: -20px; }
  .landing .header-center {
    position: relative; }
    .landing .header-center__img__container {
      position: relative;
      top: -20px; }
  .landing .header-lang__select > .ant-select-selection {
    border: 1px solid rgba(255, 255, 255, 0.4);
    box-sizing: border-box;
    border-radius: 2px; }
  .landing .lang-option__container {
    display: flex;
    flex-direction: row;
    align-items: center; }
  .landing .lang-option__img {
    width: 28px;
    height: 20px;
    min-width: 28px;
    min-height: 20px;
    margin-right: 8px; }
  .landing .lang-option__text {
    font-family: Oswald;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */
    letter-spacing: 0.05em; }
  .landing .header-lang__container {
    margin-left: 20px; }

@keyframes spinDashedCircle {
  from {
    transform: rotate(0deg) scale(1.15); }
  to {
    transform: rotate(360deg) scale(1.15); } }

@keyframes pulseCircleLow {
  from {
    transform: scale(0.75); }
  to {
    transform: scale(0.8); } }

@keyframes pulseCircleMid {
  from {
    transform: scale(0.9); }
  to {
    transform: scale(0.95); } }

@keyframes pulseSmallDot {
  from {
    opacity: 1;
    transform: scale(1); }
  to {
    opacity: 0;
    transform: scale(2.5); } }

@keyframes customBounce {
  to {
    transform: translateY(0); } }

@keyframes pulsePlayBubble {
  0% {
    transform: scale(1); }
  100% {
    transform: scale(1.2); } }

@keyframes slidedown {
  from {
    top: 0;
    opacity: 0; } }

@keyframes slideup {
  from {
    bottom: 0;
    opacity: 0; } }

@keyframes customFadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-20%); }
  to {
    opacity: 1;
    transform: translateX(0); } }

@keyframes fillbg {
  0% {
    width: 0; }
  100% {
    width: 100%; } }

@keyframes customFadeOutLeft {
  0% {
    display: flex;
    opacity: 1;
    transform: translateX(0); }
  99% {
    opacity: 0;
    transform: translateX(-20%); }
  100% {
    display: none; } }

@keyframes customFadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20%); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes customFadeIn {
  0% {
    opacity: 0; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.landing-select.ant-select-dropdown {
  background-color: #fff;
  border-radius: 0; }
  .landing-select.ant-select-dropdown .ant-select-dropdown-menu {
    list-style: none;
    padding: 0;
    margin: 0;
    border-radius: 0; }
    .landing-select.ant-select-dropdown .ant-select-dropdown-menu .ant-select-dropdown-menu-item {
      margin: 0;
      padding: 10px;
      color: #333;
      border-radius: 0; }
      .landing-select.ant-select-dropdown .ant-select-dropdown-menu .ant-select-dropdown-menu-item:hover, .landing-select.ant-select-dropdown .ant-select-dropdown-menu .ant-select-dropdown-menu-item-active {
        background-color: #eee; }
      .landing-select.ant-select-dropdown .ant-select-dropdown-menu .ant-select-dropdown-menu-item-selected {
        background-color: #ddd; }
      .landing-select.ant-select-dropdown .ant-select-dropdown-menu .ant-select-dropdown-menu-item:last-child {
        margin-bottom: 0; }

.landing .btn, .landing .for-investors,
.landing-modal .btn,
.landing-modal .for-investors {
  font-family: Oswald;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.05em;
  color: #FFFFFF;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  border-radius: 5px;
  padding: 0 16px;
  white-space: nowrap; }
  .landing .btn.big, .landing .for-investors.big,
  .landing-modal .btn.big,
  .landing-modal .for-investors.big {
    height: 60px; }
    .landing .btn.big.bordered, .landing .for-investors.big.bordered,
    .landing-modal .btn.big.bordered,
    .landing-modal .for-investors.big.bordered {
      height: 60px; }
  .landing .btn.small, .landing .for-investors.small,
  .landing-modal .btn.small,
  .landing-modal .for-investors.small {
    height: 40px; }
    .landing .btn.small.bordered, .landing .for-investors.small.bordered,
    .landing-modal .btn.small.bordered,
    .landing-modal .for-investors.small.bordered {
      height: 40px; }
  .landing .btn.bordered, .landing .for-investors.bordered,
  .landing-modal .btn.bordered,
  .landing-modal .for-investors.bordered {
    border: 2px solid #F65932; }
    .landing .btn.bordered:hover, .landing .for-investors.bordered:hover,
    .landing-modal .btn.bordered:hover,
    .landing-modal .for-investors.bordered:hover {
      background: linear-gradient(0deg, #FF2600, #FF2600);
      border: 2px solid transparent; }
  .landing .btn.filled, .landing .for-investors.filled,
  .landing-modal .btn.filled,
  .landing-modal .for-investors.filled {
    background: linear-gradient(246.81deg, #F65932 0%, #CC3535 100%), #C4C4C4; }
    .landing .btn.filled:hover, .landing .for-investors.filled:hover,
    .landing-modal .btn.filled:hover,
    .landing-modal .for-investors.filled:hover {
      background: linear-gradient(0deg, #FF2600, #FF2600); }

.landing .for-investors,
.landing-modal .for-investors {
  color: #F65932; }

.landing-modal .ant-modal-content {
  padding: 40px 35px;
  background: #FFFFFF;
  border-radius: 20px; }

.landing-modal .ant-modal-close {
  display: none; }

.landing-modal .ant-modal-body {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 40px;
  text-align: center;
  color: #666666;
  margin: 10px 0 30px 0; }

.landing-modal .ant-modal-header {
  background: #FFFFFF;
  border-bottom: none;
  padding: 0; }

.landing-modal .ant-modal-title {
  text-align: center;
  font-family: Oswald;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 75px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #1A1A1A; }

.landing-modal .ant-modal-footer {
  display: flex;
  justify-content: center;
  padding: 0; }
  .landing-modal .ant-modal-footer .btn {
    flex: 3; }

.terms-and-condition__modal .ant-modal-content {
  overflow: hidden !important;
  background: none;
  border-radius: 20px;
  background-color: #fff;
  padding: 5px; }
  .terms-and-condition__modal .ant-modal-content .ant-modal-body {
    overflow: auto !important;
    margin-top: 10px;
    margin-bottom: 10px; }

.terms-and-condition__modal .ant-modal-footer {
  border-top: none;
  padding-top: 5px; }

.terms-and-condition__modal .tc-content {
  max-height: 100%; }
  .terms-and-condition__modal .tc-content__container {
    padding: 20px 40px 0 40px; }
  .terms-and-condition__modal .tc-content__text > * {
    margin: 0 !important;
    margin-left: 0 !important; }
  .terms-and-condition__modal .tc-content__text ol {
    padding-left: 0 !important;
    list-style-type: none; }
  .terms-and-condition__modal .tc-content__text p {
    margin-left: 0 !important; }
  .terms-and-condition__modal .tc-content__text span {
    line-height: 20px; }
  .terms-and-condition__modal .tc-content__footer-container {
    display: flex;
    justify-content: center; }
  .terms-and-condition__modal .tc-content__footer-btn {
    display: flex;
    width: inherit;
    padding: 13px 50px;
    border-radius: 5px;
    text-transform: uppercase;
    font-family: 'Oswald';
    background: linear-gradient(246.81deg, #F65932 0%, #CC3535 100%), #C4C4C4; }
    .terms-and-condition__modal .tc-content__footer-btn:hover {
      background: linear-gradient(0deg, #FF2600, #FF2600); }
  .terms-and-condition__modal .tc-content__header {
    text-align: center;
    font-size: 24px;
    color: #000;
    font-family: 'Oswald';
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 30px; }

.header__title img {
  display: block;
  width: 100%; }

@media (max-width: 768px) {
  .header__title {
    margin-left: auto;
    margin-right: auto;
    max-width: 320px; } }

.reviews {
  padding-top: 100px;
  padding-bottom: 70px; }

.reviews__blocks {
  display: flex;
  margin-left: -30px;
  margin-right: -30px;
  align-items: stretch;
  justify-content: center;
  flex-wrap: wrap; }

.reviews__block {
  padding: 20px;
  margin-left: 30px;
  margin-right: 30px;
  color: white;
  width: calc(33.33% - 60px);
  background-color: #0B121C;
  border-radius: 20px; }

.r-header {
  display: flex;
  margin-bottom: 20px;
  align-items: center; }

.r-header__avatar {
  position: relative;
  margin-right: 20px; }

.r-header__img {
  width: 100px;
  height: 100px;
  min-width: 100px;
  border-radius: 50%;
  overflow: hidden; }
  .r-header__img > img {
    display: block;
    width: 102%; }

.r-header__flag {
  position: absolute;
  bottom: -5px;
  right: -5px;
  width: 30px;
  height: 30px;
  min-width: 30px;
  border-radius: 50%;
  overflow: hidden; }

.r-header__flag_gb {
  background: url(./images/reviews/gb.jpg) no-repeat center;
  background-size: 110%; }

.r-header__flag_gr {
  background: url(./images/reviews/gr.jpg) no-repeat center;
  background-size: 110%; }

.r-header__name > p {
  margin: 0;
  font-size: 24px;
  line-height: 32px;
  font-weight: 600; }

.reviews__text {
  font-size: 16px;
  line-height: 25px;
  font-weight: 400; }

@media (max-width: 1120px) {
  .reviews__blocks {
    margin-left: -20px;
    margin-right: -20px; }
  .reviews__block {
    margin-left: 20px;
    margin-right: 20px;
    width: calc(33.33% - 40px); }
  .r-header__name > p {
    font-size: 21px;
    line-height: 28px; }
  .r-header__img {
    width: 90px;
    height: 90px;
    min-width: 90px; } }

@media (max-width: 992px) {
  .reviews__block {
    margin-bottom: 30px;
    width: 100%;
    max-width: 420px; }
    .reviews__block:last-child {
      margin-bottom: 0; } }

@media (max-width: 768px) {
  .reviews {
    padding-top: 30px;
    padding-bottom: 30px; }
  .r-header__img {
    width: 70px;
    height: 70px;
    min-width: 70px; }
  .r-header__flag {
    width: 22px;
    height: 22px;
    min-width: 22px; }
  .r-header__name > p {
    font-size: 18px;
    line-height: 21px; }
  .reviews__text {
    font-weight: normal;
    font-size: 14px;
    line-height: 22px; } }

@media (max-width: 576px) {
  .r-header__img {
    width: 60px;
    height: 60px;
    min-width: 60px; }
  .r-header__name > p {
    font-size: 16px;
    line-height: 20px; } }

.f-download {
  display: flex;
  align-items: center;
  justify-content: center; }
  .f-download .links {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 5px;
    column-gap: 20px; }

.f-qrcode {
  width: 160px;
  height: 160px; }
  .f-qrcode > img {
    display: block;
    width: 100%; }

.f-download__qrcode {
  margin-right: 14px; }

.f-social {
  display: flex;
  margin-bottom: 50px;
  margin-left: -7px;
  margin-right: -7px;
  align-items: center;
  justify-content: space-between; }

.f-social__icon, .f-social__icon_wide {
  margin-left: 7px;
  margin-right: 7px; }
  .f-social__icon svg, .f-social__icon_wide svg {
    width: 28px;
    height: 28px;
    fill: white;
    transition: fill .3s ease; }
    .f-social__icon svg:hover, .f-social__icon_wide svg:hover {
      fill: #f65932; }

.f-social__icon_wide svg {
  width: 33px;
  height: 28px; }

@media (max-width: 768px) {
  .f-download__qrcode {
    display: none; } }

@media (max-width: 576px) {
  .f-social {
    margin-bottom: 20px; }
  .landing .downloadlinks .links a .links__button {
    width: 170px;
    height: 52px; } }

.benefits__btn > div {
  width: 200px;
  margin-top: 40px; }

@media (max-width: 768px) {
  .landing .benefits .container .right {
    max-width: none; }
  .benefits__btn > div {
    margin-left: auto;
    margin-right: auto; } }

@media (max-width: 768px) {
  /* Не делайте так :( Просто не мог нати классы */
  .landing__header > .landing__container > .landing__header__bottom > .left-side > .actions {
    align-items: center;
    width: auto !important; }
  .landing__header > .landing__container > .landing__header__bottom > .left-side > .actions .btn {
    max-width: 310px;
    width: 100% !important; } }
