.my-battles {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__empty_container {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
  }

  &__no_data_found {
    font-style: normal;
    font-weight: normal;
    color: #FFFFFF;
    text-align: center;
    max-width: 300px;
  
    &__description {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      margin-top: 30px;
    }
  
    &__button {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 30px;
      padding: 0 30px;
      margin-top: 20px;
      background: transparent;
      cursor: pointer;
  
      &__icon {
        margin-right: 15px;
        width: 18px;
        height: 18px;
  
        svg {
          height: 18px;
  
          path {
            fill: #FFFFFF;
          }
        }
      }
  
      &__text {
        font-size: 16px;
        line-height: 20px;
      }
  
      &.create {
        color: #FFFFFF;
        background: #22AB00;
  
        &:hover {
          background: #008e06;
        }
      }
    }
  }
}

/* .my-battles .my-battles__battles {
  max-height: calc(100% - 20px - 6px);
  overflow-y: auto;
} */

.my-battles .my-battles__battle {
  margin-bottom: 5px;
}

.my-battles .my-battles__battle .my-battles__cardheader {
  height: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
}

.my-battles .my-battles__battle .my-battles__cardheader .my-battles__name {
  font-family: 'Oswald';
  font-size: 16px;
  letter-spacing: 0.03em;
  color: #fff;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.my-battles .my-battles__battle .my-battles__cardheader .my-battles__buttons {
  flex: 0 0 auto;
}

.my-battles .my-battles__battle .my-battles__cardheader .my-battles__buttons button {
  border: 1px solid #FFFFFF;
  color: #fff;
  height: 26px;
  padding: 0 15px;
  box-shadow: none;
  font-size: 12px;
}

.my-battles .my-battles__battle.tournament-type {
  background: #244143;
}

.my-battles .my-battles__battle.battle-type {
  background: #243726;
}

.my-battles .my-battles__battle.qualification-type {
  background: #374124;
}

.my-battles .my-battles__battle.tournament-type .my-battles__cardheader {
  background: linear-gradient(244.72deg, #20A39E 0%, #01677E 100%);
}

.my-battles .my-battles__battle.battle-type .my-battles__cardheader {
  background: linear-gradient(244.72deg, #0ABB31 0%, #206B0D 100%);
}

.my-battles .my-battles__battle.qualification-type .my-battles__cardheader {
  background: linear-gradient(244.72deg, #DABB31 0%, #C06B0D 100%);
}

.my-battles .my-battles__battle:last-child {
  margin-bottom: 0;
}

// .my-battles .my-battles__battle:first-child {
//   margin-top: 7px;
// }

.my-battles .my-battles__unconfirmed {
  margin-top: 1px;
}

.my-battles .my-battles__unconfirmed-battle > .my-battles__unconfirmed-inner {
  display: flex;
  justify-content: space-between;
  background-color: #343d49;
  padding: 10px 15px;
}

.my-battles .my-battles__unconfirmed-battle .my-battles__buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.my-battles .my-battles__battle .my-battles__buttons button {
  width: 75px;
  height: 20px;
}

.my-battles .my-battles__confirmed-battle:nth-last-child(1) {
  margin-bottom: 0;
}

.my-battles .my-battles__confirmed-battle {
  background-color: #343d49;
  padding: 10px 15px;
  margin-bottom: 3px;
}

.my-battles .my-battles__confirmed-battle .opponent:first-child {
  margin-top: 0px;
}

.my-battles .my-battles__confirmed-battle .opponent:last-child {
  margin-bottom: 0;
}

.my-battles .my-battles__info .my-battles__header {
  font-size: 14px;
  height: 20px;
  line-height: normal;
}

.my-battles .my-battles__info .my-battles__header:nth-child(1) {
  flex: 0 0 35%;
}

.my-battles .my-battles__info .my-battles__header:nth-child(2) {
  flex: 0 0 25%
}

.my-battles .my-battles__info .my-battles__header:nth-child(3) {
  flex: 0 0 40%;
}

.my-battles .my-battles__info .my-battles__header.opponent_header {
  font-size: 12px;
}

.my-battles .my-battles__values {
  flex: 1;
  display: flex;
}

.my-battles .my-battles__unconfirmed-battle .my-battles__header {
  display: flex;
  opacity: 1;
}

.my-battles .my-battles__unconfirmed-battle .my-battles__header > div {
  display: flex;
}

.my-battles .my-battles__unconfirmed-battle .my-battles__header p {
  opacity: 0.6;
  margin: 0;
}

.my-battles .my-battles__unconfirmed-battle .my-battles__header span {
  background-color: #f65932;
  border-radius: 50%;
  width: 17px;
  height: 17px;
  text-align: center;
  margin-left: 5px;
  font-size: 10px;
  line-height: 17px;
}

.my-battles .my-battles__info {
  padding: 10px 15px;
}

.my-battles .my-battles__info .my-battles__text {
  font-size: 14px;
  line-height: normal;
  font-family: 'Oswald';
}

.my-battles .my-battles__info .my-battles__text:nth-child(1) {
  flex: 0 0 35%;
}

.my-battles .my-battles__info .my-battles__text:nth-child(2) {
  flex: 0 0 25%;
}

.my-battles .my-battles__info .my-battles__text:nth-child(3) {
  flex: 0 0 40%;
}

.my-battles .my-battles__info .my-battles__date_bet .my-battles__headers {
  flex: 0 0 auto;
  display: flex;
}

.my-battles .my-battles__wrapper {
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
}

.my-battles__battles_container {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
}

.my-battles .my-battles_wait-footer {
  background: #58686F;
  font-size: 12px;
  display: flex;
  align-items: center;
  height: 30px;
  padding: 0 15px;
}

.my-battles .my-battles_extender {
  padding: 0 15px;
  background: #F65932;
  transition: background 1s;
}

.my-battles .my-battles_extender.down{
  background: #F6593270;
}

.my-battles  button.my-battles_collapsable {
  padding: 0;
  border: none;
}

.my-battles  button.my-battles_collapsable > div{
  display: flex;
  height: 30px;
  width: 100%;
  padding-left: 10px;
  color: #fff;
  align-items: center;
  position: relative;
}

.my-battles  button.my-battles_collapsable > div::after {
  content: '';
  position: absolute;
  right: 5px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 8px 0 8px;
  border-color: #fff transparent transparent transparent;
}

.my-battles button.my-battles_collapsable {
  background-color: transparent !important;
}

.my-battles .button_transparent.button_aquamarine:hover {
  background-color: transparent !important;
}

.my-battles button.my-battles_collapsable.up > div::after {
  border-width: 0px 8px 8px 8px;
  border-color:  transparent transparent #fff transparent;
}

.my-battles button.my-battles_collapsable > div::after {
  content: '';
  position: absolute;
  right: 5px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 8px 0 8px;
  border-color: #fff transparent transparent transparent;
}

.my-battles  button.my-battles_collapsable p{
  margin: 0;
  margin-right: 10px;
}

.my-battles  button.my-battles_collapsable span{
  background: #fff;
  color: #f65932;
  min-width: 16px;
  height: 16px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cancel_button_placeholder {
  color: #fff;
  font-size: 12px;
  width: 75px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  white-space: nowrap;
}

.my-battles .opponent {
  position: relative;
  background: none;
  background-color: transparent;
  padding: 5px 15px;
}

.my-battles .opponent::before {
  background: #ffffff18;
  position: absolute;
  top:0; height: 1px;
  left: 0;
  right: 0;
  content: '';
}

.my-battles-filter {
  padding: 0 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #414d5c;
  padding-top: 10px;
  padding-bottom: 13px;
}

.my-battles-filter > span {
  font-size: 14px;
}

.my-battles-filter > div {
  flex: 1;
  margin-left: 18px;
}