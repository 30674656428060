.pillBoxBody {
	&, & * {box-sizing: border-box;}
	&.noShowcase {
		svg { margin: 0!important }
	}
	display: flex;
	align-items: flex-end;
	min-height: 46px;
	& > svg {
		flex: 0 0 auto;
		margin: auto;
		padding: 5px;
		border: 1px solid #f65932;
		fill: #f65932;
		transition: all 0.5s ease-in-out;
		border-radius: 5px;
		cursor: pointer;
		height: 28px;
		width: 28px;
		align-self: center;
		&:hover {
			fill: white;
			border: 1px solid white;
			background-color: #f65932;
		}
	}
	.content {
		flex: 1 1 100%;
		border-bottom: 1px solid #f65932;
		overflow-x: hidden;
		overflow-y: auto;
		text-overflow: ellipsis;
		padding-bottom: 5px;
		min-height: 28px;
		max-height: 100px;
		color: #6d7177;
		span {
			background-color: #50565e;
			border-radius: 5px;
			margin-right: 5px;
			margin-bottom: 5px;
			padding: 2px 4px;
			font-size: 12px;
			display: inline-block;
			color: white;
		}
	}
}

.pillBoxOptions {
	display: flex;
	flex-wrap: wrap;
	.checkbox {
		flex: 0 0 50%;
		margin-bottom: 15px;
	}
}