.tournament__settings {
  flex: 1 1;
  height: 100%;
  display: flex;

  .label {
    font-family: Oswald, serif;
    font-size: 20px;
    text-transform: uppercase;
    margin-bottom: 40px;
  }

  .container {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: stretch;
  }

  .section {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    flex: 1;
    //border-right: 1px solid #3B3F42;
    .bowlingcenters {
      max-height: 100%;
      overflow-y: scroll;
    }

    &.tabs {
      flex: 0.3;
      padding: 0;
      margin-top: 39px;
      min-width: 280px;
    }

    &.tab-panel {
      margin-left: 60px;
      margin-top: 40px;
      padding: 0 175px 0 0;
      min-width: 495px;
    }
  }

  .property:last-child {
    padding-bottom: 40px;
    margin-bottom: 0;
  }

  .property {
    margin-bottom: 40px;

    .name {
      font-family: Open Sans, serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      margin-bottom: 10px;
      color: #6D7177;
    }

    .checkbox {
      &-value {
        font-family: Open Sans, serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        color: #FFFFFF;
      }
      &-value2 {
        font-family: Open Sans, serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        color: #FFFFFF;
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
  }

  .select {
    &__editable-input{
      padding-right: 10px;
      display: flex;
      flex-direction: row;
      width: 10%;
    }

    &__container {
      padding-right: 10px;
      display: flex;
      flex-direction: row;
      width: 100%;

      .settings-select__container {
        width: 100%;
      }

      &.checkbox__select {
        .select__label {
          margin-right: 35px;
          font-family: Open Sans, serif;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 22px;
          color: #6D7177;
        }
        .including__label {
          margin-left: 15px;
          font-family: Open Sans, serif;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 22px;
          color: #6D7177;
        }

        margin-top: 20px;
        flex-direction: row;
        align-items: center;
      }
    }
  }

  .bc__block {
    background: #242A32;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 5px 0 18px;
    margin-bottom: 10px;
    height: 40px;
    font-size: 16px;
  }

  .messages {
    flex: 1;
  }

  .radio {
    margin-top: 10px;
  }

  .ant-radio-group {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }

  .ant-radio-button-wrapper {
    flex: 1;
    height: 48px;
    font-family: Open Sans, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    border-radius: 5px;
    border-width: 0;
    margin-right: 15px;
    margin-left: 15px;
    background-color: #2f1e1f;
    color: #9ea1a4;
    line-height: 48px;
    text-align: center;
  }

  .radio-button-label {
    margin-top: 15px;
    font-size: 16px;
    line-height: 22px;
    color: #6D7177;
  }

  .ant-radio-button-wrapper:first-child {
    margin-right: 15px;
    margin-left: 0;
  }

  .ant-radio-button-wrapper:last-child {
    margin-left: 15px;
    margin-right: 0;
  }

  .ant-radio-button-wrapper-checked {
    background: #F65932;
    box-shadow: none;
    color: white;
    opacity: 1;
  }

  .ant-radio-button-wrapper-disabled {
    background: #f5f5f5;
    color: black;
  }

  .ant-radio-button-wrapper:not(:first-child)::before {
    background-color: transparent;
  }
}

.tab {
  &__container {
    padding: 0;
    margin: 0;
  }

  &__elem {
    padding: 12px 0 15px 22px;
    list-style-type: none;
    border: 1px solid #242A32;
    color: #6D7177;
    font-family: Oswald, serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-transform: uppercase;
    transition: all 0.3s;

    &:hover {
      cursor: pointer;
    }

    &.active {
      color: #F65932;

      &:hover {
        cursor: default;
      }
    }
  }
}

.clickable {
  cursor: pointer;
}

.loading__container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardContainer {
  width: 500px;
  background-color: #232A33;
  border-radius: 15px;
  .container {
    position: relative;
    border-radius: 15px;
    padding: 16px 22px;
    display: flex;
    align-items: center;
    background-size: cover;
    .image{
      border-radius: 15px;
      position: absolute;
      bottom: 0;
      top: 0;
      left: 0;
      right: 0;
      opacity: 0.2;
      z-index: 0;
      width: 100%;
      &[src=""] {
        content:url("data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==");
      }
    }
    .cardTextContainer {
      z-index: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      flex: 1;
      height: 100%;

      .tournamentCardLabel {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        font-family: 'Oswald', sans-serif;
        margin: 0;
        padding: 0;
        font-size: 22px;
        line-height: 32px;
      }

      .tournamentCardItem {
        margin: auto 0;
        display: flex;
        align-items: center;
      }

      .icon {
        width: 27px;
        height: 27px;
        margin-right: 10px;
      }

      .tournamentCardText {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        margin: 0;
        padding: 0;
        font-size: 18px;
        line-height: 24px;
      }
    }
  }

  .logoContainer {
    z-index: 1;
    width: 120px;
    height: 120px;
    border-radius: 60px;
    background: #232A33;
    border: 1.37883px solid rgba(255, 255, 255, 0.2);
    overflow: hidden;
    .logo {
      width: 122px;
      height: 122px;
      border-radius: 60px;
    }
  }
}

.cardBgText {
  margin-top: 40px;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 21px;
  color: #6D7177;
}

.buttonContainer {
  display: flex;
  justify-content: space-between;

  .button {
    margin-right: 30px;
    border-radius: 5px;
    background-color: rgba(246, 89, 50, 0.15);
    box-shadow: none;
    &:last-child {
      margin-right: 0;
    }
    &.active {
      background-color: rgba(246, 89, 50, 1);
    }
  }
}

.tournamentCardSettingsCheck {
  margin: 40px 0;
}

.tournamentColorPickerContainer {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  .picker {
    width: 48px;
    height: 48px;
    border-radius: 5px;
    border: 1px solid white;
    background-color: #232A33;
    //color: #232A33;
    -webkit-appearance: none;
    outline: none;
    &::-webkit-color-swatch-wrapper, {
      padding: 0;
    }
    &::-webkit-color-swatch {
      border: none;
    }
  }
  .text {
    font-size: 14px;
    line-height: 19px;
    color: white;
    margin-left: 20px;
  }
}

.uploadButton{
  display: flex;
  margin-bottom: 40px;
  .upload {
    margin-right: 20px;
    width: 32px;
    height: 32px;
  }
  .text{
    color: #ffffff;
    font-size: 14px;
    line-height: 19px;
  }
}

.disabled-button {
  width: 100%;
  font-weight: 300;
  border: none;
  color: #fff;
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 18px;
  padding-bottom: 18px;
  font-size: 20px;
  background-color: rgba(246, 89, 50, 0.15);
  white-space: nowrap;
}

.editable {

  &__input {
    font-weight: bold;
    padding: 5px 10px;
    background-color: transparent;
    color: #fff;
    text-align: left;
    border: 1px solid #A7A7A7;
    box-sizing: border-box;
    width: 100%;
    min-height: 28px;
  }
}

.multiplier_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 25px;
}

.input__tip {
  display: none;
}

.multiplier_text {
  font-family: Open Sans, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #6D7177;
  padding-right: 10px;
}
