.install {

  .landing__header.install{
    background-image: none;
    background-color: '#9B9B9B';
  }
  .container {
    display: flex;
    width: 100%;
    height: 100%;
  }
  .guideContainer {
    max-width: 1200px;
    margin: 40px 10px 120px 10px;
  }
  .guideContainer ol{
    padding-inline-start: 0px ;
  }
  .installTitle {
    font-family: Oswald;
    font-style: normal;
    font-weight: normal;
    font-size: 44px;
    line-height: 54px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    margin-bottom: 50px;
  }
  .installTitle:first-letter {
    color: #F65932;
  }
  .mainList {
    align-items: center;
    margin-left: 21px;
  }
  .stepsList {
    margin: 20px 0px;
    font-family: Oswald;
    font-style: normal;
    font-weight: 200;
    font-size: 24px;
    line-height: 35px;
    letter-spacing: 0.05em;
    color: #FFFFFF;
  }
  .versionColumn {
    display: flex;
    flex-direction: row;
    align-items:flex-start;
  }
  .androidVersionColumn {
    margin: 0px 10px;
    padding-right: calc(100% / 12);
  }
  .androidVersionColumn div {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.05em;
    color: #FFFFFF;
    margin: 25px 0px 15px 0px;
  }

  .text {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;
    color: #FFFFFF;
  }

  .store {
    &__container {
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      margin-bottom: 50px;
    }

    &__item {
      margin-right: 40px;

      &-name {
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
        color: #FFFFFF;
        margin-bottom: 5px;
      }

      &:last-child {
        margin-right: 0px;
      }
    }
  }

  .download {
    &__container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      margin-top: 20px;
    }

    &__btn {
      display: flex;
      flex-direction: row;
      align-items: center;
      background: linear-gradient(264.59deg, #F65932 0%, #CC3535 100%), #C4C4C4;
      border-radius: 5px;
      padding: 8px 63px;
      margin-right: 20px;

      &-text {
        word-wrap: none;
        white-space: nowrap;
        font-family: Oswald;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #FFFFFF;
      }

      &-img {
        margin-right: 10px;
      }

      &:hover {
        cursor: pointer;
      }
    }

    &-text {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: #FFFFFF;
      opacity: 0.5;
    }
  }

  .main {
    &__container {
      display: flex;
      flex-direction: row;
      margin: 50px 0;

      .system-menu {
        &__container {
          display: flex;
          flex-direction: column;
        }

        &-img {
          width: 100%;
          
          &__container {
            width: 260px;
          }
        }

        &-text {
          font-family: Open Sans;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 16px;
          color: #FFFFFF;
          opacity: 0.5;
          margin-top: 10px;
        }
      }
    }

    &__list {
      margin-bottom: 50px;
      margin-top: 0px;

      &__container {
        margin-right: 50px;
      }

      &__item {
        margin-bottom: 50px;
        margin-left: 22px;

        & > div {
          font-family: Open Sans;
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          line-height: 27px;
          color: #FFFFFF;
          padding-left: 10px;

          & > a {
            text-decoration: underline;
          }

          .system-menu__container {
            display: none;
          }
        }

        &::marker {
          display: block;
          font-family: Open Sans;
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          line-height: 27px;
          color: #FFFFFF;
          order: 0;
        }

        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }

  @media screen and (min-width: 320px) and (max-width: 767px) {
    .installTitle {
      padding: 0 10px;
      font-family: Oswald;
      font-style: normal;
      font-weight: normal;
      font-size: 34px;
      line-height: 36px;
      text-align: center;
      letter-spacing: 0.05em;
      text-transform: uppercase;
    }

    .guideContainer {
      padding: 0 10px;
      margin: 32px 0px 20px 0px;
    }

    .text {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      color: #FFFFFF;
    }

    .store {
      &__container {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-bottom: 50px;
      }
  
      &__item {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
  
        &-name {
          font-size: 16px;
          line-height: 22px;
          margin-bottom: 5px;
          white-space: nowrap;
        }
  
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }

    .download {
      &__container {
        flex-direction: column;
      }

      &-text {
        margin-top: 10px;
        font-size: 10px;
        line-height: 14px;
      }
    }

    .main {
      &__container {
        .system-menu__container {
          display: none;
        }
      }

      &__list {
        &__item {
          margin-bottom: 30px;

          & > div {
            .system-menu__container {
              display: flex;
              margin-top: 30px;
            }

            font-size: 16px;
            line-height: 22px;
            padding-left: 5px;
          }
  
          &::marker {
            font-size: 16px;
            line-height: 22px;
          }
  
          &:last-child {
            margin-bottom: 0px;
          }
        }
      }
    }
  }
}