.notification-item-root {
  width: 100%;
  margin-bottom: 2px;
}
.notification-container-top-right {
  width: 25%;
  min-width: 281px;
  right: 0px;
  top: 30px;
}

.notification {
  display: flex;
  position: relative;
  width: 100%;
  background-color: #6d7177;

  .notification__type {
    width: 5px;
    height: 100%;
    background-color: #e5e5e5;
  }

  .notification__type.decline {
    background-color: #ff4242;
  }

  .notification__type.accept {
    background-color: #35c844;
  }

  .notification__message {
    width: 100%;
    padding: 10px;
    padding-right: 15px;
    -webkit-user-select: none;  /* Chrome all / Safari all */
    -moz-user-select: none;     /* Firefox all */
    -ms-user-select: none; 
    user-select: none;

    .notification__header {
      display: flex;
      justify-content: space-between;

      .notification__title,
      .notification__date {
        opacity: 0.6;
        line-height: 17px;
        font-size: 12px;
        color: white;
      }

      .notification__title {        
        font-weight: bold;
      }
    }

    .notification__content {
      .notification__text {
        line-height: 20px;
        font-size: 14px;
        color: white;

        span {
          font-weight: 700;
        }
      }
    }
  }

  .notification__dismiss {
    position: absolute;
    top: 10px;
    right: 5px;
  }
}