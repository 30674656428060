.walletHistory {
  width: 100%;
  height: 100%;
  .currencyPicker {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    & > div {
      width: 80px;
      margin-left: 15px;
    }
  }
}

.unpadded { padding: 0 !important }