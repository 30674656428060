.active_game_card {
  height: 78px;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  background: #2A3038;

  &__avatar {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 6px 10px;
    flex: 1;
    
    .avatar {
      width: 30px;
      min-width: 30px;
      height: 30px;
      margin-right: 15px;

      img {
        width: 30px;
        border-radius: 50%;
      }

      &__flag {
        width: 16px;
        height: 16px;
        border-radius: 50%;
      }
    }
  }
  
  &__opponent {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #FFFFFF;
  }

  &__login {
    font-size: 12px;
    line-height: 16px;
    color: #F65932;
  }

  &__button {
    position: relative;
  }

  &__footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 6px 10px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #FFFFFF;
    justify-content: space-between;
    background: rgba($color: #FFFFFF, $alpha: .2)
  }

  &__timer {
    font-family: Oswald;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.05em;
  }
}