.admin-upload {
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upload-list-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upload-list {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  margin-top: 20px;
  padding-bottom: 10px;
  max-width: 100%;
}

.bcAdmin-upload__list {
  display: flex;
  flex-direction: column;
  overflow-x: scroll;
  margin-top: 20px;
  padding-bottom: 10px;
  max-width: 100%;
  margin-bottom: 60px;
}
.bcAdmin-upload__banners{
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}

.upload-item {
  width: 217px;
  height: 122px;
  border: 1px solid #25292C;
  margin: 0px 8px;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 5px;
}

.upload-item.selected {
  border: 4px solid #F65932;
}

.upload-item.deleted {
  border: 4px solid #6D7177;
}
/* .upload-item:hover .plus {
  opacity: 0.3;
} */

.upload-item-image {
  width: 200px;
  height: 100px;
  object-fit: contain;
}

.howerImage {
  display: none;

}

.howerImageButtom {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-sizing: border-box;
  border-radius: 6px;
  color: #fff;
  margin: 0 5px;
}

.upload-item:hover .howerImage {
  display: block;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.upload-item:hover .upload-item-image {
  opacity: 0.3;
}

.upload-item:hover .plus {
  opacity: 0.3;
}


.admin-upload-conatiner .ant-upload-list-picture .ant-upload-list-item,
.admin-upload-conatiner .ant-upload-list-picture-card .ant-upload-list-item {
  height: 48px;
}
.admin-upload-conatiner .ant-upload-list-item-card-actions .anticon {
  color: #fff;
}
.admin-upload-conatiner .ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon {
  color: #f5222d;
}
.admin-upload-conatiner .ant-upload.ant-upload-select {
  display: block;
}
.admin-upload-conatiner .ant-upload-list {
  display: flex;
  flex-direction: column;
}
.admin-upload-conatiner .ant-upload-list-picture .ant-upload-list-item-thumbnail,
.admin-upload-conatiner .ant-upload-list-picture .ant-upload-list-item-thumbnail img {
  width: 104px;
}
.admin-upload-conatiner .ant-upload-list-picture .ant-upload-list-item-name {
  padding-left: 108px;
}
.admin-upload__message {
  position: relative;
  margin-top: 20px;
  width: 100%;
  height: 38px;
}
.admin-upload__message-block {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 7px 20px;
  line-height: 24px;
  text-align: center;
  border-radius: 4px;
}
.admin-upload__message-block_error {
  color: #fff;
  background-color: rgba(216, 0, 39, .4);
  cursor: pointer;
}
.admin-upload__message-block_success {
  color: #fff;
  background-color: rgba(32, 163, 158, .4);
}
.admin-upload-conatiner .ant-upload-list-item-name {
  color: #fff;
}
.ant-upload-list-picture .ant-upload-list-item-uploading .ant-upload-list-item-name {
  line-height: 44px;
  opacity: .5;
}

.admin-upload__save {
  margin-top: 0px;
}

.bc-admin-upload__save {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
  height: 100px;
}

.bc-admin-upload__save_text{
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: rgba(32, 163, 158, .4);
  line-height: 24px;
  font-size: 16px;
  /*text-align: center;*/
  width: 100%;
  height: 40px;
  position: absolute;
  top: 0;
  left: 0;
}


.admin-upload__save_button{
  width: 340px;
  height: 59px;
  font-family: 'Open Sans';
  font-style: normal;
  font-size: 20px;
  line-height: 27px;
  font-weight: 400;
}

.admin-upload__input {
  display: flex;
  width: 600px;
  margin-top: 8px;
  align-items: center;
}

.admin-upload__input .ant-radio-group {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}

.admin-upload__input .ant-radio-button-wrapper {
  flex: 1;
  height: 48px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  border-radius: 5px;
  border-width: 0px;
  margin-right: 15px;
  margin-left: 15px;
  background-color: #2f1e1f;
  color: #9ea1a4;
  line-height: 48px;
  text-align: center;
}

.radio-button-label {
  margin-top: 15px;
  font-size: 16px;
  line-height: 22px;
  color: #6D7177;
}

.admin-upload__input .ant-radio-button-wrapper:first-child {
  margin-right: 15px;
  margin-left: 0px;
}

.admin-upload__input .ant-radio-button-wrapper:last-child {
  margin-left: 15px;
  margin-right: 0px;
}

.admin-upload__input .ant-radio-button-wrapper-checked {
  background: #F65932;
  box-shadow: none;
  color: white;
  opacity: 1;
}

.admin-upload__input .ant-radio-button-wrapper-disabled {
  background: #f5f5f5;
  color: black;
}

.admin-upload__input .ant-radio-button-wrapper:not(:first-child)::before {
  background-color: transparent;
}

.ant-select {
  width: 100%;
}

.ant-select-selection {
  background-color: transparent;
  color: white;
  border-radius: 0;
  border-color: rgba(255, 255, 255, 0.4);
  height: 44px;
}

.select-container {
  margin-top: 10px
}

.ant-select-selection .ant-select-selection--single {

}

.ant-select-arrow {
  color: rgba(255, 255, 255, 0.4);
}

.ant-select-focused .ant-select-selection, .ant-select-selection:focus, .ant-select-selection:active {
  border-color: rgba(255, 255, 255, 0.4);
  box-shadow: none;
}

.ant-select-selection:hover {
  border-color: rgba(255, 255, 255, 0.4);
}

.ant-select-selection__rendered {
  line-height: 44px;
}

.admin-upload__input > p {
  margin-bottom: 0;
  margin-right: 20px;
}

.admin-radio {
  display: flex;
  flex-direction: row;
}

.twoColumns {
  display: flex;
  flex-direction: row;
}

.inputsWrapper {
  padding-left:  10px;
  flex: 1;
  padding-top: 71px;
}

.inputContainer {
  margin-top: 8px;
  height: 66px;
}

.item-datail .ant-input {
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.4);
  color: #fff;
  background-color: transparent;
  box-sizing: border-box;
  margin-top: 10px;
  font-size: 16px;
  line-height: 40px;
  padding-left: 10px;
}

.item-datail {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.uploadImagePlaceholder {
  font-family: Open Sans, sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #FFFFFF;
  margin-top: 10px;
}
.banner_data_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 5px;
}
.banner_data_text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #FFFFFF;
}
.orange_text {
  color: #F65932;
}

.item-detail__warning-container {
  display: flex;
  justify-content: center;
}

.item-detail__warning-text {
  width: 340px;
  height: 59px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  color: #F65932;
}