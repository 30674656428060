.dropdown-tournaments {
  margin-bottom: 50px;
}
.dropdown {
  &-item {
    width: 100%;

    &-element {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      width: 100%;
      border-top: 1px solid #555C65;
      padding: 20px 0;

      &.dropdown-item-title {
        &:hover {
          cursor: pointer;
          z-index: 100;
        }
        .dropdown-item-element-value {
          flex: 46%;
        }
  
        .dropdown-item-element-key {
          flex: 60%;
        }
      }

      .dropdown-item-element-value {
        flex: 25%;
      }

      .dropdown-item-element-key {
        flex: 17%;
      }

      &-key {
        font-family: 'Open Sans';
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        color: #fff;
        width: 307px;
        white-space: nowrap;
        vertical-align: top;

        &.title {
          font-size: 24px;
          line-height: 32px;
          color: #F65932;
          font-weight: normal;
          width: initial;
        }
      }

      &-value {
        font-family: 'Open Sans' sans-serif;
        font-weight: normal;
        font-size: 20px;
        line-height: 28px;
        color: #fff;

        &.value__list {
          & > ul {
            padding: 0;
            margin: 0;
          }
        }

        .value__list-item {
          list-style: none;
        }
      }
    }

    &-image {
      object-fit: contain;
      height: 100%;
      width: 100%;

      &__container {
        display: flex;
        margin: 40px 103px 0px 70px;
        width: 132px;
        height: 116px;
      }
    }

    &-info {
      max-height: 0px;
      overflow: hidden;
      display: flex;
      flex-direction: row;
      transition: max-height 1.5s;

      &__container {
        max-width: 790px;
        width: 100%;
      }

      &.opened {
        max-height: 850px;
      }
    }

    &-arrow {
      &__container {
        // flex: 15%;
        align-items: center;
        justify-content: flex-end;
        display: flex;
        flex-direction: row;
      }

      &__image {
        width: 20px;
        height: 10px;
        object-fit: contain;
        transition: transform 0.4s;

        &.opened {
          transform: rotate(-180deg);
        }
      }
    }

    &:last-child {
      .dropdown-item-info {
        border-bottom: 1px solid #555C65;

        &.opened {
          border-bottom: none;
        }
      }
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .dropdown-tournaments {
    margin-bottom: 30px;
    padding: 0 !important;
  }
  .dropdown {
    &-item {
      &-element {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 10px 0;

        &-key {
          font-family: 'Open Sans';
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          opacity: 0.9;
          color: #fff;
          width: initial;
          vertical-align: top;
  
          &.title {
            font-size: 16px;
            line-height: 24px;
            color: #F65932;
            font-weight: normal;
            width: initial;
          }
        }
  
        &-value {
          font-family: 'Open Sans' sans-serif;
          font-weight: normal;
          font-size: 12px;
          line-height: 20px;
          color: #fff;
        }

        &.dropdown-item-title {
          position: relative;
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;
          margin: 0;
          padding: 0;

          .dropdown-item-element-key {
            margin-left: 10px;
            margin-top: 10px;
          }
          .dropdown-item-element-value {
            margin-bottom: 10px;
            margin-left: 10px;
          }

          .dropdown-item-arrow {
            &__container {
              position: absolute;
              display: flex;
              align-items: center;
              justify-content: flex-end;
              margin-left: 0;
              width: 100%;
              height: 100%;
            }

            &__image {
              margin-right: 10px;
            }
          }
        }
      }

      &-image {
        object-fit: contain;
        opacity: 0.4;
  
        &__container {
          display: flex;
          margin: 0;
          width: 60px;
          height: 52px;
          margin-top: 6px;
        }
      }

      &-info {
        max-height: 0px;
        overflow: hidden;
        display: flex;
        flex-direction: row-reverse;
        transition: max-height 1.5s;
        padding: 0 10px;
  
        &__container {
          max-width: 790px;
          width: 100%;
        }
  
        &.opened {
          max-height: 850px;
        }
      }
    }
  }
}

@media screen and (min-width: 767px) and (max-width: 1200px) {
  .dropdown {
    &-item {
      &-element {
        &.dropdown-item-title {
          .dropdown-item-element-key {
            
          }
          .dropdown-item-element-value {
            
          }
        }
      }
    }
  }
}