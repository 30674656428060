.chat-modal {
  max-height: 80%;
  min-height: 400px;
  height: 600px;
  .ant-modal-content {
    height: 100%;
    .modal-group {
      height: 100%;
    }
  }
}