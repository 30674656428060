.verification-screen {
    padding: 20px 40px;
    a:hover {
        cursor: pointer;
    }
    img.verified {
        height: 24px;
        width: 24px;
        margin: -5px 0;
        margin-right: 5px;
    }
    .verification-block {
        font-family: 'Open Sans';
        font-size: 14px;
        p {
            margin: 0;
        }
        a {
            color: #f65932;
            text-decoration: none;
            transition: color 0.5s;
            min-height: auto;
            &:hover{
                color: #ff3e18;
            }
        }
        h2{
            font-family: 'Oswald';
            font-size: 30px;
            font-weight: 500;
            color: #fff;
            margin: 0;
            margin-bottom: 30px;
        }
        ._verification-field {
            display: flex;
            border-bottom: 1px solid #25292c;
            max-width: 465px;
            margin-bottom: 12px;
            padding-bottom: 10px;
            &:last-child {
                margin-bottom: 40px;
            }
            span {
                opacity: 0.6;
            }
            span, a {
                flex: 0 0 50%;
            }
        }
    }
    ._verification-field-photo {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 7px;
        border: 1px solid rgba(255, 255, 255, 0.1);
        width: 100%;
        max-width: 300px;
        min-height: 200px;
        padding: 15px;
        cursor: pointer;
        p {
            margin-top: 15px;
            margin-bottom: 20px;
            max-width: 170px;
            text-align: center;
        }
    }
    ._verification-field-btn {
        height: 30px;
        width: 150px;
        font-size: 14px;
        line-height: 30px;
        font-weight: 300;
        color: #fff;
        text-align: center;
        background-color: #f65932;
        white-space: nowrap;
        box-shadow: none;
        cursor: pointer;
    }
}

.verification-block.docs {
    ._verification-field {
        border-bottom-width: 0px;
    }
    h2 {
        margin-bottom: 5px;
    }
    p {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        margin-bottom: 30px;
    }


    .docs-add {
        padding-top: 10px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        max-width: 465px;
        display: flex;
        
        a {
            margin: auto;
            padding-bottom: 10px;
        }
        span {
            font-family: Open Sans;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 19px;
            color: #CED0D2;
            opacity: 0.6;
            flex: 0 0 40%;
            justify-content: flex-end;
            margin-bottom: 10px;
        }
        
        &__pending {
            align-items: center;
            flex-direction: row;
            display: block;
            margin:auto;
            padding-bottom: 8px;

            img {
                width: 24px;
                height: 24px;
                margin-right: 11px;
            }
            a {
                color: #F65932;
                opacity: 1;
                display: inline-block;
                vertical-align: super;
            }
            span {
                opacity: 1;
                display: inline-block;
                vertical-align: super;
            }
        }

    }

}
.docs__comments {
    margin-top: 20px;
    padding: 10px 10px 15px 10px;
    max-width: 465px;
    background: rgba(246, 89, 50, 0.1);
    border: 1px solid #F65932;
    box-sizing: border-box;
    border-radius: 3px;

    &__title {
        font-family: Oswald;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.05em;
        color: #FFFFFF;
        margin-bottom: 5px;
    }

    &__comment {
        font-size: 14px;
        line-height: 19px;
        color: #FFFFFF;
    }

    .button_orange {
        margin-top: 15px;
        height: 40px;
        box-shadow: none;
        align-items: center;
        padding: 0;

        & > div {
            font-family: "Open Sans";
            font-size: 14px;
        }
    }
}

.input-docs{
    &__status_wait{
        max-width: 465px;
        background: #242B33;
        border-radius: 3px;
        padding: 20px;
        margin-bottom: 20px;

        span{
            font-family: Open Sans;
            font-size: 14px;
            line-height: 19px;
            color: #D2D4D8;
        }
    }
    h2{
        margin-bottom: 20px;
    }

    .input-personal-data{
        flex-direction: row;
        display: flex;

        &__fields{
            flex: 0 0 50%;
        }

        .docs__comments{
            margin-top: 0;
            margin-left: 31px;
            overflow: hidden;
            height: 1%;
        }
    }

    .input-docs-data{
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        max-width: 465px;
        padding: 7px 0 4px 0;

        span{
            font-size: 14px;
            line-height: 19px;
            color: #CED0D2;
            opacity: 0.6;
            flex: 0 0 50%;
        }

        p{
            flex: 0 0 50%;
            padding: 9px;
        }

        .input-docs-data-fields{
            height: 32px;
            width: 170px;
            background-color: transparent;
            border: 1px solid rgba(255,255,255,0.4);
            color: #fff;
            font-size: 14px;
            font-weight: 300;
            box-sizing: border-box;
        }

        .ant-input {
            overflow: hidden;
            width: 89%;
            max-width: 210px;
            display: block;
            width: 149px;
        }
        .css-1pcexqc-container{
            width: 170px;
            max-width: 100%;
        }

        .input-docs-data-piker{
            padding: 0 7px 0 4px;
        }

        .filter-input{
            .input-docs-data-fields{
                overflow: hidden;
                width: 170px;
                height: 32px;
                // max-width: 232px;
                background-color: transparent;
                border: 1px solid rgba(255,255,255,0.4);
                color: #fff;
                font-size: 14px;
                font-weight: 300;
                box-sizing: border-box;
            }
    
            .ant-input {
                overflow: hidden;
                width: 100%;
                max-width: 210px;
            }
            .css-1pcexqc-container{
                width: 197px;
                max-width: 100%;
            }
    
            .input-docs-data-piker{
                padding: 0 7px 0 4px;
            }
        }
    }
}

.verification-block.images{
    h2{
        padding-top: 30px;
        padding-bottom: 16px;
    }
    
    ._verification-field-images{
        display: flex;

        ._verification-field-photo{
            max-width: 433px;

            img{
                width: 44px;
                height: 45px;
            }

            .button_orange{
                width: 171px;
                align-items: center;
                box-shadow: none;
                padding: 0;
                & > div{
                    color: #FFFFFF;
                    font-size: 14px;
                }
            }
            input[type="file"]{
                display: none;
            }
        }

        span{
            padding-left: 31px;
            max-width: 389px;
            font-size: 14px;
            line-height: 19px;
            color: #FFFFFF;
            opacity: 0.6;
        }
    }
    ._verification-field-buttons{
        padding-top: 20px;
        display: flex;
        height: 35px;

        .button_orange{
            width: 171px;
            align-items: center;
            box-shadow: none;
            padding: 0;

            & > div{
                color: #FFFFFF;
                font-size: 14px;
            }
        }

        .button_transparent{
            margin-left: 10px;
            width: 171px;
            align-items: center;
            padding: 0;

            & > div{
                color: #F65932;
                font-size: 14px;
            }
        }
    }
    
}