.ant-slider {
  font-size: 16px;
  font-variant: tabular-nums;
  color: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  margin: 14px 6px 10px;
  padding: 4px 0;
  height: 12px;
  cursor: pointer;
}
/* .ant-slider-vertical {
  width: 12px;
  height: 100%;
  margin: 6px 10px;
  padding: 0 4px;
}
.ant-slider-vertical .ant-slider-rail {
  height: 100%;
  width: 4px;
}
.ant-slider-vertical .ant-slider-track {
  width: 4px;
}
.ant-slider-vertical .ant-slider-handle {
  margin-left: -5px;
  margin-bottom: -7px;
}
.ant-slider-vertical .ant-slider-mark {
  top: 0;
  left: 12px;
  width: 18px;
  height: 100%;
}
.ant-slider-vertical .ant-slider-mark-text {
  left: 4px;
  white-space: nowrap;
}
.ant-slider-vertical .ant-slider-step {
  width: 4px;
  height: 100%;
}
.ant-slider-vertical .ant-slider-dot {
  top: auto;
  left: 2px;
  margin-bottom: -4px;
} */
.ant-slider-with-marks {
  margin-top: 28px;
}
.ant-slider-with-marks .ant-slider-handle {
  transform: none !important;
}
.ant-slider-rail {
  position: absolute;
  width: 100%;
  height: 4px;
  border-radius: 2px;
  background-color: #6d7177;
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.ant-slider-track {
  position: absolute;
  height: 4px;
  border-radius: 4px;
  background-color: #f65932;
  -webkit-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
}
.ant-slider-handle {
  position: absolute;
  margin-left: -11px;
  margin-top: -9px;
  width: 23px;
  height: 23px;
  cursor: pointer;
  border-radius: 50%;
  -webkit-transition: border-color 0.3s,
    -webkit-transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
  transition: border-color 0.3s,
    -webkit-transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
  transition: border-color 0.3s,
    transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
  transition: border-color 0.3s,
    transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28),
    -webkit-transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
  background: url('./icons/thumb.svg') no-repeat 50% 50% #f65932;
}
.ant-slider-handle:focus {
  border-color: #f65932;
  -webkit-box-shadow: 0 0 0 5px rgba(246, 89, 50, 0.6);
          box-shadow: 0 0 0 5px rgba(246, 89, 50, 0.6);
  outline: none;
}
/* .ant-slider-handle.ant-tooltip-open {
  border-color: #1890ff;
} */
/* .ant-slider:hover .ant-slider-rail {
  background-color: #e1e1e1;
}
.ant-slider:hover .ant-slider-track {
  background-color: #69c0ff;
}
.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  border-color: #69c0ff;
} */
.ant-slider-mark {
  position: absolute;
  top: -30px;
  left: 0;
  width: 100%;
  font-size: 16px;
}
.ant-slider-mark-text {
  font-size: 16px;
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  color: #6d7177;
}
.ant-slider-mark-text-active {
  color: #fff;
}
.ant-slider-step {
  position: absolute;
  width: 100%;
  height: 4px;
  background: transparent;
}
.ant-slider-dot {
  display: none;
  /* position: absolute;
  top: -2px;
  margin-left: -4px;
  width: 8px;
  height: 8px;
  border: 2px solid #e8e8e8;
  background-color: #fff;
  cursor: pointer;
  border-radius: 50%;
  vertical-align: middle; */
}
/* .ant-slider-dot:first-child {
  margin-left: -4px;
}
.ant-slider-dot:last-child {
  margin-left: -4px;
}
.ant-slider-dot-active {
  border-color: #8cc8ff;
} */
/* .ant-slider-disabled {
  cursor: not-allowed;
}
.ant-slider-disabled .ant-slider-track {
  background-color: rgba(0, 0, 0, 0.25) !important;
}
.ant-slider-disabled .ant-slider-handle,
.ant-slider-disabled .ant-slider-dot {
  border-color: rgba(0, 0, 0, 0.25) !important;
  background-color: #fff;
  cursor: not-allowed;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.ant-slider-disabled .ant-slider-mark-text,
.ant-slider-disabled .ant-slider-dot {
  cursor: not-allowed !important;
} */
