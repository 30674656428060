.photo_container{
  width: 465px;
  max-width: 465px;
  display: flex;
  flex-wrap: wrap;
  
  .photo-item{
    width: 120px;
    height: 150px;
    padding-right: 16px;
    padding-bottom: 15px;

    p{
      margin-top: 10px;
      margin-left: 0;
      opacity: 0.6;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .photo-item_image{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 118px;
    height: 118px;
    min-width: 118px;
    border-radius: 5px;
    background-color: #25292C;
    border: 1px solid hsla(0,0%,100%,.1);
    overflow: hidden;
    img{
      width: 120px;
    }

    .button_white{
      position: absolute;
      width: 21px;
      height: 21px;
      top: 3px;
      right: 3px;
      align-items: center;
      justify-content: center;
      padding: 0;
      cursor: pointer;
      img{
        display: block;
        border-width: 0;
        width: 9px;
        height: 9px;
        padding: 0;
        margin: auto;
      }
    }
  }

  input[type="file"]{
    display: none;
  }
  
  .button_transparent {
    width: 120px;
    height: 120px;
    border: 1px solid #F65932;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 25px;
    cursor: pointer;
    & input[type="file"] {
      display: none;
    }
    .photo_item_add {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      img {
        width: 18px;
        height: 18px;
        padding-bottom: 11px;
      }

      & > div {
        flex: 1 1;
        font-family: Open Sans;
        font-size: 14px;
        line-height: 19px;
        text-align: center;
        color: #F65932;
        padding: 0;
      }
    }
  }

}