.watching_user {
  &.container {
    margin: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 500px;
  }

  .textLabel {
    font-family: 'Oswald', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-transform: uppercase;
    color: white;
    margin-bottom: 20px;
  }

  .textGrey {
    color: #6D7177;
  }

  .textWrapper {
    display: flex;
    justify-content: space-between;
  }

  .editable-value {
    width: 140px;
    border: 1px solid #242A32;
    height: 40px;

  }

  .watchingUserProperty {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .editable__input {
    width: 140px;
    height: 40px;
  }

  .btnSave {
    max-width: 300px;
    margin: auto;
    margin-top: 50px;
  }

  .hidden {
    display: none;
  }
}

