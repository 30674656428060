// $track-color: #f65932;
// $thumb-color: #f65932;
$track-color: white;
$thumb-color: white;

$thumb-radius: 5px;
$thumb-height: 10px;
$thumb-width: 10px;
$thumb-shadow-size: 0px;
$thumb-shadow-blur: 0px;
$thumb-shadow-color: #111;
$thumb-border-width: 0px;
$thumb-border-color: white;

$track-width: 100%;
$track-height: 5px;
$track-shadow-size: 0px;
$track-shadow-blur: 0px;
$track-shadow-color: #222;
$track-border-width: 0px;
$track-border-color: black;

$track-radius: 5px;
$contrast: 5%;

// @mixin shadow($shadow-size, $shadow-blur, $shadow-color) {
//   box-shadow: $shadow-size $shadow-size $shadow-blur $shadow-color,
//     0px 0px $shadow-size lighten($shadow-color, 5%);
// }

.protest-label span {
  background-color: white;
  opacity: 0.8;
  border-radius: 2px;
  color: #25292c;
  font-family: Open Sans, sans-serif;
  font-size: 14px;
  padding: 2px 10px 2px 8px;
}

.protest-point {
  position: relative;
}

.protest-point div {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: white;
  margin: auto;
  top: 30px;
  position: absolute;
}

.video-slider .ant-slider-handle {
  display: none;
  width: 10px;
  height: 10px;
  position: relative;
  margin: -3px 0 0 0;
  transition: null;
  border-radius: 5px;
  background: #f65932;
}

.video-slider .ant-slider-rail {
  background-color: #d0d0d0;
  opacity: 0.4;
}

.video-volume-slider .ant-slider-handle:focus {
  box-shadow: none;
}

.video-volume-slider .ant-slider {
  width: 100px;
  z-index: 1;
}
.video-volume-slider .ant-slider-handle {
  display: none;
  width: 0px;
  height: 0px;
  position: relative;
  // margin: -3px 0 0 0;
  // padding: 0;
  // transition: null;
  // border-radius: 5px;
  background: white;
}

.video-volume-slider .ant-slider-track {
  background-color: white;
}

.video-volume-slider .ant-slider-rail {
  background-color: #d0d0d0;
  opacity: 0.4;
}

.video-slider .ant-slider-handle:focus {
  box-shadow: none;
}

.videosTab-control-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 90px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9));
  .videosTab-control {
    position: absolute;
    bottom: 5px;
  }
  .video-controller {
    position: absolute;
    display: flex;
    width: 100%;
    bottom: 5px;
    height: 24px;
    padding: 0 10px;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
    & > * {
      display: flex;
      & > * {
        display: flex;
        align-items: center;
        height: 24px;
        cursor: pointer;
      }
      & > *:not(:last-child) {
        margin-right: 10px;
      }
      .video-volume {
        min-width: 18px;
      }
      .video-play {
        min-width: 14px;
      }
      .video-live {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.15em;
        text-transform: uppercase;
        padding: 0 10px;
      }
      .video-live.live {
        background-color: #f65932;
      }
      .video-live.archive {
        background-color: #20A39E;
        color: white;
      }
    }
  }
}
.video-slider.ant-slider {
  margin: 0;
  position: absolute;
  width: calc(100% - 20px);
  left: 10px;
  bottom: 40px;
  z-index: 1;
}

@mixin track() {
  width: $track-width;
  height: $track-height;
  cursor: pointer;
  animate: 0.2s;
}

@mixin thumb() {
  // @include shadow($thumb-shadow-size, $thumb-shadow-blur, $thumb-shadow-color);
  border: $thumb-border-width solid $thumb-border-color;
  height: $thumb-height;
  width: $thumb-width;
  border-radius: $thumb-radius;
  background: $thumb-color;
  cursor: pointer;
}

input[type="range"] {
  -webkit-appearance: none;
  margin: $thumb-height/2 0;
  width: $track-width;

  &:focus {
    outline: none;
  }

  &::-webkit-slider-runnable-track {
    @include track();
    // @include shadow(
    //   $track-shadow-size,
    //   $track-shadow-blur,
    //   $track-shadow-color
    // );
    background: $track-color;
    border-radius: $track-radius;
    border: $track-border-width solid $track-border-color;
  }

  &::-webkit-slider-thumb {
    @include thumb();
    -webkit-appearance: none;
    margin-top: ((-$track-border-width * 2 + $track-height) / 2) -
      ($thumb-height / 2);
  }

  &:focus::-webkit-slider-runnable-track {
    background: lighten($track-color, $contrast);
  }

  &::-moz-range-track {
    @include track();
    // @include shadow(
    //   $track-shadow-size,
    //   $track-shadow-blur,
    //   $track-shadow-color
    // );
    background: $track-color;
    border-radius: $track-radius;
    border: $track-border-width solid $track-border-color;
  }
  &::-moz-range-thumb {
    @include thumb();
  }

  &::-ms-track {
    @include track();
    background: transparent;
    border-color: transparent;
    border-width: $thumb-width 0;
    color: transparent;
  }

  &::-ms-fill-lower {
    background: darken($track-color, $contrast);
    border: $track-border-width solid $track-border-color;
    border-radius: $track-radius * 2;
    // @include shadow(
    //   $track-shadow-size,
    //   $track-shadow-blur,
    //   $track-shadow-color
    // );
  }
  &::-ms-fill-upper {
    background: $track-color;
    border: $track-border-width solid $track-border-color;
    border-radius: $track-radius * 2;
    // @include shadow(
    //   $track-shadow-size,
    //   $track-shadow-blur,
    //   $track-shadow-color
    // );
  }
  &::-ms-thumb {
    @include thumb();
  }
  &:focus::-ms-fill-lower {
    background: $track-color;
  }
  &:focus::-ms-fill-upper {
    background: lighten($track-color, $contrast);
  }
}

/*Chrome*/
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type="range"] {
    height: 5px;
    // overflow: hidden;
    border-radius: 5px;
    width: 80px;
    -webkit-appearance: none;
    background-color: #d0d0d088 !important;
  }

  input[type="range"]::-webkit-slider-runnable-track {
    height: 5px;
    // -webkit-appearance: none;
    // background-color: #d0d0d088;
    color: white;
    // margin-top: -1px;
  }

  input[type="range"]::-webkit-slider-thumb {
    width: 10px;
    -webkit-appearance: none;
    height: 10px;
    // cursor: ew-resize;
    background: white;
    // box-shadow: -80px 0 0 80px #f65932;
  }
}
/** FF*/
input[type="range"]::-moz-range-progress {
  background-color: white;
  height: 5px;
  border-radius: 5px;
}
input[type="range"]::-moz-range-track {
  background-color: #d0d0d088;
}
/* IE*/
input[type="range"]::-ms-fill-lower {
  background-color: white;
  height: 5px;
  border-radius: 5px;
}
input[type="range"]::-ms-fill-upper {
  background-color: #d0d0d0;
}
