.notification_list {
  display: flex;
  border-bottom: 1px solid #343d49;
}

.notification_container {
  margin: 10px 15px 10px 10px;
  flex: 1;
}

.notification_header {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  margin-bottom: 2px;
  align-items: center;
}

.notification_type {
  font-weight: 600;
  opacity: 0.6;
  line-height: normal;
}

.notification_time {
  font-weight: 300;
  opacity: 0.6;
  margin-right: 10px;
}

.notification_text {
  font-size: 14px;
  font-weight: 300;
}

.green {
  width: 5px;
  flex: 0 1 5px;
  background-color: #35c844;
}

.red {
  width: 5px;
  flex: 0 1 5px;
  background-color: #ff4242;
}

.white {
  width: 5px;
  flex: 0 1 5px;
  background-color: #e5e5e5;
}

.notification_time_n_trash {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.user {
  cursor: pointer;
  color: #f65932;
}