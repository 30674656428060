.leave-modal {
  margin-bottom: 15px;
  padding-top: 10px;
  opacity: 0.6;
  color: #fff;
}

.leave-modal + .checkbox {
  margin-bottom: 15px;
}

.leave-modal + .checkbox .checkbox__checkmark {
  margin-right: 10px;
  height: 20px;
  width: 20px;
  box-sizing: border-box;
  position: relative;
  flex: 0 0 20px;
}

.leave-modal + .checkbox .checkbox__checkmark img {
  height: 20px;
  width: 20px;
  position: absolute;
  top: -2px;
  left: -2px;
}