.ant-modal-body {
  padding: 0;
}

.protest__form {
  font-size: 12px;
  color: white;
  line-height: 16px;
  margin: 15px 0;

  label {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #fff;
    opacity: .6;
    margin-bottom: 10px;
    display: block;
  }

  .reason__select {
    margin-bottom: 10px;
    width: 100%;
  }

  .select__box {
    display: flex;
    width: 100%;

    .reason__select {
      width: 100%;

      &>div {
        width: 100%;
      }
    }

    .frame__select {
      width: 75px;
      margin-left: 3px;

      &>div {
        width: 100%;
      }
    }
  }

  textarea {
    max-width: 100%;
    min-width: 100%;
    min-height: 140px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    font-size: 14px;
    line-height: 20px;
    color: white;
    padding: 10px;
    background-color: transparent;
    display: block;
    box-sizing: border-box;
  }

  border-bottom: 1px;
}

.protest__postambula {
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
  padding: 15px 0;
  line-height: normal;
}