.tariffScreen {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 40px 20px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow-y: scroll;
  .screenName {
    font-family: 'Oswald';
    font-weight: 500;
    font-size: 30px;
    line-height: 44px;
    color: #fff;
    margin-bottom: 10px;
    flex: 0;
  }
  .activeTariff {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    max-width: 400px;
    width: 100%;
    margin-bottom: 30px;
    .buttons {
      display: flex;
      button {
        flex: 1 1 50%;
        font-size: 14px;
        padding: 0;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      button:not(:last-child) {
        margin-right: 15px;
      }
    }
    .checkbox {
      font-weight: normal;
    }
    .checkboxAddition {
      padding-left: 42px;
      font-size: 12px;
      opacity: 0.6;
      margin-bottom: 15px;
      line-height: 16px;
    }
    .tariffCard {
      display: flex;
      align-items: stretch;
      flex-direction: column;
      border-radius: 3px;
      color: #fff;
      overflow: hidden;
      font-family: 'Oswald';
      font-weight: 500;
      margin-bottom: 20px;
      flex: 0 0 auto;
      .header {
        display: flex;
        justify-content: space-between;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
        padding: 15px 20px;
        z-index: 1;
        position: relative;
        font-size: 30px;
        letter-spacing: 0.1em;
        flex: 0 0 auto;
        small {
          font-size: 16px;
          margin-left: 5px;
        }
      }
      .content {
        z-index: 0;
        position: relative;
        padding: 20px;
        .barName {
          font-size: 20px;
          letter-spacing: 0.05em;
          margin-bottom: 10px;
        }
        .barSummary {
          font-size: 16px;
          margin-top: 10px;
          letter-spacing: 0.05em;
        }
        .bar {
          background: rgba(255, 255, 255, 0.2);
          border-radius: 5px;
          height: 10px;
          display: flex;
          overflow: hidden;
          .elem {
            background: linear-gradient(201.96deg, #F65932 0%, #CC3535 100%);
          }
        }
      }
    }
  }
  .noTariff {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    max-width: 400px;
    width: 100%;
    margin-bottom: 30px;
    .header {
      padding: 15px 20px;
      font-family: 'Oswald';
      font-size: 30px;
      letter-spacing: 0.05em;
      color: #fff;
      background: rgba(255, 255, 255, 0.1);
      border-radius: 3px;
      margin-bottom: 15px;
    }
    .explanation {
      font-size: 14px;
      color: #FFFFFF;
      opacity: 0.6;
      line-height: 150%;
      margin-bottom: 20px;
    }
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      height: 40px;
      font-size: 14px;
    }
  }
}

.reversalText {
  border-bottom: 1px solid #25292c;
  padding: 20px 20px;
  margin: 0 -20px;
  .reversalTextBig, .reversalTextSmall {
    color: #fff;
    display: block;
  }
  .reversalTextBig {
    line-height: 24px;
    font-size: 16px;
    margin-bottom: 15px;
  }
  .reversalTextSmall {
    line-height: 21px;
    font-size: 14px;
  }
}

.dropTariffButtons {
  & > div, button > div {
    width: 100%;
  }
  .buttonlike {
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 38px;
    margin: -10px -5px;
    &.disabled {
      pointer-events: none;
    }
  }
}