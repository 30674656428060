a{
  cursor: pointer;
}

.app-info {
  font-size: 14px;
  font-family: Open Sans;
  text-align: center;
  color: #9BAABD;
  bottom: 18px;
  position: absolute;
  width: 380px;
}

.login-language div {
  margin: 5px 0;
}

.language-header {
  font-size: 16px;
  font-weight: 300;
  opacity: 0.4;
  color: white;
}

.language-list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

.language-globe-icon {
  width: 24px;
  height: 24px;
  padding-right: 5px;
}

.login-language .language-list div {
  margin-right: 20px;
  display: flex;
  opacity: 0.4;
  align-items: center;
}

.login-language .language-list div.selected {
  opacity: 1;
}

.language-list span {
  margin-right: 5px;
}

.login-language .flag-icon-squared.flag-icon {
  border-radius: 12px;
  width: 24px;
  height: 24px;
}

.login-language .language-name {
  font-size: 16px;
  font-weight: 300;
}

.postregpage {
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  background: url('../images/bg.png') #000;
  background-size: cover;
}

.postregpage__fileinput {
  display: none;
}

.postregpage__content {
  display: flex;
  flex: 1;
}

.postregpage__leftcolumn {
  width: 37.5%;
  padding-top: 30px;
  padding-right: 96px;
  padding-bottom: 30px;
  background: rgba(0,0,0,0.4);
  display: flex;
  flex-direction: column;
}

.postregpage__leftcolumn__content {
  display: flex;
  flex-direction: column;
  max-width: 250px;
}
.postregpage__leftcolumn__content__logos {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.postregpage__leftcolumn__content__logo {
  width: 193px;
  height: 60px;
  margin-bottom: 30px;
}

.postregpage__leftcolumn__content__message {
  margin-top: 35px;
  font-size: 14px;
  font-weight: 200;
  letter-spacing: 0.5px;
  font-family: 'Open Sans';
}

.postregpage__rightcolumn {
  width: 62.5%;
  padding-left: 96px;
  padding-top: 137px;
}

.postregpage__rightcolumn__content {
  width: 350px;
}

.postregpage__rightcolumn__content__radiogroup {
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.postregpage__rightcolumn__content__radios {
  align-items: center;
  margin-left: 30px;
  display: flex;
  justify-content: space-between;
}

.postregpage__rightcolumn__content__radiogroupname {
  color: #6a6869;
  font-family: 'Open Sans';
  font-weight: 400;
}

.postregpage__rightcolumn__content__additionaldata {
  padding-top: 40px;
  padding-bottom: 10px;
  font-family: 'Open Sans';
  font-weight: 300;
}

.postregpage__rightcolumn__content__button {
  padding-top: 50px;
}
.postregpage__rightcolumn__content__photo__maincontainer {
  display: flex;
  flex-direction: column;
}

.postregpage__rightcolumn__content__photo {
  display: flex;
}

.postregpage__rightcolumn__content__img{
  justify-content: center;
  border-radius: 100px;
  overflow: hidden;
  border: 1px solid #f65932;
  align-items: center;
  display: flex;
  position: relative;
  flex: 0 0 100px;
}

.postregpage__rightcolumn__content__img::before{
  position: relative;
  content: '';
  padding-top: 100%;
  width: 100%;
  display: block; 
}

.postregpage__rightcolumn__content__img img{
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.postregpage__rightcolumn__content__photo__container {
  margin-left: 15px;
}

.postregpage__rightcolumn__content__photo__text {
  font-family: 'Open Sans';
  font-weight: 200;
  font-size: 12px;
  letter-spacing: 0.5px;
  line-height: 17px;
}

.postregpage__rightcolumn__content__photo__text__button {
  margin-top: 10px;
  width: 50%;
}

.postregpage__rightcolumn__content__radio1 {
  margin: 0px;
}

.postregpage__rightcolumn__content__radio2 {
  margin-left: 50px;
}

.postregpage__rightcolumn__content__userphoto {

}

.postregpage__rightcolumn__content__defaultphoto {
  position: absolute;
  width: unset !important;
  height: unset !important;
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: contain !important;
}

.postregpage__rightcolumn__content__select__container {
  padding-top: 10px;
  padding-bottom: 10px;
}

::-webkit-input-placeholder,
::-moz-placeholder,
:-ms-input-placeholder,
:-moz-placeholder {
  opacity: 1;
}

.hourshift {
  padding-top: 20px;
}

.postregpage .ant-calendar-picker {
  /* margin: 30px !important; */
  position: relative !important;
}

.postregpage .ant-calendar-picker div{
  width: 100% !important;
}

.postregpage .ant-calendar-picker-input {
  padding-left: 0px !important;
  padding-right: 0px !important;
  width: 100% !important;
  border-style: unset !important;
  border-bottom: 1px solid #f65932 !important;
  font-family: 'Open Sans' !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  position: relative !important;
}

.postregpage__rightcolumn__content__datepicker {
  margin-bottom: 30px;
}

.postregpage__rightcolumn__content__input__datepicker {
  display: flex;
  justify-content: space-between;
}

.postregpage__rightcolumn__content__input__datepicker > div:not(:last-child){
  margin-right: 15px;
}

.postregpage__rightcolumn__content__input__datepicker > div{
  flex: 1;
}

.input__tip.before_datepicker{
  margin-top: 30px;
}

.postregpage .ant-calendar-picker div::after {
  content: url('../images/calendar.svg') !important;
  position: absolute !important;
  padding-left: 10px !important;
  left: 310px !important;
}

.postregpage__popup .ant-calendar {
  width: 350px !important;
  margin-right: 0px !important;
}

.ant-calendar-picker-container .postregpage__popup {
  width: 350px !important;
}

.ant-calendar-picker-container .postregpage__popup .ant-calendar-picker-container-placement-bottomLeft {
  width: 350px !important;
}

.postregpage__rightcolumn__content__input {
  padding-top: 10px;
}

.postregpage__rightcolumn__content__inputs {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.postregpage .ant-radio-wrapper{
  color: #6a6869 !important;
  font-weight: 400 !important;
}

.postregpage .ant-radio-wrapper-checked{
  color: #fff !important;
}

.postregpage_inputerror{
  min-height: 36px;
  line-height: 36px;
  padding-left: 8px;
  padding-right: 8px;
  vertical-align: middle;
  font-size: 14px;
  font-weight: 300;
  background-color: rgba(246,89,50,0.2);
  color: #fff;
}

.postregpage__storybook {
  width: 100%;
}

.postregpage__storybook .postregpage_inputerror{
  min-height: 36px;
  line-height: 36px;
  padding-left: 8px;
  padding-right: 8px;
  vertical-align: middle;
  font-size: 14px;
  font-weight: 300;
  background-color: rgba(246,89,50,0.2);
  color: #fff;
  box-sizing: border-box;
}

.postregpage__storybook div{
  width: 100%;
}

.postregpage__storybook .ant-calendar-picker div{
  width: 100% !important;
}

@media (-ms-high-contrast: none), (-ms-high-contrast: active){
  .postregpage__rightcolumn__content__img img{
    position: absolute;
    width: 150%;
    height: 150%;
  }
}

.login-page__right-column__content.password-recovery *{
  line-height: normal;
}

.login-page__right-column__content.password-recovery .gray-text{
  margin-bottom: 20px;
  margin-top: 10px;
  line-height: normal;
}

.login-page__right-column__content.password-recovery .ant-radio-wrapper{
  margin: 0;
  flex: 0 0 auto;
  margin-right: 50px;
}

.login-page__right-column__content.password-recovery .ant-radio-group{
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.login-page__right-column__content.password-recovery button.recover{
  box-shadow: 0 10px 14px 0 rgba(246, 89, 50, 0.3);
  transition: box-shadow 0.5s;
  margin-bottom: 30px;
}

.login-page__right-column__content.password-recovery button.recover:hover{
  box-shadow: 0 10px 14px 0 rgba(246, 89, 50, 0.45);
}

.login-page__right-column__content.password-recovery button.cancel{
  background-color: rgba(255, 255, 255, 0.1);
  transition: background-color 0.5s;
}

.login-page__right-column__content.password-recovery button.cancel span{
  font-weight: bold;
  color: #f65932;
}

.login-page__right-column__content.password-recovery button.cancel:hover{
  background-color: rgba(255, 255, 255, 0.2);
}

.login-page__right-column__content.password-recovery .gray-text.spaced{
  min-height: 80px;
}

.login-page__right-column__content.password-recovery .resetedpassword{
  margin-bottom: 60px;
}

.login-page__right-column__content.password-recovery .phone-holder{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 5px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
}

.login-page__right-column__content.password-recovery .phone-holder label{
  display: block;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.4);
  flex: 0 0 100%;
}

.disabledRadio {
  opacity: 0.4;
  pointer-events: none;
}

@media screen and (max-width: 768px) {
  .postregpage__content{
    justify-content: center;
  }
  .postregpage__leftcolumn {
    display: none;
  }

  .postregpage__rightcolumn {
    width: auto;
    padding-left: 0;
  }
  .postregpage__rightcolumn__content {
    width: 300px;
  }
}
