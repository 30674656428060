.frame-edit__reason, .frame-edit__explanation{
  label{
    display: block;
    margin-top: 15px;
    margin-bottom: 10px;
  }
}

.frame-edit__dialogue{
  .dialog__button-decline{
    color: #20a39e;
    border: 1px solid #20a39e;
    background: transparent;
    &:hover{
      background: #00bfbf;
      color: white;
    }
  }
}

.frame-edit__score{
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  img{
    flex: 0 0 40px;
    margin: 0 30px;
    width: 40px;
    height: 14.1px;
  }
  .frame-edit__framebox{
    display: flex;
    flex: 0 0 48px;
    flex-direction: column;
    border-left: 1px solid #25292c;
    border-right: 1px solid #25292c;
    & > div{
      width: 100%;
      flex: 2 2 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #d2d4d8;
      font-size: 16px;
      color: #25292c;
      position: relative;
      span:not(:last-child){
        margin-right: 10px;
      }
      &:first-child, &:last-child{
        flex: 1 1 20px;
        font-size: 14px;
        color: #fff;
        background: #343d49;
      }
      &:last-child{
        font-weight: bold;
      }
      .frame-edit__flag{
        position: absolute;
        top: 0;
        left: 0;
        background-color: #20a39e !important;
        border-bottom-right-radius: 4px;
        width: 13px;
        height: 11px;
        font-size: 10px;
        text-align: center;
      }
    }
    &:last-child{
      div{
        background: #f65932;
        color: #fff;
        &:first-child, &:last-child{
          background: #5b4344;
        }
      }
    }
  }
}

.frame-edit__reason{
  .ant-radio-wrapper{
    margin: 0;
    margin-bottom: 15px;
  }
}

.frame-edit__explanation{
  #frame-edit__explanation__input{
    display: block;
    width: 100%;
    box-sizing: border-box;
    background: transparent;
    border: 1px solid #6d7177;
    height: 68px;
    margin-bottom: 15px;
  }
}
