
.docs-added-dialog {
  height: 364px;
  align-items: center;
  justify-content: space-between;
  padding: 0;

  &__image-container{
    align-self: center;
    text-align:center;
    width:100%;
    display:block;
    margin:auto;
  }
  &__image {
    align-self: center;
  }
  &__label {
    display: block;
    margin-bottom: 10px;
    font-family: Oswald;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #FFFFFF;
    padding-top: 23px;
    margin: 0;
  }
  & .ant-modal-content {
    width: 395px;
    height: 364px;
    background: #0C131C;
  
    & .ant-modal-body {
        height: 293px;
        padding: 40px 20px 15px 20px;
  
        & img {
            width: 177.87px;
            height: 135.61px;
        }
  
        & p {
            font-family: Oswald;
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 30px;
            text-align: center;
            letter-spacing: 0.05em;
            color: #FFFFFF;
            padding: 30px 0 20px 0;
        }
    }
  
  }
}

.ant-modal-footer{
  border-top: solid 1px #25292C;

}

.dialog__buttons{
  .dialog__button-accept{
    padding: 15px 20px;
    display: flex;
  }
}