/* input */
.input {
  position: relative;
}
.input__tip {
  display: block;
  padding-top: 2px;
  min-height: 14px;
  font-size: 12px;
  color: rgba(255,255,255,0.4);
  transition: 300ms ease all;
}
.input__tip_focused {
  color: #f65932;
}
.input__data {
  height: 30px;
  width: 100%;
  padding: 0;
  font-size: 16px;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #f65932;
  color: #fff;
  border-radius: 0;
}
.input__error {
  min-height: 36px;
  line-height: 36px;
  padding-left: 8px;
  padding-right: 8px;
  vertical-align: middle;
  font-size: 14px;
  font-weight: 300;
  background-color: rgba(246,89,50,0.2);
  color: #fff;
}

/* filter input */
.filter-input__data {
  height: 32px;
  padding: 0 10px;
  background-color: transparent;
  border: 1px solid rgba(255,255,255,0.4);
  color: #fff;
  font-size: 14px;
  font-weight: 300;
  box-sizing: border-box;
}

.filter-input__disabled {
  cursor: not-allowed;
}
