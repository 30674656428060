.account__mybattles .ant-table-row td,
.account__mybattles .ant-table-thead tr th {
  white-space: nowrap;
}

.account__mybattles .ant-table-row td .button {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #FFFFFF;
  padding-top: 6px;
  padding-bottom: 6px;
  width: 120px;
}

.account__mybattles .ant-table-row td b {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  color: #FFFFFF;
}

.account__mybattles_archive_not_exists {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: left;
  color: #7C8084;
}

.account__mybattles_play_trash {
  display: flex;
  align-items: center;
}

.account__mybattles_play_trash .svg-button {
  margin-left: 10px;
}

.account__mybattles__filter {
  padding: 10px 20px;
  border-bottom: 1px solid #25292c;
}

.account__mybattles__filters {
  display: flex;
  align-items: center;
}

.account__mybattles__filter__fee {
  margin-left: 20px;
  opacity: 0.4;
  font-size: 14px;
  font-family: 'Open Sans';
  font-weight: 300;
}

.account__mybattles__filter__selects {
  margin-left: 10px;
  display: flex;
  width: auto !important;
}

.account__mybattles__filter__selectfrom {
  width: 75px !important;
}

.account__mybattles__filter__selectto {
  margin-left: 5px;
  width: 75px !important;
}

.account__mybattles__filter__checkbox,
.account__mybattles__filter__with_protest {
  margin-left: 20px;
}

.account__mybattles__filter__checkbox__child {
  font-weight: 300;
  font-size: 14px;
  opacity: 0.4;
}

.account__mybattles__filter__with_protest__child {
  display: flex;
  flex-direction: row;
  align-items: center
}

.account__mybattles__filter__with_protest__child span {
  opacity: 0.4;
  margin-left: 5px;
}

.refereeing-svg {
  width: 18px;
}

.refereeing-svg path {
  fill: #FF4242;
}

.account__mybattles__filter__link {
  margin-left: 20px;
  font-weight: 300;
  font-size: 14px;
}

.account__mybattles__filter__link:hover {
  color: #ff3e18;
}

.account__mybattles_win {
  font-weight: 700;
  color: #20a39e;
  font-size: 14px;
  font-family: 'Open Sans';
}

.account__mybattles_lose {
  font-size: 14px;
  font-family: 'Open Sans';
  opacity: 0.6;
  font-weight: 200;
}

.account__mybattles_result {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.account__mybattles_result .refereeing-svg {
  margin-right: 10px;
}

.account__mybattles_result span {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #FFFFFF;
  opacity: 0.6;
}

.account__mybattles_result.resolved {
  cursor: pointer;
}

.account__mybattles_result.wait span {
  opacity: 1;
}

.account__mybattles_result.win span {
  font-weight: bold;
  color: #20A39E;
  opacity: 1;
}

.account__mybattles th:first-child, .account__mybattles td:not(:first-child){
  padding-left: 15px;
}

.account__mybattles .ant-spin-dot-item {
  background-color: #F65932;
}

.account__mybattles {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.mybattles-spin {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}