.refereeing {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  height: 100vh;
  background: #0B121C;

  .header {
    background-image: url('/static/media/bg2.5568fab1.png');
    background-size: cover;
    background-repeat: no-repeat;

    .header-top {
      height: 30px;
      display: flex;
      justify-content: space-between;
      background: rgba(32, 163, 158, .6);

      .left-buttons,
      .right-buttons {
        height: 100%;
        display: flex;
      }

      .left-buttons {
        button {
          padding: 5px 20px;
        }

        button:not(:last-child) {
          position: relative;
          border-right: 1px solid #20a39e;

          &::after {
            content: '';
            width: 1px;
            top: 0;
            bottom: 0;
            right: -1px;
            opacity: 0.4;
            position: absolute;
            background-image: linear-gradient(to top, rgba(255, 255, 255, 0.2), #fff 50%, rgba(255, 255, 255, 0.2));
          }
        }
      }

      .right-buttons {

        .start-datetime {
          margin-right: 17px;
          white-space: nowrap;
          font-family: Open Sans;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 118.56%;
          text-align: right;
          color: #FFFFFF;
          display: flex;
          flex-direction: row;
          align-items: center;
        }

      }

      .tournament-info {
        height: 100%;
        display: flex;
        align-items: center;
        background: #231A1E;
        padding: 3px 0;
        border-left: 1px solid #6d7176;
        border-right: 1px solid #6d7176;
        box-sizing: border-box;

        &>div {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          font-family: Oswald;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.05em;
          color: #FFFFFF;
          padding: 0 10px;
          border-right: 1px solid rgba(255, 255, 255, .2);
          box-sizing: border-box;

          &.tournament-name {
            font-weight: 500;
            font-size: 18px;
          }

          &:last-child {
            border-right: none;
          }
        }
      }
    }

    .header-bottom {
      position: relative;
      padding: 10px 0;

      &.lowwidth {
        display: flex;
        align-items: center;
        background: linear-gradient(to right, #0d1928, #0d141d);
        padding: 0 20px;

        .match-scoreboard {
          flex: 1 1 100%;
          justify-content: flex-start;

          .score {
            flex: 0 0 auto;
          }

          .antag,
          .protag {
            flex: 0 1 auto;
          }
        }

        &>button,
        &>.protest__button {
          flex: 0 0 150px;
          height: 48px;
        }
      }

      &::before,
      &::after {
        width: 100%;
        left: 0;
        height: 1px;
        position: absolute;
        content: '';
        background-image: linear-gradient(to right, rgba(255, 255, 255, 0.2), white 50%, rgba(255, 255, 255, 0.2));
        opacity: 0.4;
      }

      &::before {
        top: 0px;
      }

      &::after {
        bottom: 1px;
      }

      .match-scoreboard {

        .antag,
        .protag {
          .avatar {
            height: 64px;
            width: 64px;
            min-width: 58px;

            img {
              width: 58px;
            }

            .avatar__flag {
              width: 24px;
              height: 24px;
            }
          }
        }

        .protag .avatar__flag {
          left: -7px;
          right: 0;
        }

        .score>span {
          color: #fff;

          &:last-child {
            color: #f65932;
          }
        }
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-height: 100%;
    overflow: hidden;

    .games {
      flex: 0 0 auto;
      width: 100%;

      .react-tabs__tab-list {
        display: flex;
        justify-content: center;
        margin: 0;
      }

      .react-tabs .react-tabs__tab {
        padding: 7px 0 6px;
      }

      .react-tabs .react-tabs__tab-panel {
        width: calc(100% - 40px);
        overflow: visible;
      }

      .game {
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 0 20px;

        .player {
          margin-top: 10px;
          width: 50%;
          max-width: 942px;

          &.owner {
            margin-left: 25px;
          }

          .player-head {
            justify-content: space-between;
            display: flex;
          }

          .names {
            width: 100%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            .fullname {
              height: 22px;
              font-size: 16px;
              line-height: 22px;
            }

            .login {
              height: 22px;
              line-height: 22px;
              font-size: 16px;
              color: #f65932;
            }
          }

          .lane {
            line-height: 22px;
            align-items: center;
            width: 100%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            
            .flag-icon {
              width: 18px;
              height: 18px;
              border-radius: 50%;
              margin-right: 10px;
            }
          }

          .game-score {
            margin-top: 10px;
          }
        }
      }
    }

    .current-game {
      display: flex;
      margin-top: 15px;
      position: relative;
      height: 100%;
      overflow: hidden;

      &::before {
        content: '';
        position: absolute;
        display: block;
        height: 1px;
        width: 100%;
        top: 0;
        left: 0;
        background-color: #343d49;
        background-image: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.2) 30%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0.2) 70%, transparent);
      }

      .other {
        position: relative;
        width: 500px;
        min-width: 500px;
        margin-top: 1px;
        margin-left: 1px;
        max-height: 100%;
        display: flex;
        flex-direction: column;

        .title {
          height: 27px;
          background: #343D49;
          line-height: 27px;
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          color: #FFFFFF;
          padding-left: 10px;
        }

        &:before {
          content: '';
          position: absolute;
          height: 100%;
          width: 1px;
          top: 0;
          left: -1px;
          background: linear-gradient(to bottom, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0.08) 100%);
        }

        .refereeing-container {
          width: 100%;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;

          .frame-edit-container {
            display: flex;
            flex-direction: column;
            padding: 15px 3px;

            .frame-edit-title {
              font-family: Open Sans;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 19px;
              text-align: center;
              color: #FFFFFF;
              margin-bottom: 15px;
            }

            .digits {
              display: flex;
              flex-wrap: nowrap;
              margin-bottom: 3px;

              >.digit {
                width: 10%;
                margin-right: 3px;

                &:last-child {
                  margin: 0
                }
              }
            }

            .strike-foul {
              display: flex;
              flex-wrap: nowrap;
              margin-bottom: 3px;

              &>div {
                margin-right: 3px;
                width: 50%;

                &:last-child {
                  margin: 0
                }
              }

            }

            .save-cancel-delete {
              display: flex;
              flex-wrap: nowrap;

              &>div {
                margin-right: 3px;
                width: 50%;

                &:last-child {
                  margin: 0
                }
              }

              .cancel-delete {
                display: flex;
                flex-wrap: nowrap;

                &>div {
                  margin-right: 3px;
                  width: 50%;

                  &:last-child {
                    margin: 0
                  }
                }
              }

            }

          }

          .protest-container {
            height: 200px;
            display: flex;
            flex-direction: column;

            .protest {
              flex: 1;
              overflow: auto;
              padding: 10px;

              .protest-category,
              .protest-comment {
                margin-bottom: 10px;
              }

              .protest-category,
              .protest-comment,
              .protest-reply {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 19px;
                color: #fff;

                span {
                  opacity: .6;
                }
              }

              .protest-comment>span {
                color: #F65932;
                opacity: 1;
              }

              .protest-reply>span {
                color: #20A39E;
                opacity: 1;
              }

            }

            .protest-footer {
              border-top: 1px solid #272E35;
              padding: 10px;

              .protest-info {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 19px;
                color: #FFFFFF;
                margin-bottom: 10px;
              }

              .protest-buttons {
                display: flex;
                justify-content: center;

                >.button {
                  width: 50%;

                  &:first-child {
                    margin-right: 15px;
                  }
                }

              }
            }

          }

        }

        .archive-chat {
          overflow: auto;
          margin: 0;
          padding: 0;
          list-style: none;
          padding: 10px;
          box-sizing: border-box;
          flex: 1;

          .message {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            color: white;
            font-weight: bold;
            padding-bottom: 7px;

            &.system {
              font-weight: normal;

              .time,
              .author,
              .text {
                opacity: .6;
              }

            }

            .author {
              color: #20A39E;
            }

            &.owned {
              .author {
                color: #F65932;
              }
            }

            .time {
              font-weight: normal;
              display: inline-block;
              text-align: center;
              margin-right: 5px;
              cursor: pointer;
              padding: 0 3px 0 2px;

              &:hover {
                background: #F65932;
                color: white;
                border-radius: 2px;
                opacity: 1;
              }
            }

            &:last-child {
              padding-bottom: 0;
            }
          }

        }

      }

      .cameras {
        width: 100%;
        display: flex;
        flex-direction: column;
        background: #0B121C;

        .tabs {
          display: flex;
          flex-direction: column;
          align-items: stretch;
          justify-content: flex-start;
          flex: 1;

          .tablist {
            display: flex;
            margin: 0;
            padding: 0 20px;
            background: #343d49;
            list-style: none;

            .tab {
              font-family: 'Open Sans';
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 28px;
              color: #FFFFFF;
              margin-right: 20px;

              &:not(div) {
                opacity: 0.4;
                cursor: pointer;
              }

              &.react-tabs__tab--selected {
                color: #F65932;
                opacity: 1;
              }
            }
          }

          .tabpanel {
            flex: 1;
            display: none;

            &.react-tabs__tab-panel--selected {
              display: block;
            }

            .video-container {
              display: flex;
              justify-content: space-between;
              width: 100%;
              padding: 0;
              height: 100%;
              align-items: center;

              .left-video-container {
                width: auto;
                display: flex;
                flex-direction: column;
                height: 100%;
              }

              .right-video-container {
                width: auto;
                height: 100%;
              }

              .video {
                position: relative;
                width: 100%;
                text-align: center;
                overflow: hidden;

                .no-video {
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  top: 0;
                  left: 0;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;

                  img {
                    width: 58px;
                  }

                  .no-video-text {
                    font-family: Oswald;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    letter-spacing: 0.03em;
                    color: #FFFFFF;
                    margin-top: 10px;
                    line-height: 26px;
                    white-space: pre;
                  }

                }

                .dummy {
                  overflow: hidden;
                  height: 0;
                  width: 100%;
                  padding-top: 56.25%;
                }

                .video-react {
                  display: inline-block;
                  background-color: #0B121C;
                }

                .video-react-big-play-button {
                  display: none;
                }
              }
            }
          }
        }

        .footer {
          display: flex;
          background: #0B121C;
          height: 70px;
          max-height: 70px;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;

          .video-controller {
            padding: 0 20px;
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: start;

            .video-controls {
              display: flex;

              .state-control,
              .sound-control {
                cursor: pointer;

                >.svg-button {
                  svg path {
                    opacity: .6;
                    transition: all .1s linear;
                    fill: #fff;
                  }

                  &:hover {
                    svg path {
                      opacity: 1;
                      fill: #fff;
                    }
                  }
                }
              }

              .game-control {
                width: 100%;
                display: flex;
                justify-content: center;

                .prev-game,
                .next-game {
                  font-family: Oswald;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 14px;
                  line-height: 24px;
                  letter-spacing: 0.05em;
                  color: #FFFFFF;

                  span {
                    display: inline-block;
                    opacity: .6;
                    transition: all .1s linear;
                  }

                  &:hover span {
                    opacity: 1;
                  }
                }

                .prev-game {
                  margin-right: 30px;
                  cursor: pointer;

                  &>span {
                    margin-left: 10px;
                  }
                }

                .next-game {
                  margin-left: 30px;
                  cursor: pointer;

                  &>span {
                    margin-right: 10px;
                  }
                }

                .timing {
                  background: #3D4249;
                  border-radius: 2px;
                  height: 24px;
                  padding: 0 13px;
                  color: #b1b3b6;
                  line-height: 24px;
                  font-family: 'Open Sans';
                  font-style: normal;
                  font-weight: normal;
                  font-size: 14px;

                  .timer {
                    color: white;
                  }
                }
              }

            }

          }

          .title {
            height: 27px;
            background: #343D49;
            line-height: 27px;
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            color: #FFFFFF;
            padding-left: 20px;
          }

        }
      }
    }

  }

  .ant-slider-rail,
  .ant-slider-step,
  .ant-slider-track {
    height: 8px;
  }

  .ant-slider-rail,
  .ant-slider-track {
    border-radius: 5px;
  }

  .ant-slider-handle {
    margin-left: -8px;
    margin-top: -4px;
    width: 16px;
    height: 16px;
    background: #f65932;
  }
}