.game-score {
  display: flex;
}

.game-score .frame__total {
  background-color: #343d49;
  width: calc(100% / 12 * 1.5);
}

.game-score .frame__total .frame__number {
  height: 20px;
  line-height: 20px;
  text-align: center;
  font-size: 14px;
}

.game-score .frame__total .frame__scores {
  height: 40px;
  line-height: 40px;
  background-color: #d2d4d8;
  display: flex;
  padding: 0 5px;
  justify-content: space-around;
  position: relative;
  font-size: 16px;
  font-weight: bold;
  color: #25292c;
}

.game-score .frame__total .frame__scoresSum {
  height: 30px;
  line-height: 30px;
  text-align: center;
  background-color: #343d49;
  font-size: 14px;
  font-weight: bold;
}