.passwoed-edit-dialog__succ{
  margin-top: 15px;
  margin-bottom: 15px;
}

.password-edit-dialog__container {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 15px;
  &:first-child {
    padding-top: 20px;
  }
  * {
    flex: 0 0 100%;
    box-sizing: border-box;
  }
  label {
    margin-bottom: 10px;
  }
  .password-edit-dialog__error {
    display: none;
    background-color: rgba(246, 89, 50, 0.2);
    color: #fff;
    vertical-align: middle;
    padding: 8px;
  }
  .password-edit-dialog__input {
    background: transparent;
    border: 1px solid #6d7177;
    padding: 0 8px;
    color: #fff;
    height: 30px;
    &.err {
      animation-duration: 1.5s;
      animation-name: input_error;
      animation-timing-function: ease-in;
      & + .password-edit-dialog__error {
        display: flex;
      }
    }
  }
}

@keyframes input_error {
  from { background: rgba(246, 89, 50, 1); }
  to { background: rgba(246,89,50,.2); }
}