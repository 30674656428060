@charset "UTF-8";
@media (min-width: 992px) {
  .h1 {
    font-family: Inter;
    font-style: italic;
    font-weight: 900;
    font-size: 94px;
    line-height: 110%;
    letter-spacing: -0.02em;
    text-transform: uppercase; }
  .h2, .container-tl .article > .article-content .title {
    font-family: Inter;
    font-style: italic;
    font-weight: 900;
    font-size: 64px;
    line-height: 110%;
    letter-spacing: -0.02em;
    text-transform: uppercase; }
  .h3, .form-title, .shedule .s-d-name, .container-tl .subsection, .container-tl .header-container .header .header-f .number, .container-tl .footer .social .social-row, .container-tl .aboutTournament .thirdBlock .block-title {
    font-family: Technor;
    font-style: normal;
    font-weight: bold;
    font-size: 44px;
    line-height: 120%;
    text-transform: uppercase; }
  .h4, .form-title .form-subtitle, .shedule .g-title, .formB .participants .participant .p-h, .container-tl .notAvailable, .container-tl .header-container .header .header-h .icons, .container-tl .header-container .header .header-f .word, .container-tl .table-of-content, .container-tl .aboutTournament .firstBlock .text-block .mainText, .container-tl .aboutTournament .secondBlock .sbContent .sbInfo, .container-tl .aboutTournament .thirdBlock .info, .container-tl .sponsors .add-sponsor-a .add-sponsor .text, .container-tl .cometee .card .fullname, .container-tl .info-block .info, .container-tl .bowlingCenters .row.data, .container-tl .aboutTenpincam .firstBlock, .container-tl .aboutTenpincam .secondBlock .btn-container .btn {
    font-family: Technor;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 140%;
    text-transform: uppercase; }
  .f, .container-tl .footer .footer-content .section .section-header {
    font-family: Inter;
    font-style: italic;
    font-weight: 900;
    font-size: 44px;
    line-height: 110%;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    color: #FFFFFF; }
  .text1, .shedule .g-text, .container-tl .aboutTournament .secondBlock .sbContent ul li span {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 150%; }
  .text2, .s-label, .s-error, .l-upload .l-file, .shedule .lm-title, .formB .submit .agreenemt, .container-tl .sponsors .add-sponsor-a .add-sponsor .email, .container-tl .cometee .card .role, .container-tl .bowlingCenters .row.headers, .container-tl .aboutTenpincam .secondBlock .text {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%; }
  .text3, .container-tl .timer-container .timer .time-text, .container-tl .cometee .card .email, .container-tl .cometee .card .organization {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 150%; }
  .ib, .l-btn, .l-upload, .formB .add-participant-btn, .radio-container, .container-tl .header-container .header .header-h .hh-btn {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 30px; }
  .sub, .container-tl .article > .article-content .subtitle {
    font-family: Inter;
    font-style: italic;
    font-weight: 900;
    font-size: 20px;
    line-height: 140%;
    color: #702917;
    text-transform: uppercase; }
  .s-f, .l-input, .l-react-select {
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 30px; }
  .t-f, .container-tl .timer-container .timer {
    font-family: Technor;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 80%;
    color: #702917;
    text-transform: uppercase; } }

/* Portrait tablets and medium desktops */
@media (max-width: 991px) {
  .h2, .container-tl .article > .article-content .title {
    font-family: Inter;
    font-style: italic;
    font-weight: 900;
    font-size: 40px;
    line-height: 110%;
    letter-spacing: -0.02em;
    text-transform: uppercase; }
  .h3, .form-title, .shedule .s-d-name, .container-tl .subsection, .container-tl .header-container .header .header-f .number, .container-tl .footer .social .social-row, .container-tl .aboutTournament .thirdBlock .block-title {
    font-family: Technor;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 120%;
    text-transform: uppercase; }
  .h4, .form-title .form-subtitle, .shedule .g-title, .formB .participants .participant .p-h, .container-tl .notAvailable, .container-tl .header-container .header .header-h .icons, .container-tl .header-container .header .header-f .word, .container-tl .table-of-content, .container-tl .aboutTournament .firstBlock .text-block .mainText, .container-tl .aboutTournament .secondBlock .sbContent .sbInfo, .container-tl .aboutTournament .thirdBlock .info, .container-tl .sponsors .add-sponsor-a .add-sponsor .text, .container-tl .cometee .card .fullname, .container-tl .info-block .info, .container-tl .bowlingCenters .row.data, .container-tl .aboutTenpincam .firstBlock, .container-tl .aboutTenpincam .secondBlock .btn-container .btn {
    font-family: Technor;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    text-transform: uppercase; }
  .text1, .shedule .g-text, .container-tl .aboutTournament .secondBlock .sbContent ul li span {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%; }
  .text2, .s-label, .s-error, .l-upload .l-file, .shedule .lm-title, .formB .submit .agreenemt, .container-tl .sponsors .add-sponsor-a .add-sponsor .email, .container-tl .cometee .card .role, .container-tl .bowlingCenters .row.headers, .container-tl .aboutTenpincam .secondBlock .text {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 150%; }
  .text3, .container-tl .timer-container .timer .time-text, .container-tl .cometee .card .email, .container-tl .cometee .card .organization {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 150%; }
  .ib, .l-btn, .l-upload, .formB .add-participant-btn, .radio-container, .container-tl .header-container .header .header-h .hh-btn {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px; }
  .f, .container-tl .footer .footer-content .section .section-header {
    font-family: Inter;
    font-style: italic;
    font-weight: 900;
    font-size: 32px;
    line-height: 110%;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    color: #FFFFFF; }
  .sub, .container-tl .article > .article-content .subtitle {
    font-family: Inter;
    font-style: italic;
    font-weight: 900;
    font-size: 16px;
    line-height: 140%;
    color: #702917;
    text-transform: uppercase; }
  .s-f, .l-input, .l-react-select {
    font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px; }
  .t-f, .container-tl .timer-container .timer {
    font-family: Technor;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 80%;
    color: #702917;
    text-transform: uppercase; } }

.s-label {
  margin-bottom: 5px; }

.s-error {
  padding-left: 10px;
  color: #FFFFFF;
  background-color: #8d151a; }

.l-input {
  border: none;
  margin: 0;
  height: 58px;
  padding-left: 15px;
  width: 100%;
  -webkit-box-sizing: border-box;
  /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;
  /* Firefox, other Gecko */
  box-sizing: border-box; }
  @media screen and (max-width: 991px) {
    .l-input {
      height: 52px; } }
  .l-input.l-error {
    border: 2px solid #8d151a; }

.l-btn {
  padding: 15px;
  background-color: #F65932;
  border: none;
  box-shadow: none;
  color: #FFFFFF; }
  .l-btn:hover {
    background-color: #FFFFFF;
    color: #F65932; }
  .l-btn:active {
    background-color: #FFFFFF;
    color: #F65932; }
  .l-btn.disabled {
    background-color: #702917; }
  .l-btn.disabled:hover {
    color: #FFFFFF;
    background-color: #702917; }

.form-title {
  margin-right: 10px;
  margin-bottom: 45px; }
  @media screen and (max-width: 991px) {
    .form-title {
      margin-bottom: 20px; } }
  .form-title .form-subtitle {
    white-space: pre;
    color: #FFFFFF; }

.formA-body {
  display: grid;
  row-gap: 20px;
  width: 640px; }
  .formA-body .row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px; }
  @media screen and (max-width: 991px) {
    .formA-body {
      row-gap: 20px;
      width: auto; }
      .formA-body .row {
        column-gap: 10px; } }
  @media screen and (max-width: 480px) {
    .formA-body {
      display: grid;
      row-gap: 10px;
      width: auto; }
      .formA-body .row {
        grid-template-columns: 1fr;
        row-gap: 10px; } }

.l-datepicker {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 20px; }
  @media screen and (min-width: 480px) and (max-width: 991px) {
    .l-datepicker {
      column-gap: 10px; } }
  @media screen and (max-width: 480px) {
    .l-datepicker {
      grid-template-columns: 1fr 1fr;
      column-gap: 10px;
      row-gap: 10px; }
      .l-datepicker .year {
        grid-column: 1/3; } }

.l-upload {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #FFFFFF; }
  .l-upload img {
    margin-right: 20px;
    width: 60px;
    height: 60px; }
    @media screen and (max-width: 991px) {
      .l-upload img {
        width: 52px;
        height: 52px; } }
  .l-upload .l-file {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 7px 12px;
    border-radius: 40px;
    background-color: #FFFFFF;
    color: black; }
    .l-upload .l-file img {
      margin-right: 0;
      margin-left: 13px;
      width: 13px;
      height: 13px; }
    @media screen and (max-width: 991px) {
      .l-upload .l-file {
        padding: 5px 10px; }
        .l-upload .l-file img {
          width: 11px;
          height: 11px; } }

@media screen and (max-width: 479px) {
  .l-hide-m {
    display: none; } }

@media screen and (min-width: 480px) {
  .l-hide-d {
    display: none; } }

.shedule .s-dot {
  position: absolute;
  background-color: #232A33;
  border-radius: 6px;
  width: 12px;
  height: 12px;
  top: 45px; }
  @media screen and (max-width: 991px) {
    .shedule .s-dot {
      top: 43px; } }
  .shedule .s-dot.left {
    left: -6px; }
  .shedule .s-dot.right {
    right: -6px; }

.shedule .s-day {
  display: flex;
  flex-direction: column;
  margin-bottom: 60px; }

.shedule .s-d-name {
  margin-bottom: 20px; }

.shedule .lms {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 40px;
  overflow-x: hidden; }
  @media screen and (max-width: 991px) {
    .shedule .lms {
      overflow-x: auto;
      column-gap: 30px; } }

.shedule .lm {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 240px;
  min-height: 310px;
  padding: 20px;
  background: #EEF5FF;
  border-radius: 20px; }
  @media screen and (max-width: 991px) {
    .shedule .lm {
      width: 190px;
      min-height: 250px; } }

.shedule .lm-title {
  padding-bottom: 10px;
  border-bottom: 2px #F65932 solid; }

.shedule .g-text-block {
  flex: 1;
  display: flex;
  flex-direction: column; }
  .shedule .g-text-block .g-text:first-child {
    margin-bottom: 10px; }

.shedule .lm-group {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px #a1a1a1 solid; }
  @media screen and (max-width: 991px) {
    .shedule .lm-group {
      padding-top: 10px;
      padding-bottom: 10px; } }
  .shedule .lm-group:last-child {
    border-bottom: none;
    padding-bottom: 0px; }

.shedule .g-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 10px; }
  .shedule .g-title .g-text {
    text-align: right; }

.shedule .g-text {
  color: #702917; }

.formB {
  margin-top: 60px; }
  .formB .submit {
    display: flex;
    flex-direction: column; }
    .formB .submit .agreenemt {
      color: #FFFFFF;
      margin-bottom: 10px; }
      @media screen and (max-width: 991px) {
        .formB .submit .agreenemt {
          margin-bottom: 5px; } }
      .formB .submit .agreenemt .link {
        white-space: pre;
        cursor: pointer;
        font-weight: bolder;
        text-decoration: underline; }
  .formB .participants {
    display: grid;
    row-gap: 40px;
    margin-top: 20px; }
    @media screen and (max-width: 991px) {
      .formB .participants {
        row-gap: 20px;
        margin-top: 10px; } }
    .formB .participants .participant {
      display: grid;
      grid-template-columns: 1fr;
      row-gap: 20px; }
      @media screen and (max-width: 991px) {
        .formB .participants .participant {
          row-gap: 10px; } }
      .formB .participants .participant .p-h {
        color: #FFFFFF;
        display: flex;
        flex-direction: row;
        justify-content: space-between; }
        .formB .participants .participant .p-h .delete {
          cursor: pointer;
          color: #FF2020;
          display: inline-block;
          border-bottom: 2px solid #FF2020; }
      .formB .participants .participant .p-d1 {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 20px; }
      .formB .participants .participant .p-d2 {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 20px; }
        @media screen and (min-width: 480px) and (max-width: 991px) {
          .formB .participants .participant .p-d2 {
            column-gap: 10px; } }
        @media screen and (max-width: 480px) {
          .formB .participants .participant .p-d2 {
            grid-template-columns: 1fr;
            row-gap: 10px; } }
  .formB .add-participant-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 0;
    cursor: pointer;
    background-image: url("data:image/svg+xml;utf8,<svg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><rect width='100%' height='100%' style='fill: none; stroke: rgb(246, 89, 50); stroke-width: 2; stroke-dasharray: 10 10'/></svg>");
    color: #FFFFFF; }
    @media screen and (max-width: 991px) {
      .formB .add-participant-btn {
        padding: 14px 0; } }

.radio-container {
  display: flex;
  flex-direction: column; }
  @media screen and (max-width: 480px) {
    .radio-container {
      display: block; } }
  .radio-container .l-radio-group {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between; }
    @media screen and (max-width: 480px) {
      .radio-container .l-radio-group {
        height: 52px; } }
    .radio-container .l-radio-group .l-radio {
      padding: 15px 0;
      cursor: pointer;
      color: #FFFFFF;
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #F65932; }
      @media screen and (max-width: 991px) {
        .radio-container .l-radio-group .l-radio {
          padding: 14px 0; } }
      .radio-container .l-radio-group .l-radio.selected {
        background-color: #F65932; }

.l-react-select .l-react-select__control {
  border-radius: 0;
  border: none !important;
  box-shadow: none;
  padding: 15px; }

.l-react-select .l-react-select__value-container {
  padding: 1px 0; }

.l-react-select .l-react-select__menu {
  margin: 0;
  border-radius: 0; }

.l-react-select .l-react-select__placeholder {
  color: #A6A6A6; }

.l-react-select .l-react-select__indicators > img {
  width: 18px;
  height: 8px; }

.l-react-select .l-react-select__menu-list {
  margin: 0;
  padding: 0; }

.l-react-select .l-react-select__option {
  padding: 0;
  margin: 0;
  color: black; }
  .l-react-select .l-react-select__option:first-child.l-react-select__option--is-selected > div {
    margin-top: 0px; }
  .l-react-select .l-react-select__option:last-child > div {
    border-bottom: none; }
  .l-react-select .l-react-select__option > div {
    margin: 0px 15px;
    padding: 15px 0px;
    border-bottom: 1px solid #A6A6A6;
    color: #000000;
    display: flex;
    align-items: center; }
  .l-react-select .l-react-select__option.l-react-select__option--is-selected {
    background-color: #F65932; }
    .l-react-select .l-react-select__option.l-react-select__option--is-selected > div {
      margin-top: 3px;
      padding: 15px 0px;
      border-bottom: none; }

.container-tl {
  scroll-behavior: smooth;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: 'center';
  align-content: 'center';
  overflow-x: 'hidden';
  padding: 0 !important;
  /* Portrait tablets and medium desktops */
  /* Portrait tablets and small desktops */
  /* Landscape phones and smaller */ }
  .container-tl .subsection {
    color: #F65932; }
  .container-tl .nowrap {
    white-space: pre; }
  .container-tl .notAvailable {
    border: 1px #FFFFFF solid;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    .container-tl .notAvailable img {
      margin-bottom: 52px; }
  .container-tl .timer-container {
    background-color: #EA5442;
    width: 100%; }
    .container-tl .timer-container .timer {
      margin: auto;
      width: 1200px;
      padding: 10px 0 6px 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center; }
      .container-tl .timer-container .timer .date {
        display: flex;
        flex-direction: row; }
        @media screen and (max-width: 480px) {
          .container-tl .timer-container .timer .date {
            margin-top: 10px; } }
      .container-tl .timer-container .timer .a {
        display: flex;
        flex-direction: column;
        align-items: center; }
      .container-tl .timer-container .timer .time-number {
        color: white; }
      .container-tl .timer-container .timer .time-semicolon {
        margin: 0 16px; }
        @media screen and (max-width: 991px) {
          .container-tl .timer-container .timer .time-semicolon {
            margin: 0 8px; } }
      .container-tl .timer-container .timer .time-text {
        text-transform: initial; }
      @media screen and (min-width: 992px) and (max-width: 1219px) {
        .container-tl .timer-container .timer {
          width: 920px; } }
      @media screen and (min-width: 480px) and (max-width: 991px) {
        .container-tl .timer-container .timer {
          padding: 8px 39px 4px 39px;
          width: auto; } }
      @media screen and (max-width: 480px) {
        .container-tl .timer-container .timer {
          flex-direction: column;
          align-items: center;
          padding: 8px 39px 4px 39px;
          width: auto; } }
  .container-tl .header-container {
    background-image: url(../../images/elyt/blue-bg.png);
    background-blend-mode: overlay;
    background-color: #232A33;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 95vh;
    min-height: 350px; }
    .container-tl .header-container .header {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin: 20px 0;
      padding: 20px 0;
      border-top: 4px solid #FFFFFF;
      border-bottom: 4px solid #FFFFFF; }
      .container-tl .header-container .header .header-h {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between; }
        .container-tl .header-container .header .header-h .icons {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          justify-content: space-between; }
        .container-tl .header-container .header .header-h .hh-btn {
          cursor: pointer;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border: 4px solid #F65932; }
      .container-tl .header-container .header .header-b {
        display: flex;
        justify-content: center;
        align-items: center; }
        .container-tl .header-container .header .header-b img {
          position: relative;
          left: -10%; }
      .container-tl .header-container .header .header-f {
        color: #F65932;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between; }
        .container-tl .header-container .header .header-f .number {
          margin-right: 8px; }
          .container-tl .header-container .header .header-f .number:last-child {
            margin-right: 0; }
        .container-tl .header-container .header .header-f .word {
          margin-right: 40px; }
          .container-tl .header-container .header .header-f .word:last-child {
            margin-right: 0; }
  .container-tl .footer {
    padding: 150px 0;
    background-image: url(../../images/elyt/orange-bg.png);
    background-blend-mode: overlay;
    background-color: #EA5442;
    display: flex;
    justify-content: center; }
    .container-tl .footer .footer-content {
      display: flex;
      justify-content: row; }
      .container-tl .footer .footer-content .section {
        flex: 1;
        border-top: #FFFFFF solid 2px; }
        .container-tl .footer .footer-content .section:last-child {
          margin-left: 40px; }
        .container-tl .footer .footer-content .section .section-header {
          margin-top: 20px; }
    .container-tl .footer .download-app {
      margin-top: 50px;
      display: flex;
      flex-direction: row; }
      .container-tl .footer .download-app .QR {
        width: 240px;
        height: 240px; }
      .container-tl .footer .download-app .sources {
        margin-left: 40px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        padding: 0px; }
    .container-tl .footer .social {
      margin-top: 50px; }
      .container-tl .footer .social .social-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-top: 1px #702917 solid;
        color: #702917;
        padding-top: 10px;
        margin-bottom: 40px; }
        .container-tl .footer .social .social-row .icon {
          margin-left: 28px;
          width: 35px;
          height: 35px; }
  .container-tl .table-of-content {
    position: sticky;
    top: 0;
    float: left !important;
    top: 0;
    left: 0;
    width: 240px;
    margin-right: -240px;
    padding-top: 150px !important;
    padding-bottom: 100px !important;
    z-index: 10; }
    @media screen and (max-width: 1219px) {
      .container-tl .table-of-content {
        display: none; } }
    .container-tl .table-of-content ul {
      margin: 0; }
    .container-tl .table-of-content a {
      color: rgba(255, 255, 255, 0.5);
      text-decoration: none; }
    .container-tl .table-of-content li {
      list-style-type: none;
      margin-bottom: 20px; }
    .container-tl .table-of-content li.active > a {
      color: #FFFFFF; }
    .container-tl .table-of-content li > a:hover {
      color: #FFFFFF; }
  .container-tl .article {
    display: flex;
    flex-direction: column;
    align-items: center; }
    .container-tl .article > .article-content {
      width: 100%; }
      .container-tl .article > .article-content .title {
        margin: 0;
        padding: 42px 0 60px 0;
        scroll-behavior: smooth;
        scroll-margin-top: 16px;
        /* Safari-only */ }
        @supports (-webkit-hyphens: none) {
          .container-tl .article > .article-content .title {
            padding-top: 16px;
            margin-top: -16px; } }
      .container-tl .article > .article-content .subtitle {
        display: inline;
        padding: 0 0 0 10px; }
    .container-tl .article.dark {
      background-image: url(../../images/elyt/blue-bg.png);
      background-blend-mode: overlay;
      background-color: #232A33;
      color: #F65932; }
      .container-tl .article.dark .title {
        border-top: 1px #F65932 solid; }
    .container-tl .article.orange {
      background-image: url(../../images/elyt/orange-bg.png);
      background-blend-mode: overlay;
      background-color: #EA5442;
      color: #FFFFFF; }
      .container-tl .article.orange .title {
        border-top: 1px #FFFFFF solid; }
  .container-tl .aboutTournament {
    color: #FFFFFF; }
    .container-tl .aboutTournament .firstBlock {
      display: flex;
      flex-direction: row; }
      .container-tl .aboutTournament .firstBlock .text-block {
        flex: 1; }
        .container-tl .aboutTournament .firstBlock .text-block .mainText:last-child {
          margin-bottom: 0px; }
      .container-tl .aboutTournament .firstBlock .image {
        flex: none;
        order: 1;
        flex-grow: 0;
        border-radius: 20px; }
    .container-tl .aboutTournament .secondBlock .sbContent {
      margin-top: 40px;
      display: flex;
      flex-direction: row; }
      .container-tl .aboutTournament .secondBlock .sbContent .sbInfo {
        color: #FFFFFF; }
      .container-tl .aboutTournament .secondBlock .sbContent ul {
        margin: 0; }
        .container-tl .aboutTournament .secondBlock .sbContent ul li {
          color: #F65932; }
          .container-tl .aboutTournament .secondBlock .sbContent ul li span {
            color: #FFFFFF; }
    .container-tl .aboutTournament .thirdBlock {
      display: grid; }
      .container-tl .aboutTournament .thirdBlock .block-title {
        color: #F65932;
        margin-bottom: 40px; }
      .container-tl .aboutTournament .thirdBlock .info {
        color: #FFFFFF;
        border-top: 4px solid #F65932;
        padding-top: 10px; }
  .container-tl .sponsors {
    display: grid; }
    .container-tl .sponsors .sponsor {
      border: 2px #FFFFFF solid; }
      .container-tl .sponsors .sponsor img {
        object-fit: contain;
        width: 100%;
        height: 100%; }
    .container-tl .sponsors .add-sponsor-a .add-sponsor {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border: 2px #F65932 solid;
      padding: 20px; }
      .container-tl .sponsors .add-sponsor-a .add-sponsor .email {
        color: #FFFFFF; }
  .container-tl .cometee {
    display: flex;
    flex-direction: row;
    overflow-x: auto; }
    .container-tl .cometee .card {
      display: flex;
      flex-direction: column;
      margin-right: 25px; }
      .container-tl .cometee .card:last-child {
        margin-right: 0; }
      .container-tl .cometee .card img {
        width: 210px;
        height: 270px;
        object-fit: cover;
        border-radius: 20px; }
      .container-tl .cometee .card .fullname {
        color: #FFFFFF; }
      .container-tl .cometee .card .role {
        margin-top: -5px;
        color: #F65932; }
      .container-tl .cometee .card .email {
        margin-top: 6px;
        color: #FFFFFF; }
      .container-tl .cometee .card .organization {
        margin-top: 6px;
        border-top: 2px #F65932 solid;
        padding-top: 3px;
        text-align: right;
        color: #FFFFFF; }
  .container-tl .info-block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 40px; }
    .container-tl .info-block.orange-ib .info.right {
      color: #F65932;
      border: 1px #F65932 solid; }
    .container-tl .info-block.white-ib .info.right {
      color: #FFFFFF;
      border: 1px #FFFFFF solid; }
    .container-tl .info-block .info {
      padding-top: 20px;
      width: 440px;
      height: 180px;
      color: #FFFFFF; }
      .container-tl .info-block .info.right {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 400px;
        height: 140px;
        padding: 20px; }
        .container-tl .info-block .info.right .arrow {
          width: 66px;
          height: 66px;
          align-self: flex-end; }
  .container-tl .bowlingCenters .row {
    display: flex;
    flex-direction: row; }
    .container-tl .bowlingCenters .row .tbc {
      display: flex;
      flex-direction: row; }
    .container-tl .bowlingCenters .row.headers {
      border-bottom: 2px #F65932 solid;
      padding-bottom: 10px; }
    .container-tl .bowlingCenters .row.data {
      border-bottom: 1px #FFFFFF solid;
      padding: 20px 0;
      color: #FFFFFF; }
  .container-tl .aboutTenpincam .firstBlock {
    display: flex;
    flex-direction: column;
    color: #FFFFFF; }
    .container-tl .aboutTenpincam .firstBlock .textBlock {
      width: 560px;
      margin-top: 20px; }
      .container-tl .aboutTenpincam .firstBlock .textBlock.rightAlign {
        align-self: flex-end; }
    .container-tl .aboutTenpincam .firstBlock .image {
      width: 100%;
      margin-top: 40px;
      border-radius: 20px; }
  .container-tl .aboutTenpincam .secondBlock {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between; }
    .container-tl .aboutTenpincam .secondBlock .text {
      flex: 1;
      color: #FFFFFF;
      width: 320px;
      max-width: 320px; }
    .container-tl .aboutTenpincam .secondBlock .btn-container {
      display: flex;
      flex-direction: row; }
      .container-tl .aboutTenpincam .secondBlock .btn-container .btn {
        cursor: pointer;
        flex: 1;
        border: 1px #F65932 solid;
        color: #F65932;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 220px;
        padding: 20px; }
        .container-tl .aboutTenpincam .secondBlock .btn-container .btn:last-child {
          margin-left: 20px; }
        .container-tl .aboutTenpincam .secondBlock .btn-container .btn svg {
          align-self: flex-end;
          width: 64px;
          height: 64px; }
  @media screen and (min-width: 1220px) {
    .container-tl .header-container .header {
      width: 1200px; }
      .container-tl .header-container .header .header-h .tpc {
        margin-left: 40px;
        width: 175px;
        height: 60px; }
      .container-tl .header-container .header .header-h .etbf {
        margin-left: 30px;
        width: 65px;
        height: 51px; }
      .container-tl .header-container .header .header-h .hh-btn {
        width: 270px;
        height: 60px; }
      .container-tl .header-container .header .header-b img {
        width: 100%; }
      .container-tl .header-container .header .header-f .number {
        margin-right: 4px; }
      .container-tl .header-container .header .header-f .word {
        margin-right: 20px; }
    .container-tl .footer .footer-content {
      width: 1200px; }
      .container-tl .footer .footer-content .source {
        width: 192px;
        height: 53px; }
    .container-tl .subsection {
      margin-bottom: 40px; }
    .container-tl .article {
      padding: 150px 0; }
      .container-tl .article.smallPadding {
        padding: 150px 0px 90px 0px; }
      .container-tl .article > .article-content {
        max-width: 920px;
        padding: 0 0 0 280px;
        width: 100%; }
        .container-tl .article > .article-content > .title {
          padding: 42px 0 60px 0; }
    .container-tl .aboutTournament .firstBlock {
      margin-bottom: 120px; }
      .container-tl .aboutTournament .firstBlock .text-block .mainText {
        margin-bottom: 40px; }
      .container-tl .aboutTournament .firstBlock .image {
        margin-left: 40px;
        width: 440px;
        height: 388px;
        object-fit: cover; }
    .container-tl .aboutTournament .secondBlock .sbContent {
      margin-top: 40px; }
      .container-tl .aboutTournament .secondBlock .sbContent .sbInfo {
        width: 280px; }
      .container-tl .aboutTournament .secondBlock .sbContent ul {
        margin: 0;
        width: 410px; }
        .container-tl .aboutTournament .secondBlock .sbContent ul li {
          margin-bottom: 20px; }
    .container-tl .aboutTournament .thirdBlock {
      margin-top: 120px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr; }
      .container-tl .aboutTournament .thirdBlock .block-title {
        margin-bottom: 40px; }
      .container-tl .aboutTournament .thirdBlock .info {
        margin-bottom: 40px;
        width: 280px; }
    .container-tl .sponsors {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr; }
      .container-tl .sponsors .sponsor {
        width: 240px;
        height: 140px;
        padding: 20px;
        margin-bottom: 20px; }
      .container-tl .sponsors .add-sponsor {
        width: 240px;
        height: 140px; }
    .container-tl .bowlingCenters .row .country {
      width: 250px; }
    .container-tl .bowlingCenters .row .tbc .town {
      width: 200px; }
    .container-tl .notAvailable {
      height: 400px; } }
  @media screen and (min-width: 992px) and (max-width: 1219px) {
    .container-tl .header-container .header {
      width: 100%;
      max-width: 920px; }
      .container-tl .header-container .header .header-h .tpc {
        margin-left: 40px;
        width: 175px;
        height: 60px; }
      .container-tl .header-container .header .header-h .etbf {
        margin-left: 30px;
        width: 65px;
        height: 51px; }
      .container-tl .header-container .header .header-h .hh-btn {
        width: 270px;
        height: 60px; }
      .container-tl .header-container .header .header-b img {
        width: 100%; }
    .container-tl .footer .footer-content {
      width: auto; }
      .container-tl .footer .footer-content .section:last-child {
        margin-left: 30px; }
      .container-tl .footer .footer-content .section .section-header {
        margin-top: 20px; }
    .container-tl .footer .download-app {
      margin-top: 40px; }
      .container-tl .footer .download-app .QR {
        width: 200px;
        height: 200px; }
      .container-tl .footer .download-app .sources {
        margin-left: 40px;
        padding: 0px; }
        .container-tl .footer .download-app .sources .source {
          width: 160px;
          height: 44px; }
    .container-tl .footer .social {
      margin-top: 40px; }
      .container-tl .footer .social .social-row {
        padding-top: 10px;
        margin-bottom: 40px; }
        .container-tl .footer .social .social-row .icon {
          margin-left: 28px;
          width: 35px;
          height: 35px; }
    .container-tl .subsection {
      margin-bottom: 40px; }
    .container-tl .article {
      padding: 150px 0; }
      .container-tl .article.smallPadding {
        padding: 150px 0px 90px 0px; }
      .container-tl .article > .article-content {
        max-width: 920px;
        padding: 0 0 0 0px;
        width: 100%; }
        .container-tl .article > .article-content > .title {
          padding: 40px 0 60px 0; }
    .container-tl .aboutTournament .firstBlock {
      margin-bottom: 120px; }
      .container-tl .aboutTournament .firstBlock .text-block .mainText {
        margin-bottom: 40px; }
      .container-tl .aboutTournament .firstBlock .image {
        margin-left: 40px;
        width: 440px;
        height: 388px;
        object-fit: cover; }
    .container-tl .aboutTournament .secondBlock .sbContent {
      margin-top: 40px; }
      .container-tl .aboutTournament .secondBlock .sbContent .sbInfo {
        width: 280px; }
      .container-tl .aboutTournament .secondBlock .sbContent ul {
        width: 410px;
        margin: 0; }
        .container-tl .aboutTournament .secondBlock .sbContent ul li {
          margin-bottom: 20px; }
    .container-tl .aboutTournament .thirdBlock {
      margin-top: 120px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr; }
      .container-tl .aboutTournament .thirdBlock .block-title {
        margin-bottom: 40px; }
      .container-tl .aboutTournament .thirdBlock .info {
        margin-bottom: 40px;
        width: 280px; }
    .container-tl .sponsors {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 20px; }
      .container-tl .sponsors .sponsor {
        width: 240px;
        height: 140px;
        padding: 20px; }
      .container-tl .sponsors .add-sponsor {
        width: 240px;
        height: 140px; }
    .container-tl .bowlingCenters .row .country {
      width: 250px; }
    .container-tl .bowlingCenters .row .tbc .town {
      width: 200px; }
    .container-tl .notAvailable {
      height: 400px; } }
  @media screen and (min-width: 480px) and (max-width: 800px) {
    .container-tl .header-container {
      padding: 0 39px 0 39px; }
      .container-tl .header-container .header .header-f {
        flex-direction: row;
        align-items: center; }
        .container-tl .header-container .header .header-f .number {
          font-size: 24px;
          margin-right: 4px; }
        .container-tl .header-container .header .header-f .word {
          font-size: 12px;
          margin-right: 20px; }
      .container-tl .header-container .header .header-h .hh-btn {
        display: none; } }
  @media screen and (min-width: 480px) and (max-width: 991px) {
    .container-tl .header-container {
      padding: 0 39px 0 39px; }
      .container-tl .header-container .header {
        width: 100%; }
        .container-tl .header-container .header .header-h .tpc {
          margin-left: 20px;
          width: 117px;
          height: 40px; }
        .container-tl .header-container .header .header-h .etbf {
          margin-left: 15px;
          width: 44px;
          height: 34px; }
        .container-tl .header-container .header .header-h .hh-btn {
          width: 200px;
          height: 40px; }
        .container-tl .header-container .header .header-b img {
          margin: auto;
          width: 400px; }
    .container-tl .footer {
      padding: 150px 40px; }
      .container-tl .footer .footer-content {
        width: 100%; }
        .container-tl .footer .footer-content .section:last-child {
          margin-left: 40px; }
        .container-tl .footer .footer-content .section .section-header {
          margin-top: 20px; }
      .container-tl .footer .download-app {
        margin-top: 40px; }
        .container-tl .footer .download-app .QR {
          display: none; }
        .container-tl .footer .download-app .sources {
          margin-left: 0 !important;
          width: 100%;
          margin-left: 40px;
          padding: 0px; }
          .container-tl .footer .download-app .sources .source {
            width: 100%;
            margin-bottom: 10px; }
      .container-tl .footer .social {
        margin-top: 40px; }
        .container-tl .footer .social .social-row {
          padding-top: 10px;
          margin-bottom: 30px;
          flex-wrap: wrap; }
          .container-tl .footer .social .social-row .icon {
            margin-left: 0;
            margin-right: 16px;
            width: 26px;
            height: 26px; }
    .container-tl .subsection {
      margin-bottom: 20px; }
    .container-tl .article {
      padding: 80px 39px 120px 39px; }
      .container-tl .article.smallPadding {
        padding: 80px 39px 100px 39px; }
      .container-tl .article > .article-content {
        padding: 0px 0 0px 0;
        width: 100%; }
        .container-tl .article > .article-content > .title {
          padding: 20px 0 40px 0; }
          .container-tl .article > .article-content > .title > .subtitle {
            display: block;
            padding: 0; }
    .container-tl .aboutTournament .firstBlock {
      margin-bottom: 80px;
      flex-direction: column; }
      .container-tl .aboutTournament .firstBlock .text-block {
        width: 440px;
        margin-bottom: 30px; }
        .container-tl .aboutTournament .firstBlock .text-block .mainText {
          margin-bottom: 20px; }
      .container-tl .aboutTournament .firstBlock .image {
        height: 400px;
        object-fit: cover; }
    .container-tl .aboutTournament .secondBlock .sbContent {
      margin-top: 20px; }
      .container-tl .aboutTournament .secondBlock .sbContent .sbInfo {
        margin-right: 10px; }
      .container-tl .aboutTournament .secondBlock .sbContent ul {
        margin: 0; }
        .container-tl .aboutTournament .secondBlock .sbContent ul li {
          margin-bottom: 10px; }
    .container-tl .aboutTournament .thirdBlock {
      margin-top: 80px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 30px; }
      .container-tl .aboutTournament .thirdBlock .block-title {
        margin-bottom: 30px; }
      .container-tl .aboutTournament .thirdBlock .info {
        margin-bottom: 30px; }
    .container-tl .sponsors {
      display: grid;
      flex-wrap: wrap;
      grid-template-columns: 1fr 1fr 1fr;
      grid-column-gap: 20px; }
      .container-tl .sponsors .sponsor {
        padding: 20px;
        height: 106px;
        margin-bottom: 20px; }
      .container-tl .sponsors .add-sponsor-a .add-sponsor {
        height: 106px;
        padding: 20px; }
    .container-tl .bowlingCenters .row {
      justify-content: space-between; }
      .container-tl .bowlingCenters .row .tbc {
        flex-direction: column;
        text-align: right; }
      .container-tl .bowlingCenters .row.headers .country {
        align-self: flex-end; }
      .container-tl .bowlingCenters .row.data {
        padding: 10px 0; }
        .container-tl .bowlingCenters .row.data .tbc {
          font-family: Inter;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          text-transform: none;
          line-height: 150%; }
    .container-tl .info-block {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 30px; }
      .container-tl .info-block .info {
        padding-top: 20px;
        width: 330px;
        color: #FFFFFF; }
        .container-tl .info-block .info.right {
          margin-left: 20px;
          width: 290px;
          padding: 20px; }
          .container-tl .info-block .info.right .arrow {
            align-self: flex-end;
            width: 40px;
            height: 40px; }
    .container-tl .aboutTenpincam .firstBlock .textBlock {
      width: auto;
      max-width: 450px;
      margin-top: 20px; }
      .container-tl .aboutTenpincam .firstBlock .textBlock.rightAlign {
        align-self: flex-end; }
    .container-tl .aboutTenpincam .firstBlock .image {
      margin-top: 20px;
      border-radius: 20px; }
    .container-tl .aboutTenpincam .secondBlock {
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      justify-content: space-between; }
      .container-tl .aboutTenpincam .secondBlock .text {
        margin-top: 20px;
        flex: 1;
        color: #FFFFFF;
        max-width: 250px;
        min-width: 250px;
        margin-right: 20px; }
      .container-tl .aboutTenpincam .secondBlock .btn-container {
        flex: 1;
        margin-top: 20px;
        min-height: 146px;
        max-height: 146px;
        min-width: 210px;
        max-width: 210px;
        justify-content: flex-end; }
        .container-tl .aboutTenpincam .secondBlock .btn-container .btn {
          flex: 1;
          max-width: 200px;
          width: auto;
          padding: 20px; }
          .container-tl .aboutTenpincam .secondBlock .btn-container .btn:last-child {
            margin-left: 20px; }
          .container-tl .aboutTenpincam .secondBlock .btn-container .btn svg {
            margin-left: 10px;
            min-width: 40px;
            width: 40px;
            height: 40px; }
    .container-tl .notAvailable {
      height: 400px; } }
  @media screen and (max-width: 480px) {
    .container-tl .header-container {
      padding: 0 10px 0 10px; }
      .container-tl .header-container .header {
        width: 100%; }
        .container-tl .header-container .header .header-h .tpc {
          margin-left: 20px;
          width: 117px;
          height: 40px; }
        .container-tl .header-container .header .header-h .etbf {
          margin-left: 15px;
          width: 44px;
          height: 34px; }
        .container-tl .header-container .header .header-h .hh-btn {
          display: none; }
        .container-tl .header-container .header .header-b img {
          margin: auto;
          width: 330px; }
        .container-tl .header-container .header .header-f {
          flex-direction: column;
          align-items: center; }
          .container-tl .header-container .header .header-f .number {
            font-size: 24px;
            margin-right: 4px; }
          .container-tl .header-container .header .header-f .word {
            font-size: 12px;
            margin-right: 20px; }
    .container-tl .footer {
      padding: 150px 10px; }
      .container-tl .footer .footer-content {
        width: 100%;
        flex-direction: column; }
        .container-tl .footer .footer-content .section {
          margin-bottom: 80px; }
          .container-tl .footer .footer-content .section:last-child {
            margin-bottom: 0px;
            margin-left: 0px; }
          .container-tl .footer .footer-content .section .section-header {
            margin-top: 20px; }
      .container-tl .footer .download-app {
        margin-top: 30px; }
        .container-tl .footer .download-app .QR {
          display: none; }
        .container-tl .footer .download-app .sources {
          margin-left: 0 !important;
          width: 100%;
          margin-left: 40px;
          padding: 0px; }
          .container-tl .footer .download-app .sources .source {
            width: 100%;
            margin-bottom: 10px; }
      .container-tl .footer .social {
        margin-top: 30px; }
        .container-tl .footer .social .social-row {
          padding-top: 10px;
          margin-bottom: 30px;
          flex-wrap: wrap; }
          .container-tl .footer .social .social-row .icon {
            margin-left: 0;
            margin-right: 16px;
            width: 26px;
            height: 26px; }
    .container-tl .subsection {
      margin-bottom: 20px; }
    .container-tl .article {
      padding: 80px 10px 120px 10px; }
      .container-tl .article.smallPadding {
        padding: 80px 10px 40px 10px; }
      .container-tl .article > .article-content {
        padding: 0px 0 0px 0;
        width: 100%; }
        .container-tl .article > .article-content > .title {
          padding: 20px 0 40px 0; }
          .container-tl .article > .article-content > .title > .subtitle {
            display: block;
            padding: 0; }
    .container-tl .aboutTournament .firstBlock {
      margin-bottom: 60px;
      flex-direction: column; }
      .container-tl .aboutTournament .firstBlock .text-block {
        margin-bottom: 30px; }
        .container-tl .aboutTournament .firstBlock .text-block .mainText {
          margin-bottom: 20px; }
      .container-tl .aboutTournament .firstBlock .image {
        height: 166px;
        object-fit: cover; }
    .container-tl .aboutTournament .secondBlock .sbContent {
      margin-top: 20px;
      flex-direction: column; }
      .container-tl .aboutTournament .secondBlock .sbContent .sbInfo {
        width: 280px;
        margin-bottom: 20px; }
      .container-tl .aboutTournament .secondBlock .sbContent ul {
        margin: 0;
        padding: 0;
        /* Убираем поля */
        margin-left: 20px;
        /* Отступ слева */ }
        .container-tl .aboutTournament .secondBlock .sbContent ul li {
          margin-bottom: 10px; }
    .container-tl .aboutTournament .thirdBlock {
      margin-top: 80px;
      display: grid; }
      .container-tl .aboutTournament .thirdBlock .block-title {
        margin-bottom: 20px; }
      .container-tl .aboutTournament .thirdBlock .info {
        margin-bottom: 20px; }
    .container-tl .sponsors {
      display: grid;
      grid-template-columns: 1fr;
      grid-column-gap: 20px; }
      .container-tl .sponsors .sponsor {
        padding: 20px;
        height: 120px;
        margin-bottom: 20px; }
      .container-tl .sponsors .add-sponsor {
        height: 120px; }
    .container-tl .bowlingCenters .row {
      justify-content: space-between; }
      .container-tl .bowlingCenters .row .tbc {
        flex-direction: column;
        text-align: right; }
      .container-tl .bowlingCenters .row.headers .country {
        align-self: flex-end; }
      .container-tl .bowlingCenters .row.data {
        padding: 10px 0; }
        .container-tl .bowlingCenters .row.data .tbc {
          font-family: Inter;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          text-transform: none;
          line-height: 150%; }
    .container-tl .info-block {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 30px; }
      .container-tl .info-block .info {
        flex: 1;
        width: 100%;
        height: auto;
        padding-top: 20px; }
        .container-tl .info-block .info.right {
          margin-top: 20px;
          width: auto;
          height: auto;
          padding: 10px; }
          .container-tl .info-block .info.right .arrow {
            align-self: flex-end;
            width: 40px;
            height: 40px; }
    .container-tl .aboutTenpincam .firstBlock {
      flex-direction: column; }
      .container-tl .aboutTenpincam .firstBlock .textBlock {
        width: auto;
        margin-top: 20px; }
        .container-tl .aboutTenpincam .firstBlock .textBlock.rightAlign {
          align-self: flex-end; }
      .container-tl .aboutTenpincam .firstBlock .image {
        margin-top: 20px;
        border-radius: 20px; }
    .container-tl .aboutTenpincam .secondBlock {
      margin-top: 40px;
      display: flex;
      flex-direction: column; }
      .container-tl .aboutTenpincam .secondBlock .text {
        color: #FFFFFF;
        min-width: 300px;
        width: auto; }
      .container-tl .aboutTenpincam .secondBlock .btn-container {
        display: block; }
        .container-tl .aboutTenpincam .secondBlock .btn-container .btn {
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin-top: 20px;
          width: auto;
          height: 64px;
          padding: 0 20px; }
          .container-tl .aboutTenpincam .secondBlock .btn-container .btn:last-child {
            margin-left: 0px; }
          .container-tl .aboutTenpincam .secondBlock .btn-container .btn svg {
            align-self: unset;
            width: 22px;
            height: 22px; }
    .container-tl .notAvailable {
      height: 400px; } }
