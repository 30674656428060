.frame-edit__reason {
  margin-bottom: 15px;
}

.frame-edit__reason.confirmation,
.frame-edit__initiator.confirmation {
  p {
    margin: 10px 0;

    span {
      color: #fff;
    }
  }

  label {
    margin-top: 10px;
    margin-bottom: 5px;
  }
}

.frame-edit__reason.confirmation {
  p {
    span {
      font-size: 16px;
    }
  }
}