.handicap {
  &__container {
    margin-bottom: 20px;
    // border-bottom: 1px solid #25292c;
    padding-bottom: 20px;

    &:last-child {
      margin-bottom: 0px;
    }
  }

  &__subtitle {
    margin-bottom: 10px;
    padding-bottom: 5px;
    margin-top: 20px;
    border-bottom: 1px solid #25292c;
  }

  &__property {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 10px;
    justify-content: space-between;

    &:last-child {
      margin-bottom: 0px;
    }

    &-name {
      margin-right: 30px;
      font-weight: 400;
    }

    &-value {
      width: 30%;
    }

    &.variable {
      margin-left: 20px;
    }
  }

  &-upload__btn {
    max-width: 300px;
    margin: auto;
  }
}

.editable {
  &-value {
    font-weight: bold;
    padding: 5px 10px;
    background-color: transparent;
    color: #fff;
    text-align: left;
    border: 1px solid #A7A7A7;
    box-sizing: border-box;
    width: 100%;
    min-height: 28px;
    &:hover {
      cursor: pointer;
      outline: 1px solid;
    }
  }

  &__input {
    font-weight: bold;
    padding: 5px 10px;
    background-color: transparent;
    color: #fff;
    text-align: left;
    border: 1px solid #A7A7A7;
    box-sizing: border-box;
    width: 100%;
    min-height: 28px;
  }
}

