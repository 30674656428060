%flex-column {
  display: flex;
  flex-direction: column;
}
%flex-row {
  display: flex;
  flex-direction: row;
}
.modal-game {
  background-image: linear-gradient(to top, #0d1928, #0d141d);
  // width: 395px !important;
  .ant-modal-header, .ant-modal-content {
    background: #0d1828;
  }

  .game-score {
    &__container {
      margin-top: 12px;
    }
    &__label {
      font-family: 'Oswald';
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      text-transform: uppercase;
      color: #fff;
      opacity: 0.4;
      font-style: normal;
      margin-bottom: 10px;
    }
  }
}

.prize_showcase {
  margin-top: 8px;
  background: rgba(246, 89, 50, 0.1);
  border: 1px solid #F65932;
  box-sizing: border-box;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  font-size: 20px;
  font-family: 'Oswald';
  color: #FFFFFF;
}

.modal-game {
  .modal-text {
    & > * {
      margin-top: 5px;
    }
    margin: 10px 0;
    span {
      height: 20px;
      font-size: 14px;
      opacity: 0.6;
      color: white;
      font-family: Open Sans, sans-serif;
      font-weight: normal;
    }
    & > span {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: #FFFFFF;
      opacity: 1;
    }
    .game-stats {
      display: flex;
      // justify-content: space-between;
      // justify-content: flex-start;
      justify-content: space-around;
      & > * {
        margin-right: 30px;
        align-items: center;
        display: flex;
        flex-direction: column;
        & > * {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }
      .stat-header {
        font-size: 12px;
        opacity: 0.6;
      }
      .stat-value {
        font-size: 14px;
        opacity: 1;
      }
    }
  }
}
.modal-players {
  .players-container {
    @extend %flex-column;
    .player-item {
      @extend %flex-row;
      justify-content: space-between;
      align-items: flex-end;
      margin: 10px 0;
      > * {
        @extend %flex-column;
      }
      .player-column-left {
        width: 40%;
        text-align: right;
      }
      .player-column-right {
        width: 60%;
        justify-content: flex-start;
        flex-direction: row;
        align-items: baseline;
      }
      .player-photo {
        @extend %flex-column;
        background: linear-gradient(to bottom, #20a39e, #01677e);
        &.self {
          background: linear-gradient(to bottom, #f65932, #f59c86);
        }
        width: 64px;
        height: 64px;
        border-radius: 32px;
        margin-left: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        img {
          width: 58px;
          height: 58px;
          border-radius: 29px;
          display: flex;
        }
        .avatar__flag {
          width: 24px;
          height: 24px;
          border-radius: 12px;
        }
      }
      .player-info > * {
        min-height: 20px;
      }
      .player-name {
        font-size: 16px;
        color: white;
        font-weight: normal;
        max-width: 150px;
        white-space: nowrap;
        margin-left: auto;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .player-login {
        font-size: 14px;
        color: #f65932;
        max-width: 150px;
        white-space: nowrap;
        margin-left: auto;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .player-rating {
        .rating-container {
          display: flex;
          justify-content: flex-end;
        }
        .rating-caption {
          color: #f65932;
          font-size: 16px;
        }
        .rating-stars {
          display: flex;
          align-items: center;
          svg {
            width: 12px;
            height: 12px;
          }
        }
        .rating-score {
          font-size: 14px;
          margin-left: 2px;
          opacity: 0.6;
          color: white;
        }
      }
      .player-score {
        font-size: 50px;
        font-weight: 500;
        font-family: Oswald;
        letter-spacing: 5px;
        line-height: 0.94;
        color: white;
        margin-left: 18%;
        &.self {
          color: #f65932;
        }
      }
    }
  }
}

.avgScore {
  color: #fff;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  height: 14px;
  width: 25px;
  background: #252B32;
  border-radius: 7px;
  position: absolute;
  bottom: 0;
  left: -10px;
}