.frame {
  width: calc(100% / 12);
  background-color: #343d49;
}

.frame.owner:hover {
  cursor: pointer;
}

.frame.last {
  width: calc(100% / 12 * 1.5);
}

.frame.current {
  background-color: #f65932;
}

.frame.changing {
  border: 2px solid #20a39e;
  width: calc(calc(100% / 12) - 4px);
  box-sizing: content-box;
}

.frame.changing .frame__scores{
  padding: 0 3px;
}

.frame.last.changing {
  width: calc(12.5% - 4px);
}

.frame.owner.changing {
  border: 2px solid #f65932;
}

.frame.changing .frame__number {
  padding-top: 1px; 
}

.frame.changing .frame__scoresSum {
  height: 28px;
}

.frame.current.owner {
  background-color: #f65932;
}

.frame .frame__number {
  color: white;
  padding: 3px;
  text-align: center;
}

.frame.current .frame__number {
  background-color: #327176;
}

.frame.current.owner .frame__number {
  background-color: #8c5349;
}

.edit .frame.current.owner .frame__number,
.edit .frame.current.owner .frame__scoresSum {
  background-color: #664c4d;
}

.frame .frame__number > div {
  font-size: 14px;
  height: 14px;
}

.frame .frame__scores {
  height: 40px;
  background-color: #d2d4d8;
  display: flex;
  padding: 0 5px;
  justify-content: space-around;
  border-right: 1px solid black;
  position: relative;
}

.frame.last .frame__scores {
  padding: 0 25px;
}

.frame.current .frame__scores {
  background-color: #23ada8;
}

.frame.current.owner .frame__scores {
  background-color: #f76339;
}

.edit .frame.current .frame__scores {
  background-color: #e4ada0;
}

.frame .frame__scores .frame__score {
  line-height: 40px;
  font-size: 16px;
  color: #25292c;
  text-align: center;
}

.frame .frame__scoresSum {
  color: white;
  height: 30px;
  background-color: #343d49;
}

.frame.current .frame__scoresSum {
  background-color: #327176;
}

.frame.current.owner .frame__scoresSum {
  background-color: #8c5349;
}

.frame .frame__scoresSum > div {
  line-height: 30px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}

.frame .frame__flags {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  /* position: absolute;
  display: flex;
  top: 0;
  left: 0;
  border-bottom-right-radius: 4px;
  background-color: #20a39e; */
}

.frame .frame__flag {
  width: 13px;
  height: 12px;
  background: #20a39e;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* width: 13px;
  height: 11px;
  font-size: 9px;
  font-weight: bold;
  line-height: 10px;
  text-align: center; */
}

.frame .frame__flags > .frame__flag:last-child {
  border-bottom-right-radius: 4px;
}

.frame .frame__flag > svg {
  width: 7px;
  height: 8px;
}
.frame .frame__flag > svg > path {
  fill: #FFFFFF;
}

.frame .frame__flag.refereed {
  background: #D80027;
}

.frame .frame__flag.changed {
  background: #20A39E;
}

.frame .frame__flag.foul {
  background: #F65932;
}

.frame.current.owner .frame__flag.foul {
  background: #FFFFFF !important;
}

.frame.current.owner .frame__flag.foul > svg > path {
  fill: #F65932 !important;
}

.frame.current:not(.owner) .frame__flag.changed {
  background: #FFFFFF !important;
}

.frame.current:not(.owner) .frame__flag.changed > svg > path {
  fill: #20A39E !important;
}

.frame.editing .frame__score{
  opacity: 0.4;
}