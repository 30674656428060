.badges {
  flex: 0 0 auto;
  display: flex;
  .groupname {
    flex: 0;
    opacity: 0.6;
    font-size: 14px;
    margin-bottom: 10px;
  }
  .group {
    flex: 1;
  }
  .logos {
    margin-right: 30px;
    .group {
      justify-content: flex-start;
    }
    .item {
      height: 80px;
    }
    .item:not(:last-child) {
      margin-right: 20px;
    }
  }
  .group {
    display: flex;
    justify-content: space-between;
  }
  .sia .group {
    flex-direction: column;
  }
  .logos, .sia {
    display: flex;
    flex-direction: column;
    .item {
      font-size: 12px;
      opacity: 0.6;
    }
  }
}