.email-verification-dialog {
  &__label {
    display: block;
    font-family: 'Open Sans';
    font-size: 14px;
    color: #6d7177;
    margin-bottom: 10px;
    &:first-child {
      margin-top: 23px;
    }
  }
  &__input {
    display: block;
    height: 28px;
    width: calc(100% - 20px - 2px);
    padding: 0 10px;
    background-color: transparent;
    border: 1px solid rgba(255,255,255,0.4);
    color: #fff;
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 21px;
  }
}