.modal-trial {
  margin: 0 -20px; /* costyl */
}

.modal-trial-header {
  background: linear-gradient(230.41deg, #F65932 0%, #CC3535 100%);
  text-align: center;
  padding: 14px 0;
}

.modal-trial-title {
  font-family: Oswald;
  font-weight: 500;
  font-size: 30px;
  line-height: 44px;
  color: #fff;
  letter-spacing: 0.1em;
}

.modal-trial-wallet {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 27px;
}

.modal-trial-50000 {
  font-family: Oswald;
  font-weight: bold;
  font-size: 90px;
  line-height: 133px;
  color: #fff;
}

.modal-trial-v {
  font-family: Oswald;
  font-size: 40px;
  line-height: 59px;
  color: #6D7177;
}

.modal-trial-10Gbondisk {
  margin-left: 38px;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.modal-trial-10Gb {
  font-family: Oswald;
  font-size: 50px;
  line-height: 74px;
  color: #fff;
}

.modal-trial-ondisk{
  font-family: Oswald;
  font-size: 30px;
  line-height: 44px;
  color: #6D7177;
}

.modal-trial-description {
  padding: 0 50px;
  text-align: center;
}

.modal-trial-description-text {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
  color: #fff;
}

.modal-trial-description-tenpincam {
  font-weight: 600;
}

.modal-trial-footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 25px;
  padding-bottom: 20px;
}

.modal-trial-footer-button {
  width: 270px;
  border-radius: 19px;
}

.modal-trial-footer-button-text {
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  color: #fff;
  text-transform: capitalize;
}
