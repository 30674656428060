.avatar {
  width: 80px;
  min-width: 80px;
  height: 80px;
  background-image: #0d141d;
  background-origin: border-box;
  background-clip: content-box, border-box;
  border-radius: 40px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  & > * {
    position: relative;
    z-index: 1;
  }

  .circle {
    position: absolute;
    top: -5px; bottom: -5px;
    left: -5px; right: -5px;
    z-index: 0;
    max-height: calc(100% + 10px);
    max-width: calc(100% + 10px);
  }

  .finish__check-opponent {
    width: 50%;
    height: 50%;
    svg {
      padding-top: 7%;
    }
  }

  &.tabled svg {
    top: initial; left: initial;
    right: -2px; bottom: -2px;
    z-index: 2;
    max-height: calc(20% + 5px); 
      max-width: calc(20% + 5px);
  }

  &.tabled {
    height: 30px;
    width: 30px;
    min-width: 30px;
    margin-right: 10px;
    img {
      height: 30px;
      width: 30px;
    }
  }
}

.gamesTable {
  width: 36px;
  min-width: 36px;
  height: 36px;



  .avatar__flag {
    right: -10px;
    width: 16px;
    height: 16px;
  }
}

.avatar img {
  width: 74px;
  border-radius: 37px;
}

.gamesTable img {
  width: 42px;
  height: 42px;
}

.avatar__flag {
  position: absolute;
  bottom: 0;
  right: -7px;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  overflow: hidden;
}

.avatar__flag span{
  display: block;
  width: 100% !important;
  height: 100%;
  background-size: contain;
}

.avgScore {
  color: #fff;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  height: 14px;
  width: 25px;
  background: #252B32;
  border-radius: 7px;
  position: absolute;
  bottom: 0;
  left: -10px;
}