.bet-group__elements{
  display: flex;
  justify-content: space-between;
  .picker__container {
    margin: 10px 0;
    width: 100px;
    max-width: 100%;
  }
  .filter-input{
    width: 100%;
    input{
      height: 30px;
      box-sizing: border-box;
      
    }
    .input_error{
      font-size: 13.2px;
    }
  }
}

.date-group__pickers{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  & > *:first-child{
    flex: 1;
  }
  & > *:nth-child(2){
    flex: 0 100px;
    margin-right: 3px;
  }
  & > .input__error{
    flex: 0 100%;
  }
}

.ant-radio.ant-radio-disabled .ant-radio-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 20px;
  height: 20px;
  border: 2px solid;
  border-radius: 50%;
  background-color: initial;
  transition: all .3s;
  cursor: not-allowed;
}

.ant-radio.ant-radio-disabled .ant-radio-inner {
  border-color: rgba(246,89,50,.6);
}

.consest-Group {
  font-size: 16px;
  padding: 10px 0;
}


.select-user {
  .avgScore {
    color: #fff;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    height: 14px;
    width: 25px;
    background: #252B32;
    border-radius: 7px;
    position: absolute;
    bottom: 0;
    left: -10px;
  }
  &__main-info {
    display: flex;
  }
  &__avatar {
    position: relative;
    width: 34px;
    min-width: 34px;
    height: 34px;
    & img {
      display: block;
      width: 100%;
      object-fit: cover;
    }
  }
  &__mask {
    border-radius: 50%;
    overflow: hidden;
  }
  &__flag {
    position: absolute;
    right: -7px;
    bottom: 0;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    overflow: hidden;
  }
  &__name {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-left: 18px;
    width: calc(100% - 50px);
  }

  &__fullname {
    font-size: 15px;
    line-height: 18px;
    color: white;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__login {
    margin-top: 4px;
    font-size: 13px;
    line-height: 1.2em;
    color: #f65932;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
.btn-modal-link {
  padding: 10px 7px;
  margin-top: 10px;
  color: white;
  margin-bottom: 15px;
  border: 1px solid #f65932;
  width: 100%;
  cursor: pointer;
  &:hover,
  &:focus {
    background-color: #f65932;
  }
}

.create-battle-modal {
  overflow: auto !important;
  .ant-modal-body {
    z-index: 2;
    flex: 0 0 auto !important;
    overflow: visible !important;
    background-image: linear-gradient(to top, #0c131c, #0d141d);
    min-height: initial !important;
  }

  .ant-modal {
    overflow: visible !important;
  }

  .ant-modal {
    overflow: visible !important;
  }

  .ant-modal-content {
    overflow: visible !important;
  }

  .ant-modal-header {
    flex: 0 0 auto !important;
    background-image: linear-gradient(to top, #0c131c, #0d141d);
  }

  .ant-modal-footer {
    flex: 0 0 auto !important;
    background-image: linear-gradient(to top, #0c131c, #0d141d);
  }
}

.format-battle {
  &-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  
    &__label {
      margin-bottom: 10px;
      margin-top: 15px;
    }

    &__item {
      display: flex;
      flex-direction: row;
    }
  }

  &__tooltip {
    .ant-tooltip-inner, .ant-tooltip-arrow::before {
      background-color: #6D7177;
    }
    .ant-tooltip-inner {
      font-family: 'Open Sans';
      font-size: 13px;
      line-height: 18px;
      color: #fff;
    }
    &__btn {
      cursor: pointer;
      color: #fff;
      border-radius: 2px;
      padding: 4px 4.5px;
      font-size: 11px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #607083;
      font-weight: bold;
      display: inline-flex;
      white-space: normal;
      margin-right: 10px;
      margin-left: 0;
      height: 15px;

      &:hover {
        background-color: #626567;
      }
    }
  }
}