.mail_notifications {
  padding: 20px 40px;

  .mail_notifications__title {
    font-family: Oswald;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 44px;
    color: #FFFFFF;
  }

  .mail_notifications__description {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #FFFFFF;
    margin: 20px 0;
  }

  .mail_notifications__checkbox_container {

    .checkbox {
      line-height: 20px;
      margin-bottom: 20px;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      color: #FFFFFF;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .checkbox__checkmark {
      height: 20px;
      width: 20px;
      flex: 0 0 20px;
      margin-right: 7px;

      &.checkbox__checked > img {
        display: block;
        width: 20px;
        height: 20px;
      }
    }

  }

}