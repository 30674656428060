.drawer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  &__header {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    .close-icon {
      display: flex;
      flex-direction: column;
      position: relative;
    
      &>div {
        background-color: #fff;
        width: 30px;
        height: 2px;
    
        &:first-child {
          transform: rotate(-45deg);
        }
    
        &:last-child {
          transform: rotate(45deg) translate(-1.5px, -1.5px);
        }
      }
    }

    .lang-option__container {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  
    .lang-option__img {
      width: 28px;
      height: 20px;
      min-width: 28px;
      min-height: 20px;
      margin-right: 8px;
    }
  
    .lang-option__text {
      font-family: Oswald;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      /* identical to box height, or 125% */
  
      letter-spacing: 0.05em;
    }
  
    .header-lang__container {
      margin-left: 20px;
    }
    
    padding-bottom: 27px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }

  &__links {
    margin-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    margin-bottom: 20px;
  }

  .link {
    margin-bottom: 15px;
    font-family: Oswald;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.05em;
    color: #FFFFFF;
    text-transform: uppercase;

    &:last-child {
      margin-bottom: 0px;
    }

    &.active {
      color: #F65932;
    }
  }

  &-bottom {
    margin-top: 50px;

    .drawer__btn {
      &-download {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: Oswald;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        background: linear-gradient(264.59deg, #F65932 0%, #CC3535 100%), #C4C4C4;
        padding: 6px;
        border-radius: 5px;
        box-sizing: border-box;
      }

      &-signin {
        border: 1px solid #F65932;
        box-sizing: border-box;
        border-radius: 5px;
        font-family: Oswald;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        letter-spacing: 0.05em;
        color: #FFFFFF;
        padding: 6px;
        margin-top: 10px;
        text-transform: uppercase;
      }
    }
  }

  &-menu {
    background-color: #fff;
    width: 170px;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    div {
      line-height: 1;
      margin-bottom: 5px;
    }
    div:last-child {
      margin-bottom: 0;
    }
    .app-link {
      display: flex;
      flex-direction: row;
      align-items: center;
      border-bottom: 1px #cccccc solid;

      &__btn {
        object-fit: contain;
        height: 40px;
      }
      &__img {
        width: 30px;
        height: 24px;
        object-fit: contain;
        margin-right: 13px;
      }
    
      &__text {
        text-transform: uppercase;
        font-family: Oswald;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        /* identical to box height, or 171% */
    
        letter-spacing: 0.05em;
        text-transform: uppercase;
    
        color: #000000;
      }
    }

    .ant-dropdown-menu-item:last-child {
      .app-link__text {
        text-transform: initial;
      }
    }
  }
}

