.tariffPicker {
  position: fixed;
  top: 0; bottom: 0;
  left: 0; right: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  background: rgba(77, 77, 77, 0.8);
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 99999999;
  padding: 10px;
  .pickerBody {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .checkbox {
      margin-top: 15px;
    }
    .pickerBackground {
      background: #0C131C;
      padding: 20px;
      position: relative;
      .close {
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;
      }
    }
    .tariffPickerHeader {
      font-family: 'Oswald';
      font-weight: 500;
      font-size: 40px;
      margin-bottom: 30px;
      letter-spacing: 0.1em;
      text-align: center;
    }
    .tariffCarousel {
      display: flex;
      align-items: center;
      justify-content: center;
      .tariffRepresent {
        flex: 0 0 330px;
        width: 330px;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
        display: flex;
        flex-direction: column;
        .tariffCardHeader {
          background: linear-gradient(249.32deg, #F65932 0%, #CC3535 100%);
          padding: 15px 0;
          letter-spacing: 0.1em;
          font-weight: 500;
          font-size: 30px;
          font-family: 'Oswald';
          margin-bottom: 15px;
          flex: 0;
          text-align: center;
        }
        .tariffIsBest, .filler {
          flex: 1;
          margin-bottom: 10px;
          font-size: 18px;
          letter-spacing: 0.05em;
          text-transform: uppercase;
          color: #F65932;
          font-family: 'Oswald';
          text-align: center;
        }
        .fullinfo {
          flex: 0;
          margin-bottom: 30px;
          display: flex;
          justify-content: space-between;
          font-family: 'Oswald';
          align-items: flex-end;
          padding: 0 20px;

          .amount {
            position: relative;
            top: 3px;
          }
          .price, .services {
            display: flex;
          }
          .price {
            flex-direction: row;
            .amount {letter-spacing: 0.1em;font-size: 60px;font-weight: bold;display: flex;align-items: flex-start}
            small {font-size: 25px;margin-top:5px;font-weight: normal}
          }
          .services {
            flex-direction: column;
            text-align: right;
            .amount {font-size: 18px;}
          }
          .expl {
            color: #fff;
            opacity: 0.7;
            font-size: 24px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
          }
        }
        button {
          padding: 0;
          height: 40px;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          margin: 0 20px 20px;
          font-size: 14px;
          width: auto;
        }
      }
    }
    .postfix {
      font-size: 16px;
      line-height: 24px;
      max-width: 420px;
      margin-top: 30px;
      text-align: center;
    }
  }
}