.protest__button {
  width: 150px;
  height: 30px;
  border: 1px solid #f65932;
  display: flex;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  font-size: 14px;
  margin-left: 10px;
  font-weight: 300;

  * {
    box-sizing: border-box;
  }

  .protest_button-caption {
    color: #f65932;
    flex: 1 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .protest__dropdown-toggle {
    flex: 0 0 35px;
    background: transparent;
    border-left: 1px solid #f65932;
    display: flex;
    justify-content: center;
    align-items: center;

    .protest__amount {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 10px;
      background: #f65932;
      width: 18px;
      height: 18px;
      border-radius: 9px;
      position: absolute;
      top: -9px;
      right: -9px;
    }

    svg {
      fill: #f65932;
      transform: none;
      transition: transform 0.1s;
    }

    & .protest__dropdown {
      position: absolute;
      bottom: 30px;
      left: 0;
      width: 240px;
      display: flex;
      max-height: 0;
      flex-direction: column;
      transition: max-height 1s ease-in-out;
      overflow: hidden;
      background: #0d141d;
      z-index: 9999;

      .protest__container__header {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #343d49;
        font-size: 12px;
        flex: 0 0 24px;
      }

      .protest__container {
        overflow-y: auto;
        overflow-x: hidden;
        flex: 1 1 auto;
        border: 1px solid #3d434a;

        &::-webkit-scrollbar-thumb {
          border-left: 3px solid transparent;
        }

        &::-webkit-scrollbar {
          border-left: 1px solid #343d49;
          width: 11px;
        }

        .protest__element {
          display: flex;
          flex-wrap: nowrap;
          padding: 10px;
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 16px;
          color: #fff;

          &:not(:last-child) {
            border-bottom: 1px solid #343d49;
          }

          &>div {
            display: flex;
            flex-direction: column;
            &:first-child {
              width: 100%;
              margin-right: 10px;
            }
          }

          .protest__owner {

            &.antag {
              color: #f65932;
            }

            &.protag {
              color: #20a39e;
            }
          }

          .protest__time, 
          .protest__number {
            opacity: 0.4;
            white-space: nowrap;
            text-align: right;
          }

        }
      }
    }

    &:focus {
      background: #f65932;

      .protest__amount {
        display: none;
      }

      svg {
        fill: #fff;
        transform: scaleY(-1);
      }

      & .protest__dropdown {
        max-height: 35vh;
      }
    }
  }
}