.match-scoreboard {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;

	.competitor {
		flex: 0 0 50%;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		position: relative;
		cursor: pointer;
		&:first-child:last-child {
			justify-content: center;
		}

		&.owner {
			.avatar {
				background-image: linear-gradient(to bottom, #f65932, #f59c86);
			}
		}
		.score {
			margin: 0 10px;
			min-width: 120px;
			display: flex;
			justify-content: flex-end;
			font-family: Oswald;
			font-weight: 500;
			font-size: 50px;
			letter-spacing: 0.1em;
			&.leader {
				color: #f65932;
			}
		}
		.name {
			display: flex;
    		flex-direction: column;
		}
		.avatar {
			background-image: linear-gradient(to bottom, #20a39e, #01677e);
			margin: 0 15px;
		}
		.delayCard {
			margin: 0 5px;
			align-self: flex-start;
		}
		.ratings {
			align-items: center;
			display: flex;
			height: 20px;
			.rating-value {
				margin: 0 4px;
				font-size: 14px;
				opacity: .6;
				align-items: center;
				display: flex;
			}
		}

		&:nth-child(odd) {
			.name {
				align-items: flex-end;
				.ratings {
					flex-direction: row-reverse;
					.rating-stars {
						transform: scaleX(-1);
					}
				}
			}
		}

		&:nth-child(even) {
			flex-direction: row-reverse;

			&::before {
				content: ':';
				position: absolute;
				left: -6px;
				font-weight: 500;
				font-family: Oswald;
				font-size: 50px;
			}

			.score {
				justify-content: flex-start;
				padding-left: 0.1em;
			}

			.avatar {
				&__flag {
					left: -7px;
					right: initial;
				}

				.avgScore {
					right: -10px;
					left: initial;
				}
			}
		}
	}
}