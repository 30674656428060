.checkbox {
  /* display: block; */
  display: flex;
  position: relative;
  /* padding-left: 39px; */
  line-height: 24px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 300;
  color: #fff;

  align-items: center;
}

.checkbox .checkbox__input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkbox .checkbox__checkmark {
  /* position: absolute;
  top: 0;
  left: 0; */
  height: 24px;
  width: 24px;
  border: 2px solid rgba(246, 89, 50, 0.6);
  border-radius: 5px;
  background-color: transparent;
  flex: 0 0 24px;

  margin-right: 15px;
}

.checkbox .checkbox__checkmark__disabled {
  /* position: absolute;
  top: 0;
  left: 0; */
  height: 24px;
  width: 24px;
  border: 2px solid #6E7277;
  border-radius: 5px;
  background-color: transparent;
  flex: 0 0 24px;

  margin-right: 15px;
}

.checkbox .checkbox__checkmark__small {
  /* position: absolute;
  top: 0;
  left: 0; */
  height: 20px;
  width: 20px;
  border: 2px solid #6e7277;
  border-radius: 5px;
  background-color: transparent;
  flex: 0 0 20px;

  margin-right: 10px;
}

.checkbox .checkbox__checkmark__small__color {
  /* position: absolute;
  top: 0;
  left: 0; */
  height: 20px;
  width: 20px;
  border: 2px solid rgba(246, 89, 50, 0.6);
  border-radius: 5px;
  background-color: transparent;
  flex: 0 0 20px;

  margin-right: 10px;
}
.checkbox__checkmark > img {
  display: block;
  width: 100%;
  height: 100%;
}

.checkbox .checkbox__checkmark:hover {
  border-color: #f65932;
}

.checkbox .checkbox__checkmark__small__color:hover {
  border-color: #f65932;
}

.checkbox__checked {
  border-color: #f65932 !important;
  background-color: #f65932 !important;
}

.checkbox__checked__small__color {
  border-color: #f65932 !important;
  background-color: #f65932 !important;
}

.checkbox__checked__small {
  border-color: #6e7277 !important;
  background-color: #6e7277 !important;
}

.image__small {
  width: 20px;
  height: 20px;
}