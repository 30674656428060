.modal-user {
  &__main-info {
    display: flex;
    padding: 20px 0;
  }

  &__name {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-left: 27px;
    width: calc(100% - 107px);
  }

  &__fullname {
    line-height: 28px;
    font-size: 20px;
    color: white;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__login {
    // height: 22px;
    margin: 6px 0 10px 0;
    font-size: 16px;
    color: #f65932;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__rating {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: row-reverse;
    svg {
      margin-right: 3px;
    }
  }

  &__numeric-info {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #25292c;
  }

  &__numeric {
    max-width: 50%;
    flex: 0 0 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;
    &-value {
      font-family: 'Oswald';
      font-weight: bold;
      font-size: 20px;
      color: #fff;

      &.handicap {
        display: flex;
        align-items: center;

        .calculator-icon {
          margin-left: 5px;

          &:hover {
            cursor: pointer;
            opacity: 0.8;
          }
        }
      }
    }
    &-caption {
      line-height: 20px;
      opacity: 0.6;
      font-size: 14px;
      color: white;
    }
  }

  &__additional-info {
    & > div {
      display: flex;
      justify-content: space-between;
      padding: 10px 0;
      border-bottom: 1px solid #25292c;
    }

    & > div:nth-last-child(1) {
      margin-bottom: 30px;
    }
  }

  &__header {
    line-height: 20px;
    opacity: 0.6;
    font-size: 14px;
    color: white;
  }

  &__text {
    width: 155px;
    line-height: 20px;
    font-size: 14px;
    color: white;
  }

  &__chat-add {
    display: flex;
    width: 120px;

    & > button {
      width: 50%;
    }

    & > button:nth-last-child(1) {
      margin: 0;
      border-left: none;
    }
  }

  &__button-challenge {
    width: 220px;
  }

  &__rating {
    cursor: pointer;
  }

  &__ratingcaption {
    color: #f65932;
    font-size: 16px;
    margin-left: 10px;
    line-height: 20px;
  }
  &__ratingstar {
    display: flex;
  }
}

.modal-user_wrapper {
  display: flex;
  margin: 0 -15px;
  position: relative;
  box-sizing: border-box;
  .modal-user_info {
    height: auto;
    width: 100%;
    z-index: 10;
    transition: width 0.5s;
    position: relative;
    padding: 0 15px;
    background-image: linear-gradient(0deg,#0c131c,#0d141d);
    box-sizing: border-box;
    &.open {
      width: 50%;
      border-right: 1px solid #25292c;
    }
  }
  .modal-user_rating {
    top: 0; bottom: 0;
    right: 0;
    width: 50%;
    padding: 0 15px;
    overflow-y: auto;
    position: absolute;
    z-index: 9;
    box-sizing: border-box;
  }
  .modal-user__buttons {
    display: flex;
    justify-content: space-between;
  }

  .modal-user_singlerate {
    padding: 15px 0;
    border-bottom: 1px solid #25292c;
    * {
      box-sizing: border-box;
    }
    .preambula {
      display: flex;
      justify-content: space-between;
      .user {
        display: flex;
        align-items: center;
        .user__photo {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          object-fit: cover;
          flex: 0 1 30px;
          margin-right: 15px;
        }
        .user__flag_container {
          position: relative;
          height: 0;
          width: 0;
          .user__flag {
            width: 16px;
            height: 16px;
            position: absolute;
            bottom: -17px;
            right: 7px;
            border-radius: 8px;
            overflow: hidden;
          }
        }
        .user__name {
          flex: 1 1;
          flex-direction: column;
          justify-content: center;
          .fullname {
            display: block;
            font-size: 14px;
            font-family: 'Open Sans';
            color: #fff;
            margin-bottom: 4px;
          }
          .nickname {
            display: block;
            font-size: 12px;
            font-family: 'Open Sans';
            color: #f65932;
          }
        }
      }
      .daterate {
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        & > * {
          flex: 0 0 100%;
          text-align: end;
        }
      }
    }
    .ratelabel {
      margin-top: 10px;
      display: block;
    }
    .content {
      display: block;
      color: #fff;
    }
  }
}

.modal-user__button-challenge {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 14px;
}

.modal-user__buttons {
  margin-bottom: 15px;
}

.ant-modal {
  transition: width 0.5s;
}
