.svg-button {
  /* width: 24px;
  height: 24px; */
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
}

.svg-button.active svg,
.svg-button:hover svg path {
  fill: #f65932;
}

.svg-button {
  margin-left: 20px;
}

.svg-button:first-child {
  margin-left: 0;
}
