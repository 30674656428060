.collapsible {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;

  &__button {
    width: 75px;

    &:hover {
      & > svg path {
        fill: #FFF;
      }
    } 

    & > svg {
      transition: transform .3s;
    }

    &.up  {
      & > svg {
        transform: rotateX(180deg);
      }
    }
  }

  &__content {
    overflow: hidden;
  }
}

.collapsible-enter {
    margin-top: -100%;
}

.collapsible-enter.collapsible-enter-active {
    margin-top: 0;
    transition: margin-top 300ms 0s ease-out;
}

.collapsible-leave {
    margin-top: 0;
}

.collapsible-leave.collapsible-leave-active {
    margin-top: -100%;
    transition: margin-top 300ms 0s ease-in;
}