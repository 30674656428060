.videoPreloader {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 1;
}
.container-cover {
  position: relative;
  z-index: 2;
}
.videosTabs {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}
.videosTabs-list {
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  list-style: none;
  padding: 0;
  margin: 0;
  background: #343d49;
  position: relative;
  padding-top: 1px;
  padding-left: 20px;
}

.videosTabs .videosTabs-separator1 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 1px;
  background-image: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.2) 30%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0.2)70%, transparent);
}

.videosTabs .videosTabs-tab {
  padding: 5px 6px;
  width: auto;
  font-size: 14px;
  line-height: 1.2em;
  text-transform: none;
  padding: 7px 6px;
  position: relative;
  color: rgba(255, 255, 255, 0.4);
  font-weight: 700;
  max-width: 280px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
}

.videosTabs .videosTabs-tab:first-child {
  color: white;
  font-weight: 400;
  cursor: initial;
}

.videosTabs .videosTabs-tab:nth-child(2) {
  padding-left: 0;
}

.videosTabs .videosTabs-tab.videosTabs-tab.videosTabs-active {
  font-weight: 900;
  color: #f65932;
	border: none;
	/* min-width: calc(100% - 10px); */
}

.videosTabs .react-tabs__tab-panel {
  display: none;
}

.videosTabs .react-tabs__tab-panel--selected {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.videosContainer {
	display: flex;
	/* margin-right: 20px;
	margin-left: 20px;
	margin-bottom: 20px; */
	margin: 20px;
  justify-content: space-between;
  position: relative;
  flex: 1;
}

.videosContainer .videosContainer-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: calc((100% / 3) - 16px);
  width: 100%;
}
.videosContainer .videosContainer-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(100% / 3 * 2);
}

.videosContainer.mirrored .videosContainer-left {
  order: 1;
}

.videosContainer.mirrored .videosContainer-right {
  order: 0;
}

.videosContainer .videosContainer-right .videosContainer-screenShot {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

.videosContainer-video {
  order: 0;
  margin: 8px 0;
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 2;
}
.videosContainer-left .videosContainer-video:first-child{
  margin-top: 0;
}
.videosContainer-left .videosContainer-video:last-child{
  margin-bottom: 0;
}
.videosContainer-right .videosContainer-video{
  margin: 0;
}

.videosContainer-video video {
  object-fit: contain;
  display: block;
}

.videosTab-control {
  width: 95px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  vertical-align: middle;
  background-color: #20a39e;
  color: #fff;
  border: 0;
  border-left: 1px solid transparent;
  cursor: pointer;
  transition: all .2s 0s;
  margin: 0 5px 5px 0;
}

.videosTabs-reduction {
  max-width: 220px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

/*

.videosContainer-video.active {
  position: absolute;
  left: calc(100% / 3);
  right: 0;
  top: 0;
  bottom: 0;
  max-height: 100%;
}

.videosContainer-video:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  cursor: pointer;
}

.videosContainer-video.active:after {
  display: none;
}

.videosContainer-video * {
  width: 100%;
  height: 100%;
} */

.videosTabs .videosAllContainer {
	display: flex;
	flex-direction: row;
}

