.protocol_modal {

  .modal-group {
    padding: 0
  }

  .ant-modal-body {
    padding-bottom: 0 !important;
  }

  .ant-modal-footer {
    padding: 0 0 20px 0;
  }

  .protocol_modal_footer {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    .protocol_modal_footer_group {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      padding: 0 20px;

      &:first-child {
        border-top: 1px solid #25292C;
        border-bottom: 1px solid #25292C;
        padding: 10px 20px;
      }

      &:last-child {
        margin-top: 10px;
      }

      .party-comment {
        flex: 1;
        min-width: 355px;

        label {
          display: block;
          margin-bottom: 5px;
          text-align: left;
        }

        textarea {
          width: 100%;
          height: 55px;
        }
      }

      .match-score {
        display: flex;
        flex-direction: column;
        justify-content: center;    
        flex: 1;

        .game-result {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          font-family: Oswald;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          display: flex;
          align-items: center;
          letter-spacing: 0.05em;
          color: #FFFFFF;
          margin-bottom: 10px;

          .result {
            text-transform: uppercase;
            line-height: 26px;
          }

          .prize {
            background: rgba(246, 89, 50, 0.1);
            border: 1px solid #F65932;
            box-sizing: border-box;
            border-radius: 5px;
            margin-left: 10px;
            height: 26px;
            box-sizing: border-box;
            line-height: 24px;
            padding: 0 5px;
          }

        }

        .match-scoreboard {
          flex: 1;
          margin-right: 40px;
  
          .login, 
          .full, 
          .rating-value {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 19px;
            white-space: nowrap;
          }
  
          .rating-stars {    
            height: 20px;
            display: flex;
            flex-direction: row;
            align-items: center;
            white-space: nowrap;
          }
  
          .rating-value {
            line-height: 20px;
            color: #FFFFFF;
          }
  
          .avatar {
            width: 40px;
            height: 40px;
            min-width: 40px;    
            margin-right: 7px;
  
            &>img {
              width: 100%;
              border-radius: 50%;
            }
  
            .avatar__flag {
              width: 18px;
              height: 18px;
              border-radius: 50%;
            }
          }
  
          .score {
            justify-content: center;
            margin: 0 25px;
            flex: 0 0 auto;
            max-width: none;
  
            &> span {
              color: #FFFFFF;
              font-size: 40px;
              letter-spacing: 0.1em;
  
              &:last-child {
                color: #f65932;
              }
            }
          }
  
        }
        
      }



      .protocol_modal_footer_checkbox {
        flex: 1;
        margin-right: 40px;
  
        .checkbox {
          text-align: left;
  
          .checkbox__checkmark {
            min-width: 24px;
            box-sizing: border-box;
          }
        }
      }
    }
  }

  .protocol_modal_table {

    .protocol_modal_table_row {
      background-color: #252B32;
      
      &:nth-child(even) {
        background-color: #0C131C;
      }
      
      &:nth-child(odd) {
        background-color: #252B32;
      }
      
      &:nth-child(4n+2) {
        background-color: #252B32;
      }
      
      &:nth-child(4n+3) {
        background-color: #0C131C;
      }
    }

    .ant-table-thead tr th,
    .protocol_modal_table_row td {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 200;
      font-size: 14px;
      line-height: 19px;
      color: #FFFFFF;
    }

    .ant-table-thead tr th {
      padding: 10px;
    }

    .protocol_modal_table_row {

      &>td {
        padding: 10px;
        vertical-align: top;

        &.empty {
          padding: 0;
        }

        &.no-right-padding {
          padding-right: 0;
        }
        
        .gamenumber {
          margin-bottom: 5px;
        }

        .player {
          display: flex;
          flex-direction: row;
          white-space: nowrap;

          .avatar {
            width: 40px;
            height: 40px;
            min-width: 40px;
            margin-right: 20px;

            &>img {
              width: 100%;
              border-radius: 50%;
            }

            .avatar__flag {
              width: 18px;
              height: 18px;
              border-radius: 50%;
            }

          }
        }

        .protest_comment {
          min-width: 200px;

          .reason, .referee {
            font-weight: bold;
            margin-bottom: 5px;
          }

        }

        .protest_status {
          white-space: nowrap;

          &>div:first-child {
            margin-bottom: 5px;
          }
        }

        .scoring {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          max-height: 60px;
          // max-width: 375px;
          align-items: center;

          .frame {
            display: flex;
            flex-direction: column;
            border-left: 1px solid #25292C;
            background: #343D49;
            max-height: 60px;
            height: 60px;
            width: auto;

            &>div {
              text-align: center;
              font-family: 'Open Sans';
              font-style: normal;
              font-weight: normal;
              font-size: 11px;
              line-height: 15px;
              color: #FFFFFF;
            }

            .framenumber {
              height: 15px;
            }

            .result {
              background: #8d9096;
              color: #25292c;
              flex: 1 1;
              min-width: 35px;
              display: flex;
              align-items: center;
              flex-direction: row;
              justify-content: center;
              padding: 0 8px;
              box-sizing: border-box;

              &>.shot {
                margin-right: 8px;

                &:last-child {
                  margin-right: 0;
                }
              }
            }

            .score {
              font-weight: bold;
              height: 15px;
            }

            &.selected {
              background: #5B4344;

              .result {
                background: #F65932;
                color: #FFFFFF;
              }
            }

          }

          &.original {

            .frame.selected {
              background: #005956;

              .result {
                background: #20A39E;
                color: #FFFFFF;
              }
            }

            .vector {
              margin-left: 10px;
            }
          }
        }

      }
    }
  }
}

@media only screen and (max-width: 1024px) {

  .protocol_modal_footer {

    .protocol_modal_footer_group {

      .match-scoreboard {
        margin-right: 0;
      }

      .party-comment {
        width: 100%;
        margin-top: 10px;
      }
    }
  }

}