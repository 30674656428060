.match-scoreboard .login {
  font-size: 14px;
  font-family: 'Open Sans';
  color: #f65932;
  line-height: 20px;
  max-width: 200px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.match-scoreboard .ratings .rating-stars svg {
  height: 12px;
  width: 12px;
}
.match-scoreboard .ratings .rating-stars svg:not(:last-child) {
  margin-right: 3px;
}

.match-scoreboard .full {
  font-size: 16px;
  font-family: 'Open Sans';
  color: #fff;
  max-width: 200px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.match-scoreboard .score span {
  font-family: 'Oswald';
  font-weight: 500;
  font-size: 50px;
  letter-spacing: 5px;
  flex: 1;
}

.match-scoreboard .score span:first-child{
  text-align: right;
}

.match-scoreboard .score span:nth-child(2){
  flex: 0 0 auto;
}

.match-scoreboard .score span:last-child {
  color: #f65932;
}

.match-scoreboard .avatar {
  height: 62px;
  width: 62px;
  min-width: 62px;
}

.match-scoreboard .avatar img {
  width: 58px;
}

.match-scoreboard.single .score, .match-scoreboard.single .protag {
  flex: 0 0 auto;
}