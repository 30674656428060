.red-card-caption {
  padding: 10px 0;
  text-align: center;
}

.red-card-image {
  height: 150px;
  margin: auto;
  margin-top: 40px;
  margin-bottom: 40px;
  display: block;
}