.countdown--modal-timer{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    letter-spacing: 5px;
    font-weight: 500;
    font-family: Oswald;
    font-size: 50px;
    height: 60px;
    margin: 0;
    margin-bottom: 15px;
}

.countdown--modal-tooltip {
    text-align: center;
    max-width: 210px;
    opacity: 0.8;
    margin: 1em auto;
}
