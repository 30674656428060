@charset "UTF-8";
.ant-modal-title {
  font-weight: 500; }

.ant-modal-body {
  border-bottom: 0;
  padding-bottom: 0; }

.ant-modal-footer {
  border-top: 0; }

.start-game__modal-group {
  margin: 15px 0; }

.observer-group {
  margin-top: 17px;
  margin-bottom: 15px; }
  .observer-group__header {
    margin-bottom: 12px; }
  .observer-group__elements .checkbox__checkmark {
    margin-right: 7px; }
  .observer-group__elements .checkbox:not(:last-child) {
    margin-bottom: 15px; }

/*.modal-group:nth-child(1) {   //Внезапно погромило стили половины модалок
  padding-top: 0;
  border-bottom: 0;
}*/
.modal-group:nth-last-child(1) {
  padding-bottom: 0; }

.start-game__header {
  height: 17px;
  opacity: 0.6;
  font-size: 12px;
  line-height: normal;
  color: white; }

.start-game__text {
  height: 20px;
  font-size: 14px;
  line-height: normal;
  color: white; }

.start-game__tournament-info .start-game__bet {
  margin-left: 30px; }

.start-game__numOfWins {
  margin-left: 10px; }

.start-game__tournamentinfo {
  display: flex;
  justify-content: space-between; }

.start-game__tournament-info > div > div:nth-last-child(1) {
  margin-top: 15px; }

.start-game__opponent .opponent {
  margin-bottom: 0;
  background: transparent;
  padding: 0; }

.start-game__additional-info {
  opacity: 0.6;
  font-size: 12px;
  color: white; }

.start-game__bowlingcenter__container {
  padding: 10px 0px; }

.start-game__bowlingcenter__block1 {
  display: flex;
  flex-direction: column; }

.start-game__bowlingcenter__header {
  color: white;
  font-family: 'Oswald';
  font-weight: 500;
  font-size: 20px;
  letter-spacing: 0.1em;
  background: linear-gradient(232.72deg, #F65932 0%, #CC3535 100%);
  padding: 20px;
  margin: 0 -20px; }

.start-game__bowlingcenter__firstselectcontainer {
  display: flex;
  width: 100%;
  margin-top: 5px; }

.start-game__bowlingcenter__countyselect {
  max-width: 50%;
  width: 200px; }

.start-game__bowlingcenter__cityselect {
  margin-left: 10px;
  max-width: 50%;
  width: 200px; }

.start-game__bowlingcenter__block2 {
  display: flex;
  margin-top: 5px; }

.start-game__bowlingcenter__nameblock {
  display: flex;
  flex-direction: column; }

.start-game__bowlingcenter__name, .start-game__bowlingcenter__city {
  font-size: 12px;
  color: white;
  opacity: 0.6;
  margin-bottom: 5px; }

.start-game__bowlingcenter__roadblock {
  display: flex;
  flex-direction: column;
  margin-left: 10px; }

.start-game__bowlingcenter__roadblock.alt {
  margin-left: 0;
  padding: 10px 0; }
  .start-game__bowlingcenter__roadblock.alt .start-game__bowlingcenter__roadselect {
    width: 100%; }
  .start-game__bowlingcenter__roadblock.alt .start-game__bowlingcenter__road {
    font-family: 'Oswald';
    font-weight: 500;
    font-size: 20px;
    letter-spacing: 0.1em;
    color: #fff;
    opacity: .6; }

.start-game__bowlingcenter__road {
  font-size: 12px;
  color: white;
  opacity: 0.6; }

.start-game__bowlingcenter__roadselect {
  width: 65px;
  margin-top: 5px; }

.start-game__bowlingcenter__nameselect {
  width: 280px; }

.star-game__connection-speed-info {
  display: flex; }

.star-game__connection-speed-info span {
  width: 100%;
  padding-left: 2px; }

.start-game__tournament-info .prizeShowcase {
  margin-top: 10px;
  border-radius: 5px;
  border: 1px solid #F65932;
  background: rgba(246, 89, 50, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  letter-spacing: 0.05em;
  font-size: 16px;
  font-family: 'Oswald';
  color: #FFFFFF; }

.wifiNetwork__preambula {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .wifiNetwork__preambula img {
    flex: 0 0 auto;
    width: 60px;
    margin-right: 20px; }
  .wifiNetwork__preambula p {
    margin-top: 10px 0;
    color: #fff;
    font-size: 14px;
    flex: 1;
    line-height: 19px; }

.wifiNetwork__postscriptum {
  color: #fff;
  font-size: 12px;
  opacity: 0.8;
  margin-top: 10px;
  margin-bottom: 2px;
  line-height: 16px; }

.wifiNetwork__list {
  background: linear-gradient(253.13deg, #F65932 0%, #CC3535 100%);
  margin: 0 -20px; }
  .wifiNetwork__list .wifiNetwork__item {
    padding: 20px;
    display: flex;
    justify-content: space-evenly; }
    .wifiNetwork__list .wifiNetwork__item:not(:last-child) {
      position: relative; }
      .wifiNetwork__list .wifiNetwork__item:not(:last-child):after {
        content: '';
        bottom: 0;
        left: 0;
        right: 0;
        height: 1px;
        background-color: rgba(255, 255, 255, 0.4);
        position: absolute; }
    .wifiNetwork__list .wifiNetwork__item .wifiNetwork__name, .wifiNetwork__list .wifiNetwork__item .wifiNetwork__pass {
      flex: 1; }
      .wifiNetwork__list .wifiNetwork__item .wifiNetwork__name label, .wifiNetwork__list .wifiNetwork__item .wifiNetwork__name span, .wifiNetwork__list .wifiNetwork__item .wifiNetwork__pass label, .wifiNetwork__list .wifiNetwork__item .wifiNetwork__pass span {
        display: block;
        font-size: 16px;
        color: #fff; }
      .wifiNetwork__list .wifiNetwork__item .wifiNetwork__name span, .wifiNetwork__list .wifiNetwork__item .wifiNetwork__pass span {
        font-family: 'Oswald';
        letter-spacing: 0.05em; }
      .wifiNetwork__list .wifiNetwork__item .wifiNetwork__name label, .wifiNetwork__list .wifiNetwork__item .wifiNetwork__pass label {
        font-size: 12px;
        opacity: 0.8;
        margin-bottom: 7px; }

.start-game__bowlingcenter__usercontainer {
  background: linear-gradient(243.25deg, #F65932 0%, #CC3535 100%);
  padding: 15px 20px;
  margin: 0 -20px; }
  .start-game__bowlingcenter__usercontainer h3 {
    font-weight: 500;
    font-family: 'Oswald';
    font-size: 20px;
    letter-spacing: 0.1em;
    margin-bottom: 10px;
    color: #fff;
    margin-top: 0; }
  .start-game__bowlingcenter__usercontainer p {
    margin-bottom: 0;
    color: #fff;
    font-size: 14px;
    margin-top: 5px; }

.start-game-modal {
  overflow: auto !important; }
  .start-game-modal .ant-modal-body {
    z-index: 2;
    overflow: visible !important;
    background-image: linear-gradient(to top, #0c131c, #0d141d);
    min-height: initial !important; }

.start-battle-modal {
  overflow: auto !important; }
  .start-battle-modal .ant-modal-body {
    z-index: 2;
    flex: 0 0 auto !important;
    overflow: visible !important;
    background-image: linear-gradient(to top, #0c131c, #0d141d);
    min-height: initial !important; }
  .start-battle-modal .ant-modal {
    overflow: visible !important; }
  .start-battle-modal .ant-modal {
    overflow: visible !important; }
  .start-battle-modal .ant-modal-content {
    overflow: visible !important; }
  .start-battle-modal .ant-modal-header {
    flex: 0 0 auto !important;
    background-image: linear-gradient(to top, #0c131c, #0d141d); }
  .start-battle-modal .ant-modal-footer {
    flex: 0 0 auto !important;
    background-image: linear-gradient(to top, #0c131c, #0d141d); }
