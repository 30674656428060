.stream_page {
    flex: 1 1;
    position: relative;
    display: flex;
    flex-direction: column;
    background: #0D141D;
}

/**
 Header Styles
 */

.stream_page {
    display: flex;
    flex-direction: column;
}

.stream__header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 0 30px 0;
}

 .bc_stream_logo__header {
      width: 175px;
      height: 66px;
      margin-right: 55px;
  }

.bc_name__text {
      font-family: 'Oswald', sans-serif;
      font-size: 40px;
      line-height: 60px;
      margin-right: 30px;
  }

.bc_name__text-mobile {
    font-family: 'Oswald', sans-serif;
    font-size: 30px;
    line-height: 50px;
    display: none;
    justify-content: center;
}

.bc_link_to_download__header {
      height: 80px;
      width: 80px;
      object-fit: contain;
  }



@media  screen and (max-width: 600px) {
    .bc_name__text {
        display: none;
    }

    .bc_name__text-mobile {
        display: flex;
    }
}

/*
    Horizontal
 */

    /*Content Styles*/

.camera_container {
    display: flex;
    flex-direction: row;
     margin: auto;
    justify-content: space-between;
    max-width: 1350px;
    background-color: #262f3a;
    padding: 10px 3px 3px 3px;
}

.camera_container .bowlingcenters_camera {
    width: 100%;
}

.camera_container .bowlingcenters_camera:nth-child(odd) {
    margin-right: 3px;
}

@media screen and (max-width: 768px) {
    .camera_container {
        flex-direction: column;
    }
}

/*
    Vertical
 */

.content_container-vertical {
    display: flex;
    flex-direction: row-reverse;
    max-width: 1400px;
    margin: auto;
}

.camera_container-vertical {
    display: flex;
    flex-direction: row;
    margin: auto;
    justify-content: space-between;
    width: 100%;
    background-color: #262f3a;
    padding: 10px 3px 3px 3px;
    max-width: 950px;
}

.camera_container-vertical .bowlingcenters_camera {
    width: 100%;
}



@media screen and (max-width: 1060px) {
    .content_container-vertical {
        flex-direction: column;
    }
    .camera_container-vertical {
        width: 95%;
    }
}



