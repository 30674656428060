
.live-cut-modal-content {
  margin: 10px 0;
}

.live-cut-wrapper {
  display: flex;
  padding-right: 12px;
  padding-left: 5px;
  .content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
  }
  .cup {
    width: 27px;
    height: 27px;
  }
  .cup-small {
    width: 9px;
    height: 9px;
  }
  .text-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-left: 3px;
  }
  .text {
    font-family: 'Open Sans';
    font-size: 12px;
    line-height: 16px;
    color: #FFF;
  }
  .text-small {
    font-size: 10px;
    line-height: 14px;
  }
  .flex-start {
    align-items: flex-start;
  }
  .flex-undefined {
    flex: unset
  }
  .ml2 {
    margin-left: 2px;
  }
}
