
.landing-modal-investors {
  width: 1200px !important;

  .ant-modal-content {
    border-radius: 20px;
  }

  .ant-modal-body {
    border-radius: 20px;
  }
  .ant-modal-footer {
    display: none;
  }
  .wrapper-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 610px;
    background: #151E2B;
  }

  .thanks-container{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    &__content {
      max-width: 400px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &__title {
      font-family: 'Oswald';
      font-style: normal;
      font-weight: 400;
      font-size: 60px;
      line-height: 75px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      margin-bottom: 40px;
      &:first-letter {
        color: #F65932;
      }
    }

    &__text {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 27px;
      margin-bottom: 90px;
    }
    &__btn {
      width: 100%;
      background: linear-gradient(264.59deg, #F65932 0%, #CC3535 100%);
      border-radius: 5px;
      height: 60px;
      border: none;
      color: #FFFFFF;
    }
  }
  .ant-modal-close {
    border: none;
  }
  .ant-modal-close-x {
    font-size: 28px;
    color: #FFFFFF;
  }
}

@media (max-width: 768px) {
  .landing-modal-investors {
      width: 768px !important;

    .wrapper-container {
      flex-direction: row;
      height: 100%;
    }
    .wrapper-container-thanks {
      height: 610px;
    }
    .thanks-container {
      &__content {
        max-width: 90%;
      }
      &__title {
        font-size: 42px;
        line-height: 63px;
        text-align: center;
      }
      &__text {
        font-size: 16px;
        line-height: 24px;
      }
      &__btn {
        height: 40px;
      }
    }
    .ant-modal-close-x {
      font-size: 16px;
    }
  }
}

@media (max-width: 599px) {
  .ant-modal-wrap {
    padding: 0 !important;
  }
  .landing-modal-investors {
    width: 100vw !important;

    .ant-modal-content {
      max-height: calc(var(--vh, 1vh) * 100 - 16px) !important;
      margin-top: 16px;
    }

    .wrapper-container {
      flex-direction: column;
    }
    .wrapper-container-thanks {
      height: 500px;
    }
    .thanks-container {
      &__content {
        max-width: 90%;
      }
    }
  }
}