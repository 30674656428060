.ant-modal {
  font-size: 14px;
  font-variant: tabular-nums;
  color: #6d7177;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  width: auto;
  margin: 0 auto;
  top: 100px;
  padding-bottom: 24px;
}
.ant-modal-wrap {
  position: fixed;
  overflow: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}
.ant-modal-title {
  font-family: Oswald;
  margin: 0;
  font-size: 20px;
  line-height: 22px;
  letter-spacing: 2px;
  font-weight: 400;
  color: #fff;
}
.ant-modal-content {
  position: relative;
  background-image: linear-gradient(to top, #0c131c, #0d141d);
  border: 0;
  background-clip: padding-box;
  -webkit-box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}
.ant-modal-close {
  cursor: pointer;
  border: 0;
  background: transparent;
  position: absolute;
  right: 20px;
  top: 14px;
  z-index: 10;
  border: 1px solid rgba(255,255,255,0.4);
  text-decoration: none;
  -webkit-transition: color .3s;
  transition: color .3s;
  color: rgba(255,255,255,0.4);
  outline: 0;
  padding: 0;
}
.ant-modal-close-x {
  display: block;
  font-style: normal;
  vertical-align: baseline;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
  width: 20px;
  height: 20px;
  line-height: 23px;
  font-size: 14px;
}
.ant-modal-close:focus,
.ant-modal-close:hover {
  text-decoration: none;
}
.ant-modal-header {
  padding: 12px 20px;
  border-radius: 4px 4px 0 0;
  background-image: linear-gradient(to top, #0c131c, #0d141d);
  color: #fff;
  border-bottom: 1px solid #25292c;
}
.ant-modal-body {
  position: relative;
  padding: 10px 0;
  font-size: 14px;
  word-wrap: break-word;
}
.ant-modal-footer {
  border-top: 1px solid #25292c;
  padding: 15px 20px;
  text-align: center;
}
.ant-modal-footer>div {
  display: flex;
  justify-content: space-between;
}
.ant-modal-footer button {
  padding-top: 11px;
  padding-bottom: 11px;
  font-size: 14px;
}
.ant-modal-footer button + button {
  margin-left: 11px;
  margin-bottom: 0;
}
.ant-modal.zoom-enter,
.ant-modal.zoom-appear {
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
  opacity: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-modal-mask {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(77, 77, 77, 0.7);
  height: 100%;
  z-index: 1000;
  filter: alpha(opacity=50);
}
.ant-modal-mask-hidden {
  display: none;
}
.ant-modal-open {
  overflow: hidden;
}
.ant-modal-centered {
  text-align: center;
}
.ant-modal-centered:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  width: 0;
}
.ant-modal-centered .ant-modal {
  display: inline-block;
  vertical-align: middle;
  top: 0;
  text-align: left;
}

.modal-group {
  border-bottom: 1px solid #25292c;
  padding: 0 20px;
}
.modal-group:last-child {
  border-bottom: none;
}

.ant-modal .loading {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  background: linear-gradient(to top, #0c131c, #0d141d);
  z-index: 2;
}
.ant-modal .loading-i i:nth-child(1) {
  animation: pointcolor 3s ease infinite;
}
.ant-modal .loading-i i:nth-child(2) {
  animation: pointcolor 3s ease infinite;
  animation-delay: 1s;
}
.ant-modal .loading-i i:nth-child(3) {
  animation: pointcolor 3s ease infinite;
  animation-delay: 2s;
}

@keyframes pointcolor {
  0% { color: transparent; }
  15% { color: transparent; }
  90% { color: #fff; }
  100% { color: transparent; }
}


@media (max-width: 767px) {
  .ant-modal {
    width: auto !important;
    margin: 10px;
  }
  .ant-modal-centered .ant-modal {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
        -ms-flex: 1;
            flex: 1;
  }
}
/* .ant-modal-confirm .ant-modal-header {
  display: none;
}
.ant-modal-confirm .ant-modal-close {
  display: none;
}
.ant-modal-confirm .ant-modal-body {
  padding: 32px 32px 24px;
}
.ant-modal-confirm-body-wrapper {
  zoom: 1;
}
.ant-modal-confirm-body-wrapper:before,
.ant-modal-confirm-body-wrapper:after {
  content: "";
  display: table;
}
.ant-modal-confirm-body-wrapper:after {
  clear: both;
}
.ant-modal-confirm-body .ant-modal-confirm-title {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
  display: block;
  overflow: hidden;
}
.ant-modal-confirm-body .ant-modal-confirm-content {
  margin-left: 38px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
  margin-top: 8px;
}
.ant-modal-confirm-body > .anticon {
  font-size: 22px;
  margin-right: 16px;
  float: left;
}
.ant-modal-confirm .ant-modal-confirm-btns {
  margin-top: 24px;
  float: right;
}
.ant-modal-confirm .ant-modal-confirm-btns button + button {
  margin-left: 8px;
  margin-bottom: 0;
}
.ant-modal-confirm-error .ant-modal-confirm-body > .anticon {
  color: #f5222d;
}
.ant-modal-confirm-warning .ant-modal-confirm-body > .anticon,
.ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon {
  color: #faad14;
}
.ant-modal-confirm-info .ant-modal-confirm-body > .anticon {
  color: #1890ff;
}
.ant-modal-confirm-success .ant-modal-confirm-body > .anticon {
  color: #52c41a;
} */
