.userStatsContainer {
  padding: 20px 40px;
  display: flex;
  justify-content: flex-start;
  .containerWrap {
    display: flex;
    flex-direction: column;
  }
  .title {
    font-family: 'Oswald';
    font-weight: 500;
    font-size: 30px;
    color: #fff;
    margin-bottom: 20px;
  }
  .statBlock {
    display: flex;
    margin-bottom: 20px;
    justify-content: flex-start;
    align-items: flex-start;
    img {
      flex: 0 0 60px;
      margin-right: 20px;
      width: 60px;
      height: auto;
    }
    .statList {
      flex: 1 0 auto;
      .statItem {
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        .caption {
          flex: 0 0 auto;
          color: #9EA1A4;
          font-size: 20px;
        }
        .value {
          margin-left: 30px;
          flex: 0 0 70px;
          min-width: 70px;
          font-family: 'Oswald';
          font-weight: bold;
          color: #FFFFFF;
          font-size: 24px;
        }
      }
    }
  }
}