.container__header {
  border-bottom: 1px solid #25292c;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .tournament-info-name {
    font-family: Oswald;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: 1 1;
    padding-right: 100px;
  }
}

.header__button {
  background: #F65932;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  padding: 10px 37px;
  vertical-align: middle;
  flex-direction: row;

  &:hover {
    cursor: pointer;
    background-color: #ff3e18;
    box-shadow: 0 10px 14px 0 rgba(255, 62, 24, 0.4);
  }
}

.is-participant {
  background: none !important;

  &:hover {
    cursor: default;
    background: none !important;
    box-shadow: none !important;
  }

  & svg {
    margin-right: 10px;
  }
}

.tournamen-isover {
  background: none;

  &:hover {
    cursor: default;
    background: none !important;
    box-shadow: none !important;
  }
}

.container__body {
  width: 50%;
  padding: 20px;
}

.body__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(196,196,196,0.4);
  margin-top: 12px;

  &:last-child {
    padding-bottom: 0px;
    margin-top: 20px;
    border-bottom: none;
    flex-direction: column;
    align-items: flex-start;
  }

  &:first-child {
    margin-top: 0px;
  }

  &-key {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: rgba(255, 255, 255, 0.7);
  }

  &-value {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    text-align: right;
    color: #FFFFFF;
  }
}

.tournament-description {
  margin-top: 10px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  overflow: hidden;
  max-width: 100%;
  text-align: left;
}

.confirm-modal__body {
  margin-top: 15px;
}