.bowlingcenterspage {
  padding: 20px 40px;
}

.bowlingcenterspage__mybowlingcenters__container {
  width: 50%;
}

.bowlingcenterspage__bowlingcenters__container {
  width: 100%;
}

.bowlingcenterspage__mybowlingcenters__header {
  font-size: 30px;
  font-family: 'Oswald';
  font-weight: 400;
  letter-spacing: 0.5px;
}

.bowlingcenterspage__mybowlingcenters__body__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bowlingcenterspage__mybowlingcenters__body__header__name {
  font-size: 14px;
  font-family: 'Open Sans';
  letter-spacing: 0.5px;
  font-weight: 200;
}

.bowlingcenterspage__mybowlingcenters__body__header__roadcount__container {
  display: flex;
  align-items: center;
}

.bowlingcenterspage__mybowlingcenters__body__header__roadcount {
  font-size: 14px;
  font-family: 'Open Sans';
  letter-spacing: 0.5px;
  font-weight: 200;
}

.bowlingcenterspage__mybowlingcenters__body__header__roadcount__icon {
  margin-left: 10px;
  cursor: pointer;
}

.bowlingcenterspage__mybowlingcenters__body__container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.bowlingcenterspage__mybowlingcenters__body__list__container {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
}

.bowlingcenterspage__mybowlingcenters__body__content__container {
  display: flex;
  flex-direction: column;
}

.bowlingcenterspage__mybowlingcenters__body__content {
  margin-top: 3px;
  font-size: 14px;
  font-family: 'Open Sans';
  letter-spacing: 0.5px;
  font-weight: 200;
  color: #6d7177;
}

.bowlingcenterspage__mybowlingcenters__body__content__list {
  margin-top: 7px;
  font-size: 14px;
  font-family: 'Open Sans';
  letter-spacing: 0.5px;
  font-weight: 200;
  color: #6d7177;
}

.bowlingcenterspage__mybowlingcenters__body__separator {
  width: 100%;
  border: 0px;
  height: 1px;
  background-color: #25292c;
}

.bowlingcenterspage__mybowlingcenters__body__separator__list {
  width: 100%;
  border: 0px;
  height: 1px;
  background-color: #25292c;
  margin-top: 0px;
}

.bowlingcenterspage__bowlingcenters__header {
  font-size: 30px;
  font-family: 'Oswald';
  font-weight: 400;
  letter-spacing: 0.5px;
}

.bowlingcenterspage__bowlingcenters__subheader {
  margin-top: 10px;
  font-size: 14px;
  color: #6d7177;
  letter-spacing: 0.5px;
  font-family: 'Open Sans';
  font-weight: 200;
}

.bowlingcenterspage__bowlingcenters__button {
  margin-top: 15px;
  margin-bottom: 15px;
  max-width: 30%;
}

.bowlingcenterspage__bowlingcenters__filter {
  margin-top: 25px;
  margin-bottom: 25px;
  display: flex;
  justify-content: space-between;
}

.bowlingcenterspage__bowlingcenters__filter__selectcountry {
  margin-left: 10px;
  /* max-width: 30%;
  width: 200px; */
  flex: 1 1 33.33%;
}

.bowlingcenterspage__bowlingcenters__filter__selectcity {
  margin-left: 10px;
  /* max-width: 30%;
  width: 500px; */
  flex: 1 1 33.33%;
}

.bowlingcenterspage__bowlingcenters__filter .filter-input {
  flex: 1 1 33.33%;
  box-sizing: border-box;
}

.bowlingcenterspage__bowlingcenters__filter .filter-input .filter-input__data {
  box-sizing: border-box;
  width: 100%;
}

.bowlingcenterspage__bowlingcenters__filter__input {
  max-width: 30%;
  width: 500px;
}

.bowlingcenterspage__mybowlingcenters__body__link {
  margin-top: 10px;
  font-size: 14px;
  letter-spacing: 0.5px;
  font-family: 'Open Sans';
  font-weight: 200;
}

.bowlingcenterspage__mybowlingcenters__body__link:hover {
  color: #ff3e18;
}

.bowlingcenterspage__mybowlingcenters__body__withoutlink {
  margin-top: 10px;
}

@media screen and (max-height: 800px){
  .bowlingcenterspage__bowlingcenters__filter__selectcountry > div > div > div > div:last-child{
    max-height: 200px;
    height: auto;
    position: absolute;
    bottom: 30px;
    width: 100%;
  }
  .bowlingcenterspage__bowlingcenters__filter__selectcountry > div > div > div > div:last-child img{
    position: absolute;
    right: 8px;
    bottom: -20px;
  }
  .bowlingcenterspage__bowlingcenters__filter__selectcity > div > div > div > div:last-child {
    max-height: 200px;
    height: auto;
    position: absolute;
    bottom: 30px;
    width: 100%;
  }
  .bowlingcenterspage__bowlingcenters__filter__selectcity > div > div > div > div:last-child img {
    position: absolute;
    right: 8px;
    bottom: -20px;
  }
}

.bowlingcenter-flag {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  overflow: hidden;
  margin: auto;
}

.bowlingcenter-flag > span {
  display: block;
  width: 100% !important;
  height: 100%;
  background-size: contain;
}

.bowlingcenter-flag__title {
  display: flex;
  align-items: center;
  justify-content: center;
}