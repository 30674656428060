.container {
  padding: 20px 0px;

  .formula {
    background-color: #fff;
    color: #EB3F15;
    text-align: center;
    padding: 8px 0px;
    border-radius: 8px;
    font-family: Open Sans;
    font-style: italic;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: -0.02em;
  }

  .variable {
    &__container {
      display: flex;
      align-items: flex-start;
      flex-direction: row;
      &:nth-child(2) {
        margin-top: 20px;
      }
    }

    &__label {
      margin-right: 5px;
      font-family: Open Sans;
      font-style: italic;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      color: #F65932;
    }

    &__value {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      color: #FFFFFF;
      text-transform: lowercase;
    }
  }
}