@media screen and (min-width: 320px) and (max-width: 767px) {
  .landing {

    &__agreement {

      .ant-modal-body {
  
        p {
          font-size: 12px;
          line-height: 18px;
        }
  
        ul {
          list-style: none;
          padding-left: 20px;
      
          li {
            margin: 4px 0;
            font-size: 10px;
            line-height: 16px;
      
            div {
              margin-top: 4px;
            }
            
            b, i {
              font-size: 10px;
              line-height: 16px;
            }
          }
        }
      }
    }

    &__header {
      background-image: url('images/bg_480.png');
      background-size: cover;
      background-position: top;

      &>.landing__container {
        justify-content: space-between;

        &>.landing__header__top {
          margin-left: 15px;
          margin-top: 12px;
          align-items: flex-start;
          justify-content: space-between;

          .dropdown {

            &__menu {
              top: 32px;
            }
          }

          &>.links {
            height: 101px;
            flex-wrap: wrap-reverse;
            font-size: 14px;
            justify-content: space-between;
            flex-direction: column;
            margin-right: 11px;
            display: none;
            width: 60%;

            &>.btn {
              height: 29px;
              margin-right: 0px;
              padding: 4px 0px;
              letter-spacing: 0.05em;
              font-size: 14px;
              margin-left: 0px;
            }
            &>.btn.install {
              margin-bottom: 25px;
            }

            &>.sign-in {
              border-width: 1px;
              height: 30px;
              padding-left: 12px;
              padding-right: 13px;
              padding-top: 5px;
              padding-bottom: 4px;
              margin-left: 0px;
              letter-spacing: 0.05em;
              font-size: 14px;
            }

            &>.dropdown {
              height: 29px;
              padding: 4px;
              margin: 0px;
              letter-spacing: 0.05em;
              font-size: 14px;
            }
          }

          &>.burger {
            display: flex;
            margin-right: 17px;
          }
        }

        &>.landing__header__bottom {
          flex-direction: column;
          margin-top: 50px;

          &>.left-side {
            width: 100%;
            flex: none;

            &>img {
              align-self: center;
              width: calc(100% - 40px);
              margin-left: calc(20px);
              margin-right: calc(20px);
            }

            &>.slogan {
              width: 100%;
              font-size: 20px;
              text-align: center;
              vertical-align: text-bottom;
              line-height: 30px;
              margin: 0px;
              margin-top: 16px;
              padding-top: 9px;
              height: 48px;

              &>.slogan__mask {
                width: 100%;
              }

              &>.slogan__mask::before {
                align-items: center;
                width: 100%;
                margin-left: 0;
                height: 48px;
              }
            }

            &>.description {
              font-size: 16px;
              line-height: 26px;
              margin-left: 20px;
              margin-right: 20px;
              margin-top: 16px;
              text-align: left;
            }

            &>.actions {
              flex-direction: column;
              width: 100%;
              margin-left: 20px;
              margin-right: 20px;
              margin-top: 20px;
              align-items: center;

              &>.btn {
                height: 40px;
                width: calc(100% - 40px);
                margin-bottom: 10px;
                margin-right: 0px;
                font-size: 12.5px;
              }
            }

          }

          &>.right-side {
            margin-left: 28px;

            &>.phone {
              width: auto;
              margin-top: 50px;

              &>img {
                width: 422px;
              }

              &>.phone__play {
                left: 170px;
                top: -30px;
                z-index: 2;
                width: 101px;
                height: 101px;

                &>.phone__play__big_bubble {
                  width: 101px;
                  height: 101px;
                }

                &>.phone__play__small_bubble {
                  left: 25px;
                  top: 25px;
                  width: 50px;
                  height: 50px;
                }

                &>.phone__play__button {
                  left: 32px;
                  top: 32px;
                  width: 37px;
                  height: 37px;

                  &>svg {
                    width: 17px;
                    height: 17px;
                  }
                }
              }

              &>.phone__screen {
                left: 46px;
                width: 175px;
                top: 8px;
                height: 385px
              }
            }
          }

        }

      }

    }

    .landing__full_container {

      .firstTournament {
        .container {
          max-width: calc(100% - 20px);
          margin: 70px 0px;

          .headerBlock {    
            padding-top: 10px;
            flex-direction: column;
            align-items: flex-start;

            .headerTitle {
              font-size: 38px;
            }

            .headerSmallMsgBlock {
              width: 100%;
              margin: 0px;
    
              .headerSmallMessage {
                display: none;
              }
              .headerSmallMessage.mobile {
                display: flex;
                width: 90%;
                font-size: 16px;
              }
            }
          }
          .logoBlock {
            margin: 40px 0px 40px 0px;

            .logo {
              width: calc(100% + 50px);
              margin-left: calc(-100% / 4);
            }
            .date {
              margin-top: 13px;
              width: 70%;
            }
    
          }
          .msgFirstBlock {
            display: flex;
            flex-direction: column;
    
            .message {
              font-size: 16px;
              margin-bottom: 20px;
    
              &:nth-child(2) {
                margin: 0px;
                padding-left: 0px;
              }
            }
          }
          .msgSecondBlock {
            flex-direction: column;
    
            .message {
              align-items: flex-start;
              padding-top: 10px;
              flex: 0 0 49%;
              font-size: 16px;
              margin: 10px 0px;
            }
          }
          .footerBlock {
            flex-direction: column;
            flex-wrap: wrap;
  
            .footerTitle {
              font-size: 32px;
              letter-spacing: 2px;
              width: 100%;
            }
            .footerLinkBlock {
              margin-top: 15px;
              width: 100%;
      
              .footerLinkMsg {
                font-size: 16px;
                padding: 15px 15px 0px 15px;
                margin-right: 0px;
              }
              .footerLink {
                width: 40px;
                height: 40px;
                margin: 15px 15px 15px 0px;

                .footerArrow {
                  width: 40px;
                  height: 40px;
                }
              }
            }
          }
        }
      }


      .mainrules {
        margin: 90px 15px 79px 15px;
        width: calc(100% - 30px);

        &>h2 {
          font-size: 24px;
          line-height: 36px;
        }

        &>.ruleslist {
          padding-bottom: 0px;
          padding-top: 30px;

          &>.rule {
            margin-bottom: 30px;

            &>.imgwrap {
              width: 32px;
              height: 32px;
              margin-right: 10px;

              &>img {
                width: 12px;
                height: 12px;
              }
            }

            &>span {
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 19px;
              display: flex;
              align-items: center;
            }
          }
        }

        &>.btn {
          height: 30px;
          font-size: 14px;
          line-height: 21px;
        }
      }

      .downloadlinks {
        // height: 196px;
        margin: 0 10px 0 10px;
        width: calc(100% - 20px);
        padding: 50px 5px 0 5px;
        display: block;
        align-items: center;

        &>h2 {
          font-size: 24px;
          line-height: 36px;
          margin-bottom: 20px;
          max-width: 1200px;
        }

        &>.links {
          flex-direction: row;
          justify-content: center;
          display: flex;

          img {
            height: 40px;
          }

          &>a:first-child {
            margin-right: 15px;
          }
        }

        .links {
          display: flex;
          flex-direction: column;
        }
      }

      .footer {
        margin: 0 10px 0 10px;
        width: calc(100% - 20px);
        padding: 10px 0 30px 0;

        &>div {

          &>img {
            width: 235px;
          }

          &>span {
            text-align: center;
          }
        }

        &>div::before {
          content: '';
          margin-bottom: 20px;
        }
      }

      &>.customFadeInDown {

      }

      .another-bg {
        padding: 0 15px;
        margin-bottom: 60px;
        background: linear-gradient(rgba(#FFFFFF, 0.1));

        &.another-bg::before {
          content: '';
          background: none;
          height: 0;
        }

        &.another-bg::after {
          content: '';
          background: none;
          height: 0px;
        }

        &.tournament {
          padding: 0;
        }

        .alley-list {
          margin: 0;
          padding: 0;

          &>h2 {
            font-size: 24px;
            line-height: 36px;
            color: white;
            margin-bottom: 36px;
            margin-top: 0;
          }

          &>.divider {
            opacity: 0;
            height: 0;
            margin: 0;
          }


          &>.country {
            flex-direction: column;
            margin-bottom: 0;

            &>.country__name {
              font-size: 20px;
              line-height: 30px;
              flex: 0 0 33%;
              margin-bottom: 12px;
              margin-top: 13px;
            }

            &>.bc__details {
              display: flex;
              flex-wrap: wrap;
              flex-direction: column;
              width: 100%;

              &>.bc__details__item {
                flex-direction: column;
                margin-left: 0;

                &>.country__address {
                  font-size: 16px;
                  line-height: 22px;
                  margin-left: 0;
                  font-style: normal;
                  font-weight: normal;
                  margin-bottom: 5px;
                  width: 100%;
                }

                &>.country__center {
                  position: relative;
                  font-size: 16px;
                  line-height: 22px;
                  margin-left: 0;
                  margin-bottom: 30px;
                  font-style: normal;
                  font-weight: normal;
                  width: 100%;
                }

                &>.country__center::before {
                  position: absolute;
                  width: 100%;
                  content: '';
                  bottom: -30px;
                  height: 1px;
                  background: #585E68;
                  margin-bottom: 15px;
                }
              }
            }
          }
        }

        .landing__container {
          width: 100%;

          &>.benefits {
            margin: 50px 0 0 0;

            &>h2 {
              font-style: normal;
              font-size: 24px;
              line-height: 36px;
              margin-bottom: 10px;
            }

            &>.container {
              flex-direction: column;
              padding: 0px 15px;

              &>.left {
                &>img {
                  width: calc(100%);
                }
              }

              &>.right {
                margin-left: 0;

                &>.benefits__btn > div {
                  width: calc(97%);
                }

                &>ol {

                  li {
                    font-size: 14px;
                    line-height: 21px;
                    margin-bottom: 20px;
                    margin-right: 15px;
                  }

                  span {
                    text-align: center;
                    margin-right: 20px;
                  }
                  // &>.customFadeInLeft {
                  // }
                }
              }
            }
          }
        }

        .landing__container {
          width: 100%;
 
           &>.reviews {
             padding: 0px 15px;
           }
         }
 
         #list_of_alleys.landing__container  {
            width: 100%;
            padding: 0px 15px;
         }
 
         #formEquipment.landing__container  {
            width: 100%;
            padding: 0px 10px;
         }      
 
         .landing__container {
          margin-bottom: 60px;

          .equipment__from {
            padding: 36px 5px 20px 5px;
            margin: 60px 0 0 0;

            .btn.big.filled {
              height: 40px;
            }

            &>.container {
              flex-direction: column;
              width: 100%;
              align-items: center;
              margin-bottom: 0;

              &>.left {
                align-self: center;
                align-items: center;

                &>h2 {
                  text-align: center;
                  width: 100%;
                  font-style: normal;
                  font-size: 24px;
                  line-height: 36px;
                }

                &>p {
                  text-align: center;
                  font-size: 16px;
                  line-height: 22px;
                  text-align: center;
                  color: #D0D2D5;
                  margin: 20px 10px 0 10px;
                }
              }

              .right {
                width: 100%;
                margin: 10px;

                .error {
                  position: static;
                  display: flex;
                  justify-content: center;
                  font-size: 14px;
                }

                .ant-form {

                  p {
                    margin-top: 0;
                  }

                  .row {
                    flex-direction: column;
                    margin-bottom: 0;

                    .ant-row {
                      width: 100%;
                      margin: 0 0 11px 0;

                      &.postcode {
                        max-width: none !important;
                      }
                    }

                  }
                }
              }
            }

            &>.btn {
              width: 100%;
              max-width: 513px;
            }

            &>p {
              text-align: center;
              font-size: 14px;
              line-height: 19px;
            }
          }
        }
        
      }

      .downloadlinks.fullcontainer {
        width: 100%;
        margin: 0px;

        &>.f-download{
          margin: 0px;
        }
      }
      .footer.fullcontainer {
        width: 100%;
        margin: 0px;
      }
    }

  }
}

@media screen and (min-width: 320px) and (max-width: 520px) {
  .landing {
    &__header {

      &>.landing__container {
        justify-content: space-between;

        &>.landing__header__top {
          margin-left: 15px;
          margin-top: 12px;
          align-items: flex-start;
          justify-content: space-between;

          .dropdown {

            &__menu {
              top: 32px;
            }
          }

          &>.links {
            height: 101px;
            flex-wrap: wrap-reverse;
            font-size: 14px;
            justify-content: space-between;
            flex-direction: column;
            margin-right: 11px;
            width: 60%;

            &>.btn {
              height: 18px;
              margin-right: 0px;
              letter-spacing: 0.05em;
              font-size: 11px;
              padding: 3px 10px;
              margin-left: 0px;
            }
            
            &>.btn.install {
              height: 18px;
              font-size: 11px;
              padding: 3px 10px;
              margin: 0px;
              min-height: 0;
            }

            &>.sign-in {
              border-width: 1px;
              height: 28px;
              padding-left: 12px;
              padding-right: 13px;
              padding-top: 4px;
              padding-bottom: 4px;
              margin: 0px;
              letter-spacing: 0.05em;
              font-size: 11px;
            }

            &>.dropdown {
              height: 28px;
              padding: 4px;
              margin: 0px;
              letter-spacing: 0.05em;
              font-size: 11px;
            }
          }
        }
      }
    }
  }
}


@media screen and (min-width: 768px) and (max-width: 867px) {
  .landing {
    &__header {
      &>.landing__container {
        &>.landing__header__top {
          &>.links {
            &>.btn {
              // margin-right: 10px;
              padding-left: 10px;
            }
            &>.sign-in {
              border-width: 1px;
              height: 30px;
              padding-left: 12px;
              padding-right: 13px;
              padding-top: 5px;
              padding-bottom: 4px;
              margin-left: 0px;
              letter-spacing: 0.05em;
              font-size: 14px;
            }
            &>.dropdown {
              height: 29px;
              padding: 4px;
              margin: 0px;
              letter-spacing: 0.05em;
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .landing {

    &__header {

      &>.landing__container {
        flex: none;

        &>.landing__header__top {
          margin-left: 20px;

          margin-right: 20px;
        }

        &>.landing__header__bottom {
          margin-left: 20px;

          &>.left-side {
            width: 50%;
            flex: none;

            &>img {
              align-self: center;
              width: 100%;
            }

            &>.slogan {
              width: 100%;
              font-size: 20px;
              text-align: center;
              vertical-align: text-bottom;
              line-height: 30px;
              margin: 0px;
              margin-top: 16px;
              padding-top: 9px;
              height: 48px;

              &>.slogan__mask {
                width: 100%;
              }

              &>.slogan__mask::before {
                align-items: center;
                width: 100%;
                margin-left: 0;
                height: 48px;
              }
            }

            &>.description {
              font-size: 16px;
              line-height: 26px;
              margin-top: 16px;
              text-align: left;
            }

            &>.actions {
              justify-content: center;

              &>.btn {
                font-size: 12px;
                height: 50px;
                margin-right: 10px;
              }
            }

          }

          &>.right-side {
            width: 45%;

            &>.phone {
              width: 100%;
              height: 100%;

              &>img {
                width: 498px;
                height: 557px;
              }

              &>.phone__play {
                left: 170px;
                top: -36px;
                z-index: 2;
              }

              &>.phone__screen {
                left: 54px;
                width: 209px;
                top: 14px;
                height: 449px
              }
            }
          }

        }

      }

    }

    .landing__full_container {

      .firstTournament {
        .container {
          max-width: calc(100% - 80px);
          margin: 110px 0px;

          .headerBlock {    
            padding-top: 10px;
            flex-direction: column;
            align-items: flex-start;

            .headerTitle {
              font-size: 40px;
            }

            .headerSmallMsgBlock {
              display: flex;
              flex-direction: row;
              margin: 0px;
    
              .headerSmallMessage {
                font-size: 16px;
                &:nth-child(2) {
                  margin-left: 3px;
                }
              }
            }
          }
          .logoBlock {
            margin: 80px 0px 80px 0px;
            .logo {
              width: calc(100% + 80px);
              margin-left: calc(-100% / 4);
            }
            .date {
              margin-top: 15px;
              width: 35%;
            }
          }
          .msgFirstBlock {
            display: flex;
            flex-direction: column;
    
            .message {
              font-size: 16px;
              margin-bottom: 20px;
    
              &:nth-child(2) {
                margin: 0px;
                padding-left: 0px;
              }
            }
          }
          .msgSecondBlock {    
            .message {
              align-items: flex-start;
              margin: 40px 0px;
              padding-top: 10px;
              flex: 0 0 49%;
              font-size: 16px;
              margin: 20px 0px;
            }
          }
          .footerBlock {    
            .footerTitle {
              font-size: 32px;
              flex: 0 0 64%;
            }
            .footerLinkBlock {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              border: 2px solid #FFF;
              flex: 0 0 36%;
      
              .footerLinkMsg {
                font-size: 16px;
                padding: 15px 15px 0px 15px;
                margin-right: 0px;
              }
              .footerLink {
                width: 40px;
                height: 40px;
                margin: 15px 15px 15px 0px;

                .footerArrow {
                  width: 40px;
                  height: 40px;
                }
              }
            }
          }
        }
      }
      
      .mainrules {
        margin: 30px 15px 219px 15px;
        width: calc(100% - 30px);
      }

      .downloadlinks {
        margin: 15px;
        width: calc(100% - 30px);
      }

      .footer {
        margin: 30px 15px;
        width: calc(100% - 30px);
      }

      .another-bg {
        background: url('./images/background2.png');

        .landing__container {
          width: 100%;

          .benefits {
            .container {
              flex: 1 1 0;
              justify-content: center;

              .left {
                width: 48%;

                img {
                  width: 100%;
                }
              }

              .right {
                margin-left: 25px;
                width: 50%;

                ol {
                  li {
                    font-size: 16px;
                    line-height: 24px;

                    span {
                      font-size: 20px;
                      line-height: 30px;
                      margin-right: 10px;
                    }
                  }
                }
              }
            }
          }
        }

        .equipment__from {
          padding: 47px 3% 50px 3%;

          .container {
            width: calc(100% - 30px);
            max-width: 1026px;
          }

          p {
            margin-top: 15px;
          }
        }

        .customFadeInDown {
          margin-left: 15px;
          margin-right: 15px;
        }

        .landing__container {
          width: calc(100% - 30px);

          .alley-list {

            .country {
              margin-left: 0;
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}


.landing {

  @media screen and (max-width: 1300px) {

    .features,
    .application {
      .containerbox {
        padding: 30px 40px 0 40px;
      }
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1199px) {

    h2 {
      font-size: 40px;
      line-height: 52px;
      letter-spacing: 0.05em;
      margin-bottom: 10px;
    }

    h3 {
      font-size: 36px;
      line-height: 46px;
    }


    .links {
      display: grid;
      grid-template-columns: 1fr 1fr;
      row-gap: 10px;
      column-gap: 10px;
    }
    .landing__header__top > .links > * {
      margin-right: 0px !important;
    }
    .tournamentBadge {
      margin-top: -35px;
    }

    .features,
    .application {
      .containerbox {
        padding: 30px 20px 0 20px;

        .captions {
          .item {
            margin-bottom: 5px !important;

            .textwrap {
              .title {
                font-size: 20px;
                margin-bottom: 0 !important;
              }

              .text {
                font-size: 14px;
                flex-wrap: wrap;

                &>.btn:last-child {
                  margin-top: 10px;
                }
              }
            }

            .imgwrap {
              margin-right: 30px;
              margin-left: 20px;
              width: 24px;
              height: 24px;
              margin-top: 10px;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 767px) {

    h2 {
      font-size: 24px;
      line-height: 36px;
      letter-spacing: 0.05em;
      margin: 0;
    }

    h3 {
      font-size: 20px;
      line-height: 30px;
    }

    .tournamentBadge {
      margin-top: -13px;
    }

    .features,
    .application {
      margin-top: 50px !important;
      margin-bottom: 115px;

      .containerbox {
        width: 100%;
        padding-top: 30px;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .captions {
          display: none;
        }

        .slider,
        .mobile-captions {
          display: flex;
          flex: none;
          align-items: center;
          justify-content: center;
        }

        .mobile-captions {

          .item {
            position: relative;
            width: 100%;
            margin-bottom: 0 !important;

            .imgwrap {
              display: none;
            }

            .textwrap {
              flex-direction: column;
              text-align: center;

              .title {
                font-size: 18px;
                line-height: 24px;
                position: relative;
                display: inline-block;

                &>img {
                  position: absolute;
                  display: block;
                  height: 24px;
                  width: 24px;
                  object-fit: contain;
                  z-index: 2;
                  top: 0;
                  left: -34px;
                }

                &:not(:last-child) {
                  margin-bottom: 0;
                }

              }

              .text {
                display: block;
                margin-top: 13px;

                .btn {
                  margin-top: 13px;
                  font-size: 14px;
                }
              }

              .btn {
                width: 100% !important;
              }

            }

            &:not(.active) {
              display: none;
            }

            &:last-child {
              .btn {
                font-size: 12px !important;
                margin-right: 0px;
              }

              .btn:last-child {
                margin-right: 0px !important;
              }
            }
          }
        }

        .slider {
          display: flex;
          margin: 0;
          margin-top: 20px;

          .control {
            display: flex;
            max-width: 100%;
            justify-content: center;

            .left,
            .right {
              min-width: 50px;
              width: 23%;
              overflow: hidden;
              display: flex;
              align-items: center;
              cursor: pointer;
            }

            .center {
              flex: 1;
              display: flex;
              justify-content: center;

              span {
                font-family: Oswald;
                font-style: normal;
                font-weight: 300;
                font-size: 30px;
                line-height: 44px;
                display: flex;
                align-items: center;
                text-align: center;
                letter-spacing: 0.05em;
                color: #FFFFFF;

                &.numall {
                  color: #666;
                }
              }
            }

            .right {
              justify-content: flex-end;
            }
          }
        }

        .representation {
          height: 380px;
          margin-bottom: 20px;

          .frameimg {
            border-radius: 0;
          }

          .bubble {
            display: none;
          }
        }
      }
    }
    

    .features {
      margin-top: 0 !important;
    }
  }

  @media screen and (max-width: 900px) {
    .slider {
      margin-bottom: 80px;

      .containerbox {
        padding: 40px 20px 0 20px;

        .sliderbox {
          padding: 0 10px;

          .images {
            .elem {
              span {
                font-size: 24px;
              }

              video {
                border-radius: 5px;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 780px) {
    .mobileapp {
      margin-bottom: 0;
      
      .background {
        top: 0;
        // transition: none;
        opacity: 1;
        // display: none;

        &>div {
          max-width: 60%;
        }

        .circleone {
          // animation: none;
          filter: none !important;
        }
  
        .circletwo {
          // animation: none;
          filter: none !important;
        }
  
        .circlethree {
          // animation: none;
          filter: none !important;
        }
      }

      .phonebox {
        .pointers {
          display: none;
        }

        .mobile-pointers {
          display: block;

          .pointerBox {
            padding: 3px 3px 1px 3px;
            border-radius: 3px 3px 0px 0px;
            font-family: Oswald;
            font-style: normal;
            font-weight: 300;
            font-size: 14px !important;
            line-height: 18px;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            color: #FFFFFF;
            border-width: 1px;
          }

          .ponterLine {
            &.b {
              border-bottom-width: 1px;
            }

            &.l {
              border-left-width: 1px;
            }

            &.r {
              border-right-width: 1px;
            }

            &.t {
              border-top-width: 1px;
            }
          }

          .pointerDot {
            width: 4px;
            height: 4px;

            &::after {
              width: 4px;
              height: 4px;

            }
          }
        }

        img {
          max-width: 48.75vw;
        }
      }
    }
  }

  @media screen and (min-width: 768px) and (max-width: 950px) {
    .features {

      .containerbox {

        .representation {
          max-height: 510px;

          .overlay {

            .content {
              bottom: 17% !important;

              video {
                width: 120% !important;
              }

              img {
                visibility: hidden;
              }
            }
          }
        }
      }

    }
  }

  @media screen and (max-width: 767px) {

    .alley-list {

      .filters {

        .row {
          flex-direction: column;

          .ant-row {
            margin-right: 0;
            margin-top: 10px;

            .ant-select .ant-select-selection__rendered {
              line-height: 37px;
            }
          }
        }
      }
    }

    .slider {
      .containerbox {
        &>img {
          max-width: 50%;
        }

        .mobile-slider {
          position: absolute;
          display: flex;
          margin: 0;
          top: 116%;
          left: 0;
          justify-content: center;
          width: 100%;

          .control {
            display: flex;
            max-width: 100%;
            justify-content: center;

            .left,
            .right {
              min-width: 50px;
              width: 23%;
              overflow: hidden;
              display: flex;
              align-items: center;
              cursor: pointer;
            }

            .center {
              flex: 1;
              display: flex;
              justify-content: center;

              span {
                font-family: Oswald;
                font-style: normal;
                font-weight: 300;
                font-size: 30px;
                line-height: 44px;
                display: flex;
                align-items: center;
                text-align: center;
                letter-spacing: 0.05em;
                color: #FFFFFF;

                &.numall {
                  color: #666;
                }
              }
            }

            .right {
              justify-content: flex-end;
            }
          }
        }

        .sliderbox {
          .control {
            display: none;
          }

          .images {
            .elem {
              width: 63%;

              span {
                font-size: 20px;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    .header-left__container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-right: 20.5px;
    }

    .header-center__img__container {
      top: -12px;
    }

    .header-center__img__container {
      top: -12px;
    }
  
    .header-lang__container {
      margin-left: 0;
      margin-top: 10px;
      display: none;
    }

    .tournamentBody {
      margin: 50px 0 0 0;
      &>* {
        padding: 0 15px;
      }
      &>.tournament {
        margin: 0 0 20px 0;
        flex-direction: column-reverse;
        .description {
          font-size: 14px;
          line-height: normal;
        }
        .text {
          margin: 0;
        }
        .image {
          margin: 30px 0;
        }
      }
      .oswald_text {
        font-size: 20px;
        text-transform: uppercase;
        line-height: 30px;
      }
      .tournament-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        &>div {
          width: 360px;
          height: 40px;
        }
      }
    }
  }

}

@media screen and (max-width: 767px) {
  .landing-modal {

    .ant-modal-content {
      padding: 15px 15px 30px 15px;
    }

    .ant-modal-body {
      font-size: 16px;
      line-height: 26px;
      margin: 10px 0 20px 0;
    }

    .ant-modal-title {
      font-size: 24px;
      line-height: 30px;
      margin: 15px 0;
    }

    .ant-modal-footer {
      display: flex;
      justify-content: center;
      padding: 0;

      .btn {
        height: 40px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .CoookiePanel {
    // display: none;
    width: 100%;

    .PanelBody {
      width: 100% !important;
      padding: 45px 20px 20px 20px;

      .content {
        flex-direction: column;
        align-items: center;
  
        .text {
          text-align: center;
        }
  
        .cookiebutton {
          margin-top: 10px;
          width: 100%;
          padding: 5px 0;
          margin-left: 0;
        }
      }

    }
  }
}

// @media screen and (min-width: 768px) and (max-width: 950px)
@media screen and (min-width: 768px) and (max-width: 1080px) {
  .landing__header__top {
    // .header-center__img__container {
    //   top: -29px;
    // }
    .links {
      // height: 78px;  
      flex-wrap: wrap-reverse;
      font-size: 14px;
      justify-content: space-between;
      flex-direction: column;
      margin-right: 11px;
      width: 40%;
  
      &>.btn {
        height: 29px;
        margin-right: 0px;
        padding: 4px 0px;
        letter-spacing: 0.05em;
        font-size: 14px;
        margin-left: 0px;
      }
      &>.btn.install {
        // margin-bottom: 25px;
      }
  
      &>.sign-in {
        border-width: 1px;
        height: 30px !important;
        padding-left: 12px;
        padding-right: 13px;
        padding-top: 5px;
        padding-bottom: 4px;
        margin-left: 0px;
        letter-spacing: 0.05em;
        font-size: 14px;
      }
  
      &>.dropdown {
        height: 29px;
        padding: 4px;
        margin: 0px;
        letter-spacing: 0.05em;
        font-size: 14px;
      }
    }
  }
}