.badgeIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  &__counter {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 6px;
    left: 60%;
    padding: 0 5px;
  }
}