.dropDownContainer {
  display: flex;
  width: 250px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  .burgerMenu {
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 22px;
  height: 22px;

    .burgerPart {
      background-color: #F65932;
      width: 22px;
      height: 3px;
    }
  }
  .currentPage {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 8px;
    flex-basis: 62%;
    font-family: Oswald;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-transform: uppercase;
    color: #F65932;
    border-left: #25292C solid 1px;
  }
  .arrowContainer {
    display: flex;
    justify-content: center;
    width: 20px;
    height: 100%;
    border-right: #25292C solid 1px;
    .menuArrow {
      align-self: center;
      height: 9px;
      width: 16px;
    }
  }
}
.drop-menu {
  background-color: #0C131C;

  .ant-dropdown-menu-item {
    :hover{
      background-color: #0C131C;
    }

    .link-container {
      border-bottom: #25292C solid 1px;
      margin-left: -3px;
      margin-right: -3px;
      padding-left: 1px;

        .nav-link-text {
          font-family: Oswald;
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          display: flex;
          align-items: center;
          text-transform: uppercase;
          color: #6E7277;
        }

        .nav-link-text-active {
          font-family: Oswald;
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          display: flex;
          align-items: center;
          text-transform: uppercase;
          color: #F65932;
        }
    }
  }
  .ant-dropdown-menu-item:hover{
      background-color: #0C131C;
      .nav-link-text {
        font-size: 22px;
      }
    }
}
.ant-dropdown-menu {
  max-width: 204px;
  border: #25292C solid 1px;
  border-radius: 0;
  left: 44px;
  top: -4px;
  width: auto;
}