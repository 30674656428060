.textarea {
  background-color: transparent;
  width: 100%;
  padding: 5px 10px;
  border: 1px solid rgba(255,255,255,0.4);
  box-sizing: border-box;
  color: #fff;
  font-size: 14px;
  font-weight: 300;
  resize: none;
}
