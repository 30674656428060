.user-menu-tabs, .accountPage__sidebar, .aboutPage__sidebar {
  display: flex;
  height: 100%;
  position: relative;
  border-left: 1px solid #fff3;
  box-sizing: border-box;
  .menu-header {
    width: 100%;
    font-size: 20px;
    height: 50px;
    margin-top: -1px;
    box-sizing: border-box;
    line-height: normal;
    background-color: #414d5c;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #626567;
    border-bottom: 1px solid #626567;
    position: relative;
    font-family: 'Oswald';
    font-weight: 500;
    text-transform: uppercase;
  }
}

.tournament-table-wrapper {
  overflow: auto;
  flex: 1;
  background: linear-gradient(to top, #0c131c, #0d141d);
  height: calc(100% - 46px);
  .infoPin {
    color: #fff;
    border-radius: 2px;
    padding: 4px 4.5px;
    font-size: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #607083;
    font-weight: bold;
    display: inline-flex;
    white-space: normal;
    margin-right: 10px;
    margin-left: 0;
    &:hover {
      background-color: #626567;
    }
  }

  button {
    padding-top: 6px;
    padding-bottom: 6px;
    &:not(:last-child) {
      margin-right: 10px;
    }
    &:hover {
      svg, path {
        fill: #fff;
      }
    }
  }
  th {
    white-space: pre-line;
    line-height: 19px;
  }
  td:first-child, th:first-child {
    position: relative;
    padding-left: 20px;
  }
}

.createTourney__button {
  width: auto;
  height: 30px;
  font-size: 14px;
  margin-left: auto;
  margin-right: 20px;
  min-width: 173px;
  padding: 0;
  display: flex;
}

.react-tabs__tab-panel {
  overflow: auto;
}

.description {
  margin-bottom: 20px;
  margin-top: 15px;
}

.descriptionBody {
  margin-top: 8px;
  color: #fff;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
}

.status-soonStarted {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
}

.status-continious {
  background-color: #D80027;
  padding: 0px 4px;
  border-radius: 2px;
  display: inline-block;
  text-align: center;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
}

.status-finished {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  color: rgba(255, 255, 255, 0.6);
}

.date {
  min-width: 60px;
}

.tournament-tab__title {

}

.tournamentTable {
  .react-tabs__tab {
    color: #FFFFFF99 !important;

    &.react-tabs__tab--selected  {
      color: #f65932 !important;
    }
  }
}