.chat-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  .menu-header {
    height: 27px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    background-color: rgb(52, 61, 73);
  }
}
.chatVC.fullHeight {
  max-height: 100%;
}
.chat {
  font-family: 'Open Sans';
  font-weight: 100;
  font-size: 12px;
  left: 0;
  right: 0;
  // top: 0;
  bottom: 0;
  position: absolute;
  background: #0d141d;
  background-position: 95% 100%;
  background-repeat: no-repeat;
}
.current-game .right.lowwidth .chatVC{
  max-height: calc(100% - 35px);
}

.chat-container .header {
  display: flex;
  justify-content: center;
}

.chatVC{
  height: 100%;
  position: absolute !important;
  bottom: 0;
  max-height: calc(100% - 27px);
  font-weight: 100;
  font-size: 14px;
  font-family: 'Open Sans';
  .socket-info {
    width: 300px;
  }
  .user-list {
    margin: 10px;
  }
  .user-list > [class^='room-user-'] > [class^='room-user__'] {
    padding: 5px;
  }
  .room-info, .room-info > * {
    margin: 10px;
  }
  .chat-room {
    height: 100%;
    .chat-room__center {
      height: 100%;
    }
  }
  [class^='room-info__'] {
    padding: 5px;
  }
  .room-admin-api__adduser, .room-admin-api__removeuser, .common-api-item, .message-input {
    display: flex;
  }

  .chat-message {
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin: 0 5px 7px 15px;
    .chat-message__wrapper {
      word-wrap: break-word;
      width: calc(100% - 45px);
      padding-left: 10px;
      span.chat-message__author {
        margin-right: 3px;
      }
    }
  }

  .message-input__text {
    height: 100%;
    box-sizing: border-box;
    width: 100%;
  }
  .message-input__submit {
    // width: 95px;
    background-color: #20a39e;
    border: none;
    color: #fff;
  }
  .message-input .filter-input {
    width: 100%;
  }
  .message-input {
    height: 30px;
    margin-right: 2px;
    padding: 10px 15px;
    border-top: 1px solid #343d49;
  }
  .message-list {
    overflow-y: auto;
    height: calc(100% - 50px);
    padding-left: 0;
    margin: 0;
  }

  .chat-room__right {
    display: none;
  }

  [class*='__select'] {
    width: 100px;
  }

  .chat-message__date {
    color: #fff;
  }

  .chat-message__author {
    color: #20A39E;
  }

  .chat-message.chat-message__system {
    .chat-message-date,
    .chat-message__author,
    .chat-message__text,
    .chat-message__comment_label {
      opacity: 0.6;
    }
  }

  .chat-message:not(.chat-message__system) {
    font-weight: bold;
    .chat-message__date {
      color: rgba(255, 255, 255, 0.6);
      font-weight: normal;
    }
  }

  .chat-message.me {
    .chat-message__author {
      color: #F65932;
    }
  }

  .chat-message-date {

    .chat-message__date-pointer {
      display: block;
      position: relative;
      line-height: 20px;
      background: rgba(255,255,255,.1);
      padding: 0 5px;
      border-radius: 54px;
      cursor: pointer;
    }
  
    &:hover {
      opacity: 1 !important;

      .chat-message__date-pointer {
        background: #F65932;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: -14px;
          width: 14px;
          height: 20px;
          background: #f65932;
        }

        &::before {
          content: "";
          position: absolute;
          top: 6px;
          left: -8px;
          width: 0px;
          height: 0;
          border-color: transparent transparent transparent #fff;
          border-style: solid;
          border-width: 4px 0 4px 6px;
          z-index: 1;
        }
      }
    }
  }

  .chat-message__text {
    color: #fff;
    line-height: 1.5em;
  }
  
  .chat-message__comment {
    font-weight: bold;
  }

  .chat-message__wrapper:not(.chat-message__system) {
    .chat-message__author {
      font-weight: bold;
    }
  }
}

.chatbody {
  width: 100%;
  position: relative;
  & > button {
    position: absolute;
    z-index: 5;
    top: 35px;
    right: 5px;
    width: auto;
    height: auto;
    padding: 3px;
    font-size: 10px;
  }
}

.user-menu-tabs {
  .chat-container {
    .header {
      height: 26px;
      background-color: #343d49;
      align-items: center;
    }
  }
}