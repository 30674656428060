.player-search {
  padding-top: 10px;
}

.player-search .buttonaddfriend__container {
  margin-left: 10px;
}

.friends__table-loading {
  margin-top: 100px;
}

.friends__table-notfind {
  margin-top: 100px;
  display: flex;
  align-self: center;
  justify-content: center;
}

.notfind_container {
  width: 250px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.notfind__img {
  width: 200px;
}

.notfind__text {
  text-align: center;
  margin-top: 10px;
}
