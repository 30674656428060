.battle_info_panel {
  display: flex;
  flex-direction: row;
  height: 100%;
  flex: 1;
  padding-left: 45px;
  align-items: center;

  &__create_button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #22ab00;
    padding: 10px 22px;
    height: 78px;
    box-sizing: border-box;
    cursor: pointer;

    &:hover {
      background: #008E06;
    }

    &__icon {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-bottom: 6px;

      svg {
        width: 32px;
        height: 32px;
      }
    }

    &__text {
      font-family: 'Oswald';
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.05em;
      color: #FFFFFF;
    }
  }

  &__aside {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    height: 78px;
    margin-left: 10px;
  }

  &__button {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 185px;
    height: 36px;
    font-family: Oswald;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 14px;
    letter-spacing: 0.05em;
    color: #FFFFFF;
    box-sizing: border-box;
    cursor: pointer;

    &.pending_battles {
      background: #F65932;

      &:hover {
        background: #FF3E18;
      }
    }

    &.play_with_friend {
      border: 1px solid #F65932;

      &:hover {
        background: #F65932;

        svg path {
          fill: #FFFFFF;
        }
      }
    }
  }

  &__button_icon {
    min-width: 44px;
    text-align: center;
    box-sizing: border-box;
    padding: 0 12px;
  }

  &__button_text {
    text-align: left;
  }

}