.creditcard_picker {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  & > * {
    flex: 0 0 100%;
  }

  .paymentMethodSelector {
    margin-bottom: 15px;
  }

  .title {
    margin: 0 0 15px 0;
    letter-spacing: 0.05em;
    font-family: 'Oswald';
    font-size: 20px;
  }

  .ant-radio-group {
    width: 100%;
  }

  .loader {
    position: absolute;
    background: #0c131c;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .creditcard_item {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    width: 100%;
    & > label {
      flex: 1;
    }
    &:not(.active) > label {
      opacity: 0.6;
    }
    & > a {
      flex: 0 0 auto;
      display: flex;
    }
    .ant-radio-wrapper {
      margin-bottom: 0;
    }
  }

  .addcard {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    & .big {
      font-size: 35px;
      line-height: 14px;
      display: block;
      margin: 0 10px 0 2px;
    }
  }
}

.confirmation-modal-content {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50px;
}
.IBANmodal {
  display: flex;
  flex-wrap: wrap;
  // border-bottom: 1px solid #25292C;
  label {
    color: #fff;
    margin-bottom: 5px;
    font-size: 14px;
    display: block;
  }
  .date-group__elements__picker {
    padding-left: 0 !important;
  }
  .IBANinput,
  p {
    flex: 0 0 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    color: rgba(255, 255, 255, 0.8);
    &.double {
      flex-direction: row;
    }
    input {
      width: 100%;
      box-sizing: border-box;
    }
    .ant-calendar-picker-input,
    .filter-input__data {
      height: 30px;
    }
    .piece {
      flex: 1 1 50%;
      &:not(:last-child) {
        margin-right: 11px;
      }
    }
  }
  .error {
    font-size: 14px;
    font-weight: 300;
    color: #fff;
    min-height: 36px;
    line-height: 36px;
    padding-left: 8px;
    padding-right: 8px;
    vertical-align: middle;
    background-color: rgba(246, 89, 50, 0.2);
  }
}
