.refereeing_table {
  position: absolute;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  &__container {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 100%;
    flex: 1;
    overflow: hidden;
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;

    .refereeing_table_filter_container {
      display: flex;
      align-items: center;
      padding-bottom: 10px;
      border-bottom: 1px solid rgba(100, 100, 100, 0.3);
      flex-wrap: wrap;
      flex: 0 0 auto;
      padding-top: 6px;
    }
  
    .refereeing_table_type_container,
    .refereeing_table_status_container,
    .refereeing_table_reset_container {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: 20px;
    }
  
    .refereeing_table_status_label,
    .refereeing_table_type_label {
      opacity: 0.4;
      font-size: 14px;
      font-weight: 300;
      letter-spacing: 0.5px;
    }
  
    .refereeing_table_type_select_container {
      margin-left: 10px;
      width: 150px;
    }
  
    .refereeing_table_status_select_container {
      margin-left: 10px;
      width: 220px;
    }
  
    .refereeing_table_reset_container {
      font-size: 14px;
      font-weight: 300;
      letter-spacing: 0.5px;
    }
  
    .refereeing_table_table {
      flex: 1;
      overflow: auto;
  
      .ant-table-thead tr th {
        padding: 10px;
      }
  
      .refereeing_table_table_row td {
        padding: 10px;
        line-height: 19px;
    
        .refereeing_table_expired,
        .refereeing_table_closed {
          opacity: 0.5;
        }
  
        .refereeing_table_resolved {
          display: flex;
          flex-direction: row;
          align-items: center;
          
          &__icon {
            width: 30px;
            height: 30px;
            border: 1px solid #20A39E;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row;
  
            &>svg>path {
              fill: #20A39E;
            }
  
            &:hover {
              background: #20A39E;
  
              &>svg>path {
                fill: white;
              }
            }
          }
          
          &__link {
            margin-left: 10px;
            opacity: 0.5;
          }
        }
      }
    }
  }

  &__aside {
    display: flex;
    flex-direction: column;
    width: 25vw;
    min-width: 281px;

    &__body {
      flex: 1;
      overflow: auto;
      background: #25292C;

      .menu-header {
        width: 100%;
        font-size: 20px;
        height: 50px;
        margin-top: -1px;
        box-sizing: border-box;
        line-height: normal;
        background-color: #414d5c;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top: 1px solid #626567;
        border-bottom: 1px solid #626567;
        position: relative;
        font-family: Oswald;
        font-weight: 500;
        text-transform: uppercase;
      }
    }

    &__items {
      display: flex;
      flex-direction: row;
    }

    &__item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1;
      border-left: 1px solid #25292C;
      box-sizing: border-box;
      cursor: pointer;

      svg path {
        fill: #6C7076;
      }

      &.active {
        background: #25292C;

        svg path {
          fill: #F65932;
        }
      }
    }

    &__footer {

    }
  }
}

.refereeing-usermenu {

  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .refereeing-usermenu-header {
    width: 100%;
    line-height: 48px;
    background: #414d5c;
    font-family: Oswald;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
  }

  .refereeing-usermenu-filter {
    width: 100%;
    display: flex;
    padding: 10px 15px 13px;
    box-sizing: border-box;
    align-items: center;
    background: #414C59;
    margin-top: 1px;

    .refereeing-usermenu-filter-label {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #fff;
      // opacity: .4;
      margin-right: 18px;
    }

    .refereeing-usermenu-filter-select {
      width: 100%;
    }
  }

  .refereeing-usermenu-block {
    background: #343D49;
    width: 100%;
    margin: 10px 0;
    padding: 0 15px 15px;
    box-sizing: border-box;

    .refereeing-usermenu-block-row {
      display: flex;
      padding-top: 15px;

      .refereeing-usermenu-block-column {
        width: 50%;

        .refereeing-usermenu-block-column-label {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 16px;
          color: #FFFFFF;
          opacity: 0.6;
        }

        .refereeing-usermenu-block-column-value {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 19px;
          color: #FFFFFF;
        }

      }

    }


  }

}