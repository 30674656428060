.protest_list_modal {
  .modal-group {
    padding: 0
  }

  .protest_list_modal_table {

    .ant-table-thead tr th,
    .protest_list_modal_table_row td {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 200;
      font-size: 14px;
      line-height: 19px;
      color: #FFFFFF;
    }

    .ant-table-thead tr th {
      padding: 10px;
    }

    .protest_list_modal_table_row {
      &>td {
        padding: 14px 10px 13px 10px;

        &:last-child {
          padding-right: 20px;
        }

        .protest_list_modal_status {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .protest_list_modal_edit {

            margin-left: 10px;

            .svg-button {
              width: 30px;
              height: 30px;
              border: 1px solid #20A39E;
  
              &:hover {
                background: #20a39e;
  
                & > svg > path {
                  fill: #ffffff;
                }
              }
            }
          }
        }
      }
    }
  }

  // .ant-modal-footer {

  //   .button {
  //     width: 172px;
  //   }
  // }
}