.referral_table_container {
  border-collapse: collapse;
  width: 100vw;
}

.referral-table {
  & th, td {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    color: #FFFFFF;
  }
  & td {
    height: 67px;
    font-size: 16px;
    line-height: 22px;
  }
  & th {
    height: 30px;
    background: linear-gradient(89.91deg, #0C131C 0%, #0D141D 100%);
    font-size: 14px;
    line-height: 19px;
  }

  & th:first-child, td:first-child{
    padding-left: 20px;
  }

  & tr:nth-child(odd) {
    background-color: #111721;
  }
  & tr:nth-child(even) {
    background-color: #252B32;
  }
  &__text-td, &__date-td, &__check-td, &__header-container, &__login-td {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  &__login-td {
    & img {
      width: 51px;
      height: 51px;
      border-radius: 60px;
      margin-right: 10px;
    }
    & span {
      color: #F65932;
      font-size: 14px;
      line-height: 19px;
    }
  }


  &__header-filter {
    margin-right: 2px;
    & svg {
      width: 12.5px;
      height: 11.19px;
    }
  }
  &__header-container {
    border-right: 1px solid #25292C;
    align-items: center;
    & span {
      margin-right: 4px;
    }
  }
  &__radio-container {
    height: 70px;
    & .ant-radio-button-wrapper {
      flex: 1;
      height: 48px;
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      border-radius: 5px;
      border-width: 0px;
      margin-right: 15px;
      margin-left: 15px;
      background-color: #2f1e1f;
      color: #9ea1a4;
      line-height: 48px;
      text-align: center;
    }
    & .ant-radio-button-wrapper-checked {
      background: #F65932;
      box-shadow: none;
      color: white;
      opacity: 1;
    }

    & .ant-radio-button-wrapper-disabled {
      background: #f5f5f5;
      color: black;
    }

    & .ant-radio-button-wrapper:not(:first-child)::before {
      background-color: transparent;
    }
  }
  &__radio {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }

  &__header-sort {
    width: 14px;
    height: 18px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
  }
  &__sort-up {
    position: absolute;
    transform: rotate(180deg);
    top: 0;
  }
  &__sort-down {
    position: absolute;
    bottom: 0;
  }
  &__arrow-container {
    height: 9px;
  }
}