.select-block {
  display: block;
  width: 100%;
}
.select-block.open {
  position: relative;
}
.select-block-group {
  position: relative;
  padding: 10px 7px;
  box-sizing: border-box;
  background-color: transparent;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
}
.select-block-group::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.select-block-select .select-block-group {
  position: relative;
  padding-right: 30px;
  border: 1px solid rgba(247, 236, 236, 0.4);
}
.select-block-select .select-block-group::before {
  content: '▼';
  position: absolute;
  top: 50%;
  right: 7px;
  transform: translateY(-50%);
  transition: color .3s ease;
}
.select-block-select .select-block-group:hover:before,
.select-block-select:focus .select-block-group:before {
  color: #fff;
}
.select-block-select .select-block-group:hover,
.select-block-select:focus .select-block-group {
  border-color: #fff;
}
.select-block-list {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #303c47;
  overflow: auto;
  z-index: 3;
}
.select-block-list.top {
  top: auto;
  bottom: 100%;
  transform: translateY(-1px);
}
.select-block.open .select-block-list {
  display: block;
}
.select-block.open .select-block-select {
  pointer-events: none;
}
.select-block-list .select-block-group {
  position: relative;
  border-bottom: 1px solid #252b32;
  background-color: transparent;
  transition: background .3s ease;
}
.select-block-list .select-block-group:last-child {
  border-bottom: none;
}
.select-block-list .select-block-group:hover {
  background-color: #245058;
}
.select-block-list .select-block-group.move {
  background-color: #245058;
}