.tablet__page {
  //min-width: 1920px;
  max-width: 1800px;
  width: 100%;
  margin: 0 auto 0 auto;
  padding-top: 65px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  max-height: 1080px;
  .avatar.tabled {
    width: 45px;
    height: 45px;
    img {
      width: 45px;
      height: 45px;
    }
  }
  .tablet__left_section {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 1410px;
    min-width: 1410px;
    .tablet__header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 52px;
      h1 {
        text-transform: uppercase;
        font-size: 70px;
        line-height: 104px;
        letter-spacing: 3px;
        font-weight: normal;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 1050px;
        text-align: center;
        min-width: 1050px;
      }
    }
    .tablet__content {
      flex: 1;
      position: relative;
      display: flex;
      max-height: 900px;
      .tablet__table {
        display: flex;
        flex: 1;
        flex-direction: column;
        max-height: 900px;
        overflow: hidden;
        .tablet__table_row {
          display: flex;
          flex-direction: row;
          flex: 1;
          min-height: 66px;
          max-height: 66px;
          .tablet__table_col {
            display: flex;
            align-items: center;
            justify-content: center;
            flex:1;
            border: 1px #25292C solid;
            p {
              color: #D0D2D5;
              font-size: 24px;
              line-height: 35px;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
          }
          .cup {
            position: relative;
            max-width: 80px;
            .position_absolute {
              position: absolute;
              top: 13px;
              left: 0px;
            }
            p {
              color: black;
              z-index: 2;
              font-size: 25px;
              line-height: 34px;
            }
          }
          .people {
            max-width: 315px;
            min-width: 313px;
            justify-content: flex-start;
            padding: 10px 41px 10px 20px;
            .username__name_container {
              max-width: 280px;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          .flag {
            max-width: 320px;
            min-width: 320px;
            display: flex;
            justify-content: flex-start;
            flex-direction: row;
            padding-right: 10px;
            p {
              color: #D0D2D5;
              font-size: 25px;
              line-height: 34px;
            }
            .flag_round_container {
              width: 30px;
              height: 30px;
              border-radius: 15px;
              overflow: hidden;
              margin-left: 20px;
              margin-right: 10px;
              span {
                width: 30px;
                height: 30px;
              }
            }
          }
          .geo {
            max-width: 249px;
            min-width: 249px;
            justify-content: flex-start;
            padding: 10px 20px;
          }
          .total {
            max-width: 105px;
          }
          .avg {
            max-width: 105px;
          }
          .cut {
            max-width: 105px;
          }
        }
        .row_data {
          .tablet__table_col .people {
            justify-content: flex-start;
          }
        }
        .first_row {
          max-height: 52px;
          min-height: 52px;
          .tablet__table_col {
            justify-content: center;
          }
          .cup {
            border-radius: 15px 0 0 0;
          }
          .cut {
            border-radius:  0 15px 0 0;
          }
        }
        .last_row {
          .cup {
            border-radius: 0 0 0 15px;
          }
          .cut {
            border-radius:  0 0 15px 0;
          }
        }
        .prize_row {
          background-color: #1C6162;
        }
      }
      .tablet__gradient{
        width: 100%;
        background: linear-gradient(180deg, rgba(11, 18, 28, 0) -12.47%, rgba(11, 18, 28, 0.9) 55.38%, #0B121C 95.54%);
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 0;
        z-index: 15;
        height: 200px;
        p {
          font-family: 'Oswald';
          font-size: 40px;
          line-height: 60px;
          letter-spacing: 0.05em;
          text-transform: uppercase;
          margin-top: auto;
          margin-bottom: 0;
        }
      }
    }
  }
  .tablet__right_section {
    padding-left: 80px;
    //max-width: 450px;
    min-height: 1080px;
    flex:1;
    display: flex;
    flex-direction: column;
    .tablet__info_section {
      margin-bottom: 50px;
      width: 300px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      .tablet__info_image {
        display: flex;
        justify-content: center;
        align-items: center;
        background: linear-gradient(180deg, #F65932 0%, rgba(246, 89, 50, 0.2) 45.84%, rgba(246, 89, 50, 0) 100%);
        width: 110px;
        height: 110px;
        border-radius: 55px;
        margin-right: 20px;
        img {
          width: 45px;
          height: 45px;
          object-fit: contain;
        }
      }
      .tablet__info_text {
        p {
          font-family: "Oswald";
          font-size: 40px;
          line-height: 60px;
          margin: 0;
          padding: 0;
          font-weight: 400;
        }
      }
    }
    .tablet__qr_section {
      img {
        width: 200px;
        height: 200px;
        object-fit: contain;
      }
    }
  }
}