.subscribers {
    display: flex;
    width: 100%;
    &__btn {
        height: 100%;
        padding: unset;
        width: 100px;
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        text-align: center;
        
        &_margin {
            margin-right: 10px;
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;
        padding: 10px 10px;
        &__date-container {
            display: flex;
            align-items: center;
            &_text {
                font-family: "Open Sans";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 19px;

                margin-right: 10px;
            }
            &_date {
                margin-right: 10px;
            }
        }
        &__total {
            margin-right: 10px;
            & span {
                color: #20a39e;
            }
        }
    }
}
