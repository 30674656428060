.navMenuContainer {
  display: flex;
  width: 100%;
  padding-left: 23px;
  border-top: #25292C solid 1px;
  border-bottom: #25292C solid 1px;
}
.preloader {
  position: relative;
  left: 50%;
}
.admin_panelWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  .ant-table-wrapper {
    border-top: 1px solid #222;
    border-bottom: 1px solid #222;
    max-width: 100%;
    overflow: auto;
  }
  .filters {
    display: flex;
    width: 100%;
    margin-bottom: 5px;
    & > div {
      //flex: 25%;
      padding: 0 20px;
    }
    & > div:not(:last-child) {
      margin-right: 15px;
    }
    & > div:nth-last-child(1), & > div:nth-last-child(2), & > div:nth-last-child(3) {
      align-self: flex-end;
    }
    .excel {
      display: flex;
      padding: 3px 20px;
      align-items: center;
      border: 1px solid white;
      border-radius: 4px;
      transition: all .3s ease;
      cursor: pointer;
      & p {
        margin: 0 0 0 4px;
        font-size: 12px;
        line-height: 20px;
      }
      & svg {
        transition: fill .3s ease;
      }
      &:hover {
        border-color: #f65932;
        color: #f65932;
        & svg {
          fill: #f65932;
        }
      }
    }
  }
  .battlelist {
    .watch_button {
      padding: 0;
      height: 35px;
    }
  }
  .admin_accountTables {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 15px;
    max-width: 550px;
    width: 100%;
    .header {
      display: flex;
      margin-bottom: 10px;
      border-bottom: 1px solid #fff4;
      .login {
        flex: 0 0 75px;
      }
      .accs {
        flex: 1;
        display: flex;
        & > * {
          display: flex;
          justify-content: flex-end;
          flex: 33%;
        }
      }
    }
    .userwrap {
      margin-bottom: 10px;
      border-bottom: 1px solid #fff4;
      display: flex;
      & > span {
        flex: 0 0 75px;
        color: #ff3e18;
      }
      & > .accwrap {
        flex: 1;
        display: flex;
        flex-direction: column;
        .singleaccount {
          display: flex;
          border-bottom: 1px solid #fff2;
          & > div {
            flex: 0 0 33%;
            justify-content: flex-end;
            display: flex;
          }
          & > div:not(:last-child) {
            border-right: 1px solid #fff2;
          }
        }
      }
    }
  }
  .admin_createTransaction {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 15px;
    max-width: 550px;
    width: 100%;
    & > * {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 15px;
      & > *:first-child {
        margin-right: 15px;
      }
    }
    .user-wrap {
      & > *:first-child {
        flex: 0 0 150px;
      }
      & > *:last-child {
        flex: 1;
      }
    }
    .currency-wrap {
      & > *:first-child {
        flex: 1;
      }
      & > *:last-child {
        flex: 0 0 150px;
      }
    }
    .suffix-wrap {
      & > *:first-child {
        flex: 1;
      }
      & > *:last-child {
        flex: 0 0 150px;
      }
    }
  }
}

.admin_paymentHistory {
  .actions {
    display: flex;
    flex-direction: column;
  }
  .action {
    display: flex;
    .id { flex: 0 0 15% }
    .type { flex: 0 0 15% }
    .backlog { flex: 0 0 55% }
    .amount { flex: 0 0 15% }
  }
  .user-wrap, .wallet-wrap {
    padding: 10px 20px;
    display: flex;
    align-items: flex-end;
    & > * {
      flex: 1;
      &:not(:last-child) {
        margin-right: 15px;
      }
    }
  }
}

.admin_panelWrapper .userlist {
  * { box-sizing: border-box; }
  table {
    tr:nth-child(odd) {
      td:not(:last-child) {
        border-right: 1px solid #090f1a;
      }
    }
    tr:nth-child(even) {
      td:not(:last-child) {
        border-right: 1px solid #252c32;
      }
    }
  }
  .input__data {
    border-bottom: none;
    cursor: pointer;
    text-overflow: ellipsis;
    &:focus, &:hover {
      border-bottom: 1px solid #fff6;
    }
  }
  .input__tip {
    display: none !important;
  }
  .loginWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .photo {
    position: relative;
    width: 47px;
    margin-right: 10px;
    flex: 0 0 auto;
    &.active::before {
      box-sizing: border-box;
      content: '';
      right: 0;
      bottom: 0;
      border: 2px solid #0c131c;
      background: #35c844;
      width: 12px;
      height: 12px;
      border-radius: 6px;
      position: absolute;
    }
    img {
      height: 42px;
      width: 42px;
      border-radius: 21px;
    }
  }
  .user_login {
    color: #f65932;
    white-space: nowrap;
    flex: 0 0 auto;
  }
  .user_info {
    padding: 5px 0;
    min-width: 150px;
    label:not(.checkbox) {
      color: hsla(0,0%,100%,.4);
      font-size: 12px;
      display: block;
      padding-top: 2px;
      min-height: 16px;
    }
    .infoblock {
      position: relative;
      &.checked {
        display: flex;
        flex-direction: row;
        .input {
          flex: 1 1 100%;
        }
        .check {
          flex: 0 0 auto;
          margin-left: 5px;
        }
      }
    }
    .infoblock:not(:last-child),
    .email:not(:last-child),
    .phone:not(:last-child),
    .user_name:not(:last-child),
    .user_login:not(:last-child) {
      margin-bottom: 10px;
    }
    .date-user_info {
      input {
        width: 150px;
        height: 30px;
        border: none;
        border-bottom: 1px solid #fff6;
      }
    }
    .user_name {
      white-space: nowrap;
      .input:not(:last-child) {
        margin-bottom: 10px;
      }
    }
    .email, .phone {
      white-space: nowrap;
    }
    .check {
      width: 16px;
    }
  }
  .checkbox img {
    height: 100%;
    width: 100%;
  }
}

.admin-panel__admin-size {
  padding-top: 10px;
  padding-left: 20px;
  padding-bottom: 10px;
  width: calc(100% - 20px);
}
.admin-panel__admin-size-p {
  margin: 0;
  font-size: 14px;
  line-height: 19px;
  color: #fff;
  text-align: left;
  opacity: .8;
}

.adminTabs {
  border-bottom: 1px solid #25292c;
  margin: 0 0 10px;
  padding: 0;
  display: flex;
  .adminTab {
    font-size: 20px;
    font-family: Oswald;
    color: #6d7177;
    max-width: 250px;
    flex: 1 1;
    display: inline-block;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 12px 0 10px;
    cursor: pointer;
    text-transform: uppercase;
    text-align: center;
    &.selected {
      color: #f65932;
      border: none;
      border-bottom: 3px solid #f65932;
    }
  }
}

.paymentdiff.table {
  .ant-table-row, .ant-table-thead {
    td:not(:first-child), th:not(:first-child) {
      text-align: right,
    }
  }
}

.admin_panelWrapper {
  .systemAccountsWrapper {
    .table {
      box-sizing: border-box;
      border: 1px solid #fff4;
      border-bottom: none;
    }
  }
  .TransferForm {
    padding: 15px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .input {
      flex: 0 0 100%;
      margin-bottom: 15px;
    }
    .account__container {
      margin-bottom: 15px;
      flex: 0 0 calc(50% - 7.5px);
    }
    .submit {
      padding: 10px;
    }
  }
}

.adminTableHeader {
  display: flex;
  flex-direction: row;
  .searchField {
    flex: 0 0 auto;
    margin-right: 5px;
    opacity: 0.4;
    &.active {
      opacity: 1;
    }
  }
  .filterBody {
    display: flex;
    flex: 1 1 100%;
    .filterState {
      flex: 0 0 auto;
      margin-left: 5px;
    }
    span {
      flex: 1 1 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .filterState {
    & > div {
      display: inline-block;
      width: 0;
      height: 0;
      border-style: solid;
    }
    .up {
      border-width: 0 10px 10px 10px;
      border-color: transparent transparent #f65932 transparent;
    }
    .down {
      border-width: 10px 10px 0 10px;
      border-color: #f65932 transparent transparent transparent;
    }
  }
}

.IBANbutton {
  padding: 0;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  min-width: 150px;
}
.requestRow {
  min-height: 50px;
}

.docs_viewer {
  .button {
    display: flex;
  }
  .ant-modal-content {
    width: auto;
    height: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    max-height: calc(var(--vh, 1vh) * 100 - 30px);
    .ant-modal-header, .ant-modal-footer {
      flex: 0 0 auto;
    }
    .ant-modal-body {
      flex: 1 1 auto;
      overflow: auto;
      min-height: 50px;
      padding-bottom: 3px;
    }
  }
  span {
    font-family: Oswald;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    color: #FFFFFF;
  }
  .docs_data {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    margin-bottom: 30px;
  }
  .docs_viewer_images {
    flex-direction: row;
    display: flex;
    margin-top: 10px;
    margin-bottom: 30px;
    .zoomWrap {
      overflow: hidden;
      flex: 1 1 100%;
      position: relative;
      max-width: 343px;
      max-height: 350px;
      img {
        height: 200%;
        width: 200%;
        left: 0;
        top: 0;
      }
    }
    .docs_viewer_images__preview {
      height: 350px;
      flex: 0 0 391px;
      margin-right: 5px;
      .docs_viewer_images__preview_container {
        width: 391px;
        height: 241px;
        background: #242B33;
        border-radius: 5px;
        align-items: center;
        justify-content: center;
        margin-bottom: 15px;
        position: relative;
        display: flex;
        flex: 0 0 auto;

        img {
          object-fit: contain;
          max-width: 389px;
          max-height: 239px;
          margin: 0;
          padding: 0;
          border-radius: 0;
        }
        canvas {
          position: absolute;
          // left: 0; right: 0;
          // top: 0; bottom: 0;
          // object-fit: contain;
          // max-width: 391px;
          // max-height: 241px;
        }
      }
      
      img {
        width: 94px;
        height: 94px;
        border: 1px solid #25292C;
        border-radius: 5px;
        margin-right: 2px;
      }
      :last-child {
        margin-right: 0;
      }
    }
    .docs_viewer_images__zoomed {
        width: 343px;
        height: 350px;
        img {
          object-fit: contain;
          width: auto;
          height: auto;
          max-width: 343px;
          max-height: 350px;
          border: 1px solid #25292C;
          border-radius: 5px;
          margin-right: 5px;
        }
    }
  }
  .docs_viewer_comment {
    width: 739px;
    background: rgba(246, 89, 50, 0.1);
    border: 1px solid #F65932;
    box-sizing: border-box;
    border-radius: 3px;
    padding: 10px;
    margin-top: 5px;
    .docs_viewer_comment_header {
      flex-direction: row;
      display: flex;
      justify-content: space-between;

      span {
        font-family: 'Oswald';
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        letter-spacing: 0.05em;
        color: #FFFFFF;
        margin: 0 0 5px 0;
        padding: 0;
      }
    }
    & > *:last-child {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      color: #FFFFFF;
      margin-top: 5px;
    }
  }

  .docs_viewer_comment_write {
    margin-top: 15px;
    span {
      font-family: 'Oswald';
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      letter-spacing: 0.05em;
      color: #FFFFFF;
    }
  }
}

.docs_viewer_block {
    display: block;
    flex: "0 0 50%";
    margin: 0 5px 15px 0;
  span {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #CED0D2;
  }
}

.docs_viewer_field {
    margin-top: 5px;
    width: 365px;
    height: 30px;
    border: 1px solid #242A32;
    box-sizing: border-box;
    padding: 5px;
    span {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      color: #FFFFFF;
    }
}

.ant-radio-wrapper {
  height: 22px;
}

.ap-tab-container,
.ap-tab-container-550 {
  padding: 15px;
  width: calc(100% - 30px);
  & hr {
    margin-top: 10px;
    margin-bottom: 10px;
    border-color: #6d7177;
  }
}
.ap-tab-container-550 {
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;
}
.ap-row {
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 15px;
}
.ap-col {
  margin-left: 15px;
  margin-right: 15px;
  width: 100%;
  & .date-group__elements__picker {
    padding: 0;
    width: calc(100% - 22px);
  }
  & .checkbox {
    display: inline-flex;
  }
}
.ap-input__col {
  display: flex;
  flex-direction: column;
}
.ap-input__label {
  margin-bottom: 12px;
  color: #6d7177;
}
.ap-input__elements {
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
  align-items: flex-end;
  flex-wrap: wrap;
  & .ant-radio-group {
    display: flex;
    margin-right: -10px;
  }
  & .ant-radio-wrapper {
    margin-bottom: 0;
    margin-right: 10px;
  }
  & > * {
    margin-left: 15px;
    margin-right: 15px;
  }
}
.ap-input__description {
  margin-top: 0;
  margin-bottom: 5px;
  font-size: .7rem;
  line-height: 1.2em;
}
.ap-btn--margin-top {
  margin-top: 30px;
}
.ap-btn--center {
  margin-bottom: 10px;
  & .button {
    margin-left: auto;
    margin-right: auto;
    max-width: 300px;
  }
}

.input__error {
  min-height: 36px;
  line-height: 36px;
  padding-left: 8px;
  padding-right: 8px;
  vertical-align: middle;
  background-color: rgba(246,89,50,.2);
}
.ap-shift {
  padding-top: 23px;
}

.ap-report {
  padding-top: 20px;
  max-height: 360px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.ap-report__title {
  margin-bottom: 12px;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
}
.ap-report__p {
  margin: 0;
  font-size: .8rem;
  color: #6d7177;
  & strong {
    color: #fff;
    font-weight: normal;
  }
}
.ap-report__block {
  margin-bottom: 20px;
}
.ap-report__list {
  margin-left: 40px;
  & .ap-report__p {
    margin-bottom: 10px;
  }
  & p {
    margin-top: 5px;
    margin-top: 5px;
    font-size: .7rem;
    line-height: 1.2em;
    color: #6d7177;
  }
}
.clickableSpan {
  &:hover {
    color: #f65932;
    cursor: pointer;
  }
}
.btn--small {
  padding-top: 7px;
  padding-bottom: 7px;
  font-size: 16px;
  line-height: 1.2em;
}
.tarifflist {
  & td,
  & th {
    font-weight: 200;
    font-size: 14px;
    text-align: start;
    padding: 5px 10px;
    overflow: hidden;
  }
}
.tariffs__date {
  & span:first-child {
    opacity: .5;
  }
}
.tariffs__storage {
  & span:first-child {
    opacity: .5;
  }
}
.tariffs__auto {
  & > div {
    margin-left: auto;
    margin-right: auto;
    width: 24px;
    height: 24px;
    border-radius: 5px;
    background-color: #f65932;
  }
  & > img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 24px;
  }
}
.modal-edit {
  margin-top: 10px;
  & .battle_competitor {
    padding-left: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  // & .ap-row {
  //   margin-bottom: 7px;
  // }
}
.col_admin_40 {
  width: 40px;
}
.col_admin_80 {
  width: 80px;
}
.col_admin_120 {
  width: 120px;
}
.col_admin_160 {
  width: 160px;
}
.col_admin_220 {
  width: 220px;
}
.ant-spin-dot i {
  background-color: red;
}
.modal_footer {
  display: flex;
}

.admin-verification__filter {
  padding-left: 20px;
  padding-bottom: 30px;
  width: 100%;
  & > div {
    display: flex;
    align-items: flex-end;
    width: 420px;
  }
  & .button {
    margin-left: 10px;
    padding: 10px 18px;
    font-size: 16px;
    line-height: 1.2em;
    width: auto;
  }
  & .input {
    width: 100%;
  }
}



.avgScore {
  color: #fff;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  height: 14px;
  width: 25px;
  background: #252B32;
  border-radius: 7px;
  position: absolute;
  bottom: 0;
  left: -10px;
}

.battle_row-btns {
  display: flex;
  margin-left: -2px;
  margin-right: -2px;
  & button {
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: 2px;
    margin-right: 2px;
    width: 42px;
    min-width: 42px;
  }
}

.battle_result {
  display: flex;
}
.battle_result_protest {
  display: block;
  margin-right: 5px;  
}
