.ant-modal-content{
  .verification-modal-block{
    margin: 0 -20px;
    padding: 15px 20px;
    border-bottom: 1px solid #25292c;
    font-family: 'Open Sans';
    font-size: 14px;
    color: #6d7177;
    .phone-container{
      display: flex;
      width: 100%;
      justify-content: space-between;
      span{
        color: #fff;
      }
    }
    p, span{
      margin: 0;
    }
    a{
      color: #20a39e;
      min-height: auto;
      a:hover{
        color: #20a39e;
      }
    }
    input{
      width: 100%;
      display: block;
      background: transparent;
      border: 1px solid #6d7177;
      height: 30px;
      margin-top: 10px;
      margin-bottom: 15px;
      color: #fff;
      box-sizing: border-box;
      padding: 0 10px;
      & + .input_error{
        display: none;
        align-items: center;
        padding: 0 8px;
        min-height: 36px;
        line-height: 36px;
        background-color: rgba(246,89,50,.2);
        color: #fff;
        margin-bottom: 15px;
        margin-top: -15px;
      }
      &.highlight + .input_error{
        display: flex;
      }
    }
  }
}

