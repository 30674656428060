@import url('normalize.css');
@import url('font.css');
@import url('animation.css');
@import url('tabs.css');

body {
  font-family: 'Open Sans', sans-serif;
  background: #000;
  color: #fff;
}

* {
  -webkit-font-smoothing: antialiased;
}

/* Transparent autofill */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
   -webkit-transition-delay: 9999s;
   transition-delay: 9999s;
}

h1 {
  font-family: Oswald, sans-serif;
  font-weight: 500;
  font-size: 50px;
  text-transform: uppercase;
  letter-spacing: 5px;
  color: #fff;
  margin-top: 10px;
  margin-bottom: 15px;
}
h1:first-letter {
  color: #f65932;
}

a {
  color: #f65932;
  text-decoration: none;
  min-height: 28px;
}

:focus {
  outline: 0;
}

/********* AntD Table ***********/
/* remove overflow to fix iPad issue. don't use global style for table body, if need scroll, then add ".parent-element-class-name .ant-table-body { overflow: auto; }"
.ant-table-body{
  overflow: auto;
}
*/

.ant-table-body table thead {
  background: transparent;
  cursor: pointer;
}

.ant-table-body table {
  border-spacing: 0;
  width: 100%;
  font-weight: 200;
  letter-spacing: .5px;
}

.ant-table-body table thead tr th > div{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 5px;
  justify-content: space-between;
}
.ant-table-body table tbody tr{
  background-color: #252b32;
}
/* .ant-table-body table tbody tr:hover{
  background-color: #20a39e !important;
} */

/* .ant-table-body table tbody tr:hover span{
  color: #fff !important;
} */

.ant-table-body table th:hover{
  background-color: #25292C !important;
}

.ant-table-body table tbody tr td, .ant-table-body table thead tr th{
  font-weight: 200;
  font-size: 14px;
  text-align: start;
  padding: 5px 10px;
}

.ant-table-body table thead tr th{
  border-bottom: 1px solid #25292C;
}

.ant-table-body table tbody tr:nth-child(even){
  background-color: transparent;
}

.ant-table-body table th:not(:last-child) {
  border-right: 1px solid rgba(100, 100, 100, 0.3);
}

.rowTable{
  background-color: #20a39e !important;
  cursor: pointer;
}
.rowTable_hover{
  cursor: pointer;
}
.rowTable span{
  color: #fff !important;
}

.ant-table-column-sorter{
  width: 12px;
  position: relative;
  display: flex;
}

.ant-table-column-sorter .anticon svg{
  display: none;
}

.ant-table-column-sorter i.anticon{
  position: static;
}

.ant-table-column-sorter .anticon.on::after{
  position: absolute;
  content: url('../images/Group2.svg');
  right: 0;
  top: 3px;
  left: 5px;
}

.ant-table-column-sorter .anticon.on.ant-table-column-sorter-down::after{
  transform: scaleY(-1);
}

.ant-table-body table tbody tr td.tableColumnNumbers {
  text-align: right;
}
/********* AntD Table ***********/

.usersHeader {
  display: flex;
  justify-content: space-between;
}

.lang_button {
  display: flex;
}

.lang_button .tariffLink {
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 0 15px;
  min-width: 209px;
  color: #fff;
  font-weight: bold;
  margin-right: 3px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  font-family: 'Oswald';
  letter-spacing: 0.05em;
  font-size: 14px;
}

.lang_button .tariffLink > div {
  display: flex;
  width: 100%;
}

.lang_button .tariffLink > div > a {
  color: inherit;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lang_button .tariffLink span {
  flex: 1 1 50%;
  display: flex;
  justify-content: center;
}

.lang_button .tariffLink span.delimeter {
  flex: 0 0 1px;
  background: #FFFFFF;
  opacity: 0.4;
  height: 15px;
}

.version_desc {
  padding: 5px 0;
  display: block;
  text-align: center;
}

.version_desc:first-child {
  font-size: 16px;
  
}

.lang_button .button {
  position: relative;
  background-color: transparent;
  box-shadow: none;
  position: relative;
  padding: 0 15px;
  margin: 0;
}

.usersHeader a {
  position: relative;
  display: flex;
  align-items: center;
}

.headerLink {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: rgba(255, 255, 255, 0.6);
  padding: 5px 20px;
}

.headerLink.active {
  color: #FFFFFF;
  background: #22AB00;
}
.friend-badge {
  margin-left: 3px;
  background: #f65932;
  color: #000;
  border-radius: 8px;
  min-width: 16px;
  text-align: center;
  font-weight: 600;
}

_:-webkit-full-screen:not(:root:root), .safarifixspan {
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-text-stroke: 1px rgba(255, 255, 255, 0.7);
}

_:-webkit-full-screen:not(:root:root), .headerLink {
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-text-stroke: 1px rgba(255, 255, 255, 0.7);
}

_:-webkit-full-screen:not(:root:root), .tournamentInfo {
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-text-stroke: 1px rgba(255, 255, 255, 0.7);
}

.splashscreen {
  animation: obPulse 2.5s ease-in-out infinite;
}

div[disabled="disable"], div[disabled] {
  pointer-events: none;
  opacity: 0.4;
}

/************** Scrolls **************/
::-webkit-scrollbar {
  background: transparent;
  width: 10px;
  height: 10px;
  padding: 2px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #666666;
  background-clip: content-box;
  border: 2px solid transparent;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-track-piece {
  background: transparent;
}

::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

