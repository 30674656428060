/* open-sans-300 */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Open Sans Light'), local('OpenSans-Light'),
       url('../fonts/Open_Sans/OpenSans-Light.ttf') format('truetype');
}
/* open-sans-300italic */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  src: local('Open Sans Light Italic'), local('OpenSans-LightItalic'),
       url('../fonts/Open_Sans/OpenSans-LightItalic.ttf') format('truetype');
}
/* open-sans-regular */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
       url('../fonts/Open_Sans/OpenSans-Regular.ttf') format('truetype');
}
/* open-sans-italic */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: local('Open Sans Italic'), local('OpenSans-Italic'),
       url('../fonts/Open_Sans/OpenSans-Italic.ttf') format('truetype');
}
/* open-sans-600 */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
       url('../fonts/Open_Sans/OpenSans-SemiBold.ttf') format('truetype');
}
/* open-sans-600italic */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600;
  src: local('Open Sans SemiBold Italic'), local('OpenSans-SemiBoldItalic'),
       url('../fonts/Open_Sans/OpenSans-SemiBoldItalic.ttf') format('truetype');
}
/* open-sans-700 */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
       url('../fonts/Open_Sans/OpenSans-Bold.ttf') format('truetype');
}
/* open-sans-700italic */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  src: local('Open Sans Bold Italic'), local('OpenSans-BoldItalic'),
       url('../fonts/Open_Sans/OpenSans-BoldItalic.ttf') format('truetype');
}
/* open-sans-800 */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  src: local('Open Sans ExtraBold'), local('OpenSans-ExtraBold'),
       url('../fonts/Open_Sans/OpenSans-ExtraBold.ttf') format('truetype');
}
/* open-sans-800italic */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 800;
  src: local('Open Sans ExtraBold Italic'), local('OpenSans-ExtraBoldItalic'),
       url('../fonts/Open_Sans/OpenSans-ExtraBoldItalic.ttf') format('truetype');
}

/* open-sans-200 */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 200;
  src: local('Oswald ExtraLight'), local('Oswald-Light'),
       url('../fonts/Oswald/Oswald-ExtraLight.ttf') format('truetype');
}
/* open-sans-300 */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 300;
  src: local('Oswald Light'), local('Oswald-Light'),
       url('../fonts/Oswald/Oswald-Light.ttf') format('truetype');
}
/* open-sans-regular */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 400;
  src: local('Oswald Regular'), local('Oswald-Regular'),
       url('../fonts/Oswald/Oswald-Regular.ttf') format('truetype');
}
/* open-sans-medium */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 500;
  src: local('Oswald Medium'), local('Oswald-Medium'),
       url('../fonts/Oswald/Oswald-Medium.ttf') format('truetype');
}
/* open-sans-600 */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 600;
  src: local('Oswald SemiBold'), local('Oswald-SemiBold'),
       url('../fonts/Oswald/Oswald-SemiBold.ttf') format('truetype');
}
/* open-sans-700 */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 700;
  src: local('Oswald Bold'), local('Oswald-Bold'),
       url('../fonts/Oswald/Oswald-Bold.ttf') format('truetype');
}

/* open-sans-200 */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 200;
  src: local('Inter ExtraLight'), local('Inter-Light'),
       url('../fonts/Inter/Inter-ExtraLight.ttf') format('truetype');
}
/* open-sans-300 */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  src: local('Inter Light'), local('Inter-Light'),
       url('../fonts/Inter/Inter-Light.ttf') format('truetype');
}
/* open-sans-regular */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: local('Inter Regular'), local('Inter-Regular'),
       url('../fonts/Inter/Inter-Regular.ttf') format('truetype');
}
/* open-sans-medium */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: local('Inter Medium'), local('Inter-Medium'),
       url('../fonts/Inter/Inter-Medium.ttf') format('truetype');
}
/* open-sans-600 */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  src: local('Inter SemiBold'), local('Inter-SemiBold'),
       url('../fonts/Inter/Inter-SemiBold.ttf') format('truetype');
}
/* open-sans-700 */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: local('Inter Bold'), local('Inter-Bold'),
       url('../fonts/Inter/Inter-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Technor';
  src: url('../fonts/Technor/Technor-Extralight.ttf') format('truetype');
       font-weight: 200;
       font-display: swap;
       font-style: normal;
}

@font-face {
  font-family: 'Technor';
  src: url('../fonts/Technor/Technor-Light.ttf') format('truetype');
       font-weight: 300;
       font-display: swap;
       font-style: normal;
}

@font-face {
  font-family: 'Technor';
  src: url('../fonts/Technor/Technor-Regular.ttf') format('truetype');
       font-weight: 400;
       font-display: swap;
       font-style: normal;
}

@font-face {
  font-family: 'Technor';
  src: url('../fonts/Technor/Technor-Medium.ttf') format('truetype');
       font-weight: 500;
       font-display: swap;
       font-style: normal;
}

@font-face {
  font-family: 'Technor';
  src: url('../fonts/Technor/Technor-Semibold.ttf') format('truetype');
       font-weight: 600;
       font-display: swap;
       font-style: normal;
}

@font-face {
  font-family: 'Technor';
  src: url('../fonts/Technor/Technor-Bold.ttf') format('truetype');
       font-weight: 700;
       font-display: swap;
       font-style: normal;
}

@font-face {
  font-family: 'Technor';
  src: url('../fonts/Technor/Technor-Black.ttf') format('truetype');
       font-weight: 900;
       font-display: swap;
       font-style: normal;
}

/* open-sans-200 */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 200;
  src: local('Manrope ExtraLight'), local('Manrope-Light'),
       url('../fonts/Manrope/Manrope-ExtraLight.ttf') format('truetype');
}
/* open-sans-300 */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 300;
  src: local('Manrope Light'), local('Manrope-Light'),
       url('../fonts/Manrope/Manrope-Light.ttf') format('truetype');
}
/* open-sans-regular */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  src: local('Manrope Regular'), local('Manrope-Regular'),
       url('../fonts/Manrope/Manrope-Regular.ttf') format('truetype');
}
/* open-sans-medium */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  src: local('Manrope Medium'), local('Manrope-Medium'),
       url('../fonts/Manrope/Manrope-Medium.ttf') format('truetype');
}
/* open-sans-600 */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  src: local('Manrope SemiBold'), local('Manrope-SemiBold'),
       url('../fonts/Manrope/Manrope-SemiBold.ttf') format('truetype');
}
/* open-sans-700 */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  src: local('Manrope Bold'), local('Manrope-Bold'),
       url('../fonts/Manrope/Manrope-Bold.ttf') format('truetype');
}