.save_battle__subtitle {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #FFFFFF;
  padding: 10px 44px 10px 0px;
}

.save_battle__underline {
  width: 100%;
  height: 1px;
  background: #25292C;
}

.save_battle__select_cameras {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #FFFFFF;
  margin: 10px 0;
}

.save_battle__select_cameras > span {
  margin: 10px 0;
  opacity: 0.8;
}

.save_battle__select_cameras > .checkbox {
  margin: 10px 0;
}

.save_battle__space {
  color: #9EA1A4;
}

.save_battle__checkbox_container {
  margin-bottom: 5px;
}

.save_battle__checkbox_container .checkbox {
  line-height: 20px;
}

.save_battle__checkbox_container .checkbox__checkmark {
  width: 18px;
  height: 18px;
  border: 1px solid rgba(246, 89, 50, .6);
  border-radius: 3px;
  margin-right: 7px;
  flex: 0 0 18px;
}

.save_battle__checkbox_container .checkbox__checkmark img {
  width: 18px;
}

.save_battle__flexbox {
  display: flex;
  flex-wrap: wrap;
}

.save_battle {
  flex: 0 0 50%;
  overflow: hidden;
}

.save_battle__camera_list {
  display: flex;
  margin-top: 3px;
}

.save_battle__structure {
  width: 16px;
  margin-left: 10px;
  margin-right: 3px;
}

.save_battle__checkbox_list > div:first-child {
  margin-top: 7px;
}

.save_battle__checkbox_list > div {
  margin: 10px 0;
}

.save_battle__info {
  position: relative;
  margin: 10px 0;
}

.save_battle__space_diagram {
  position: absolute;
  width: 100px;
  max-height: 100px;
  overflow: hidden;
  text-align: center;
  right: 34px;
  top: 0;
}

.save_battle__space_indicator {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-family: Oswald;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 95px;
  text-align: center;
  color: #FFFFFF;
}

.save_battle__space_info {
  display: inline-block;
  width: 50%;
}

.save_battle__space_info_mh {
  min-height: 90px;
}

.save_battle__space_indicator > span {
  font-size: 14px;
}

.save_battle__no_space {
  font-family: Oswald;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.05em;
  color: #FFFFFF;
}

.save_battle__all_space {
  font-family: Oswald;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.05em;
  color: #FFFFFF;
  margin-top: 10px;
  white-space: nowrap;
}

.save_battle__free_space {
  font-family: Oswald;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.05em;
  color: #20A39E;
  margin-top: 5px;
  white-space: nowrap;
}

.save_battle__need_space {
  font-family: Oswald;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.05em;
  color: #F65932;
  margin-top: 5px;
  white-space: nowrap;
}

.save_battle__overflow_space {
  font-family: Oswald;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #E11D40;
  white-space: nowrap;
  margin-top: 5px;
}

.save_battle__modal .ant-modal-body > .modal-group:last-child {
  border-bottom: 1px solid #25292c;
}

.save_battle__modal .ant-modal-footer {
  padding: 10px 20px;
}

.save_battle__select_btn {
  display: inline-block;
  width: 50%;
  position: relative;
  top: -15px;
}

.save_battle__select_btn button {
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 14px;
}

.save_battle__overflow_notice {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #FFFFFF;
  margin-top: 10px;
}

.save_battle_no_tariff {
  margin-top: 15px;
}

.save_battle_no_tariff .save_battle_offer_text {
  font-family: 'Oswald';
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.05em;
  margin-bottom: 20px;
  color: #fff;
  text-align: center;
}

.save_battle_no_tariff button {
  height: 40px;
  font-size: 14px;
  padding: 0;
  margin-bottom: 15px;
}