.notifications {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  &__empty {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
  }

  &__no_data_found {
    font-style: normal;
    font-weight: normal;
    color: #FFFFFF;
    text-align: center;
    max-width: 300px;

    &__description {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      margin-top: 25px;
    }
  }
}

.notifications .header_trash {
  margin-right: 15px;
  position: absolute;
  right: 0;
  cursor: pointer;
}

.notifications .notifications_container {
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  flex: 1 1;
  display: flex;
  flex-direction: column;
}

.notifications .notifications_container > * {
  margin-right: -10px;
}

.notification_list {
  flex: 0 0 auto;
}