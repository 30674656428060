.protest__opponent__label{
  margin-top: 15px;
  margin-bottom: 5px;
}

.protest__opponent__value{
  margin-top: 0;
  margin-bottom: 15px;
  color: #fff;
}

.protest__opponent textarea {
  margin: 15px 0;
  max-width: 100%;
  min-width: 100%;
  min-height: 140px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  font-size: 14px;
  line-height: 20px;
  color: white;
  padding: 10px;
  background-color: transparent;
  display: block;
  box-sizing: border-box;
  &::placeholder {
    opacity: 0.4;
  }
}

.protest__opponent textarea.empty {
  background: rgba(246, 89, 50, 0.1);
  border: 1px solid #F65932;
}