.battles {

}

.battles__flag {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  overflow: hidden;
}

.battles__flag span{
  display: block;
  width: 100% !important;
  height: 100%;
  background-size: contain;
}

.battles__table {
  width: 100%;
}

.battles__table .now_message {
  font-weight: 600;
}

.battles__filter {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(100, 100, 100, 0.3);
  flex-wrap: wrap;
  padding: 1px 20px 12px;
}

.create-battle_btn {
  width: 168px;
}

.bet-group {
  display: flex;
  flex-direction: column;
  margin-top: 13px;
}
.bet-group__label {
  margin-bottom: 12px;
}
.bet-group__elements {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
}
.bet-group__elements .ant-radio-group:first-child:last-child {
  margin-bottom: 15px;
}
.bet-group__elements .ant-radio-group {
  margin-bottom: 4px;
  display: flex;
  flex: 0 0 100%;
  flex-direction: row;
}
.bet-group__elements .ant-radio-group:not(:first-child) {
  flex-direction: column;
  margin-top: 20px;
  font-size: 14px;
}

.wallet-container > th {
  font-size: 12px;
  color: #6d7177;
  padding: 0;
  font-weight: normal;
  white-space: nowrap;
}

.wallet-container {
  font-size: 14px;
}

.wallet-container.disabled .picker__container {
  pointer-events: none;
}

.wallet-container > td {
  border-bottom: 1px solid #25292c;
  padding: 15px 0;
  white-space: nowrap;
}

.wallet-container > th.wallet-name {
  text-align: left;
}

.wallet-container > td.wallet-name .ant-radio-wrapper {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #FFFFFF;
}

.wallet-container > th.wallet-fee,
.wallet-container > td.wallet-fee {
  text-align: right;
}

.wallet-container > td.wallet-fee .picker__container {
  margin: 0;
  width: 80px;
  max-width: 100%;
  display: inline-block;
  text-align: left;
  white-space: normal;
}

.wallets > tbody > tr:last-child > td {
  border-bottom: none;
}

.wallet-container .wallet-value {
  text-align: right;
  padding-right: 30px;
}

.bet-group__elements .ant-radio-group > label {
  margin: 0;
}

.bet-group__elements .filter-input {
  margin-left: 6px;
}
.bet-group__elements .filter-input__data {
  width: 100%
}

.win-count-group {
  display: flex;
  flex-direction: column;
  margin-top: 34px;
  margin-bottom: 9px;
}
.win-count-group__label {
  margin-bottom: 18px;
  display: inline-block;
}

.date-group {
  display: flex;
  flex-direction: column;
  margin-top: 17px;
  margin-bottom: 15px;
}
.date-group__label {
  margin-bottom: 12px;
  display: inline-block;
}
.date-group__elements {
  display: flex;
  align-items: flex-end;
}
.date-group__elements__picker {
  width: 97px;
  padding-left: 5px;
  /* overflow: hidden; */
}
.date-group__elements .ant-calendar-picker {
  /* margin: 0 5px; */
  padding-left: 0px;
}
.date-group__elements .ant-radio-group {
  margin-bottom: 4px;
}
.date-group__elements .date-group__elements__picker .ant-input,
.date-group__elements .date-group__elements__picker .ant-time-picker-input {
  width: calc(100% - 20px);
}

.opponent-group {
  padding: 15px 0 5px 0;
}

.opponent-group__header {
  margin-bottom: 12px;
}

.opponent-group .opponent {
  padding: 0;
  background: transparent;
}

.opponent-group .opponent .opponent__fullname {
  color: white;
}

.comment-group {
  display: flex;
  flex-direction: column;
  margin-top: 17px;
  margin-bottom: 16px;
}
.comment-group__label {
  margin-bottom: 12px;
  display: inline-block;
}
.rowbattle {
  cursor: default;
  filter: brightness(1);
  transition: 10s;
}
.rowbattle.new {
  filter: brightness(3);
  transition: 0s;
}
.new-battles-btn {
  color: #f65932;
  height: 40px;
}
.new-battles-btn td {
  border: #f65932 solid 2px;
}
.new-battles-btn div {
  text-align: center;
  font-size: 16px;
}
.new-battles-btn div:hover {
  cursor: pointer;
}

.table_created {
  min-width: 100px;
}

.betContainer {
  display: flex;
  justify-content: space-between;
}

.tooltipPin {
  margin-left: 9px;
  position: relative;
  background-color: #f65932;
  color: #fff;
  border-radius: 8px;
  height: 16px;
  width: 16px;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.tooltipPin:hover {
  background-color: #ff1f00;
}

.tooltipBubble {
  display: none;
  position: fixed;
  z-index: 999;
  background-color: #6d7177;
  border-radius: 3px;
  padding: 10px;
  color: #fff;
  text-align: left;
  max-width: 350px;
  transform: translateY(calc(50% + 8px)) translateX(50%);
  font-size: 14px;
  font-weight: normal;
}

.tooltipBubble::before {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 0 8px 8px;
  border-color: transparent transparent transparent #6d7177;
  position: absolute;
  top: -8px;
  left: 0;
  content: '';
  display: none;
}

.tooltipPin:hover .tooltipBubble, .tooltipPin:hover .tooltipBubble::before {
  display: block;
}
