.radio {
  &-group {
    display: flex;
    flex-direction: column;

    &__container {
      display: flex;
    }
    &__label {
      font-family: 'Open Sans';
      font-size: 16px;
      line-height: 22px;
      color: #6D7177;
      margin-bottom: 10px;
    }

    &-users {
      .ap-row {
        margin-left: 0;
      }
    }
  }
}

.new-version {
  &__container {
    .ap-input__description {
      margin-top: 5px;
    }
  }
}

.event {
  &__container {
    .ap-input__description {
      margin-top: 5px;
    }
    .emails {
      margin-bottom: 20px;
    }
    .report {
      &__container {
        margin-top: 30px;
        max-height: 360px;
        overflow-y: scroll;
        overflow-x: hidden;
        min-height: 300px;
      }
      &__header {
        font-size: 20px;
        text-align: center;
      }
      &__section {
        margin-top: 10px;
        .section {
          &__header {
            margin-top: 20px;
            text-align: left;
            font-size: 18px;
          }
          &-value__list {
            font-size: 14px;
            li {
              margin-bottom: 5px;
            }
          }
        }
      }
    }
  }
}