.lobby {
  position: absolute;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  &__reload {
    position: absolute;
    bottom: 25px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    animation: fadein 500ms;
    -moz-animation: fadein 500ms;
    -webkit-animation: fadein 500ms;
    -o-animation: fadein 500ms;

    &__button {
      padding: 0 40px;
      height: 36px;
      display: flex;
      flex-direction: row;
      align-items: center;
      background: #F65932;
      cursor: pointer;

      &:hover {
        background: #FF3E18;
      }

      &__icon {
        width: 24px;
        height: 22px;

        svg path {
          fill: #FFFFFF;
        }

        &.animate {
          -webkit-animation: rotating 1.3s linear infinite;
          -moz-animation: rotating 1.3s linear infinite;
          -ms-animation: rotating 1.3s linear infinite;
          -o-animation: rotating 1.3s linear infinite;
          animation: rotating 1.3s linear infinite;
        }
      }

      &__text {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
        color: #FFFFFF;
        margin-left: 20px;
      }
    }
  }

  &__content {
    position: relative;
    flex: 1 1 100%;
    & > .react-tabs {
      display: flex;
      height: 100%;
      flex-direction: column;
      & > .react-tabs__tab-list {
        flex: 0 0 auto;
        padding: 0 20px;
      }
      & > .react-tabs__tab-panel {
        flex: 1 1 100%;
      } 
    }
    & > .react-tabs > .react-tabs__tab-list .react-tabs__tab {
      flex: 0 0 auto;
      padding: 10px 15px;
    }
    &, &  * {
      box-sizing: border-box;
    }
    .lobbyBattleList {
      overflow: auto;
      height: 100%;
      padding: 0 20px;
      & > .date {
        margin-top: 12px;
        margin-bottom: 10px;
        opacity: 0.8;
        color: #fff;
        letter-spacing: 0.03em;
        text-align: center;
        font-size: 16px;
        font-family: Oswald;
      }
      .BattleItem {
        border-radius: 5px;
        overflow: hidden;
        background: #252B32;
        margin-bottom: 10px;
        &.my {
          background: #1A301C;
          .title {
            background: linear-gradient(206.96deg, #0ABB31 0%, #206B0D 100%) !important;
          }
        }
        .title {
          padding: 5px 10px;
          background: #435060;
          letter-spacing: 0.05em;
          font-size: 14px;
          font-family: Oswald;
          &.tournament {
            background: linear-gradient(206.96deg, #20A39E 0%, #01677E 100%);
          }
        }
        .content {
          padding: 5px 10px;
          display: grid;
          grid-template-columns: 1fr 120px 60px 60px 120px;
          grid-gap: 10px;
          & > * {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
          }
          .caption {
            color: #fff;
            font-size: 12px;
            margin-bottom: 5px;
          }
          .value {
            font-family: Oswald;
            font-size: 16px;
            letter-spacing: 0.03em;
            color: #fff;
            .started {
              background: #E9153B;
              height: 22px;
              text-transform: uppercase;
              border-radius: 11px;
              display: flex;
              justify-content: center;
              align-items: center;
              font-family: Oswald;
              letter-spacing: 0.05em;
              color: #fff;
              font-size: 14px;
              margin: -2px -5px;
              padding: 0 5px;
            }
          }
          button {
            border-radius: 5px;
            height: 40px;
            width: 90px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
          }
          .action {
            display: flex;
            justify-content: center;
          }
          & > .players {
            display: flex;
            align-items: center;
            flex-direction: row;
            &.short {
              .player:not(:last-child) {
                margin-right: 20px;
              }
            }
            .group {
              flex: 1 1 100%;
              display: flex;
            }
            & > *:not(:last-child) {
              margin-right: 20px;
            }
          }
          .VSdelimeter {
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            font-size: 20px;
            font-weight: 600;
            font-family: Oswald;
            flex: 0 0 auto;
          }
          .action {
            color: #fff;
            align-items: center;
            span {
              opacity: 0.6;
              font-size: 14px;
              text-align: center;
            }
          }
          .player {
            cursor: pointer;
            display: flex;
            align-items: center;
            .playerAvatar {
              flex: 0 0 auto;
              position: relative;
              img {
                display: block;
                height: 40px;
                width: 40px;
                border-radius: 20px;
                border: 2px solid;
                border-color: #435060;
                margin-right: 25px;
              }
              &.active img {
                border-color: #22ab00;
              }
              .avgScore {
                color: #fff;
                font-size: 12px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 600;
                height: 14px;
                width: 25px;
                background: #252B32;
                border-radius: 7px;
                position: absolute;
                bottom: 0;
                left: -10px;
              }
              .flag {
                position: absolute;
                height: 24px;
                width: 24px;
                border-radius: 12px;
                overflow: hidden;
                bottom: 0;
                right: 6px;
                & > span {
                  height: 100%;
                  width: 100%;
                  display: block;
                }
              }
            }
            .name {
              color: #fff;
              font-size: 14px;
              .rating {
                margin-top: 5px;
                display: flex;
                align-items: center;
                color: #fff9;
                font-size: 14px;
                svg {
                  margin-right: 3px;
                }
              }
            }
          }
        }
      }
    }
  }

  &__container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    flex: 1;
    overflow: hidden;
  }

  &__empty_container {
    justify-content: center;
    align-items: center;
    display: flex;
  }

  &__filters {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 15px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #CCCCCC;
    background: #252B32;

    &__user_search {
      margin-right: 20px;

      .filter-input__data {
        height: 30px;
        line-height: 30px;
        width: 310px;

        &::-webkit-input-placeholder {
          color: #CCCCCC;
        }

        &:-ms-input-placeholder {
          color: #CCCCCC;
        }

        &::-ms-input-placeholder {
          color: #CCCCCC;
        }

        &::placeholder {
          color: #CCCCCC;
        }
      }
    }

    &__bet_between {
      display: flex;
      flex-direction: row;
      align-items: center;

      &__label {
        margin-right: 10px;
      }

      &__select__container {
        width: 80px;

        &:last-child {
          margin-left: 5px;
        }
      }
    }

    &__reset {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 30px;
      padding: 0 8px;
      color: #F65932;
      margin-left: 25px;
      border: 1px solid #F65932;
      box-sizing: border-box;
      background: transparent;
      cursor: pointer;

      &:hover {
        color: #FFFFFF;
        background: #F65932;
      }
    }
  }

  &__no_data_found {
    font-style: normal;
    font-weight: normal;
    color: #FFFFFF;
    text-align: center;
    max-width: 300px;

    &__title {
      font-family: Oswald;
      font-size: 30px;
      line-height: 44px;
      letter-spacing: 0.05em;
      margin: 20px 0 10px 0;
    }

    &__description {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
    }

    &__button {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      min-height: 30px;
      padding: 0 30px;
      margin-top: 20px;
      background: transparent;
      cursor: pointer;

      &__icon {
        margin-right: 15px;
        width: 18px;
        height: 18px;

        svg {
          height: 18px;

          path {
            fill: #FFFFFF;
          }
        }
      }

      &__text {
        font-size: 16px;
        line-height: 20px;
      }

      &.reset {
        border: 1px solid #F65932;
        box-sizing: border-box;
        color: #F65932;

        &:hover {
          color: #FFFFFF;
          background: #F65932;
        }
      }

      &.create {
        color: #FFFFFF;
        background: #22AB00;

        &:hover {
          background: #008e06;
        }
      }
    }
  }

  &__sidebar {
    display: flex;
    flex-direction: column;
    width: 25vw;
    flex: 0 0 auto;
    min-width: 281px;
    background: #404040;
    margin-left: 1px;

    &__content {
      display: flex;
      flex-direction: column;
      flex: 1;
      overflow: hidden;

      .chat-container.non-battle-chat .menu-header {
        border-bottom: none !important;

        .header__text {
          display: block;
        }
      }

      .menu-header {
        position: relative;
        display: block;
        font-family: Oswald;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 38px;
        text-align: center;
        text-transform: uppercase;
        color: #fff;
        background: #252B32;
        height: 38px;
        border-bottom: none;
        padding: 0;
      }

      .notifications .header_trash {
        top: 10px;
      }

      .chat-container.non-battle-chat .search,
      .my-battles-filter {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        background: #252B32;
        background-color: #252B32;
        padding: 0 15px;
        height: 46px;
      }
    }

    &__tabs {
      display: flex;
      flex-direction: row;
      background: #0A101A;
    }

    &__tab {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1;
      cursor: pointer;

      svg {
        height: 24px;

        path {
          fill: #6D7177;
        }
      }

      &.active {
        background: #404040;

        svg path {
          fill: #F65932;
        }
      }
    }
  }
}
.battle-item__passed {
  display: flex;
  padding: 0 10px 5px 10px;
  margin-left: -2px;
  margin-right: -2px;
  font-size: 0.7rem;
  line-height: 1.2em;
  opacity: .7;
  & div {
    margin-left: 2px;
    margin-right: 2px;
  }
}

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes rotating {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes brightappear {
  from {
    background: #22ab00;
  } to {
  }
}

@keyframes dimappear {
  from {
    background: #000;
  } to {
  }
}