.buttonaccept__container {
  margin-left: 10px;
}

.buttondecline__container {
  margin-left: 10px;
}

.buttonmessage__container button{
  height: 30px;
}

