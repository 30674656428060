.SquadScreen {
	* {
		box-sizing: border-box;
	}
	flex-shrink: 1;
	flex-grow: 1;
	flex-basis: 100vh;
	flex-basis: calc(var(--var, 1vh) * 100);
	overflow: auto;
	display: flex;
	flex-direction: column;
	.header {
		flex: 0 0 auto;
		display: flex;
		flex-direction: column;
	}
	.control {
		flex: 0 0 30px;
		display: flex;
		justify-content: space-evenly;
		& > * {
			flex: 1 1 100%;
			button {
				flex: 0 0 auto;
				width: auto;
				position: relative;
				padding: 5px 20px;
				&:not(:last-child)::after {
					content: '';
					width: 1px;
					top: 0;
					bottom: 0;
					right: -1px;
					opacity: .4;
					position: absolute;
					background-image: linear-gradient(0deg,hsla(0,0%,100%,.2),#fff 50%,hsla(0,0%,100%,.2));
				}
			}
		}
		.squadControl {
			display: flex;
			justify-content: flex-start;
		}
		.squadMeta {
			display: flex;
			justify-content: center;
			.meta {
				height: 100%;
				display: flex;
				align-items: center;
				background: rgba(246,89,50,.1);
				padding: 3px 0;
				border-left: 1px solid #6d7176;
				border-right: 1px solid #6d7176;
				box-sizing: border-box;
				& > * {
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: center;
					font-family: Oswald;
					font-style: normal;
					font-weight: 400;
					font-size: 14px;
					line-height: 21px;
					letter-spacing: .05em;
					color: #fff;
					padding: 0 10px;
					border-right: 1px solid hsla(0,0%,100%,.2);
					box-sizing: border-box;
				}
				& > *:last-child {
					border-right: none;
					margin-right: -1px;
				}
				.name {
					font-weight: 500;
    				font-size: 18px;
				}
			}
		}
		.appControl {
			display: flex;
			justify-content: flex-end;
		}
	}
	.opponents {
		padding: 10px 0;
		position: relative;
		&::before, &::after {
			right: 0;
			left: 0;
			height: 1px;
			position: absolute;
			content: '';
			opacity: .4;
			background-image: linear-gradient(90deg,hsla(0,0%,100%,.2),#fff 50%,hsla(0,0%,100%,.2));
		}
		&::before {
			top: 0;
		}
		&::after {
			bottom: 0;
		}
	}
	.results {
		flex: 0 0 auto;
		.react-tabs .react-tabs__tab-list {
			margin-bottom: 0;
			border-bottom: none;
			position: relative;
			justify-content: center;
			padding-bottom: 2px;
			&::after {
				right: 0;
				left: 0;
				height: 1px;
				position: absolute;
				content: '';
				opacity: .4;
				background-image: linear-gradient(90deg,hsla(0,0%,100%,.2),#fff 50%,hsla(0,0%,100%,.2));
				bottom: 0;
			}
		}
		.game {
			// display: flex;
			// // flex-wrap: wrap;
			// overflow: hidden;
			// padding: 10px 5px 15px;
			&.disabled {
				opacity: .8;
				pointer-events: none;
			}
			// .player {
			// 	flex: 0 0 50%;
			// 	padding: 0 15px;
			// 	&:first-child:last-child {
			// 		flex: 0 0 100%;
			// 	}
			// 	.player-head {
			// 		justify-content: space-between;
			// 		display: flex;
			// 		margin-bottom: 10px;
					// // .names {
					// // 	display: flex;
					// // 	align-items: flex-end;
					// // 	.fullname {
					// // 		font-size: 16px;
					// // 		line-height: 20px;
					// // 		max-width: 120px;
					// // 		white-space: nowrap;
					// // 		text-overflow: ellipsis;
					// // 		overflow: hidden;
					// // 	}
					// // 	.login {
					// // 		margin-left: 10px;
					// // 		font-size: 14px;
					// // 		color: #f65932;
					// // 		max-width: 80px;
					// // 		white-space: nowrap;
					// // 		text-overflow: ellipsis;
					// // 		overflow: hidden;
					// // 	}
					// // }
					// // .lane {
					// // 	display: flex;
					// // 	flex-direction: row;
					// // 	align-items: center;
					// // 	.flag-icon {
					// // 		width: 18px;
					// // 		height: 18px;
					// // 		border-radius: 50%;
					// // 		margin-right: 10px;
					// // 	}
					// // }
					// .lane-name {
					// 	margin: 0 7px 0 0;
					// 	font-size: 16px;
					// 	line-height: 1.2em;
					// 	max-width: 210px;
					// 	white-space: nowrap;
					// 	text-overflow: ellipsis;
					// 	overflow: hidden;
					// }
			// 	}
			// }
		}
	}
	.gui {
		display: flex;
		flex: 1 1 100%;
		font-size: 14px;
		overflow: hidden;
		min-height: 25vw;
		.cameras {
			flex: 1 1 66%;
		}
		.right {
			flex: 1 1 33%;
			display: flex;
			flex-direction: column;
			position: relative;
			&::before {
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				width: 1px;
				content: '';
				opacity: .6;
				background: linear-gradient(0deg,hsla(0,0%,100%,.2),#fff 50%,hsla(0,0%,100%,.2));
				z-index: 1;
			}
			.adding-results {
				flex: 0 0 138px;
				position: relative;
				&::after, &::before {
					height: 1px;
					left: 0;
					right: 0;
					content: '';
					z-index: 1;
					position: absolute;
				}
				&::before {
					top: 0;
					background: linear-gradient(90deg,hsla(0,0%,100%,.2),#fff 50%,hsla(0,0%,100%,.2));
					opacity: 0.4;
				}
				&::after {
					bottom: 0;
					background: linear-gradient(90deg,rgba(246,89,50,.5) 30%,hsla(0,0%,100%,.7) 50%,rgba(246,89,50,.5) 70%);
				}
				.addingHeader {
					display: flex;
					flex-direction: row;
					height: 31px;
					align-items: center;
					justify-content: space-between;
					padding-left: 10px;
					background-color: #343d49;
					position: relative;
					&-right {
						display: flex;
						flex-direction: row;
						.edit-canceler {
							position: relative;
							right: 0;
							top: 0;
							height: 31px !important;
							z-index: 1;
							width: 110px;
							display: flex;
							justify-content: center;
							align-items: center;
							color: #F65932;
							font-size: 14px;
							cursor: pointer;
							&:after {
								position: absolute;
								left: 0;
								top: 0;
								bottom: 0;
								width: 1px;
								content: '';
								opacity: .6;
								background-image: linear-gradient(0deg,hsla(0,0%,100%,.2),#fff 50%,hsla(0,0%,100%,.2));
							}
							&:before {
								content: url('/cancel.png');
								margin-right: 10px;
							}
						}
						& > .turn-timer-container {
							// position: absolute;
							display: flex;
							justify-content: center;
							align-items: center;
							top: 0;
							bottom: 0;
							right: 0;
							padding: 0 20px;
							font-family: Oswald;
							letter-spacing: 1.4px;
							font-weight: 500;
							min-width: 80px;
							position: relative;
							&::before {
								background: linear-gradient(0deg,hsla(0,0%,100%,.2),#fff 50%,hsla(0,0%,100%,.2));
								width: 1px;
								left: 0;
								top: 0;
								bottom: 0;
								opacity: 0.4;
								content: '';
								position: absolute;
							}
						}
					}
				}
			}
			.chat-wrapper {
				flex: 1 1 100%;
				position: relative;
				height: 100%;
				overflow: hidden;
				.badge {
					background: #f65932;
					display: inline-flex;
					justify-content: center;
					align-items: center;
					font-size: 10px;
					height: 18px;
					min-width: 18px;
					border-radius: 9px;
					margin-left: 5px;
				}
				.message-input {
					height: initial;
				}
				.react-tabs__tab {
					font-family: Open Sans;
					font-style: normal;
					font-weight: 400;
					font-size: 14px;
					line-height: 19px;
					color: hsla(0,0%,100%,.6);
					flex: none;
					padding: 4px 10px;
					text-transform: none;
					max-width: none;
					&--selected {
						color: #fff;
					}
					&--disabled {
						opacity: 1;
					}
					.checkbox {
						justify-content: flex-end;
						line-height: 16px;
						opacity: 1;
						margin-right: 5px;
						img {
							width: 100%;
						}
					}
				}
				.react-tabs {
					display: flex;
					flex-direction: column;
					height: 100%;
					.react-tabs__tab-list {
						background: #343d49;
						margin: 0;
					}
				}
			}
		}
	}
	.footer {
		flex: 0 0 auto;
		padding: 0 20px;
		height: 42px;
		display: flex;
		justify-content: space-between;
		position: relative;
		&::before {
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			height: 1px;
			content: '';
			opacity: .6;
			background: linear-gradient(90deg,hsla(0,0%,100%,.2),#fff 50%,hsla(0,0%,100%,.2));
	}
		.left-buttons {
			padding: 6px 0;
			display: flex;
			button, .protest__button {
				width: 150px;
			}
		}
		& > .turn-timer-container {
			display: flex;
			justify-content: center;
			padding-left: 20px;
			min-width: 80px;
			position: relative;
			letter-spacing: 1.4px;
			font-weight: 500;
			font-size: 14px;
			opacity: .6;
			font-family: Oswald;
			align-items: center;
			height: 100%;
			&::before {
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					width: 1px;
					content: '';
					opacity: .6;
					background: linear-gradient(0deg,hsla(0,0%,100%,.2),#fff 50%,hsla(0,0%,100%,.2));
			}
		}
	}
}

.addingResults {
	.keyboardLock {
		height: 90px;
		width: 100%;
		padding: 10px 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		.title {
			font-family: Oswald;
			font-size: 20px;
			font-weight: 500;
			letter-spacing: 2px;
			color: #fff;
		}
		.content {
			opacity: .4;
			font-size: 14px;
			line-height: 20px;
			color: #fff;
		}
	}
	.finish__inner {
		display: flex;
		padding: 10px 15px;
		align-items: center;
		justify-content: center;
		.finish__readiness, .finish__info {
			display: flex;
			flex-direction: column;
			justify-content: center;
			flex: 1 1 100%;
			.finish__text {
				opacity: .4;
				font-size: 12px;
				margin-bottom: 7px;
			}
		}
		.finish__readiness {
			.finish__playercard {
				display: flex;
				flex-direction: row;
				&.owner {
					.finish__check-opponent {
						background: linear-gradient(180deg,#f65932,#f59c86);
					}
				}
				&:not(:last-child) {
					margin-bottom: 10px;
				}
				span {
					margin-left: 10px;
					flex: 1 1;
					display: flex;
					align-items: center;
					font-size: 12px;
				}
				.avatar {
					width: 30px;
					min-width: 30px;
					height: 30px;
					flex: 0 0 30px;
					img {
						width: 30px;
					}
					circle {
						transform: scale(0.9);
    					transform-origin: 50%;
					}
				}
				.finish__check-opponent {
					display: flex;
					justify-content: center;
					align-items: center;
					height: 30px;
					width: 30px;
					flex: 0 0 30px;
					border-radius: 15px;
					background: linear-gradient(180deg,#20a39e,#01677e);
				}
			}	
		}
		.finish__info {
			.finish__buttons {
				display: flex;
				flex-wrap: wrap;
				& > div {
					flex: 0 0 100%;
				}
				& > div:not(:last-child) {
					margin-bottom: 5px;
				}
				.finish__buttonedit {
					display: flex;
					flex-direction: row;
					& > button {
						color: #fff;
						&:first-child {
							border-right: none;
						}
					}
				}
			}
		}
	}
	.addingBtnContainer {
		display: grid;
		grid-template-columns: repeat(10, 1fr);
		grid-gap: 4px;
		padding: 4px 4px 0 4px;
		.addingBtnContainer-btn {
			background: #f65932;
			color: #fff;
			cursor: pointer;
			padding: 7px 0;
			border: none;
			border-radius: 2px;
			box-sizing: initial;
			opacity: 1;
			transition: opacity .3s;
			grid-column: span 2;
			font-size: 14px;
			&.addingBtnContainer-btn[disabled] {
				opacity: .3;
				cursor: initial;
			}
		}
		.addingBtnContainer-btn-big {
			grid-column: span 4;
		}
		.addingBtnContainer-btn-small {
			grid-column: span 1;
		}
	}
}

.rating_body {
	.opponent_rating {
		display: flex;
		flex-direction: column;
		&_stars {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 12px 10px;
			background: rgba(255, 255, 255, 0.1);
			border-radius: 3px;
			margin: 10px 0;
		span {
			flex: 1 0 50%;
			padding-right: 20px;
			font-size: 14px;
			max-width: calc(100% - 140px);
			min-width: calc(100% - 300px);
			font-family: 'Oswald';
			color: #fff;
			letter-spacing: 0.1em;
		}
		&_container {
			flex: 1 1 50%;
			min-width: 140px;
			max-width: 300px;
			position: relative;
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-direction: row-reverse;
			svg {
			transition: fill ease-out 0.5s;
			fill: transparent;
			cursor: pointer;
			}
			svg.selected, svg.selected ~ svg {
			fill: #FFB31E;
			}
			svg:hover {
			&, & ~ svg {
				fill: #FFB31E88 !important;
			}  
			}
		}
		}
		&_form {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			& > * {
				margin-bottom: 10px;
			}
			textarea {
				flex: 0 0 100%;
				max-width: 100%;
				min-width: 100%;
				min-height: 76px;
				max-height: 76px; //resize block
				border: solid 1px #6d7177;
				font-size: 12px;
				line-height: 20px;
				color: #fff;
				padding: 10px;
				background-color: initial;
				display: block;
				box-sizing: border-box;
				resize: none;
			}
			button {
				flex: 0 0 calc(50% - 5px);
			}
		}
	}
}

.pauseError {
	padding: 15px 0;
}