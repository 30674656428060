/**
    Horizontal
 */

    /*Banner Card Style*/

.banner {
    display: flex;
    flex-direction: column;
    max-width: 393px;
    margin: auto;
    height: 220px;
}

.banner_image {
    max-width: 343px;
    max-height: 193px;
    margin: auto;
}

.banner_description {
    margin: auto ;
    align-self: center;
    font-family: Oswald;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 30px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.05em;
    color: #FFFFFF;
}

    /*Carousel Style*/

.carousel_container {
    display: flex;
    flex-direction: row;
    max-width: 70%;
    margin: 40px auto auto auto;
}

.carousel_slide_place {
    width: 100%;
    overflow: hidden;
}

.carousel_slide_place .slick-track {
    display: flex;
    flex-direction: row;
    width: 100%;
}
.btn-carousel {
    background: #0D141D;
    border: none;
}


.arrow-left {
    transform: rotate(180deg);
    width: 34px;
    height: 34px;
}

.arrow-right {
    width: 34px;
    height: 34px;
}

@media screen and (max-width: 1700px) {
    .carousel_container {
        max-width: 90%
    }
}

@media screen and (max-width: 1300px) {
    .carousel_container {
        max-width: 100%;
    }
}

@media screen and (max-width: 815px) {
    .btn-carousel {
        display: none;
    }
}

/*
    Vertical
 */

    /*Banner Card style*/

.banner-vertical {
    display: flex;
    flex-direction: column;
    max-width: 393px;
    margin: auto;
    height: 200px;
}

.banner_image-vertical {
    width: 296px;
    height: 167px;
    margin: auto;
}

    /*Carousel Style*/

.carousel_container-vertical {
    display: flex;
    flex-direction: column;
    min-width: 300px;
}

.carousel_slide_place-vertical {
    width: 100%;
    overflow: hidden;
}

.arrow-up {
    transform: rotate(-90deg);
    width: 34px;
    height: 34px;
}

.arrow-down {
    transform: rotate(90deg);
    width: 34px;
    height: 34px;
}

@media screen and (max-width: 1060px) {
    .banner-vertical {
        height: auto;
    }
    .carousel_container-vertical {
        display: flex;
        flex-direction: row;
        max-width: 90%;
        margin: 40px auto auto auto;
    }
    .carousel_slide_place-vertical .slick-track {
        display: flex;
        flex-direction: row;
        width: 100%;
    }
    .arrow-down {
        transform: none;
    }
    .arrow-up {
        transform: rotate(-180deg)
    }

}



