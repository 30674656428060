.report_protest_opponent_container {
  padding-top: 10px;
}

.report_protest_opponent_container .opponent {
  padding: 0px;
  background-color: transparent;
}

.report_protest_reason_container {
  display: flex;
  justify-content: space-between;
}

.report_protest_reason_time,
.report_protest_reason_time_label {
  text-align: right;
}

.report_protest_reason_text,
.report_protest_reason_time,
.report_protest_reason_context,
.report_protest_reason_comment {
  margin-top: 5px;
  color: #fff;
  font-size: 14px;
  font-family: 'Open Sans';
  font-weight: 200;
  letter-spacing: 0.5px;
}

.report_protest_context_container {
  margin-top: 10px;
  margin-bottom: 10px;
}

.report_protest_comment_container, .report_protest_additional_text_container {
  padding: 10px 0px 10px 0px;
}
