.yellowCardCaption {
  padding: 10px 0;
  text-align: center;
}

.yellowCardImage {
  height: 150px;
  margin: auto;
  margin-top: 40px;
  margin-bottom: 40px;
  display: block;
}