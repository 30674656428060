.tournamentCreate_name, .tournamentCreate_desc {
    padding-top: 10px;
    padding-bottom: 10px;
    #costInput {
        margin-bottom: 15px;
    }
    .ant-radio-group {
        display: flex;
        flex-direction: row;
        & > * {
            flex: 1;
        }
    }
    label {
        font-size: 14px;
        color: #FFFFFF;
        margin-bottom: 10px;
        display: block;
        &.checkbox {
            display: flex;
        }
    }
    input {
        width: 100%;
        box-sizing: border-box;
    }
    textarea {
        min-height: 150px;
    }
    .datepickers {
        display: flex;
        flex-direction: row;
        margin-bottom: 15px;
    }
    .datepicker {
      width: 82px !important;
    }
    .timepicker {
      width: 140px !important;
    }
    .dategroup {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        flex: 1;
        &:not(:last-child) {
            margin-right: 20px;
        }
        & > label {
            flex: 0 0 100%;
        }
        & > div {
            flex: 1 1 0;
            margin-right: 5px;
        }
        & > span {
            flex: 0 0 140px;
        }
    }

    .error-border {
      input {
        border-color: red !important;
        background-color: rgba(246, 89, 50, 0.2);
      }
    }

    .error {
      border-color: red !important;
      background-color: rgba(246, 89, 50, 0.2);
    }

    .win-count-group {
      display: flex;
      flex-direction: column;
      margin-top: 34px;
      margin-bottom: 9px;
    }
}

.error-message-footer__container {
  min-height: 36px;
  line-height: 36px;
  padding-left: 8px;
  padding-right: 8px;
  vertical-align: middle;
  font-size: 14px;
  font-weight: 300;
  background-color: rgba(246,89,50,0.2);
  color: #fff;
  margin-bottom: 10px;
}

.modal-footer {
  display: flex;
  flex-direction: column;

  .buttons_wrap {
    display: flex;
    flex-direction: row;
  }
}

.tournament-create {
  &__timezone {
    margin-bottom: 10px;
  }
}

.create-tournament-modal {
  overflow: auto !important;
  .ant-modal-body {
    z-index: 2;
    flex: 0 0 auto !important;
    overflow: visible !important;
    background-image: linear-gradient(to top, #0c131c, #0d141d);
    min-height: initial !important;
  }

  .ant-modal {
    overflow: visible !important;
  }

  .ant-modal {
    overflow: visible !important;
  }

  .ant-modal-content {
    overflow: visible !important;
  }

  .ant-modal-header {
    flex: 0 0 auto !important;
    background-image: linear-gradient(to top, #0c131c, #0d141d);
  }

  .ant-modal-footer {
    flex: 0 0 auto !important;
    background-image: linear-gradient(to top, #0c131c, #0d141d);
  }
}