input {
  border-radius: 0;
}

.appWrap {
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #0A101A;
}

.align-right {
  text-align: right;
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

//modal overwrite
.ant-modal-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 15px;
  .ant-modal {
    top: initial;
    padding: 0;
    margin: 0;
    overflow: hidden;
    max-height: 100%;
    .ant-modal-content {
      display: flex;
      flex-direction: column;
      max-height: calc(var(--vh, 1vh) * 100 - 30px);
      .ant-modal-header, .ant-modal-footer {
        flex: 0 0 auto;
      }
      .ant-modal-body {
        flex: 1 1 auto;
        overflow: auto;
        min-height: 50px;
        // padding-bottom: 3px;
      }
    }
  }
}

.ant-table-column-sorters {
  display: flex;
  justify-content: space-between;
}

.ant-pagination { 
  display: flex; 
  & > li { 
    margin-right: 10px; 
    border: 1px orangered solid; 
    border-radius: 3px; 
    display: flex; 
    justify-content: center; 
    align-items: center; 
    height: 30px; 
    min-width: 30px; 
    box-sizing: border-box; 
    opacity: 0.7; 
    transition: opacity 0.5s ease-in-out; 
    a { 
      display: flex; 
      justify-content: center; 
      align-items: center; 
      padding: 0 5px; 
      width: 100%; 
    } 
    &:hover { 
      opacity: 1; 
    } 
  } 
  .ant-pagination-item-active { 
    background: #f6593266; 
  } 
}

.auth-pages__wrap {
  display: flex;
  flex: 1;
  position: relative;
  flex-direction: column;
  margin: 0 auto;
  width: 100vw;
}