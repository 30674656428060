.right-wrapper {
  max-width: 510px;
  padding-top: 50px;
  font-style: normal;
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #FFFFFF;
  &__text &__bottom-text {
    max-width: 507px;
  }
  &__text {
    margin-bottom: 20px;
  }

  &__bottom-text {
    text-align: center;
    color: #D0D2D5;
  }

  &__title {
    font-family: 'Oswald';
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  & .error-message {
    font-size: 12px;
    line-height: 16px;
    color: #FF7373;
    margin-bottom: 20px;
  }

  & form {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
    margin-top: 0;

    & div {
      display: flex;
      flex-direction: row;
    }

    & .submit-btn {
      height: 60px;
      background: linear-gradient(264.59deg, #F65932 0%, #CC3535 100%);
      border-radius: 5px;
      border: none;
      font-family: 'Oswald';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: #FFFFFF;
      margin-bottom: 10px;
    }
  }

  & input {
    box-sizing: border-box;
    background-color: #555C65;
    height: 40px;
    color: #FFFFFF;
    margin-bottom: 10px;
    border-radius: 10px 10px 0 0;
    border: none;
    border-bottom: 1px solid #FFFFFF;
    width: 100%;
    padding-left: 15px;

    &::placeholder {
      color: #D0D2D5;
    }

    &:first-child {
      margin-right: 10px;
    }
    &#investor-additional {
      height: 62px;
    }
    &.required {
      border-bottom: 1px solid #F65932;
    }
    &.invalid {
      border: 1px solid red;
    }
  }
}

@media (max-width: 768px){
  .right-wrapper {
    &__content {
      padding-left: 20px;
      max-width: 280px;
    }
    &__text {
      line-height: 22px;
    }
    &__title {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 10px;
    }
    & form {
      & div {
        flex-direction: column;
      }

      & .submit-btn{
        height: 40px;
      }
    }
    &__bottom-text {
      line-height: 19px;
      margin-bottom: 27px;
    }
  }
}

@media (max-width: 599px) {
  .right-wrapper {
    padding-top: 0;
    display: flex;
    justify-content: center;
    &__content {
      padding-left: 0;
      max-width: 95%;
    }
    &__bottom-text {
      font-size: 14px;
    }
  }
}