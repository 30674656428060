.with-icons {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    &__left {
        display: flex;
        align-items: center;
    }
    &__left-icon {
        margin-right: 4px;
        display: flex;
        align-items: center;
    }

    & .filterState {
        & > div {
            display: flex;
            width: 0;
            height: 0;
            border-style: solid;
        }
        .up {
            border-width: 0 6px 6px 6px;
            border-color: transparent transparent #f65932 transparent;
            margin-bottom: 2px;
        }
        .down {
            border-width: 6px 6px 0 6px;
            border-color: #f65932 transparent transparent transparent;
        }
    }
}

.table-header-cell__date-group {
    display: flex;
    width: 400px;
    height: 100%;
    margin-top: 10px;
    justify-content: space-between;
}
