.ant-modal-body {
  padding: 0;
}

.ant-tooltip {
  display: none;
}

.modal-group {
}
.image-crop {

  &__dropzone {
    margin-bottom: 15px;
  }

  &__params {

    & > div {
      display: flex;
      justify-content: space-between;
    }
  }

  &__new-image {
    .image-crop__input {

      & > div {
        width: 50%;
      }
  
      & > button {
        width: 50%;
        margin-left: 5px;
        cursor: pointer;
      }
  
      & > input {
        display: none;
      }
    }    
  }

  &__zoom {
    margin-bottom: 10px;

    .image-crop__header {
      height: 36px;
      line-height: 36px;
    }

    .ant-slider {
      width: 100%;
    }
  }

  &__rotate {
    .image-crop__input {
      button:nth-last-child(1) {
        margin-left: 5px;
      }
    }
  }

  &__header {
    opacity: 0.6;
    font-size: 12px;
    color: white;
    height: 22x;
    line-height: 22px;
    width: 100px;
  }

  &__input {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}