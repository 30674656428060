.transactionScreen {
  height: 100%;
  display: flex;
  flex-direction: column;
  button {
    height: 36px;
    & > div { width: 100% }
  }
  .react-tabs {
    flex: 1;
    display: flex;
    flex-direction: column;
    .react-tabs__tab-panel {
      &, &--selected {
        overflow: auto;
      }
    }
    .react-tabs__tab-panel--selected {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      padding: 30px 40px 20px;
      justify-content: space-between;
      align-items: flex-start;
      .transactionWrap {
        flex: 0 0 auto;
        display: flex;
        flex-direction: row;
      }
      .transactionForm {
        height: auto;
        flex: 0 0 auto;
        background: #242B33;
        border-radius: 3px;
        padding: 15px 20px 30px;
        width: 320px;
        margin-bottom: 15px;
        &:not(:last-child) {
          margin-right: 10px;
        }
        button {
          padding: 10px;
          font-size: 14px;
        }
        .label {
          margin-bottom: 15px;
          letter-spacing: 0.05em;
          color: #FFFFFF;
          font-size: 20px;
          font-family: 'Oswald';
        }
        .inputgroup {
          display: flex;
          align-items: center;
          margin-bottom: 15px;
          input {
            margin-right: 10px;
            flex: 1;
            border: 1px solid #6D7177;
            background: none;
            color: #fff;
            padding: 5px 10px;
          }
        }
      }
      .badges {
        flex: 0 0 auto;
        display: flex;
        .groupname {
          flex: 0 0 auto;
          opacity: 0.6;
          font-size: 14px;
          margin-bottom: 10px;
        }
        .group {
          flex: 1;
        }
        .logos {
          margin-right: 30px;
          .group {
            justify-content: flex-start;
          }
          .item:not(:last-child) {
            margin-right: 20px;
          }
        }
        .group {
          display: flex;
          justify-content: space-between;
        }
        .sia .group {
          flex-direction: column;
        }
        .logos, .sia {
          display: flex;
          flex-direction: column;
          .item {
            font-size: 12px;
            opacity: 0.6;
          }
        }
      }
    }
  }

  .buttonlike {
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    width: calc(100% + 20px);
    margin: -10px;
    &.disabled {
      pointer-events: none;
      opacity: .4;
    }
  }
  .commission {
    margin-top: -5px;
    margin-bottom: 20px;
    span, b {
      display: block;
      margin: 0;
      font-size: 14px;      
    }
  }
}

input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input.error {
  background-color: rgba(246,89,50,.2) !important;
  border-color: rgba(246,89,50,1) !important;
}

.paymentText {
  font-size: 14px;
  line-height: 19px;
  color: '#fff';
  img {
    display: block;
    margin: 20px auto 30px auto;
  }
}