.visibility__button {
  background-color: rgba(0, 0, 0, 0);
  border: none;
}

.icon-sizer-off {
  width: 24px;
  height: 24px;
}

.icon-sizer-on {
  width: 20px;
  height: 14px;
}

.idColor {
  color: #f65932;
}