.n-table .ant-table-thead th {
  position: relative;
  padding-top: 18px;
  padding-bottom: 18px;
}
.n-table-container {
  position: absolute;
  /* position: fixed; */
  top: 0;
  left: 0;
  width: 100%;
}
.n-table-title {
  padding-right: 20px;
}
.n-table-title p {
  margin: 0;
}
.n-table-filter-icon {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 100%;
  background-color: transparent;
  cursor: pointer;
  transition: background-color .3s ease;
}
.n-table-filter-icon:hover {
  background-color: rgba(255, 255, 255, .05);
}
.n-table__icon {
  width: 12px;
  height: 12px;
}
.n-table__icon svg {
  width: 100%;
  height: 100%;
  fill: rgba(255,255,255,.4);
  transition: fill .3s ease;
}
.n-table-filter-icon:hover svg,
.n-table .n-table__icon.active svg {
  fill: #f65932;
  color: #f65932;
}
.n-table-filter__mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
}
.n-table-filter__mask.active {
  display: block;
}
.n-table-filter {
  position: absolute;
  top: -9999px;
  left: -9999px;
  padding: 10px;
  color: #fff;
  width: 120px;
  min-width: 120px;
  background-color: rgba(37, 43, 50, .95);
  box-shadow: 0 3px 6px -4px rgba(0,0,0,0.8), 0 6px 16px 0 rgba(0,0,0,.6), 0 9px 28px 8px rgba(0,0,0,.1);
}
.n-table-filter__input input {
  font-size: 14px;
}
.n-table-filter__footer {
  display: flex;
  margin-left: -4px;
  margin-right: -4px;
  justify-content: space-between;
  margin-top: 10px;
}
.n-table-filter__footer button {
  padding-top: 7px;
  padding-bottom: 7px;
  margin-left: 4px;
  margin-right: 4px;
  font-size: 14px;
  line-height: 1.2em;
}
.n-table-filter__options ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.n-table-filter__options li {
  margin-bottom: 10px;
}
.n-table-filter__options li:last-child {
  margin-bottom: 0;
}
.n-table-filter__options .checkbox {
  line-height: 18px;
}
.n-table-filter__options .checkbox .checkbox__checkmark__small {
  flex: 0 0 16px;
  height: 16px;
  width: 16px;
}
.n-table-filter__options .image__small {
  width: 16px;
  height: 16px;
}

.n-table-filter__label {
  padding-top: 2px;
  padding-bottom: 10px;
  margin: 0;
  font-size: 12px;
  line-height: 1.2em;
}
.n-table-filter__range input {
  margin-bottom: 4px;
}
.n-table-filter__range input {
  font-size: 14px;
  width: calc(100% - 22px);
}
.n-table-filter__range .date-group__elements__picker {
  padding-left: 0;
}
.n-table-filter__range input:last-child {
  margin-bottom: 0;
}
.n-table-filter__range .input__tip {
  display: none;
}

.ant-table-placeholder {
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: center;
}

