.opponent {
    padding: 10px 15px;
    background-color: #25292c;
    border-bottom: solid 1px #343d49;
}

.opponent:nth-last-child(1) {
  border: none;
}

.opponent .opponent__info,
.opponent .opponent__buttons {
    display: flex;
    justify-content: space-between;
}

.opponent .opponent__info > div {
  display: flex;
  width: 100%;
}

.opponent .opponent__info .opponent__avatar {
  height: 50px;
  width: 50px;
  position: relative;
}

.opponent .opponent__info .opponent__avatar .opponent__image {
  width: 48px;
  height: 48px;
  padding: 1px;
  background: linear-gradient(180deg, #F65932 0%, #F59C86 100%);
  border-radius: 50%;
  object-fit: cover;
}

.opponent .opponent__info .opponent__avatar .opponent__flag {
  width: 16px;
  height: 16px;
  position: absolute;
  bottom: -1px;
  right: -7px;
  border-radius: 8px;
  overflow: hidden;
}

.opponent .opponent__info .opponent__avatar .opponent__flag span{
  display: block;
  width: 100% !important;
  height: 100%;
  background-size: contain;
}

.opponent .opponent__info .opponent__name {
  margin-left: 16px;
  width: 100%;
  overflow: hidden;
}

.opponent .opponent__info .opponent__name svg{
  margin-right: 3px;
}

.opponent .opponent__info .opponent__name .opponent__fullname {
  height: 20px;
  font-size: 14px;
  line-height: normal;
  color: #fff;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.opponent .opponent__info .opponent__name .opponent__nickname {
  height: 20px; 
  font-size: 12px; 
  color:#f65932;
  line-height: normal;
  cursor: pointer;
}

.opponent .opponent__info .opponent__actions {
  display: flex;
  flex-direction: column;
}

.opponent .opponent__info .opponent__actions > button {
  background: transparent;
  width: 30px;
  height: 30px;
  padding: 0;
  border: solid 1px #20a39e;
}

.opponent .opponent__info .opponent__actions > button:hover {
  background: #20a39e;
  cursor: pointer;
}

.opponent .opponent__info .opponent__actions > button:hover svg path {
  fill: white;
}

.opponent .opponent__buttons {
  margin-top: 10px;
}

.opponent .opponent__buttons .opponent__button {
  font-size: 14px;
}

.opponent .opponent__buttons .opponent__button:nth-last-child(1) {
  margin-left: 11px;
}

.avgscore {
  color: #fff;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  height: 14px;
  width: 25px;
  background: #252B32;
  border-radius: 7px;
  position: absolute;
  bottom: 0;
  left: -10px;
}