.protocol_decision_modal {

  .scores {

    .player {
      display: flex;
      align-items: center;
      margin: 20px 0;
  
      .player_name {
        text-align: right;
        max-width: 175px;
        width: 175px;
        overflow: hidden;
  
        .player_fullname {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 22px;
          color: #FFFFFF;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
  
      .player_avatar {
        margin-left: 15px;
  
        .avatar {
          width: 60px;
          height: 60px;
          min-width: 60px;
          margin-right: 14px;
          border-radius: 50%;
  
          &>img {
            width: 100%;
            border-radius: 50%;
            border: 2px solid transparent;
            box-sizing: border-box;
          }
  
          .avatar__flag {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            right: -14px;
            bottom: 0;
          }
        }
      }
  
      .player_score {
        font-family: Oswald;
        font-style: normal;
        font-weight: 500;
        font-size: 50px;
        line-height: 60px;
        text-align: center;
        letter-spacing: 0.1em;
        color: #FFFFFF;
        flex: 1;
      }
  
      &.winner {
  
        .player_avatar .avatar {
          background-image: linear-gradient(180deg, #f65932, #f59c86);
        }
  
        .player_score {
          color: #F65932;
        }
      }
  
      &.loser {
  
        .player_avatar .avatar {
          background-image: linear-gradient(180deg, #20a39e, #01677e);
        }
      }
    }
  }

  .decision-result {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    padding: 10px 0;
  }

  .ant-modal-footer {
    border-top: 1px solid #25292c;
  }

}