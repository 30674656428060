.commonerror {
  padding: 10px 0;
  &:not(.trace) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    label.checkbox {
      span{
        transform: scale(0.6);
        margin-right: 0;
      }
    }
  }
  p {
    margin: 0;
  }
}