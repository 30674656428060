.react-tabs {
  -webkit-tap-highlight-color: transparent;
}

.react-tabs .react-tabs__tab-list {
  border-bottom: 1px solid #25292c;
  margin: 0 0 10px;
  padding: 0;
  display: flex;
  flex: 0 0 auto;
}

.react-tabs .react-tabs__tab {
  font-size: 20px;
  font-family: Oswald;
  color: #6d7177;
  max-width: 250px;
  flex: 1;
  display: inline-block;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 12px 0px 10px;
  cursor: pointer;
  text-transform: uppercase;
  text-align: center;
}

.react-tabs .react-tabs__tab--selected {
  color: #f65932;
  border: none;
  border-bottom: 3px solid #f65932;
}
.react-tabs .react-tabs__tab-panel {
  height: calc(100% - 60px);
}
/*.react-tabs .react-tabs__tab-panel > * {
  height: 100%;
}*/

.react-tabs .react-tabs__tab--disabled {
  /* color: GrayText; */
  cursor: default;
  opacity: .4;
}

.react-tabs .react-tabs__tab:focus {
  outline: none;
}

.react-tabs .react-tabs__tab-panel {
  display: none;
}

.react-tabs .react-tabs__tab-panel--selected {
  display: block;
  flex: 1;
}



.react-bottom-tabs {
  -webkit-tap-highlight-color: transparent;
  background-color: #25292c;
  width: 100%;
}

.react-bottom-tabs .react-tabs__tab-list {
  bottom: 0;
  background-color: #0a101a;
  margin: 0 0 10px;
  padding: 0;
  display: flex;
}

.react-bottom-tabs .react-tabs__tab {
  font-size: 20px;
  font-family: Oswald;
  height: 42px;
  position: relative;
  list-style: none;
  cursor: pointer;
  text-transform: uppercase;
  text-align: center;
  flex-basis: 33.33%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-bottom-tabs .react-tabs__tab--selected {
  color: #f65932;
  border: none;
  background-color: #25292c;
}
.react-bottom-tabs .react-tabs__tab--selected svg path {
  fill: #f65932;
}

.react-bottom-tabs .react-tabs__tab--disabled {
  color: GrayText;
  cursor: default;
}

.react-bottom-tabs .react-tabs__tab:focus {
  outline: none;
}

.react-bottom-tabs .react-tabs__tab-panel {
  display: none;
}

.react-bottom-tabs .react-tabs__tab-panel--selected {
  display: block;
  position: relative;
  max-height: calc(100% - 42px);
}
