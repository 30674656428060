.create_battle_tutotial_modal {
  overflow: visible !important;
}

.create_battle_tutotial_modal,
.join_battle_tutotial_modal,
.confirm_battle_modal {

  .ant-modal-header,
  .modal-group {
    border: none;
  }

  .ant-modal-body {
    padding-bottom: 0;
  }

  .ant-modal-footer {
    padding: 10px 20px 15px 20px;
  }

  &__opponent {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 20px 0;

    .opponent {
      background-color: transparent;
      padding: 0;
      border: none;
      width: 100%;

      &__info {
        width: 100%;

        &__avatar {
          box-sizing: border-box;
        }
      }

      &__avatar {
        height: auto !important;
        width: auto !important;
        padding: 4px !important;
        background: linear-gradient(180deg, #6CAE5C, #3EC63C) !important;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
      }

      &__image {
        width: 110px !important;
        height: 110px !important;
        padding: 4px !important;
        background: #0C131C !important;
        border-radius: 50% !important;
        box-sizing: border-box;
      }

      &__flag {
        width: 33px !important;
        height: 33px !important;
        border-radius: 50% !important;
        border: 4px solid #0C131C;
      }

      &__name {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin-left: 20px !important;
        overflow: hidden;
        flex: 1;
      }

      &__fullname {
        height: auto !important;
        font-size: 20px !important;
        line-height: 27px !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &__nickname {
        height: auto !important;
        font-size: 16px !important;
        line-height: 22px !important;
        cursor: default !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &__rating {
        width: 200px;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 8px;
        margin-bottom: 5px;

        svg {
          width: 20px;
          height: 20px;
        }

        &__avg {
          margin-left: 5px;
          font-size: 20px;
          line-height: 20px;
        }
      }
    }
  }

  &__image {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 20px 0;
  }

  &__arrow {
    position: absolute;
    bottom: 122px;
    right: -25px;
    width: 38px;
    height: 95px;
  }

  &__subtitle {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #FFFFFF;
    padding-left: 20px;
  }

  &__parameters {
    margin: 0 0 0 -20px;
  }

  &__table {
    display: flex;
    flex-direction: row;
    background: #CC3535;
    margin: 10px 0;
    padding: 10px 20px;
    width: calc(100% - 20px);
  }

  &__parameter {
    margin-right: 50px;

    &:last-child {
      margin: 0;
    }

    &__key {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      color: rgba($color: #FFFFFF, $alpha: .6);
    }

    &__value {
      font-family: 'Oswald';
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.03em;
      color: #FFFFFF;
    }
  }

  &__description {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #FFFFFF;
    padding-bottom: 20px;
    max-width: 350px;
  }

  &__checkbox {
    margin-top: 20px;

    .checkbox {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;

      &__checkmark {
        height: 20px;
        width: 20px;
        flex: 0 0 20px;
        margin-right: 7px;
      }

      &__checked>img {
        width: 100%;
      }
    }
  }
  &.open {
    width: 50%!important;
    border-right: 1px solid #25292c;
  }
}
.modal-opponent-info {
  height: auto;
    width: 100%;
    z-index: 10;
    transition: width 0.5s;
    position: relative;
    background-image: linear-gradient(0deg,#0c131c,#0d141d);
    box-sizing: border-box;
    padding: 0px 20px;
    padding-top: 20px;
  &.open{
    width: 50%;
    border-right: 1px solid #25292c;
  }
}
.modal-user_info .modal-group{
  padding: 0px;
} 
.modal-user_info.ant-modal-body{
  background-image: linear-gradient(0deg,#0c131c,#0d141d);
}