.registration-page,
.login-page {
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: url('../../images/bg.png') #000;
  background-size: cover;
}

.registration-page__content,
.login-page__content {
  display: flex;
  flex: 1;
}

.registration-page__left-column,
.login-page__left-column {
  width: 37.5%;
  padding-top: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
  background: rgba(0,0,0,0.4);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.leftcolumn__content__logo {
  width: 193px;
  height: 60px;
  margin-bottom: 30px;
}

.registration-page__left-column__content,
.login-page__left-column__content {
  max-width: 350px;
  padding-left: 15px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  flex: 1;
}

.registration-page__right-column,
.login-page__right-column {
  width: 62.5%;
  padding-left: 96px;
  display: flex;
  align-items: center;
}

.registration-page__right-column__content,
.login-page__right-column__content {
  width: 380px;
}

.registration-page .title-description {
  max-width: 260px;
  font-size: 14px;
  font-weight: 300;
  padding-top: 20px;
  line-height: normal;
}

.registration-page .input-group .input,
.login-page .input-group .input {
  margin-bottom: 20px;
}
.login-page .input-group .input:last-child {
  margin-bottom: 12px;
}

.registration-page .gray-text,
.login-page .gray-text {
  color: rgba(255,255,255,0.4);
  font-size: 16px;
  font-weight: 300;
}

.login-page .forgotPasswordLink {
  color: #fff;
  float: right;
  font-weight: 300;
  margin-bottom: 30px;
  font-size: 14px;
  letter-spacing: 0.5px;
}

.userAgreementNav {
  padding: 20px 20px 0 20px;
}

.userAgreementNav .headlink{
  margin-bottom: 10px;
  cursor: pointer;
}

.userAgreementText {
  padding: 20px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
  line-height: 170%;
}

.userAgreementText p {
  margin-bottom: 0;
}

.userAgreementText b {
  letter-spacing: 0.05em;
  font-family: 'Oswald';
  color: #fff;
  font-weight: normal;
  line-height: 1;
  font-size: 16px;
}

.userAgreementText input {
  margin-bottom: 20px;
}

.emailVerif input {
  margin-bottom: 0px;
}

.registration-page__right-title {
  display: none;
}

.registration-checkbox {
  padding-top: 18px;
  padding-bottom: 10px;
}

@media screen and (max-width: 768px) {
  .registration-page__content,
  .login-page__content {
    justify-content: center;
  }

  .registration-page__left-column,
  .login-page__left-column{
    display: none;
  }

  .registration-page__right-column,
  .login-page__right-column {
    padding-left: 0;
  }


  .registration-page__right-title {
    display: block;
    text-transform: uppercase;
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 44px;
    letter-spacing: 0.1em;
    margin-bottom: 30px;
    padding-top: 20px;
  }
  .registration-page__right-title::first-letter {
    color: #f65932;
  }
  .registration-checkbox {
    padding-top: 0;
    padding-bottom: 10px;
  }
}