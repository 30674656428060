@charset "UTF-8";
.myTournaments {
  height: 100%;
  display: flex;
  flex-direction: column; }
  .myTournaments__filters {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(100, 100, 100, 0.3);
    padding-top: 10px;
    padding-bottom: 5px;
    flex-wrap: wrap; }
  .myTournaments__filters-inputs {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 20px; }
  .myTournaments__filters-input {
    margin-right: 20px;
    margin-bottom: 5px; }
    .myTournaments__filters-input_type {
      width: 162px; }
    .myTournaments__filters-input_fee {
      margin-right: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: nowrap; }
    .myTournaments__filters-input_status {
      width: 200px;
      margin-bottom: 5px; }
  .myTournaments__filters-select-small {
    margin-right: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap; }
  .myTournaments__label  {
    opacity: .4; }
  .myTournaments__filters-reset {
    color: #f65932;
    opacity: 1;
    font-weight: 200;
    text-decoration: none;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    margin-left: 20px;
    font-size: 14; }
  .myTournaments__table {
    overflow: auto;
    flex: 1;
    background: linear-gradient(to top, #0c131c, #0d141d);
    height: calc(100% - 46px); }
  .myTournaments__status {
    display: flex;
    align-items: center;
    justify-content: flex-start; }
  .myTournaments__status-text {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start; }
  .myTournaments__status-buttons {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end; }
  .myTournaments__status-button-lobby {
    font-size: 14px;
    padding: 0;
    width: 150px;
    height: 30px; }
  .myTournaments__status-button-game {
    font-size: 14px;
    padding: 0;
    width: 150px;
    height: 30px; }
