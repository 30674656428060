.button {
  width: 100%;
  font-weight: 300;
  border: none;
  color: #fff;
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  padding-top: 18px;
  padding-bottom: 18px;
  font-size: 20px;

  background-color: #f65932;
  box-shadow: 0 10px 14px 0 rgba(246, 89, 50, 0.3);

  white-space: nowrap;
}
.button:hover {
  background-color: #ff3e18;
  box-shadow: 0 10px 14px 0 rgba(255, 62, 24, 0.4);
}
.button_aquamarine {
  background-color: #20a39e;
  box-shadow: none;
}
.button_aquamarine:hover {
  background-color: #00bfbf;
  box-shadow: none;
}
.button_green {
  background-color: #22AB00;
  box-shadow: none;
}
.button_green:hover {
  background-color: #22AB00;
  box-shadow: none;
}

.button-size_xs {
  padding-top: 3px;
  padding-bottom: 4px;
  font-size: 12px;

  border: 1px solid transparent;
  box-shadow: 0 5px 10px 0 rgba(246, 89, 50, 0.2);
}
.button-size_xs:hover {
  box-shadow: 0 5px 10px 0 rgba(255, 62, 24, 0.4);
}
.button-size_xs.button_aquamarine {
  box-shadow: none;
}
.button-size_xs.button_aquamarine:hover {
  box-shadow: none;
}

.button-size_md {
  padding-top: 6px;
  padding-bottom: 6px;
  font-size: 14px;

  border: 1px solid transparent;
  box-shadow: none;
}
.button-size_md:hover {
  box-shadow: none;
}
.button-size_md.button_aquamarine {
  box-shadow: none;
}
.button-size_md.button_aquamarine:hover {
  box-shadow: none;
}

.button-size_s {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 16px;

  border: 1px solid transparent;
  box-shadow: none;
}
.button-size_s:hover {
  box-shadow: none;
}
.button-size_s.button_aquamarine {
  box-shadow: none;
}
.button-size_s.button_aquamarine:hover {
  box-shadow: none;
}

.button-size_lg {
  padding-top: 18px;
  padding-bottom: 18px;
  font-size: 20px;

  border: 1px solid transparent;
  box-shadow: 0 10px 14px 0 rgba(246, 89, 50, 0.3);
}
.button-size_lg:hover {
  box-shadow: 0 10px 14px 0 rgba(255, 62, 24, 0.4);
}
.button-size_lg.button_aquamarine {
  box-shadow: none;
}
.button-size_lg.button_aquamarine:hover {
  box-shadow: none;
}

.button_glowing {
  -webkit-animation: glowing 1s infinite; 
  animation: glowing 1s infinite; 
}

.button_transparent {
  background-color: rgba(0, 0, 0, 0) !important;
  box-shadow: none;
}
.button_transparent.button_orange {
  color: #f65932;
  border: 1px solid #f65932;
}
.button_transparent.button_aquamarine {
  color: #20a39e;
  border: 1px solid #20a39e;
}
.button_transparent.button_aquamarine:hover {
  color: #fff;
  background-color: #00bfbf !important;
}

.button_transparent.button_aquamarine .button__icon path {
  fill: #20A39E;
}

.button_transparent.button_aquamarine:hover .button__icon path {
  fill: #fff;
}

.login-button {
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: none;
}
.login-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: none;
}

.button__icon {
  width: 18px;
  height: 18px;

}
.button__icon__withtext {
  margin-right: 12px;
}
.button_square {
  width: 35px;
}
.button_disabled {
  opacity: 0.4;
  pointer-events: none;
}

.button_transparent.button_aquamarine.button_loading,
.button_aquamarine.button_loading {
  border: none;
  color: white;
  background: transparent ;
  cursor: default;
}

.button_aquamarine.button_loading:hover,
.button_transparent.button_aquamarine.button_loading:hover {
  background: transparent;
}

.button_loading svg path {
  fill: #00bfbf;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */

.ant-spin {
  font-family: "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  color: #1890ff;
  vertical-align: middle;
  text-align: center;
  opacity: 0;
  position: absolute;
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: -webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), -webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  display: none;
}
.ant-spin-spinning {
  opacity: 1;
  position: static;
  display: inline-block;
}
.ant-spin-nested-loading {
  position: relative;
}
.ant-spin-nested-loading > div > .ant-spin {
  display: block;
  position: absolute;
  height: 100%;
  max-height: 360px;
  width: 100%;
  z-index: 4;
}
.ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -10px;
}
.ant-spin-nested-loading > div > .ant-spin .ant-spin-text {
  position: absolute;
  top: 50%;
  width: 100%;
  padding-top: 5px;
  text-shadow: 0 1px 2px #fff;
}
.ant-spin-nested-loading > div > .ant-spin.ant-spin-show-text .ant-spin-dot {
  margin-top: -20px;
}
.ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-dot {
  margin: -7px;
}
.ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-text {
  padding-top: 2px;
}
.ant-spin-nested-loading > div > .ant-spin-sm.ant-spin-show-text .ant-spin-dot {
  margin-top: -17px;
}
.ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-dot {
  margin: -16px;
}
.ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-text {
  padding-top: 11px;
}
.ant-spin-nested-loading > div > .ant-spin-lg.ant-spin-show-text .ant-spin-dot {
  margin-top: -26px;
}
.ant-spin-container {
  position: relative;
  -webkit-transition: opacity .3s;
  transition: opacity .3s;
  zoom: 1;
}
.ant-spin-container:before,
.ant-spin-container:after {
  content: "";
  display: table;
}
.ant-spin-container:after {
  clear: both;
}
.ant-spin-blur {
  pointer-events: none;
  user-select: none;
  overflow: hidden;
  opacity: 0.5;
  -webkit-filter: blur(0.5px);
  filter: blur(0.5px);
  /* autoprefixer: off */
  filter: progid\:DXImageTransform\.Microsoft\.Blur(PixelRadius\=1, MakeShadow\=false);
}
.ant-spin-blur:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #fff;
  opacity: 0.3;
  -webkit-transition: all .3s;
  transition: all .3s;
  z-index: 10;
}
.ant-spin-tip {
  color: rgba(0, 0, 0, 0.45);
}
.ant-spin-dot {
  position: relative;
  display: inline-block;
  font-size: 20px;
  width: 20px;
  height: 20px;
}
.ant-spin-dot i {
  width: 9px;
  height: 9px;
  border-radius: 100%;
  background-color: #1890ff;
  -webkit-transform: scale(0.75);
      -ms-transform: scale(0.75);
          transform: scale(0.75);
  display: block;
  position: absolute;
  opacity: 0.3;
  -webkit-animation: antSpinMove 1s infinite linear alternate;
          animation: antSpinMove 1s infinite linear alternate;
  -webkit-transform-origin: 50% 50%;
      -ms-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
}
.ant-spin-dot i:nth-child(1) {
  left: 0;
  top: 0;
}
.ant-spin-dot i:nth-child(2) {
  right: 0;
  top: 0;
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}
.ant-spin-dot i:nth-child(3) {
  right: 0;
  bottom: 0;
  -webkit-animation-delay: 0.8s;
          animation-delay: 0.8s;
}
.ant-spin-dot i:nth-child(4) {
  left: 0;
  bottom: 0;
  -webkit-animation-delay: 1.2s;
          animation-delay: 1.2s;
}
.ant-spin-dot-spin {
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg);
  -webkit-animation: antRotate 1.2s infinite linear;
          animation: antRotate 1.2s infinite linear;
}
.ant-spin-sm .ant-spin-dot {
  font-size: 14px;
  width: 14px;
  height: 14px;
}
.ant-spin-sm .ant-spin-dot i {
  width: 6px;
  height: 6px;
}
.ant-spin-lg .ant-spin-dot {
  font-size: 32px;
  width: 32px;
  height: 32px;
}
.ant-spin-lg .ant-spin-dot i {
  width: 14px;
  height: 14px;
}
.ant-spin.ant-spin-show-text .ant-spin-text {
  display: block;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ */
  .ant-spin-blur {
    background: #fff;
    opacity: 0.5;
  }
}
@-webkit-keyframes antSpinMove {
  to {
    opacity: 1;
  }
}
@keyframes antSpinMove {
  to {
    opacity: 1;
  }
}
@-webkit-keyframes antRotate {
  to {
    -webkit-transform: rotate(405deg);
            transform: rotate(405deg);
  }
}
@keyframes antRotate {
  to {
    -webkit-transform: rotate(405deg);
            transform: rotate(405deg);
  }
}

.anticon {
  display: inline-block;
  font-style: normal;
  text-align: center;
  text-transform: none;
  line-height: 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.anticon > * {
  line-height: 1;
}
.anticon svg {
  display: inline-block;
}
.anticon:before {
  display: none;
}
.anticon .anticon-icon {
  display: block;
}
.anticon-spin:before {
  display: inline-block;
  -webkit-animation: loadingCircle 1s infinite linear;
          animation: loadingCircle 1s infinite linear;
}
.anticon-spin {
  display: inline-block;
  -webkit-animation: loadingCircle 1s infinite linear;
          animation: loadingCircle 1s infinite linear;
}

@-webkit-keyframes loadingCircle {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes loadingCircle {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes glowing { 
  0% { background-color: #FF3300; box-shadow: 0 10px 14px 0 rgba(246,89,50,.3) }
  50% { background-color: #FF7957; box-shadow: 0 10px 14px 0 rgba(255,138,108,.3) }
  100% { background-color: #FF3300; box-shadow: 0 10px 14px 0 rgba(246,89,50,.3) } 
} 

@keyframes glowing { 
  0% { background-color: #FF3300; box-shadow: 0 10px 14px 0 rgba(246,89,50,.3) }
  50% { background-color: #FF7957; box-shadow: 0 10px 14px 0 rgba(255,138,108,.3) }
  100% { background-color: #FF3300; box-shadow: 0 10px 14px 0 rgba(246,89,50,.3) } 
} 