.bowlingcenters_container > * {
  margin: 10px;
}

.bowlingcenters_header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.bowlingcenters_header .bowlingcenters_list {
  width: 250px;
}
.bowlingcenters_list {
  /*background-color: #0f6674;*/
}
.bowlingcenters_header .bowlingcenters_lanes {
  width: 100px;
  margin-left: 10px;
}
.bowlingcenters_watch {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bowlingcenters_name {
  text-align: right;
}

.bowlingcenters_cameras {
  padding-top: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.bowlingcenters_camera {
  width: 33%;
}

.bowlingcenters_camera_title {
  margin-bottom: 10px;
  margin-top: 10px;
}

.bowlingcenters_camera .video-react-big-play-button-center {
  display: none;
}

.bowlingcenters_control {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 50px;
}
.bowlingcenters_control > * {
  margin-right: 10px;
  line-height: 50%;
  cursor: pointer;
}

.enable_stream__button {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  padding-left: 40px;
}
.stream__link {
  font-size: 14px;
  line-height: 28px;
  margin: 0 20px;
}

.bc_admin_buttons_container {
  display: flex;
  flex-direction: row;
  height: 50px;
  border-bottom: 1px solid #25292C;
}
.bc_admin_button {
  font-family: Oswald, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  height: 24px;
  width: 175px;
  cursor: pointer;
  margin-left: 21px;
}

.textGray {
  font-family: Open Sans, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: #6D7177;
  margin-bottom: 10px;
}

.bc_banners_container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.banners_bowling_centers_container{
  display: flex;
  flex-direction: column;
  width: 532px;
}
.banners_bowling_centers{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.bc_names {
  color: #FFFFFF;
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  font-style: normal;
}

.bc_names_disabled {
  color: #6E7277;
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  font-style: normal;
}

.CheckBoxContainer {
  margin-bottom: 10px;
  padding-left: 10px;
}

*#AdminActive {
  color: #f65932;
  text-decoration-line: underline;
  text-underline-offset: 12px;
}

*#AdminDisabled {
  color: #6E7277;
}
.banners_spoiler {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-decoration-line: underline;
  color: #F65932;
  cursor: pointer;
  margin-left: 15px;
}
.banners_spoiler_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}


