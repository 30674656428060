.bugsplat {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -99999999;
}
.appreloader {
  width: 250px;
  height: 40px;
  box-shadow: 0 10px 14px 0 rgba(246, 89, 50, 0.3);
  background-color: #f65932;
  font-family: "Open Sans";
  font-size: 16px;
  color: #fff;
  border: none;
}
.reloadersvg {
  margin-bottom: 35px;
  width: 100px;
  height: 97px;
}