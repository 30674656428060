.left-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
  background: linear-gradient(180deg, #20A39E 0%, rgba(32, 163, 158, 0.2) 25.84%, rgba(50, 246, 234, 0) 50%);
  width: 500px;
  margin-right: 63px;
  &__content {
    padding-top: 50px;
    margin-bottom: 50px;
    max-width: 340px;
  }
  &__title {
    font-style: normal;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    margin-bottom: 42px;
  }
  &__title-text, &__bottom-title {
    font-family: 'Oswald';
    font-size: 60px;
    line-height: 75px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    padding-bottom: 20px;
  }
  &__subtitle-text, &__bottom-text {
    font-family: 'Open Sans';
    font-size: 20px;
    line-height: 32px;
  }
  &_orange {
    color: #F65932;
  }
  &__bottom {

  }
  &__bottom-title {
    font-size: 36px;
    line-height: 53px;
  }
  &__bottom-item {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  &__bottom-dash {
    width: 19px;
    height: 3px;
    background-color: #F65932;
    margin-right: 20px;
  }
  &__bottom-text {

  }
}

@media (max-width: 768px) {
  .left-wrapper {
    width: 350px;
    margin-right: 0;
    background: linear-gradient(180deg, #20A39E 0%, rgba(32, 163, 158, 0.2) 50%, rgba(50, 246, 234, 0) 100%);

    &__content {
      padding-top: 40px;
      margin-bottom: 20px;
      padding-left: 20px;
    }

    &__title {
      margin-bottom: 20px;
    }

    &__title-text, &__bottom-title {
      font-size: 24px;
      line-height: 36px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      padding-bottom: 10px;
    }

    &__subtitle-text, &__bottom-text {
      font-size: 16px;
      line-height: 25px;
    }
    &__bottom-text {
      line-height: 22px;
    }
  }
}

@media (max-width: 599px) {
  .left-wrapper {
    width: 100%;
    &__content {
      max-width: 95%;
      padding-left: 0;
    }
  }
}