.gameResultsModal {
    overflow: hidden;
    .gaveUpStatus {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 14px;
        font-weight: 400;
        line-height: 30px;
        color: #F65932;
    }
    .infoText {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 14px;
        font-weight: 400;
        line-height: 30px;
    
        &.underline {
            border-bottom: 1px solid rgba($color: #C4C4C4, $alpha: 0.4);
        }
        .label {
            color: rgba($color: #FFFFFF, $alpha: 0.7);
            text-align: left;
        }
        .text {
            color: #FFF;
            text-align: right;
        }
    }
    
    .players {
        display: flex;
        flex-direction: column;
        background-color: #232A33;
        padding: 5px 0;
        margin: 10px 0;
        .player {
            padding: 0 12px;
            flex: 1;
        }
        .infoText {
            font-size: 12px;
            line-height: 20px;
            .label {
                color: rgba($color: #FFFFFF, $alpha: 0.6);
            }
        }
    }
    
    .onePlayerInfo {
        flex: 1;
        display: flex;
        flex-direction: row;
        .score {
            padding: 10px 8px;
            flex: 1;
            display: flex;
            flex-direction: row;
            align-items: center;
            .result {
                font-family: Oswald;
                font-style: normal;
                font-weight: 500;
                font-size: 40px;
                line-height: 45px;
                letter-spacing: 0.1em;
                color: #FFFFFF;
                margin-right: 17px;
            }
            .avatar__grm  {
                margin-right: 13px;
                .avatar__flag {
                    right: auto;
                    left: -7px;
                }
            }
        }
    }
    
    .twoPlayersScore {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-bottom: 12px;
        .result {
            font-family: Oswald;
            font-style: normal;
            font-weight: 500;
            font-size: 40px;
            line-height: 45px;
            letter-spacing: 0.1em;
            color: #FFFFFF;
            margin: 0 17px;
        }
        .leftPart {
            flex: 1;
            display: flex;
            align-items: end;
            margin-right: 15px;
            justify-content: flex-end;
						align-items: center;
						min-width: 50%;
            .playerData {
                margin-right: 10px;
								text-align: right;
								width: calc(100% - 135px);
            }
        }
        .rightPart {
            flex: 1;
            display: flex;
            flex-direction: row;
            margin-left: 15px;
            justify-content: flex-start;
						align-items: center;
						min-width: 50%;
            .playerData {
                margin-left: 10px;
								text-align: left;
								width: calc(100% - 135px);
            }
            .avatar__grm  {
                margin-right: 13px;
                .avatar__flag {
                    right: auto;
                    left: -7px;
                }
            }
        }
    }
    .twoPlayersInfo {
        flex: 1;
        display: flex;
        flex-direction: row;
    }

    .name__grm {
        color: #FFFFFF;
    }
    .login__grm {
        cursor: pointer;
        color: #F65932;
    }
    
    .winner {
        color: #F65932
    }

    .gameScore {
        margin: 12px 0;
    }
    
    .gameLabel {
        font-family: Oswald;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        text-transform: uppercase;
        margin-bottom: 10px;
        &.margin {
            margin-left: 66px;
        }
    }
    
    .avatars__grm {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 20px 0 0 0;
        margin-left: 6px;
        margin-right: 20px;
        .avatar {
            margin-bottom: 5px;
        }
    }
    
    .avatar__grm {
        .avatar{
            height: 40px;
            width: 40px;
            border-radius: 50%;
            min-width: 40px;
            img {
                width: 36px;
            }
            .avatar__flag {
                width: 16px;
                height: 16px;
            }
        }
        &.green__grm {
            .avatar{
                background: linear-gradient(#20A39E, #01677E);
            }
        }
        &.orange__grm {
            .avatar{
                background: linear-gradient(#F65932, #F59C86);
            }
        }
    }
    
    
    .games {
        margin: 25px 0 0 0;
        color: rgba($color: #FFFFFF, $alpha: 0.4);
        line-height: 19px;
        display: flex;
        flex-direction: row;
        
        .game {
            display: block;
            width: 60px;
            text-align: center;
            &.solo {
                width: 90px;
            }
        }
        .total {
            width: 100px;
            text-align: center;
            color: #FFFFFF;
            opacity: 1;
        }
        .gameResult {
            border-left: 1px solid #25292C;
            margin-bottom: 5px;
            vertical-align: middle;
            line-height: 40px;
            height: 40px;
            font-weight: 700;
            color: #FFFFFF;
            &.playerA {
                background-color: #144D50;
            }
            &.playerB {
                background-color: #692E25;
            }
            &.handicap {
                position: relative;
                &::after {
                    content: 'HDC';
                    position: absolute;
                    top: 0;
                    left: -8px;
                    font-size: 12px;
                    line-height: 12px;
                    font-weight: 600;
                    color: white;
                    transform: rotate(-90deg) translateX(-50%);
                }
            }
            &.handicap-view:hover {
                cursor: pointer;
                opacity: 0.8;
            }
        }
        .winner {
            color: #F65932
        }
    }
    
    .frames {
        display: flex;
        flex-direction: row;
        text-align: center;
    
        .frame__gs {
            .bordered {
                border-left: 1px solid #25292C;
            }
            .opponent1 {
                margin-top: 5px;
            }
            width: 49px;
            
            &.wide {
                width: 101px;
            }
            &.total {
                .frameNum {
                    color: #FFFFFF;
                    opacity: 1;
                }
                .frameBody {
                    font-weight: 700;
                }
            }
            .frameNum {
                color: rgba($color: #FFFFFF, $alpha: 0.4);
                background-color: #343D49;
                height: 20px;
                line-height: 20px;
            }
            .frameBody {
                height: 30px;
                background-color: #D2D4D8;
                white-space: break-spaces;
                color: #25292C;
                font-size: 16px;
                line-height: 30px;
            }
            .frameScore {
                color: #FFFFFF;
                font-weight: 700;
                height: 20px;
                line-height: 20px;
                &.green1 {
                    background-color: #144D50;
                }
                &.orange {
                    background-color: #692E25;
								}
								&.handicap {
									position: relative;
									&::after {
										content: 'HDC';
										position: absolute;
										top: -2px;
										left: 0;
										font-size: 8px;
										line-height: 8px;
										font-weight: 600;
										color: white;
										transform: rotate(-90deg) translateX(-50%);
									}
								}
            }
        }
        
    }    
}
